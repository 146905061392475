const initialState = {
    grade: [],
    gradeSchool: [],
    gradeCollege: [],
    gradeLifeLong: [],
    loader: false,
    otp: null,
    validation: false,
    error: false,
    valid: null,
    emailValidation: { result: null, message: null },
    studentNumberAvail: null,
    teacherNumberAvail: null,
    refferalValidation: { result: null, message: null },
    validateOtpLoader: false,
    validateOtpValidateLoader: false,
    validateOtpLoaderT: false,
    validateOtpValidateLoaderT: false,
    refferalValidationLoader: false,
    degree: null,
    specialization: null,
    subscriptionStatus:null,
    subscriptionLoader:false,
    enquiryData:[],
}

const signUpReducer = (state = initialState, { type, payload }) => {
    console.log("signup..",payload);
    switch (type) {


        case 'GRADE_SUCCESS_SCHOOL': {

            return {
                ...state, loader: false, gradeSchool: payload,loadgrade:false

            }

        }
        case 'GRADE_REQUEST':{
            return{
                ...state,loadgrade:true

            }
        }
        case 'GRADE_SUCCESS_COLLEGE': {

            return {
                ...state, loader: false, gradeCollege: payload,loadgrade:false

            }

        }
        case 'GRADE_FAILED':{
            return{
                ...state,loadgrade:false

            }
        }

        case 'GRADE_SUCCESS_LIFE_LONG_SUCCESS': {

            return {
                ...state, loader: false, gradeLifeLong: payload

            }

        }
        case 'DEGREE_SUCCESS': {
            return {
                ...state, degree: payload.grades
            }
        }
        case 'OTP_RECEIVED': {
            console.log("payloooooo", payload)
            return {
                ...state, loader: false, otp: payload.result

            }
        }
        case 'OTP_VALIDATE_REQUEST': {
            return {
                ...state, validateOtpValidateLoader: true
            }
        }
        case 'OTP_VALIDATION_SUCCESS': {

            return {
                ...state, loader: false, validation: payload.result, valid: payload.result, validateOtpValidateLoader: false

            }
        }
        case 'OTP_VALIDATION_FAILED': {
            return {
                ...state, validateOtpValidateLoader: false

            }
        }
        case 'EMAIL_VALIDATION_SUCCESS': {

            return {
                ...state, emailValidation: payload

            }
        }
        case 'STUDENT_NUMBER_VALIDATE_REQUEST': {
            return {
                ...state, validateOtpLoader: true
            }
        }
        case 'STUDENT_NUMBER_VALIDATE_SUCCESS': {

            return {
                ...state, studentNumberAvail: payload.result, validateOtpLoader: false

            }
        }
        case 'TEACHER_NUMBER_VALIDATE_REQUEST': {
            return {
                ...state, validateOtpLoaderT: true

            }
        }
        case 'TEACHER_NUMBER_VALIDATE_SUCCESS': {

            return {
                ...state, teacherNumberAvail: payload.result, validateOtpLoaderT: false

            }
        }

        case 'REGISTER_STUDENT_REQUEST': {

            return {
                ...state, loader: true


            }
        }


        case 'REGISTER_STUDENT_SUCCESS': {

            return {
                ...state, emailValidation: { result: null, message: null }, loader: false,
                otp: null,
                validation: false,
                error: false,
                valid: null,
                emailValidation: { result: null, message: null },
                studentNumberAvail: null,
                teacherNumberAvail: null


            }
        }
        case 'REGISTER_STUDENT_FAILED': {
            return {
                ...state, loader: false

            }
        }
        case 'VALIDATE_REFFERAL_REQUEST': {
            return {
                ...state, refferalValidationLoader: true
            }
        }
        case 'VALIDATE_REFFERAL_SUCCESS': {
            return {
                ...state, refferalValidation: payload, refferalValidationLoader: false
            }
        }
        case 'VALIDATE_REFFERAL_FAILED': {
            return {
                ...state, refferalValidationLoader: false
            }
        }
        case 'RESET_GRADE': {
            return {
                ...state, grade: [],
                gradeSchool: [],
                gradeCollege: [],
                gradeLifeLong: [],
            }
        }
        case 'RESET_SIGNUP_STORE': {
            return initialState
        }
        
        case 'REGISTER_SUBSCRIPTION': {
            console.log("=============",payload);
            return {
                ...state, subscriptionStatus: payload,subscriptionLoader: false
            }
        }
        case 'SUBSCRIPTION_SUCCESS': {
            console.log("=============",payload);
            return {
                ...state, subscriptionStatus: payload,subscriptionLoader: false
            }
        }
        case 'REGISTER_ENQUIRY': {
            return {
                ...state, subscriptionLoader: true
            }
        }
        case 'ENQUIRY_SUCCESS': {
            return {
                ...state, enquiryData: payload,subscriptionLoader: false
            }
        }
        case 'RESET_GRADE': {
            return {
                ...state, grade: [],
                gradeSchool: [],
                gradeCollege: [],
                gradeLifeLong: [],
            }
        }
        
        default:
            return state
    }

}

export default signUpReducer