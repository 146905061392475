import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import Slider from "react-slick";
import { getGradeList, populateDegree, requestOtp, validatingOtp, registerStudent, verifyEMAIL, mobileAvailability } from '../../redux/Actions/signUp'
import { useDispatch, useSelector } from 'react-redux';
import { emailValidator, passwordValidator, cpasswordValidator, checkBoxValidtor, nameValidator, mobileValidator } from '../../utilities/Validator'
// import LaddaButton, {

//     ZOOM_IN

// } from 'react-ladda';
// import Loader from 'react-loader'
import moment from 'moment-timezone'

import './sty.scss'
import bg3 from '../../assets/utils/images/originals/f1a.jpg';

import { Col, Row } from 'reactstrap';

import logo from '../../assets/utils/images/logo-inverse.png'
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Step1 from './Step1'
import Step2 from './Step2'
import MultiForm from './Wizard'
// import { populateGrade } from "../../redux/Actions/homeTeacher";
// import Echo from 'laravel-echo';


const Register = () => {

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true,

    }
    const dispatch = useDispatch();

    useEffect(() => {
        const tokenData = JSON.parse(localStorage.getItem("authDetails"));
        if (tokenData) {
            history.push('/dashboard')
        }
        // dispatch(getGradeList())
        return () => {
            dispatch({ type: 'RESET_SIGNUP_STORE' })
        }
    }, [dispatch])

    const Grades = useSelector(({ signUp }) => signUp)
    // const [initialState, setInitialState] = useState({
    //     first_name: null,
    //     last_name: null,
    //     email: null,
    //     grade_id: "",
    //     password: null,
    //     parent_mobile: '',
    //     type: "student",
    //     termsAndCondition: false,
    //     timezone: moment.tz.guess(),
    //     education_type: "",
    //     specialization_id: "",
    //     degree_id: ""

    // })
    const [inputField, setInputField] = useState({
        first_name: null,
        last_name: null,
        email: null,
        grade_id: "",
        password: null,
        parent_mobile: '',
        type: "student",
        termsAndCondition: false,
        timezone: moment.tz.guess(),
        education_type: "",
        specialization_id: "",
        degree_id: ""


    });


    const onChng = (e) => {
        console.log(e + ' Anil123')
        setInputField({ ...inputField, parent_mobile: e })
    }
    const handleChangeTerm = () => {
        setInputField({ ...inputField, termsAndCondition: !inputField.termsAndCondition })

    }
    const handleChange = (e) => {

        const { name, value } = e.target;
        console.log("eeeeeeeee-------", value)

        if (name === 'teacher_mobile') {
            if (value === '') {
                setInputField({ ...inputField, [name]: value })
            }
            if (!Number(value)) {
                console.log("not number")
            }
            else {
                console.log("is numb")
                setInputField({ ...inputField, [name]: value })
            }
        }
        else {
            console.log("not teacher fil")
            setInputField({ ...inputField, [name]: value })
        }
        console.log({ inputField })
    }
    const verifyEmail = (e) => {
        console.log(e.target.value)
        if (e.target.value) {
            dispatch(verifyEMAIL(e.target.value))
        }
    }

    const verifyMobile = () => {

        dispatch(mobileAvailability(inputField.parent_mobile, sendOtp))

    }
    const sendOtp = () => {
        dispatch(requestOtp(inputField.parent_mobile))
    }
    const [otp, setOtp] = useState()
    const handleChangeOTP = (e) => {
        setOtp(e.target.value)
    }

    const validateOtp = () => {
        dispatch(validatingOtp(inputField.parent_mobile, otp, callback2))
    }
    const callback2 = () => {
        setErrorz({ ...errorz, mobileValidated: true })
    }
    const [passCheck, setPassCheck] = useState();

    const confirmPass = (e) => {
        const { value } = e.target;
        setPassCheck(value)
    }


    const [errorzTwo, setErrorzTwo] = useState({})
    const formSubmit = (e) => {

        setErrorz({})
        console.warn("from submit")
        const errors = {
            // first_name: nameValidator(inputField.first_name),
            // last_name: nameValidator(inputField.last_name),
            email: emailValidator(inputField.email),
            password: passwordValidator(inputField.password),
            cCheckpass: cpasswordValidator(inputField.password, passCheck),
            cpassword: nameValidator(passCheck),
            // grade: nameValidator(inputField.grade_id),
            // mobile: mobileValidator(inputField.parent_mobile),
            // mobileValidated: Grades.valid,
            termsAndCondi: checkBoxValidtor(inputField.termsAndCondition)
        }

        if (
            !errors.cCheckpass &&
            !errors.email &&
            !errors.password &&
            !errors.termsAndCondi &&
            Grades.emailValidation.result

        ) {
            console.log("errorss after condition satisfied====", errors)
            dispatch(registerStudent(inputField, cb))
            setInputField({})

        } else {
            console.log("err")
            setErrorzTwo(errors)
            console.log("errrrr", errors)
        }



    }


    // const [value, setValue] = useState()
    const history = useHistory()
    const cb = () => {
        history.push('/dashboard')
    }


    const [type, setType] = useState(null)
    const handleType = (e) => {
        const { name, value } = e.target;
        setType(value)

        setInputField({ ...inputField, [name]: value })
        if (value === "schools") {
            // alert("school api")
            // dispatch(populateGrade(null))
            dispatch(getGradeList(null, 1))
            setInputField({ ...inputField, [name]: value, specialization_id: null, grade_id: null, degree_id: "empty" })
            setErrorz({ ...errorz, grade: null, specialization: null, course: null })

        }
        if (value === "college") {
            dispatch(populateDegree())
            setInputField({ ...inputField, [name]: value, specialization_id: null, grade_id: null, degree_id: null })
            setErrorz({ ...errorz, grade: null, specialization: null, course: null })

            //  alert("college api")

        } if (value === "lifeLong") {
            dispatch(getGradeList(null, 3))
            setInputField({ ...inputField, [name]: value, specialization_id: null, grade_id: null, degree_id: "null" })
            setErrorz({ ...errorz, grade: null, specialization: null, course: null })

        }

    }
    const handleDegree = (e) => {
        const { name, value } = e.target;
        console.log("gegree", name)
        setInputField({ ...inputField, [name]: value })
        dispatch(getGradeList(value, 2))

    }
    const [errorz, setErrorz] = useState({});

    const steps = [
        {
            name: 'Student Details', component: <Step1
                inputField={inputField}
                handleChange={handleChange}
                handleType={handleType}
                type={type}
                handleDegree={handleDegree}
                store={Grades}
                onChng={onChng}
                errorz={errorz}
                verifyMobile={verifyMobile}
                handleChangeOTP={handleChangeOTP}
                validateOtp={validateOtp}

            />
        },
        {
            name: 'Credential', component: <Step2
                inputField={inputField}
                handleChange={handleChange}
                errorz={errorzTwo}
                formSubmit={formSubmit}
                Grades={Grades}
                verifyEmail={verifyEmail}
                confirmPass={confirmPass}
                handleChangeTerm={handleChangeTerm}

            />
        },

    ];

    const validateForm1 = () => {
        const errors = {
            first_name: nameValidator(inputField.first_name),
            last_name: nameValidator(inputField.last_name),
            education_type: nameValidator(inputField.education_type),
            grade: nameValidator(inputField.grade_id),
            course: nameValidator(inputField.degree_id),
            specialization: nameValidator(inputField.grade_id),
            mobile: mobileValidator(inputField.parent_mobile),
            mobileValidated: Grades.valid,
        }

        if (!errors.first_name &&
            !errors.last_name &&
            !errors.grade &&
            !errors.mobile &&
            !errors.education_type &&
            !errors.course &&
            !errors.specialization &&
            Grades.valid

        ) {
            setErrorz({})

            return true

        } else {
            console.log("errors----", errors)
            setErrorz(errors)
            return false
        }
    }
    return (

        <Fragment>
            <div className="h-100 ">
                <Row className="h-100 no-gutters">
                    <Col lg="7" md="12" className="h-100 jms-h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            {/* <div className="app-logo" /> */}
                            <Link to="/">
                                <img src={logo} width="100px" height="60px" style={{ paddingBottom: '30px' }} />
                            </Link>
                            <h4>
                                <div style={{ float: 'left' }}>Student Signup</div>
                                <p style={{ float: 'right', fontSize: '14px', paddingTop: '10px' }}>Already have an account?<Link to={`/login`}>
                                    <a className="text-primary">Sign in</a>
                                </Link></p>
                            </h4>

                            <div>


                                <MultiForm
                                    steps={steps}
                                    inputField={inputField}
                                    Grades={Grades}
                                    validateForm1={validateForm1}


                                />
                                {/* <Form onSubmit={formSubmit} autocomplete="off">
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}First Name
                                                    </Label>
                                                <Input type="text" name="first_name" id="exampleNameF"
                                                    value={inputField.first_name}
                                                    placeholder="First Name..."
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.first_name ? true : false}
                                                />
                                                <FormFeedback >oops! your first name is required</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleNameL">
                                                    <span className="text-danger">*</span>
                                                    {' '} Last Name</Label>
                                                <Input type="text" name="last_name" id="exampleNameL"
                                                    placeholder="Last Name... "
                                                    value={inputField.last_name}
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.last_name ? true : false}

                                                />
                                                <FormFeedback >oops! your last name is required</FormFeedback>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    <span className="text-danger">*</span>
                                                    {' '}Email
                                                    </Label>
                                                <Input type="email" name="email" id="exampleEmail"
                                                    placeholder="Email here..."
                                                    value={inputField.email}
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => verifyEmail(e)}
                                                    valid={Grades.emailValidation.result ? true : false}
                                                    invalid={Grades.emailValidation.result === false || errorz.email ? true : false}
                                                />
                                                {errorz.email ?
                                                    <FormFeedback>oops! email id is required</FormFeedback>
                                                    : null
                                                }{
                                                    Grades.emailValidation.result === false ?
                                                        <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

                                                        : null
                                                }
                                                <FormFeedback valid>Sweet! that email id is available</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleSelect">
                                                    <span className="text-danger">*</span>
                                                    {' '}Grade</Label>
                                                <Input type="select"
                                                    name="grade_id"
                                                    id="exampleSelect"
                                                    placeholder="Email here..."
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.grade ? true : false}
                                                >
                                                    <option value={''}>Select Grade</option>
                                                    {
                                                        Grades.grade.grades ? Grades.grade.grades.map((data) => {
                                                            return (
                                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }
                                                </Input>
                                                <FormFeedback >oops!  grade is required</FormFeedback>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="examplePassword">
                                                    <span className="text-danger">*</span>
                                                    {' '}Password
                                                    </Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="examplePassword"
                                                    placeholder=" Password here..."
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.email ? true : false}
                                                />
                                                <FormFeedback >oops!  password is required</FormFeedback>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="examplePasswordRep">
                                                    <span className="text-danger">*</span>
                                                    {' '}Confirm Password
                                                    </Label>
                                                <Input type="password" name="cpassword" id="examplePasswordRep"
                                                    placeholder="Confirm Password here..."
                                                    onChange={(e) => confirmPass(e)}

                                                    invalid={errorz.cCheckpass ? true : false}
                                                />
                                                <FormFeedback>oops! the password not matching</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>

                                            <Row>
                                                <Col md={8} xs={8}>
                                                    <Label for="exampleName">
                                                        <span className="text-danger">*</span>
                                                        {' '}Mobile Number</Label>
                                                    <PhoneInput
                                                        style={{ width: '170px' }}

                                                        placeholder="Enter phone number"
                                                        value={inputField.parent_mobile}
                                                        name="parent_mobile"
                                                        disabled={Grades.valid ? true : false}
                                                        placeholder="Mobile here..."
                                                        onChange={(e) => onChng(e)}
                                                        maxLength={15}
                                                        invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
                                                    />

                                                </Col>
                                                <Col md={4} xs={4}>

                                                    <Button
                                                        style={{ borderRadius: '0px', width: '74px', fontSize: '10px', padding: '10px 10px', marginTop: '29px', marginLeft: '-8px' }}

                                                        onClick={verifyMobile}
                                                        disabled={inputField.parent_mobile ? inputField.parent_mobile.length <= 9 : null || Grades.validateOtpLoader || Grades.valid}
                                                    >
                                                        <span style={Grades.validateOtpLoader ? { visibility: 'hidden' } : null}>
                                                            {Grades.studentNumberAvail ? 'Resend' : 'Send OTP'}
                                                        </span>

                                                        <span>
                                                            {Grades.validateOtpLoader ? <Loader options={option} /> : null}

                                                        </span>
                                                    </Button>
                                                </Col>

                                            </Row>


                                            <InputGroup>



                                                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                                <span style={{ color: '#dc3545', fontSize: '80%' }}>
                                                    {Grades.studentNumberAvail === false ?
                                                        'Mobile Already Registered' : null
                                                    }
                                                    {
                                                        errorz.mobile ?
                                                            `oops! ${errorz.mobile}`
                                                            : null
                                                    }
                                                    {!errorz.mobile ? errorz.mobileValidated === null || errorz.mobileValidated === false ?
                                                        `oops! verify mobile number `
                                                        : null : null}

                                                </span>



                                            </InputGroup>
                                        </Col>
                                        <Col md={6}>

                                            {Grades.studentNumberAvail ?

                                                <FormGroup>
                                                    <Label for="exampleName">
                                                        <span className="text-danger">*</span>
                                                        {' '}Enter OTP</Label>
                                                    <InputGroup>

                                                        <Input
                                                            name="otp"
                                                            onChange={handleChangeOTP}
                                                            type="number"
                                                            invalid={Grades.valid === false || errorz.mobileValidated === null ? true : false}
                                                            valid={Grades.valid ? true : false}
                                                            readOnly={Grades.valid ? true : false}
                                                        />

                                                        <InputGroupAddon addonType="prepend"><Button
                                                            onClick={() => validateOtp()}
                                                            disabled={Grades.validateOtpValidateLoader || Grades.valid ? true : false}
                                                        >
                                                            <span style={Grades.validateOtpValidateLoader ? { visibility: 'hidden' } : null}>
                                                                Validate
                                                                 </span>
                                                            {Grades.validateOtpValidateLoader ? <Loader options={option} /> : null}
                                                        </Button>
                                                        </InputGroupAddon>
                                                        <br />
                                                        <FormFeedback>{`oops! Invalid OTP`}</FormFeedback>
                                                    </InputGroup>

                                                </FormGroup>
                                                : null

                                            }




                                        </Col>

                                    </Row>

                                    <FormGroup className="mt-3" check >


                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Input type="checkbox" name="check"
                                                    onChange={() => handleChangeTerm()}
                                                    checked={inputField.termsAndCondition}

                                                />{' '}
                                                <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>

                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Label for="exampleCheck" check style={{ paddingRight: '10px' }}> Are you a Teacher? <Link to={'teacher-register'}><a>Signup Here</a></Link></Label>

                                            </Col>
                                        </Row>


                                    </FormGroup>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h7 className="mb-0">
                                            Already have an account?{' '}

                                            <Link to={`/`}>
                                                <a className="text-primary">Sign in</a>
                                            </Link>
                                        </h7>
                                        <div className="ml-auto">


                                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                loading={Grades.loader}

                                                type="submit"
                                                data-style={ZOOM_IN}
                                                style={{ whiteSpace: 'nowrap' }}

                                            >
                                                Create Account
                                            </LaddaButton>



                                        </div>
                                    </div>
                                </Form> */}
                            </div>
                        </Col>
                    </Col>
                    <Col lg="5" className="d-lg-flex d-xs-none">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <div style={{ visibility: 'hidden' }}>
                                            <h3>Scalable, Modular, Consistent</h3>
                                            <p>
                                                Easily exclude the components you don't require. Lightweight, consistent
                                                Bootstrap based styles across all elements and components
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment >
    );

}
export default Register
