export default function NewReducer(state={},action)

{
 switch (action.type) {
    case "SET_NEWACTION_DATA": {
      return { ...state, ...action.payload };
    }
    case "NEWACTION_ALL_REQUEST": {
        return { ...state, ...action.payload, }
      }
      case "NEWACTION_ALL_SUCCESS": {
        return { ...state,  ...action.payload }
      }
      case "NEWACTION_ALL_FAILED": {
        return { ...state,...action.payload }
      }
    default: {
      return { ...state }
    }

  }
}
