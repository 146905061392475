
import axios from 'axios'
import { development } from '../../config.json'
// import { useHistory } from 'react-router-dom'     
import Swal from 'sweetalert2'




export const migrateGroupStudents = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    if (data.type == 'copy') {
        dispatch({ type: 'MIGRATE_GROUP_STUDENT_COPY_REQUEST' })

    } else {
        dispatch({ type: 'MIGRATE_GROUP_STUDENT_MOVE_REQUEST' })

    }

    axios.post(`${development.baseUrlEnte}migrateGroupStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {

                if (data.type == 'copy') {
                    //dispatch({ type: 'RESET_SOURCE_STUDENT_LIST' })
                    //dispatch({ type: 'RESET_DESTINATION_STUDENT_LIST' })

                    dispatch({ type: 'MIGRATE_GROUP_STUDENT_COPY_SUCCESS', payload: res.data })

                } else {
                    // dispatch({ type: 'RESET_SOURCE_STUDENT_LIST' })
                    // dispatch({ type: 'RESET_DESTINATION_STUDENT_LIST' })

                    dispatch({ type: 'MIGRATE_GROUP_STUDENT_MOVE_SUCCESS', payload: res.data })

                }
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Migrated  successfully',

                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'MIGRATE_GROUP_STUDENT_FAILED' })

        }
        )
}
export const populateGroupStudents = (token, group_d, type) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: group_d
    }
    if (type == 'source') {
        dispatch({ type: 'POPULATE_SOURCE_GROUP_STUDENT_REQUEST' })
    } else {
        dispatch({ type: 'POPULATE_DESTINATION_GROUP_STUDENT_REQUEST' })
    }

    axios.post(`${development.baseUrlEnte}populateGroupStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {

                if (type == 'source') {
                    dispatch({ type: 'POPULATE_SOURCE_GROUP_STUDENT_SUCCESS', payload: res.data.details })
                } else {
                    dispatch({ type: 'POPULATE_DESTINATION_GROUP_STUDENT_SUCCESS', payload: res.data.details })
                }

            }
        })
        .catch(err => {
            if (type == 'source') {
                dispatch({ type: 'POPULATE_SOURCE_GROUP_STUDENT_FAILED' })
            } else {
                dispatch({ type: 'POPULATE_DESTINATION_GROUP_STUDENT_FAILED' })
            }

        }
        )
}
export const assignTeachersToGroup = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }

    dispatch({ type: 'ASSIGN_REGISTERED_TEACHER_REQUEST' })

    axios.post(`${development.baseUrlEnte}assignTeachersToGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ASSIGN_REGISTERED_TEACHER_SUCCESS', payload: res.data.details })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Assigned  successfully',

                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'ASSIGN_REGISTERED_TEACHER_FAILED' })

        }
        )
}
export const getGroupAssignedTeachers = (token, id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: id
    }
    dispatch({ type: 'POPULATE_REGISTERED_TEACHER_REQUEST' })

    axios.post(`${development.baseUrlEnte}getGroupAssignedTeachers`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_REGISTERED_TEACHER_SUCCESS', payload: res.data.details })

            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_REGISTERED_TEACHER_FAILED' })

        }
        )
}
export const editGroup = (token, data) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }

    dispatch({ type: 'EDIT_GROUP_REQUEST' })

    axios.post(`${development.baseUrlEnte}updateGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'EDIT_GROUP_SUCCESS', payload: data })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Edit group successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'DETELE_GROUP_FAILED' })
            //console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}
export const updateMotsGroup = (token, data) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }

    dispatch({ type: 'EDIT_GROUP_REQUEST' })

    axios.post(`${development.baseUrl}updateGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'EDIT_GROUP_SUCCESS', payload: data })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Edit group successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'DETELE_GROUP_FAILED' })
            //console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}

export const deleteGroup = (token, groupID) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: groupID
    }

    dispatch({ type: 'DETELE_GROUP_REQUEST' })

    axios.post(`${development.baseUrlEnte}deleteGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DETELE_GROUP_SUCCESS', payload: groupID })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Delete group successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'DETELE_GROUP_FAILED' })
            //console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}
export const deleteMotsGroup = (token, groupID) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: groupID
    }

    dispatch({ type: 'DETELE_GROUP_REQUEST' })

    axios.post(`${development.baseUrl}deleteGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DETELE_GROUP_SUCCESS', payload: groupID })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Delete group successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'DETELE_GROUP_FAILED' })
            //console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}


export const motsCreateGroup = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    dispatch({ type: 'CREATE_GROUP_REQUEST' })

    axios.post(`${development.baseUrl}createGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'CREATE_MOTS_GROUP_SUCCESS', })

                cb()
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Group Created Successfully',

                })
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Something Went Wrong',
                })
            }

        })
        .catch(err => {
            dispatch({ type: 'CREATE_GROUP_FAILED' })
            console.log("errorrrrrrrrrrrrrrr", err)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}

export const createGroup = (token, data, cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    dispatch({ type: 'CREATE_GROUP_REQUEST' })

    axios.post(`${development.baseUrlEnte}createGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'CREATE_GROUP_SUCCESS', payload: res.data.groups[0] })

                cb()
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Create group successfully',

                })
            }

        })
        .catch(err => {
            dispatch({ type: 'CREATE_GROUP_FAILED' })
            console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}

export const populateGroup = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',

    }
    dispatch({ type: 'POPULATE_GROUP_REQUEST' })

    axios.post(`${development.baseUrlEnte}populateGroup`, null, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_GROUP_SUCCESS', payload: res.data.groups })
            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_GROUP_FAILED' })
            console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}
export const listAllMotsGroups = (token) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',

    }
    dispatch({ type: 'POPULATE_GROUP_REQUEST' })

    axios.get(`${development.baseUrl}listAllGroups`,{ headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_MOTS_GROUP_SUCCESS', payload: res.data.groups })
            }
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_GROUP_FAILED' })
            console.log("errorrrrrrrrrrrrrrr", err.response)
            // if (err) {
            //     if (err.response) {
            //         if (err.response.status === 401) {
            //             cb()
            //         }
            //     }

            // }
        }
        )
}
