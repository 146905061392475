const initialState = {
  Enterprises:[],
  EidAvilable:false
}

const superAdminReducer = (state = initialState, {type,payload}) => {
    switch (type) {
        case 'LIST_ENTERPRISE_SUCCESS':{
            return{
                ...state,Enterprises:payload
            }
        }
        case 'ENTERPRISE_ID_AVILABILITY_STATUS':{
            return{
                ...state,EidAvilable:payload
            }
        }
    
        default:
            return state;
    }
}

export default superAdminReducer