import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import ListExams from "../../common/DataTable";
import { useHistory } from "react-router-dom";
import {
  getTeacherQuestions,
  addQuestionid,
  deleteQuestionPaper,
} from "../../../redux/Actions/exam";
// import './style.scss'

const columns = [
  {
    title: "Paper Title ",
    field: "paper_title",
    headerStyle: { fontWeight: "600" },
  },
  { title: "Grade", field: "grade_name", headerStyle: { fontWeight: "600" } },
  {
    title: "Subject",
    field: "subject_name",
    headerStyle: { fontWeight: "600" },
  },
  {
    title: "Added/Questions",
    field: "question_left",
    headerStyle: { fontWeight: "600" },
  },
  {
    title: "Questions type",
    field: "question_type",
    headerStyle: { fontWeight: "600" },
  },
  {
    title: "Status",
    field: "status",
    headerStyle: { fontWeight: "600" },
    render: (rowData) => (
      <Badge
        color={rowData.status === "completed" ? "success" : "warning"}
        pill
      >
        {rowData.status}
      </Badge>
    ),
  },
];

const Exam = () => {
  const dispatch = useDispatch();

  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const examStore = useSelector(({ exam }) => exam);
  console.log("estore", examStore.listQuestionPaper);

  const grade_id = examStore?.listQuestionPaper?.grade_id;
  const grade_name = examStore?.listQuestionPaper?.grade_id;

  useEffect(() => {
    dispatch(getTeacherQuestions(tokenData.token, grade_id, grade_name));
  }, []);
  const history = useHistory();
  const addQuestion = (question_paper_id) => {
    history.push(`/dashboard/exam/addQuestions/${question_paper_id}`);
    dispatch(addQuestionid(question_paper_id));
  };
  const listQuestions = (question_paper_id) => {
    history.push(`/dashboard/exam/showQuestions/${question_paper_id}`);
    //dispatch(addQuestionid(question_paper_id))
  };
  const editQuestionPaper = (question_paper_id) => {
    //console.log("roww",rowData)
    history.push(`/dashboard/exam/editQuestionPaper/${question_paper_id}`);
  };
  const handleDeleteQuestionPaper = (question_paper_id) => {
    dispatch(deleteQuestionPaper(tokenData.token, question_paper_id, cb));
  };
  const cb = () => {
    dispatch(getTeacherQuestions(tokenData.token, grade_id, grade_name));
  };
  return (
    <>
      <ListExams
        columns={columns}
        actions={"list_question_paper"}
        title={"Question Papers"}
        loader={examStore.listQuestionPaperLoader}
        data={examStore.listQuestionPaper}
        addQuestion={addQuestion}
        listQuestions={listQuestions}
        deleteQuestionPaper={handleDeleteQuestionPaper}
        editQuestionPaper={editQuestionPaper}
      />
    </>
  );
};
export default Exam;
