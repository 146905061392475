import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router'
import { Col, Row, Card,  Label, FormFeedback, Input, Form } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './createQuestionPaper.scss'
// import PageTitle from '../../HeaderTitle/PageTitle'
import {  populateSubject,  } from '../../../redux/Actions/homeTeacher'
import { createQuestionPaper,getTeacherQuestions } from '../../../redux/Actions/exam'
import { requiredValidator, numberValidator } from '../../../utilities/Validator'
import Swal from 'sweetalert2'
import { getGradeListLifeLong } from '../../../redux/Actions/signUp'

const CreateLifeLongExam = () => {

    const dispatch = useDispatch()
    // const [teacherId, setTeacherId] = useState(null)
    // const [errors, setErrors] = useState({})
    // const { exam_id, student_id } = useParams();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [inputField, setInputField] = useState({
        title: '',
        subject_id: '',
        grade_id: '',
        total_mark: '',
        shuffle_type: 'all',
        question_type: ''
    })

    useEffect(() => {
        // dispatch(validateStartExam(startExam, tokenData.token, exam_id))
        dispatch(getGradeListLifeLong(null, 3))
        //dispatch(populateAllUserClass(tokenData.token))

    }, [])
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const Grades = useSelector(({ signUp }) => signUp)

    const handleChangeLLcourse = e => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value, subject_id: '' })
        dispatch(populateSubject(tokenData.token, value, 3))
    };
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setInputField({ ...inputField, [name]: value })
    }
    const [questionTypes, setQuestionTypes] = useState([])
    const handleQuestionType = (e) => {
        const { name, value } = e.target;
        let qs;
        if (value === "both") {
            qs = [{ name: "Subjective", value: "subjective" }, { name: "Objective", value: "objective" }]
        } else if (value === "subjective") {
            qs = [{ name: "Subjective", value: "subjective" }]
        } else if (value === "objective") {
            qs = [{ name: "Objective", value: "objective" }]
        }
        else {
            qs = []
        }
        setQuestionTypes(qs)
        setInputField({ ...inputField, [name]: value })

    }
    const [slots, setSlots] = useState([])
    const createCard = (slot) => {
        setSlots([...slots, slot])
    }
    console.log({ slots });
    const [mainError, setMainError] = useState({})
    const createPaper = () => {

        const error = {
            title: requiredValidator(inputField.title),
            subject_id: requiredValidator(inputField.subject_id),
            grade_id: requiredValidator(inputField.grade_id),
            total_mark: numberValidator(inputField.total_mark),
           // shuffle_type: requiredValidator(inputField.shuffle_type),
            question_type: requiredValidator(inputField.question_type)
        }
        if (!error.title && !error.subject_id && !error.grade_id && !error.total_mark && !error.shuffle_type && !error.question_type) {
            if (slots.length <= 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Required',
                    text: 'Please create minimum number of question type',

                })
            } else {
                const data = inputField
                data.slots = slots
                dispatch(createQuestionPaper(tokenData.token, data, resetForm))
                console.log({ data });
            }


        }
        setMainError(error)

    }
    const resetForm = () => {
        dispatch(getTeacherQuestions(tokenData.token));
        setSlots([])
        setInputField({
            title: '',
            subject_id: '',
            grade_id: '',
            total_mark: '',
            shuffle_type: '',
            question_type: ''
        })
    }
    const PreviewCard = (props) => {
        const { question_type, mark, no_of_questions, Ukey, deleteSlot } = props
        return (
            <Card style={{ backgroundColor: '#35afaf', marginBottom: 1 }}>
                <Row style={{ padding: '10px 10px ' }} >
                    < Col md={3} >
                        <p className="jms-m0 f-white align-center">
                            {question_type}
                        </p>
                    </Col>
                    <Col md={2}>
                        <p className="jms-m0 f-white align-center">
                            {mark}
                        </p>

                    </Col>
                    <Col md={1} style={{ margin: 'auto', textAlign: 'center', fontWeight: 900 }}>
                        x
                    </Col>
                    <Col md={2}>
                        <p className="jms-m0 f-white align-center">
                            {no_of_questions}
                        </p>

                    </Col>
                    <Col md={1} style={{ margin: 'auto', textAlign: 'center', fontWeight: 900 }}>
                        =
                    </Col>
                    <Col md={2}>
                        <p className="jms-m0 f-white align-center">
                            {mark * no_of_questions}
                        </p>
                    </Col>
                    <Col md={1} style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>
                        <FontAwesomeIcon onClick={() => deleteSlot(Ukey)} icon={faTrashAlt} />
                    </Col>
                </Row>

            </Card >
        )
    }
    const deleteSlot = (key) => {
        
        const afterRemove = slots.filter(slot => slot.key !== key)
        setSlots(afterRemove)
    }
    const SlotForm = (props) => {
        const { createCard } = props;
        const [slotInput, setSlotInput] = useState({
            q_type: '',
            mark: '',
            no_of_questions: '',
            key: '',
            total_mark: ''
        })
        const handleSlot = (e) => {
            const { value, name } = e.target
            setSlotInput({ ...slotInput, [name]: value })
        }
        const [errors, setErrors] = useState({})

        const createSlot = () => {
            const error = {
                q_type: requiredValidator(slotInput.q_type),
                mark: numberValidator(slotInput.mark),
                no_of_questions: numberValidator(slotInput.no_of_questions),

            }
            console.log({ error });
            setErrors(error)

            if (!error.q_type && !error.mark && !error.no_of_questions) {

                const afterAddUniqueId = slotInput
                afterAddUniqueId.key = Math.floor((Math.random() * 100) + 1)
                afterAddUniqueId.total_mark = slotInput.mark * slotInput.no_of_questions


                let sum = slots.reduce((a, { total_mark }) => a + total_mark, 0);

                sum = parseInt(sum) + parseInt(slotInput.total_mark)

                if (!inputField.total_mark) {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Required',
                        text: 'Please enter total mark first ',

                    })
                } else {
                    if (sum > inputField.total_mark) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Mark Exceed',
                            text: 'Your mark exceed than total mark ',

                        })
                    } else {
                        createCard(afterAddUniqueId)
                        setSlotInput({
                            q_type: '',
                            mark: '',
                            no_of_questions: '',
                            key: ''
                        })
                    }
                }



            }


        }

        return (
            <Card style={{ backgroundColor: 'rgb(120 204 204)', margin: ' 20px', marginBottom: 10 }}>

                <Row style={{ padding: 40 }} >
                    <Col md={10}>
                        <p style={{ color: '#495259', fontWeight: 600, marginBottom: 0 }}>QUESTION TYPE</p>

                    </Col>
                    <Col md={2}>
                        <p style={{ color: 'red', fontWeight: 600 }}>Marks : {`${slots.reduce((a, { total_mark }) => a + total_mark, 0)} ${inputField.total_mark ? "/" + inputField.total_mark : ""}`}</p>


                    </Col>
                    <Col md={12}>
                        <hr />
                    </Col>
                    <Col md={3}>

                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Question Type
                                </Label>
                        <Input
                            type="select"
                            name="q_type"
                            onChange={handleSlot}
                            invalid={errors.q_type}
                        >
                            <option value={""}>{"Select Type"}</option>
                            {
                                questionTypes.map(item =>
                                    <option value={item.value}>{item.name}</option>
                                )
                            }


                        </Input>
                        <FormFeedback >{errors.q_type}</FormFeedback>
                    </Col>
                    <Col md={2}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Mark
                                </Label>
                        <Input
                            onChange={handleSlot}
                            invalid={errors.mark}

                            type="text"
                            name="mark"
                        />
                        <FormFeedback >{errors.mark}</FormFeedback>
                    </Col>
                    <Col md={1} style={{ margin: 'auto', textAlign: 'center', fontWeight: 900 }}>
                        <Label for="exampleTime" style={{ visibility: 'hidden' }}>bt</Label><br /> X
                    </Col>
                    <Col md={2}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}No. of Questions</Label>
                        <Input
                            onChange={handleSlot}
                            invalid={errors.no_of_questions}
                            type="text"
                            name="no_of_questions"
                        />
                        <FormFeedback >{errors.no_of_questions}</FormFeedback>
                    </Col>
                    <Col md={1} style={{ margin: 'auto', textAlign: 'center', fontWeight: 900 }}>
                        <Label for="exampleTime" style={{ visibility: 'hidden' }}>bt</Label><br />

                                =
                            </Col>
                    <Col md={2}>
                        <Label for="exampleNameF" >

                            {' '}Total
                                </Label>
                        <Input
                            type="text"
                            value={slotInput.mark * slotInput.no_of_questions}
                        />
                        <FormFeedback >{"error.dfs"}</FormFeedback>
                    </Col>
                    <Col md={1}>
                        <Label for="exampleTime" style={{ visibility: 'hidden' }}>bt</Label><br />
                        {/* <Button style={{ width: '-webkit-fill-available' }} color="danger" onClick={() => createCard()}>Create time slot</Button> */}


                        <LaddaButton className="mb-2 mr-2 btn "
                            // loading={loader}
                            style={{ width: 'auto', backgroundColor: 'black', color: 'white' }}
                            onClick={() => createSlot()}
                            type="button"
                            data-style={ZOOM_IN}

                        >
                            Add
                                            </LaddaButton>
                    </Col>
                </Row>

            </Card >
        )
    }
    return (
        <>
        <Form onSubmit={"submitForm"} id="class-form" >
        <Card style={{ margin: '32px 29px 10px 29px' }}>
            <Row style={{ padding: '40px' }}>
                <Col md={12}>
                    <p style={{ color: '#495259', fontWeight: 600 }}>GENERAL DETAILS</p>
                    <hr />
                </Col>
                <Col md={8} style={{ paddingRight: '29px', borderRight: '1px solid #d4d0d0' }}>
                    <Row>

                        <Col md={12} className="jms-p5">
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Title
                  </Label>
                            <Input
                                onChange={handleChangeInput}
                                type="text"
                                name="title"
                                invalid={mainError.title}
                                value={inputField.title}
                            />
                            <FormFeedback >{mainError.title}</FormFeedback>
                        </Col>
                        <Col md={6} className="jms-p5">
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Class
                  </Label>
                            <Input type="select"
                                onChange={(e) => handleChangeLLcourse(e)}
                                name='grade_id'
                                //required

                                invalid={mainError.grade_id}

                                value={inputField.grade_id}
                            >

                                <option value={''}>{'select'}</option>
                                {Grades.gradeLifeLong.grades?
                                    Grades.gradeLifeLong.grades.map(grade => {
                                        return (
                                            <option value={grade.grade_id}>{grade.grade_name}</option>
                                            //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                        )
                                    }) : []
                                }

                            </Input>
                            <FormFeedback >{mainError.grade}</FormFeedback>
                        </Col>
                        <Col md={6} className="jms-p5">
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Subject
                 </Label>
                 <div class="form-group">
                                        <div class="input-group">
                            <Input type="select"
                                name='subject_id'
                                //required
                                onChange={handleChangeInput}
                                value={inputField.subject_id}
                                invalid={mainError.subject_id}
                            >
                                <option value={''}>{'select'}</option>

                                {tStore.subject ?
                                    tStore.subject.map(subj => {
                                        return (
                                            <option value={subj.subject_id}>{subj.subject_name}</option>

                                        )
                                    }) : null
                                }

                            </Input>
                            {
                                tStore.loadersub ? 
                                <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                                </span>
                                :
                                null
                            }
                            
                            </div>
                            </div>
                            <FormFeedback >{mainError.subject}</FormFeedback>
                        </Col>
                       
                    </Row>
                </Col>
                <Col md={4} style={{ paddingLeft: 29 }}>
                    <Row>
                        <Col md={12} className="jms-p5">
                        <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Total Mark
         </Label>
                    <Input
                        onChange={handleChangeInput}
                        invalid={mainError.total_mark}
                        type="text"
                        name="total_mark"
                        value={inputField.total_mark}
                    />
                    <FormFeedback >{mainError.total_mark}</FormFeedback>
                        </Col>
                        <Col md={12} className="jms-p5">
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Question Type
                   </Label>
                            <Input
                                onChange={handleQuestionType}
                                value={inputField.question_type}
                                invalid={mainError.question_type}
                                type="select"
                                name="question_type"
                            >
                                <option value={""}>{"Select type"}</option>
                                <option value={"objective"}>{"Objective"}</option>
                                <option value={"subjective"}>{"Subjective"}</option>
                                <option value={"both"}>{"Objective & Subjective"}</option>
                            </Input>
                            <FormFeedback >{mainError.question_type}</FormFeedback>
                        </Col>
                    </Row>
                </Col>

            </Row >
        </Card>
        <hr />
        <SlotForm
            createCard={createCard}
        />

        <Row style={{ padding: 29 }}>
            {slots.map(slot =>
                <Col md={12}>
                    <PreviewCard
                        mark={slot.mark}
                        question_type={slot.q_type}
                        no_of_questions={slot.no_of_questions}
                        Ukey={slot.key}
                        deleteSlot={deleteSlot}
                    />
                </Col>)}
        </Row>
        <Row style={{ padding: 29 }}>
            <Col md={12}>
                <LaddaButton className="mb-2 mr-2 btn "
                    // loading={loader}
                    style={{ width: 'auto', backgroundColor: '#e44848', color: 'white' }}
                    onClick={() => createPaper()}
                    type="button"
                    data-style={ZOOM_IN}

                >
                    Create Question Paper
                </LaddaButton>
            </Col>
        </Row>
    </Form>
 </>

    )

}
export default CreateLifeLongExam