import React, { Fragment, useState } from 'react';
import { Row, Col, Button } from 'reactstrap'
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'

import './updatestyle.scss'
import tag from './tag.png';
import tagGray from './tagGray.png'
import { useDispatch } from 'react-redux'
import { updatePaymentStatus, basicPlanMigrate,logout } from '../../redux/Actions/auth'
import { development } from '../../config.json'
import axios from 'axios'
import Modals from '../common/Modal'
import animationData from './animation/check-circle.json'
import Lottie from 'react-lottie';
import errorAnimation from './animation/error.json'


const loadScript = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}



const PriceList = (props) => {
    const { data, changePlan } = props;
    const dispatch = useDispatch()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [successModal, setSuccessModal] = useState({open:false})
    const [failModal, setFailModal] = useState(false)
    // const url = window.location.href.split('/')
    // console.log("const url = props.location.pathname.split('/')",url)
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successModal.open ? animationData :failModal? errorAnimation:null,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }
    
const checkUpgrade=(plan_id)=>{

if(plan_id<tokenData.plan_id){

  Swal.fire({
    icon: 'warning',
                title: 'Warning',
        text: "All you previous classes will be deleted",
        input: 'checkbox',
//   inputOptions: inputOptions,
  inputPlaceholder: 'I agree with you',
  inputValidator: (value) => {
    if (!value) {
      return 'You need to agree to change plan'
    }
    //reactLocalStorage.set('conference', value);
    
},
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Downgrade'
      }).then((result) => {

                    if (result.value) {
                        displayRazorpay(plan_id)
    
                    }
    
             
                })

    // Swal.fire({
    //             icon: 'warning',
    //             title: 'Warning',
    //             text: 'may features will be change',
    //             confirmButtonText: 'Downgrade',
    //             showCancelButton: true,
    //             confirmButtonColor: '#3085d6',
    //             cancelButtonColor: '#d33',

    //         }).then((result) => {

    //             if (result.value) {
    //                 displayRazorpay(plan_id)

    //             }

         
    //         })

        
}else{
    displayRazorpay(plan_id)

}
}
const [choosePlan,setChoosePlan]=useState({loader:false,plan_id:''})
    const displayRazorpay = async (plan_id) => {

        setChoosePlan({loader:true,plan_id:plan_id})
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        console.log("loding$$$$$$....",res)
        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?')
            setChoosePlan({loader:false,plan_id:''})
            return
        } else {
            console.log("res", res)
        setTimeout(()=>
        setChoosePlan({loader:false,plan_id:''})
        ,6000
        )

        }
        try {
            const order = await axios.post(`${development.baseUrl}payment`, { plan_id: plan_id }, {
                headers: { Accept: 'application/json' },

                headers: { Authorization: `Bearer ${tokenData.token}` }

            }).then((respon) => {
                if (respon.data.result) {
                    const options = {
                        key: 'rzp_test_ro6tnznEjwDx1J',
                        currency: respon.data.order_details.currency,
                        amount: respon.data.order_details.amount.toString(),
                        order_id: respon.data.order_details.order_id,
                        name: 'Donation',
                        description: 'Thank you for nothing. Please give us some money',
                        image: 'http://localhost:1337/logo.svg',
                        handler: function (response) {
                            // alert("hi")
                            // alert(response.razorpay_payment_id)
                            // alert(response.razorpay_order_id)
                            // alert(response.razorpay_signature)
                            const details = {
                                razorpay_payment_id: response.razorpay_payment_id,
                                razorpay_order_id: response.razorpay_order_id,
                                razorpay_signature: response.razorpay_signature,
                                tnx_id: respon.data.order_details.tnx_id
                            }
                            dispatch(updatePaymentStatus(tokenData.token, details, paymentStatus))
                        },
                        prefill: {
                            // name,
                            email: 'sdfdsjfh2@ndsfdf.com',
                            phone_number: '9899999999'
                        }
                    }
                    const paymentObject = new window.Razorpay(options)
                    paymentObject.open()
                }

            }
                // t.data.order_details.json()

            )
            console.log("order----",order)

        } catch (err) {
            console.log(err)
        }





    }
const basicPlan=(plan_id)=>{
    




    if(plan_id<tokenData.plan_id){

        Swal.fire({
            icon: 'warning',
                        title: 'Warning',
        
                        text: "All you previous classes will be deleted",

            input: 'checkbox',
    //   inputOptions: inputOptions,
      inputPlaceholder: 'I agree with you',
      inputValidator: (value) => {
        if (!value) {
            return 'You need to agree to change plan'

        }
        //reactLocalStorage.set('conference', value);
        
    },
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Downgrade'
          }).then((result) => {
    
                        if (result.value) {
                                dispatch(basicPlanMigrate(tokenData.token,plan_id,basicPlanSuccess))

        
                        }
        
                 
                    })

        // Swal.fire({
        //             icon: 'warning',
        //             title: 'Warning',
        //             text: 'may features will be change',
        //             confirmButtonText: 'Downgrade',
        //             showCancelButton: true,
        //             confirmButtonColor: '#3085d6',
        //             cancelButtonColor: '#d33',
    
        //         }).then((result) => {
    
        //             if (result.value) {
        //                 dispatch(basicPlanMigrate(tokenData.token,plan_id,basicPlanSuccess))

    
        //             }
    
             
        //         })
    
            
    }else{
        dispatch(basicPlanMigrate(tokenData.token,plan_id,basicPlanSuccess))

    
    }



}
const basicPlanSuccess=(res)=>{
    if (res.result) {
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Plan choosed successfully',
            //confirmButtonText: 'Downgrade',
            //showCancelButton: true,
            //confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',

        }).then((result) => {

            if (result.value) {
                dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})
                dispatch({type:'CHANGE_PLAN',payload:1})



            }
// alert(result.value)
     
        })


    } else {
        Swal.fire({
            icon: 'erroe',
            title: 'Failed',
            // text: 'Plan choosed failed',
            //confirmButtonText: 'Downgrade',
            //showCancelButton: true,
            //confirmButtonColor: '#3085d6',
            //cancelButtonColor: '#d33',

        }).then((result) => {

            if (result.value) {
                dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})



            }
// alert(result.value)
     
        })
    }
}
    const paymentStatus = (res) => {

        if (res.result) {
            setSuccessModal({open:true,res})


        } else {
            setFailModal(true)
        }
        // alert("success")
    }
    const history = useHistory();

    const signout = () => {
        localStorage.removeItem("authDetails");
        history.push('/')
        dispatch(logout())
    }
    const closeSuccessModal=()=>{
        dispatch({type:'PAYMENT_SUCCESSFULLY_COMPLETED'})
        setSuccessModal({...successModal,open:false})
    }
    console.log("successmodallllll",successModal)
    return (
        <Fragment>
            <section id="price" class="plan-section" style={{ padding: '20px 60px 25px' }}>
                <div className="container-fluid">
                    <div className="col-lg-12">
                        <div className="left-plan">
                            <h3 className="hh3">Plans & Pricing<br />
                                <span>great explorer</span>
                            </h3>
                            <p>All plans,for FIRST month are FREE and all features are open</p>
                            {/* <a class="button-blue-big" href="#">Plan</a> */}
                            <div className="clearfix"></div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div onClick={() => changePlan(1)} className="platinum basic jms-basic" style={data.plan_id !== 1 ? { backgroundColor: '#c7c7c7' } : null}>
                                <div className="plan-hdr"
                                // style={data.plan_id !== 1 ? { backgroundColor: '#ababab' } : null}
                                 >
                                    <h5 class="hh5">Basic</h5>
                                    <img className="img-fluid tag-price" src={data.plan_id !== 1 ? tagGray:tag} />
                                </div>

                                <div className="data-list">
                                    <h6>Free</h6>

                                    <ul>
                                        <li>10 concurrent students </li>
                                        <li>8 classes / mo </li>
                                        <li>Class duration : 2hr / class</li>

                                    </ul>
                                    <button onClick={() => basicPlan(1)} className="button-blue-big basic-btn" style={data.plan_id !== 1 ? { backgroundColor: ' #909090' } : null}>
                                        Choose plan</button>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div onClick={() => changePlan(2)} className="platinum gold jms-silver" style={data.plan_id !== 2 ? { backgroundColor: '#c7c7c7' } : null}>
                                <div className="plan-hdr silver" 
                                // style={data.plan_id !== 2 ? { backgroundColor: '#ababab' } : null}
                                >
                                    <h5 class="hh5">Silver</h5>
                                    <img className="img-fluid tag-price" src={data.plan_id !== 2 ? tagGray:tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$10 / mo </h6>

                                    <ul>
                                        <li>20 concurrent students</li>
                                        <li>15 classes / mo</li>
                                        <li>Class duration : 3hr / class</li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring</li>
                                        <li>Recording up to 1GB </li>
                                    </ul>
                                    <button onClick={() => checkUpgrade(2)} className="button-blue-big silver-btn" style={data.plan_id !== 2 ? { backgroundColor: ' #909090' } : null}>
                                       {choosePlan.loader===true&&choosePlan.plan_id===2?'Loading.....':'Choose Plan'}
                                    </button>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div onClick={() => changePlan(3)} className="platinum gold silver jms-gold" style={data.plan_id !== 3 ? { backgroundColor: '#c7c7c7' } : null}>
                                <div className="plan-hdr jms-hdr" 
                               // style={data.plan_id !== 3 ? { backgroundColor: '#ababab' } : null}
                                >
                                    <h5 class="hh5">Gold</h5>
                                    <img className="img-fluid tag-price" src={data.plan_id !== 3 ? tagGray:tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$15 / mo</h6>

                                    <ul>
                                        <li>40 concurrent students </li>
                                        <li>Unlimited number of classes </li>
                                        <li>No duration limit </li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring </li>
                                        <li>Recording up to 1GB</li>
                                    </ul>
                                    <button onClick={() => checkUpgrade(3)} className="button-blue-big gold-btn" href="/teacher-register" style={data.plan_id !== 3 ? { backgroundColor: ' #909090' } : null}>
                                    {choosePlan.loader===true&&choosePlan.plan_id===3?'Loading.....':'Choose Plan'}
                                    </button>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div onClick={() => changePlan(4)} className="platinum jms-platinum" style={data.plan_id !== 4 ? { backgroundColor: '#c7c7c7' } : null}>
                                <div className="plan-hdr" 
                                // style={data.plan_id !== 4 ? { backgroundColor: '#ababab' } : null} 
                                >
                                    <h5 class="hh5">Platinum</h5>
                                    <img className="img-fluid tag-price" src={data.plan_id !== 4 ? tagGray:tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$25 / mo</h6>

                                    <ul>
                                        <li>100 concurrent students</li>
                                        <li>Unlimited number of classes </li>
                                        <li>No duration limit </li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring</li>
                                        <li>Recording up to 2.5GB</li>
                                    </ul>
                                    <button onClick={() => checkUpgrade(4)} className="button-blue-big platinum-btn" style={data.plan_id !== 4 ? { backgroundColor: ' #909090' } : null}>
                                    {choosePlan.loader===true&&choosePlan.plan_id===4?'Loading.....':'Choose Plan'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !window.location.href.includes("my-account")?
                    <Row>
                    <Col md={12} style={{textAlign: 'center',paddingTop: '20px'}}>
                        <Button type="button" style={{width:128}}onClick={() => signout()} color="danger">
                           
                                No,Thanks
                          

                        </Button>
                    </Col>
                </Row>
                :
                <Row>
                    <Col md={12} style={{textAlign: 'center',paddingTop: '20px'}}>
                        <Button type="button" style={{width:128}}onClick={() => closeSuccessModal()} color="danger">
                           
                               Close
                          

                        </Button>
                    </Col>
                </Row>
                }
                
            </section>
            <Modals
                open={successModal.open}
                // open={true}
                handleClose={closeSuccessModal}
                // title={`Time shedule`}
                size="sm"
            >

                <Row>
                    <Col md={12}>
                        <Lottie options={defaultOptions}
                            height={145}
                            width={145}
                        //isStopped={this.state.isStopped}
                        //isPaused={this.state.isPaused}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col md={12} style={{ fontSize: '20px', color: 'green', textAlign: 'center' }}>
                        Success
                    </Col>

                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'center', color: '#5e8e5f' }}>
                        <strong style={{ fontWeight: 400 }}>Payment completed successfully</strong>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'center', paddingTop: 25 }}>
                        <strong >Transaction ID</strong>
                    </Col>
                    <Col md={12} style={{ textAlign: 'center' }}>
                        <p style={{ textAlign: 'center' }}>{successModal.res&&successModal.res.tnx_id}</p>
                    </Col>
                    <Col md={12} style={{ textAlign: 'center' }}>
                        <Button type="button" onClick={closeSuccessModal} color="success">Okay</Button>
                    </Col>
                </Row>

            </Modals>
            <Modals


                open={failModal}
                // open={true}
                handleClose={() => setFailModal(false)}
                // title={`Time shedule`}
                size="sm"
            >
                <Row>
                    <Col md={12}>
                        <Lottie options={defaultOptions}
                            height={145}
                            width={145}
                        //isStopped={this.state.isStopped}
                        //isPaused={this.state.isPaused}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col md={12} style={{ fontSize: '20px', color: '#c0392b', textAlign: 'center' }}>
                        Failed
                    </Col>

                </Row>
                <Row>
                    <Col md={12} style={{ textAlign: 'center', color: '#ab2d20' }}>
                        <strong style={{ fontWeight: 400 }}>Payment failed </strong>
                    </Col>
                </Row>
                <Col md={12} style={{ textAlign: 'center', paddingTop: 25 }}>
                    <Button type="button" onClick={() => setFailModal(false)} color="danger">Okay</Button>
                </Col>
            </Modals>
        </Fragment>
    );
}
export default PriceList;
