import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap'
import Table from './table';
import { useDispatch, useSelector } from 'react-redux'
import { populateAllVideoClass, populateVideoClassStudents, changeStatusOfVideoClass } from '../../../redux/Actions/playlist'
import { getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'

import { useHistory } from "react-router-dom";
import Modals from '../../common/Modal'
import AssignStudents from './assignStudents'
const columns = [
    { title: '#', field: 'tableData.id' },
    {
        title: 'Teacher_Name ', field: 'teacher_name',
    },
    {
        title: 'Class ', field: 'video_class_name',
    },
    { title: ' Grade', field: 'grade_name' },
    { title: 'Subject', field: 'subject_name' },
    // {

    //     render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Button color="danger" pill>{props.name}</Button></>
    // }

]

const columnsteacher = [
    { title: '#', field: 'tableData.id' },
   
    {
        title: 'Class ', field: 'video_class_name',
    },
    { title: ' Grade', field: 'grade_name' },
    { title: 'Subject', field: 'subject_name' },
    // {

    //     render: rowData => <>{rowData.title}&nbsp;&nbsp;&nbsp;<Button color="danger" pill>{props.name}</Button></>
    // }

]

const VideoClass = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const videoStore = useSelector(({ playlist }) => playlist)
    
    console.log("videoo list", videoStore);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    useEffect(() => {
        dispatch(populateAllVideoClass(tokenData.token))

        

        return () => {

        }
    }, [])

    const onVideopress = (classId) => {
        console.log("videooooossss", classId);
        history.push(`/dashboard/videolist/${classId}`)
    }
    const [openAssignModal, setAssignModal] = useState(false)
    const [classID, setClassID] = useState()
    const hadleOpenStudentModal = (classId, gradeId) => {
        if (tokenData.enterpriseUser) {
            dispatch(getTeacherAssignedGroups(tokenData.token, classId, "", "video"))

        } else {
            dispatch(populateVideoClassStudents(tokenData.token, classId, gradeId))

        }
        setClassID(classId)
        setAssignModal(true)

        // setTimeout(() => {
        //     setAssignModal(true)
        // }, 500);

    }
    const hadleCloseStudentModal = () => {
        dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" }); //Resetting specific student and group
        dispatch({ type: "RESET_GROUP_LIST" });
        setAssignModal(false)

    }
    const editClass = (class_id, subject_id) => {
        history.push(`/dashboard/videoClass/edit/${class_id}/${subject_id}`)

    }

    const changeStatus = (class_id, status, teacher_id) => {
        let activeClass = status
        if (activeClass === 1) {
            activeClass = 0
        } else {
            activeClass = 1
        }


        dispatch(changeStatusOfVideoClass(tokenData.token, class_id, activeClass, teacher_id, cb))
    }
    const cb = () => {
        dispatch(populateAllVideoClass(tokenData.token))

    }
    // console.log("tStore.specificGroup", tStore.specificGroup);
    // console.log({ tStore });

    return (
        <>
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    {
                        tokenData.role==='teacher' ? 
                        <Table
                        role={tokenData.role}
                        title={'Video Class'}
                        columns={columnsteacher}
                        actions={"video_class"}
                        editClass={editClass}
                        data={videoStore.allVideoClasses}
                        loader={videoStore.allVideoClassesLoader}
                        onVideopress={onVideopress}
                        hadleOpenStudentModal={hadleOpenStudentModal}
                        changeStatus={changeStatus}
                    />
                    :
                    <Table
                        role={tokenData.role}
                        title={'Video Class'}
                        columns={columns}
                        actions={"video_class"}
                        editClass={editClass}
                        data={videoStore.allVideoClasses}
                        loader={videoStore.allVideoClassesLoader}
                        onVideopress={onVideopress}
                        hadleOpenStudentModal={hadleOpenStudentModal}
                        changeStatus={changeStatus}
                    />

                    }
                    
                </Col>

            </Row>
            <Modals
                open={openAssignModal}
                handleClose={hadleCloseStudentModal}
                title={`Assign students`}
            >
                <AssignStudents
                    // loader={videoStore.specificStudentLoader}
                    loader={tokenData.enterpriseUser
                        ? tStore.specificGroupLoader
                        : videoStore.specificStudentLoader}
                    classId={classID}
                    data={tokenData.enterpriseUser ? tStore.specificGroup : videoStore.specificStudent}

                    // data={videoStore.specificStudent}
                    handleCloseStudentModal={hadleCloseStudentModal}
                    timeData={"timeData"}
                />
            </Modals>
        </>
    )
}

export default VideoClass;