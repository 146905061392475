import React, { useState, useEffect, } from 'react'
import { addDays } from 'date-fns';
// import toDate from 'date-fns/toDate'
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Swal from 'sweetalert2'

import {
    Row, Col, Input,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody, Label,
    CardHeader,
    FormFeedback, Button
    // DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap';
import PageTitle from "../../HeaderTitle/PageTitle";
import Modals from "../../common/Modal";
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { requiredValidator } from '../../../utilities/Validator'
import Table from "./table";
// import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
import moment from 'moment'
// import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { getAttendance, populateAllUserClass, getAttendanceOfTeacher, filterAttendance, populateRegisteredStudents, populateTeacherClasses, populateAllEnterpriseUserClass, populateRegisteredTeachers, populateStudentClasses, exportTeacherAttendance } from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";
import { DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// import uuid from 'react-uuid'

// import DatePicker from 'react-datepicker';
// import CreateTimeSlot from './createTimeSlot'
// import TimeSlot from './timeSlotCard'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//     faClock, faWindowClose, faCalendarAlt, faBusinessTime,
//     faCog
// } from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';
import classnames from 'classnames';

const Index = () => {


    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const [ExportDate, setExportDate] = useState(new Date())

    useEffect(() => {
        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }


        if (tokenData.role != 'student') {
            // dispatch(getAttendance(tokenData.token, null, cb))

        } else {
            // dispatch(filterAttendance(tokenData.token, null, cb))
        }


        if (tokenData.role == 'principal' || tokenData.role == 'staff') {
            //dispatch(populateAllUserClass(tokenData.token))
            dispatch(populateAllEnterpriseUserClass(tokenData.token))

            dispatch(populateRegisteredTeachers(tokenData.token))

        } else if (tokenData.role == 'student') {
            //   dispatch(populateAllUserClass(tokenData.token))
            dispatch(populateStudentClasses(tokenData.token))


        } else if (tokenData.role == 'teacher') {
            dispatch(populateAllUserClass(tokenData.token))

        }
        return () => {
            setError({})
            setTeacher({ teacher_id: "", class_id: "", student_id: "" })

        }


    }, [dispatch]);

    const [Class, setClass] = useState(null)

    const handleChangeClass = (e) => {
        setClass(e.target.value)
    }

    const handleExport = () => {
        // const dt = moment(ExportDate).format("YYYY-MM-DD");
        console.log("date", ExportDate)

        var firstDay = new Date(ExportDate.getFullYear(), ExportDate.getMonth(), 1); //gets first day of the month
        var lastDay = new Date(ExportDate.getFullYear(), ExportDate.getMonth() + 1, 0);

        let start_date = moment(firstDay).format("YYYY-MM-DD")
        let end_date = moment(lastDay).format("YYYY-MM-DD")


        let data = {
          
            from_date: start_date ,
            to_date: end_date,
            class_id: Class,
        }

        if (Class) {
            dispatch(exportTeacherAttendance(tokenData.token, data, DwnloadCallback))
        } else {
            Swal.fire('Warning', 'Select class', 'warning')
        }


    }

    const [state2, setState2] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,

        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        //dispatch({ type: 'RESET_SUBJECT' })
        setError({})
        setTeacher({ teacher_id: "", class_id: "", student_id: "" })
        dispatch({ type: 'RESET_STUDENT_ATTANDANCE' })
        dispatch({ type: 'RESET_ATTANDANCE' })

        if (state2.activeTab !== tab) {
            setState2({
                activeTab: tab
            });
        }
        // if (tab == 2) {
        //     dispatch(filterAttendance(tokenData.token, null, cb))
        // }
    }
    let columns = ''
    if (tokenData.enterpriseUser && tokenData.role != 'student' && tokenData.role != 'teacher' && state2.activeTab != 2) {
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Teacher Name', field: 'teacher' },
            { title: 'Date', field: 'date' },
            { title: 'From Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },
            { title: 'Class name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Status', field: 'status' },
            // { title: 'Group', field: 'standard' },

        ]
    } else if (tokenData.role == 'student' || state2.activeTab == 2) {
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Date', field: 'date' },
            { title: 'Class_Name', field: 'class_name' },
            { title: 'Time Slot', field: 'time_slot' },
            { title: 'Status', field: 'status' },
            { title: 'Student Duration ', field: 'student_duration' },
            // { title: 'Teacher Duration', field: 'teacher_duration' },
            // { title: 'Standerd', field: 'grade' },
        ]
    } else if (tokenData.role == 'teacher') {
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Class_name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Group', field: 'standard' },
            { title: 'Date', field: 'created_at' },
            { title: 'From Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },

        ]
    } else {
        columns = [
            { title: '#', field: 'tableData.id' },
            { title: 'Date', field: 'created_at' },
            { title: 'From Time', field: 'class_from_time' },
            { title: 'To Time', field: 'class_to_time' },
            { title: 'Class name', field: 'class_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'Grade', field: 'grade' },

        ]
    }



    const handleSelectedRow = (class_id, session) => {
        // history.push(`/dashboard/show-attendance/${class_id}/${session_id}`)
        if (session.length > 0) {
            history.push({
                pathname: `/dashboard/show-attendance/${class_id}`,
                data: session // your data array of objects
            })
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Empty',
                text: "Empty session",
            })
        }
        //alert(id)
    }




    const [state, setState] = useState([
        {
            startDate: addDays(new Date(), -32),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [teacher, setTeacher] = useState({ teacher_id: "", class_id: "", student_id: "" })
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTeacher({ ...teacher, [name]: value })
        //alert(name)
        if ((tokenData.role == 'principal' || tokenData.role == 'staff') && name == 'teacher_id') {
            dispatch(populateTeacherClasses(tokenData.token, value))
        }

        if ((tokenData.role == 'principal' || tokenData.role == 'staff') && name !== 'student_id') {
            dispatch(populateRegisteredStudents(tokenData.token, null, value))

        }
    }

    // const handleChangeClass = (e) => {
    //     // alert(e.target.value)
    //     dispatch(populateRegisteredStudents(tokenData.token, null, e.target.value))

    // }
    const handleCloseExport = () => {
        setExportModal(false);
    }

    const [error, setError] = useState({})
    const filter = () => {
        //  debugger
        let err;
        let invalid;

        const body = {
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
            teacher_id: teacher.teacher_id,
            class_id: teacher.class_id,
            student_id: teacher.student_id

        }
        if (tokenData.role == 'principal' || tokenData.role == 'staff') {
            if (state2.activeTab == 1) {
                err = {
                    class_id: requiredValidator(teacher.class_id),
                    teacher_id: requiredValidator(teacher.teacher_id)
                }
                invalid = err.class_id || err.teacher_id
            }
            if (state2.activeTab == 2) {
                err = {
                    class_id: requiredValidator(teacher.class_id),
                    student_id: requiredValidator(teacher.student_id)
                }
                invalid = err.class_id || err.teacher_id
            }

        }
        // if (tokenData.role == 'teacher' || tokenData.role == 'student') {
        //     err = {
        //         class_id: requiredValidator(teacher.class_id)
        //     }
        //     invalid = err.class_id
        // }
        if (tokenData.role == 'student') {
            err = {
                class_id: requiredValidator(teacher.class_id)
            }
            invalid = err.class_id
        }

        if (!invalid) {

            setError(err)
            if ((tokenData.role == 'principal' || tokenData.role == 'staff') && teacher.teacher_id == "" && state2.activeTab == 1) {

                //alert('Select teacher')
                Swal.fire({
                    icon: 'warning',
                    title: 'Required',
                    text: "Select teacher",

                })

            }
            else if (tokenData.role == 'student' && teacher.class_id == "") {
                // alert('Select class')
                Swal.fire({
                    icon: 'warning',
                    title: 'Required',
                    text: 'Select class',

                })

            } else {
                setFilterLoader(true)

                if (tokenData.role == 'student' || state2.activeTab == 2) {

                    dispatch(filterAttendance(tokenData.token, body, cb))

                } else if (tokenData.role == "principal" || tokenData.role == "staff") {
                    dispatch(getAttendanceOfTeacher(tokenData.token, body, cb))

                } else {
                    dispatch(getAttendance(tokenData.token, body, cb))

                }

            }
        } else {
            setError(err)


        }


        //console.log("state---", moment(state[0].endDate).format("DD-MM-YYYY"))


    }
    const [filterLoader, setFilterLoader] = useState(false)
    const cb = () => {
        setFilterLoader(false)
    }

    const Download = () => {
        let data = {
            from_date: moment(state[0].startDate).format("YYYY-MM-DD"),
            to_date: moment(state[0].endDate).format("YYYY-MM-DD"),
            teacher_id: teacher.teacher_id,
            class_id: teacher.class_id,
            student_id: teacher.student_id

        }
        dispatch(exportTeacherAttendance(tokenData.token, data, DwnloadCallback))
    }
    const DwnloadCallback = (val) => {
        setExportModal(false);
        if (val) {
            window.open(val, "_blank")
        }
    }
    const [ExportModal, setExportModal] = useState(false)

    function renderButton() {
        return (
            <Row>
                <Col>
                    <Button
                        style={{ marginRight: "10px" }}
                        // className="btn-pill btn-shadow fsize-1 "
                        // size="lg"
                        color="primary"
                        onClick={() => setExportModal(true)}
                    >
                        Export
                    </Button>


                </Col>
            </Row>
        );
    }

    return (<>
        <PageTitle

            heading="Attendance Details"
            subheading="Attandance details"
            icon="pe-7s-users icon-gradient bg-happy-itmeo"
            actions={tokenData.role === 'teacher' ? renderButton :''}
        />




        {tokenData.role == 'principal' || tokenData.role == 'staff' ?


            <Card tabs={true} className="mb-3">
                <CardHeader>
                    <Nav justified>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: state2.activeTab === '1' })}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Teacher
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: state2.activeTab === '2' })}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Student
                            </NavLink>
                        </NavItem>


                    </Nav>
                </CardHeader>
                <CardBody>

                    <TabContent activeTab={state2.activeTab}>
                        <TabPane tabId="1">
                            <Card style={{ marginBottom: 20, padding: 20 }}>

                                <DateRangePicker
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    color='#fed858'
                                />
                                <hr />
                                <Row>
                                    <Col md={4}>


                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Teacher
                                        </Label>

                                        <Input type="select"
                                            onChange={(e) => handleChange(e)}
                                            name='teacher_id'
                                            required
                                            // value={this.state.loaderType}
                                            invalid={error.teacher_id}
                                        >
                                            <option value={''}>{'select'}</option>
                                            {tStore.registeredTeachers ?
                                                tStore.registeredTeachers.map(registeredTeachers => {
                                                    return (
                                                        <option value={registeredTeachers.user_id}>{registeredTeachers.first_name + " " + registeredTeachers.last_name}</option>
                                                        //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                                    )
                                                }) : []
                                            }

                                        </Input>
                                        <FormFeedback >{error.teacher_id}</FormFeedback>



                                    </Col>
                                    <Col md={5}>

                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Class
                                        </Label>

                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => handleChange(e)}
                                                    name='class_id'
                                                    required
                                                    // value={this.state.loaderType}
                                                    invalid={error.class_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        tStore.teacherClasses ? tStore.teacherClasses.map((data) => {
                                                            return (
                                                                <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>

                                                {
                                                    tStore.teacherClassesLoader ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>
                                        <FormFeedback >{error.class_id}</FormFeedback>


                                    </Col>
                                    <Col md={3}>
                                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                            <span className="text-danger">*</span>
                                            {' '}btn
                                        </Label>

                                        <LaddaButton className=" mr-2 btn btn-primary"
                                            style={{ width: '-webkit-fill-available' }}
                                            loading={filterLoader}
                                            type="button"
                                            onClick={filter}
                                            data-style={ZOOM_IN}  >
                                            Filter
                                        </LaddaButton>
                                    </Col>
                                </Row>

                            </Card>
                        </TabPane>
                        <TabPane tabId="2">
                            <Card style={{ marginBottom: 20, padding: 20 }}>

                                <DateRangePicker
                                    onChange={item => setState([item.selection])}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={state}
                                    maxDate={new Date()}
                                    direction="horizontal"
                                    color='#fed858'
                                />
                                <hr />
                                <Row>
                                    <Col md={5}>

                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Class
                                        </Label>

                                        <Input type="select"
                                            onChange={(e) => handleChange(e)}
                                            name='class_id'
                                            required
                                            // value={this.state.loaderType}
                                            invalid={error.class_id}
                                        >

                                            <option value={''}>{'select'}</option>
                                            {
                                                tStore.enterpriseClasses ? tStore.enterpriseClasses.map((data) => {
                                                    return (
                                                        <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                                    )
                                                })
                                                    : []
                                            }

                                        </Input>
                                        <FormFeedback >{error.class_id}</FormFeedback>



                                    </Col>
                                    <Col md={4}>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Student
                                        </Label>

                                        <div class="form-group">
                                            <div class="input-group">
                                                <Input type="select"
                                                    onChange={(e) => handleChange(e)}
                                                    name='student_id'
                                                    required
                                                    // value={this.state.loaderType}
                                                    invalid={error.student_id}
                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {
                                                        tStore.specificStudent ? tStore.specificStudent.map((data) => {
                                                            return (
                                                                <option value={data.user_id} key={data.user_id}>  {`${data.first_name} ${data.last_name}`}</option>
                                                            )
                                                        })
                                                            : []
                                                    }

                                                </Input>
                                                {
                                                    tStore.specificStudentLoader ?
                                                        <span class="input-group-addon" style={{ border: '1px solid #ccc', padding: '6px 6px' }}>
                                                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </span>
                                                        :
                                                        null
                                                }

                                            </div>
                                        </div>

                                        <FormFeedback >{error.student_id}</FormFeedback>


                                    </Col>
                                    <Col md={3}>
                                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                            <span className="text-danger">*</span>
                                            {' '}
                                        </Label>

                                        <LaddaButton className=" mr-2 btn btn-primary"
                                            style={{ width: '-webkit-fill-available' }}
                                            loading={filterLoader}
                                            type="button"
                                            onClick={filter}
                                            data-style={ZOOM_IN}  >
                                            Filter
                                        </LaddaButton>
                                    </Col>
                                </Row>

                            </Card>
                        </TabPane>


                    </TabContent>

                </CardBody>
            </Card>
            :




            <Card style={{ marginBottom: 20, padding: 20 }}>

                <DateRangePicker
                    onChange={item => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    maxDate={new Date()}
                    direction="horizontal"
                    color='#fed858'
                />
                <hr />
                <Row>
                    <Col xs={8} md={9}>
                        {
                            tokenData.role == 'principal' || tokenData.role == 'staff' ?
                                <>
                                    <Label for="exampleNameF">
                                        <span className="text-danger">*</span>
                                        {' '}Select Teacher
                                    </Label>

                                    <Input type="select"
                                        onChange={(e) => handleChange(e)}
                                        name='teacher_id'
                                        required
                                        invalid={error.teacher_id}
                                    // value={this.state.loaderType}
                                    >
                                        <option value={''}>{'select'}</option>
                                        {tStore.registeredTeachers ?
                                            tStore.registeredTeachers.map(registeredTeachers => {
                                                return (
                                                    <option value={registeredTeachers.user_id}>{registeredTeachers.first_name + " " + registeredTeachers.last_name}</option>
                                                    //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                                )
                                            }) : []
                                        }

                                    </Input>
                                    <FormFeedback >{error.teacher_id}</FormFeedback>

                                </>
                                : tokenData.role == 'student' ?
                                    <>
                                        <Label for="exampleNameF">
                                            <span className="text-danger">*</span>
                                            {' '}Select Class
                                        </Label>

                                        <Input type="select"
                                            onChange={(e) => handleChange(e)}
                                            name='class_id'
                                            required
                                            // value={this.state.loaderType}
                                            invalid={error.class_id}
                                        >

                                            <option value={''}>{'select'}</option>
                                            {
                                                tStore.studentClass ? tStore.studentClass.map((data) => {
                                                    return (
                                                        <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                                    )
                                                })
                                                    : []
                                            }

                                        </Input>
                                        <FormFeedback >{error.class_id}</FormFeedback>

                                    </>
                                    : tokenData.role == 'teacher' ?
                                        <>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Class
                                            </Label>

                                            <Input type="select"
                                                onChange={(e) => handleChange(e)}
                                                name='class_id'
                                                required
                                                // value={this.state.loaderType}
                                                invalid={tokenData.role !== "teacher" ? error.class_id : null}
                                            >

                                                <option value={''}>{'select'}</option>
                                                {
                                                    tStore.classes ? tStore.classes.map((data) => {
                                                        return (
                                                            <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                                        )
                                                    })
                                                        : []
                                                }

                                            </Input>
                                            <FormFeedback >{tokenData.role !== "teacher" ? error.class_id : null}</FormFeedback>

                                        </>
                                        : null


                        }
                    </Col>
                    <Col xs={2} md={1}>
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}
                        </Label>

                        <LaddaButton className="btn btn-primary"
                            // style={{ width: '-webkit-fill-available' }}
                            loading={filterLoader}
                            type="button"
                            onClick={filter}
                            data-style={ZOOM_IN}  >
                            Filter
                        </LaddaButton>
                    </Col>
                    {/* {
                        tokenData.role == 'teacher' ?

                            <Col xs={2} md={1}>
                                <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                    <span className="text-danger">*</span>
                                    {' '}
                                </Label>
                                <LaddaButton className="btn btn-danger"
                                    loading={tStore.teacherinvoiceloader}
                                    type="button"
                                    onClick={Download}
                                    data-style={ZOOM_IN}  >
                                    Export
                                </LaddaButton>
                            </Col>
                            : ''
                    } */}

                </Row>

            </Card>

        }


        {/* <form onSubmit={filter}>
            <Card style={{ marginBottom: 20, padding: 20 }}>
                <Row>
                    <Col md={3}>
                    </Col>
                    <Col md={3}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}From
                                                    </Label>

                        <Input type="date" name="from_date" />
                    </Col>
                    <Col md={3}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}To
                                                    </Label>

                        <Input type="date" name="to_date" />

                    </Col>
                    <Col md={3} >
                        <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                            <span className="text-danger">*</span>
                            {' '}To
                                                    </Label>
                        <LaddaButton className=" mr-2 btn btn-primary"
                            style={{ width: '-webkit-fill-available' }}
                            //loading={groupStore.createGroupLoader}
                            type="submit"
                            data-style={ZOOM_IN}  >
                            Filter
                        </LaddaButton>
                    </Col>
                </Row>
            </Card>
        </form>
 */}

        <Row>
            <Col sm={12} md={12}>
                <Table
                    loader={tStore.attendanceLoader}
                    data={tokenData.role == 'student' || state2.activeTab == 2 ? tStore.studentAttandance.details : tStore.attendanceInfo}
                    //  data={dummyData}
                    role={tokenData.role}
                    columns={columns}
                    tab={state2.activeTab}
                    actions='attandance'
                    handleSelectedRow={handleSelectedRow}
                />
            </Col>
        </Row >
        <Modals
            size='lg'
            open={ExportModal}
            handleClose={handleCloseExport}
            title={`Export Monthly Attendance`}
        >
            <Row>
                <Col md={5}>

                    <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Select Class
                    </Label>
                    <Input type="select"
                        onChange={(e) => handleChangeClass(e)}
                        // name='class_id'
                        required
                    // value={this.state.loaderType}
                    // invalid={tokenData.role !== "teacher" ? error.class_id : null}
                    >

                        <option value={''}>{'select'}</option>
                        {
                            tStore.classes ? tStore.classes.map((data) => {
                                return (
                                    <option value={data.class_id} key={data.class_id}>{data.class_name}</option>
                                )
                            })
                                : []
                        }

                    </Input>
                    {/* <FormFeedback >{error.class_id}</FormFeedback> */}


                </Col>

                <Col md={5}>
                    <Label for="exampleNameF">
                        <span className="text-danger">*</span>
                        {' '}Select Month
                    </Label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker

                            //format="dd/MM/yyyy"
                            // label=""
                            size='small'
                            //  inputVariant="outlined"
                            value={ExportDate}
                            onChange={date => setExportDate(date)}
                            // minDate={start_date ? start_date : ''}
                            fullWidth
                            id='course_end_date'
                            views={["year", "month"]}
                            openTo="year"
                        />
                    </MuiPickersUtilsProvider>

                </Col>
                <Col sm={2} >
                    {/* <Button onClick={handleExport} style={{ marginTop: '15px' }} >Export</Button> */}
                    <LaddaButton className="btn btn-danger mt-3"
                        loading={tStore.teacherinvoiceloader}
                        type="button"
                        onClick={handleExport}
                        data-style={ZOOM_IN}  >
                        Download
                    </LaddaButton>
                </Col>
            </Row>
        </Modals>


    </>
    )
}

export default Index
