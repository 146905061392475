import React, { useEffect, useState } from 'react'
import PageTitle from '../common/HeaderTitle/PageTitle'
import { Card, Col, Row, Input, Label, FormFeedback } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Table from '../common/DataTable'
import { motsCreateGroup, listAllMotsGroups, updateMotsGroup, deleteMotsGroup } from '../../redux/Actions/grouping'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
//import AssignTeacher from './assignTeacher'
import Modals from '../common/Modal'
import Swal from 'sweetalert2'
import Assign from './Assign'
import {useHistory} from 'react-router-dom'
// import ListStudent from './ListStudent'
import { requiredValidator } from '../../utilities/Validator';

function Index() {

    const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const history = useHistory();
    const groupStore = useSelector(({ grouping }) => grouping)

    useEffect(() => {
        dispatch(listAllMotsGroups(tokenData.token))
    }, [])

    const [inputField, setInputField] = useState({
        group_name: ''
    })
    const [errors, setErrors] = useState({})

    const resetForm = () => {
        setInputField({ group_name: '' })
        document.getElementById("group-form").reset();
        dispatch(listAllMotsGroups(tokenData.token))
    }

    const formSubmit = (e) => {
        const err = {
            group_name: requiredValidator(inputField.group_name)
        }
        e.preventDefault()
        if (!err.group_name) {
            dispatch(motsCreateGroup(tokenData.token, inputField, resetForm))
            setErrors(err)
        } else {
            setErrors(err)

        }
    }

    const handleChange = (e) => {
        const { value, name } = e.target
        setInputField({ ...inputField, [name]: value })
    }

    const onEdit = (newData) => {

        console.log("newData", newData)
        if (!requiredValidator(newData.group_name)) {
            dispatch(updateMotsGroup(tokenData.token, newData))

        } else {
            Swal.fire(
                'Required',
                requiredValidator(newData.group_name),
                'warning'
            )
        }

    }

    const onDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteMotsGroup(tokenData.token, id))


            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }

    const [AssignModal, setAssignModal] = useState({ open: false, group_id: null })

    const handleAssignStudent = (id) => {
        history.push(`/dashboard/group/${id}`)
        // setAssignModal({
        //     open: true,
        //     group_id: id
        // })
    }

    const handleCloseModal = () => {
        setAssignModal({
            open: false,
            group_id: null
        })
    }

    return (
        <div>
            <Card style={{ padding: 20 }}>
                <h6>Group</h6><hr />
                <form onSubmit={formSubmit} id="group-form">
                    <Row >
                        <Col md={2}>

                        </Col>
                        <Col md={5}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Group Name
                            </Label>

                            <Input
                                type="text"
                                name="group_name"
                                // required
                                value={inputField.group_name}
                                onChange={handleChange}
                                invalid={errors.group_name}
                            />
                            <FormFeedback >{errors.group_name}</FormFeedback>

                        </Col>
                        <Col md={2}>
                            <Label for="exampleNameF" style={{ visibility: 'hidden' }}>
                                <span className="text-danger">*</span>
                                {' '}Group Name
                            </Label>
                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                loading={groupStore.createGroupLoader}
                                type="submit"
                                data-style={ZOOM_IN}  >
                                CREATE GROUP
                            </LaddaButton>
                        </Col>
                        <Col md={3}>

                        </Col>
                    </Row>
                </form>
            </Card>
            <div style={{ marginTop: '30px' }} >


                <Table

                    data={groupStore.groupList}
                    loader={groupStore.groupListLoader}
                    columns={columns}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    // handleAssignTeacher={handleAssignTeacher}
                    title={'Groups'}
                    handleAssignStudent={handleAssignStudent}
                    // handleSelectedRow={handleSelectedRow}
                    actions={"mots_group"}
                />
            </div>

            {/* <Modals
                open={AssignModal.open}
                title={"Assign Modal"}
                handleClose={handleCloseModal}
            >
                <Assign
                    group_id={237}
                />

            </Modals> */}

        </div>
    )
}

export default Index
