import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import {  requiredValidator } from "../../../utilities/Validator";

import SchoolClass from "./school";
import CollegeClass from "./college";
import LifeLongClass from "./lifeLong";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import serialize from "form-serialize";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Loader from "react-loaders";
import moment from "moment";
import classnames from "classnames";
import ChooseFromPlaylist from "../common/chooseFromPlaylist";
import Swal from "sweetalert2";
import VideoClass from "../VideoClass";
import {
  Row,
  Col,
  FormGroup,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Label,
  CardHeader,
} from "reactstrap";
import {
  populatePlayListInClass,
  getPlayListVideos,
  createVideoClass,
  populateAllVideoClass
} from "../../../redux/Actions/playlist";

import PageTitle from "../../HeaderTitle/PageTitle";
import "../Playlist/playlist.scss";
import "./subject.scss";
const Index = () => {
  const dispatch = useDispatch();
  const textInputRef = useRef(null);
  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_PLAYLIST_DETAILS" });
    };
  }, []);

  const playlistStore = useSelector(({ playlist }) => playlist);
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const [state, setState] = useState({
    activeTab: "1",
    showMore: true,
    transform: true,
    showInkBar: true,

    selectedTabKey: 0,
    transformWidth: 400,
  });
  const [tabNow, setTabNow] = useState(1);
  const toggle = (tab) => {
    setErrors({});
    if (tabNow !== tab) {
      dispatch({ type: "RESET_PLAYLIST" });
    }
    setTabNow(tab);
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };
  const getPlaylist = (subject_id) => {
    // alert(subject_id)
    if (!subject_id) {
      dispatch({ type: "RESET_PLAYLIST" });
    } else {
      dispatch(populatePlayListInClass(tokenData.token, subject_id));
    }
  };
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const getVideos = (playlist_id) => {
    setSelectedPlaylist(playlist_id);
    setMultiSelect([]);
    //dispatch(getPlayListDetails(tokenData.token, playlist_id))
    dispatch(getPlayListVideos(tokenData.token, playlist_id));
  };
  const handleOrder = (id) => {
    setPlaylistDetails({ ...palylistDetails, [id]: id });
  };
  const handleTime = (e) => {
    const { id, value } = e.target;
    const date = id.split("-");
    // console.log(""{ id });
    //alert(date[1])
    let keyName = date[1];
    console.log("selectedId=======", id);
    // debugger;
    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
      // console.log(selectedId);
    }
    setPlaylistDetails({ ...palylistDetails, [id]: value });
    // selectedId[keyName].push(id)
  };

  const [mutliSelect, setMultiSelect] = useState([]);
  const [playlistsVideoId, setPlaylistsVideoId] = useState([]);
  const [palylistDetails, setPlaylistDetails] = useState({ Details: [] });
  // const st={
  //     name1:{
  //         name:"fds"
  //     },
  //     name2:{
  //         name:"fds"
  //     }
  // }
  const [selectedId, setSelectedId] = useState({});
  const [selectedIdOrigin, setSelectedIdOrigin] = useState({});
  console.log("rrrrrr-----", selectedId);

  const multiSelectVideo = (video_id, playlists_video_id, thumbnile, index) => {
    console.log({ palylistDetails });
    document.getElementById(`fromDate-${index}`).value = "";
    document.getElementById(`toDate-${index}`).value = "";
    document.getElementById(`fromTime-${index}`).value = "";
    document.getElementById(`toTime-${index}`).value = "";
    if (thumbnile.includes("default")) {
      Swal.fire({
        icon: "warning",
        title: "Processing",
        text: "you can't choose this video,because its on process",
      });
    } else {
      console.log(playlistsVideoId);
      if (playlistsVideoId.includes(playlists_video_id)) {
        const filtered = playlistsVideoId.filter(
          (videoId) => videoId != playlists_video_id
        );
        setPlaylistsVideoId(filtered);
        console.log({ palylistDetails });
        // const filtering = palylistDetails.filter(row => {
        delete selectedId[index];

        // })
        // const filtering = palylistDetails.splice(index, 1)
        // console.log("filtering--", typeof palylistDetails);
      } else {
        setPlaylistsVideoId([...playlistsVideoId, playlists_video_id]);
        setSelectedId({ ...selectedId, [index]: [] });
      }

      if (mutliSelect.includes(video_id)) {
        // debugger
        const filtered = mutliSelect.filter((videoId) => videoId != video_id);
        console.log("after removed", filtered);
        setMultiSelect(filtered);
      } else {
        setMultiSelect([...mutliSelect, video_id]);
        console.log("video selected", mutliSelect);
      }
    }

    // if (mutliSelect.includes(video_id)) {
    //     // debugger
    //     const filtered = mutliSelect.filter(videoId => videoId != video_id)
    //     console.log("after removed", filtered)
    //     setMultiSelect(filtered)

    // } else {
    //     setMultiSelect([...mutliSelect, video_id])
    //     console.log("video selected", mutliSelect)

    // }
  };
  const [syncVideo, setSyncVideo] = useState([]);

  const handleClickSync = (video_id) => {
    if (syncVideo.includes(video_id)) {
      // debugger
      const filtered = syncVideo.filter((videoId) => videoId != video_id);
      console.log("after removed", filtered);
      setSyncVideo(filtered);
    } else {
      setSyncVideo([...syncVideo, video_id]);
      console.log("video selected", syncVideo);
    }
  };
  const [dateVideo, setDateVideo] = useState({});
  const handleDate = (e) => {
    const { id, value } = e.target;
    const date = id.split("-");
    console.log({ selectedId });
    //alert(date[1])
    let keyName = date[1];
    console.log("selectedId=======", selectedId);

    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
      // console.log(selectedId);
    }
    if (date[0] === "fromDate") {
      // alert(dateVideo[`toDate-${date[1]}`])
      //console.log("dateVideo", dateVideo[`toDate-${date[1]}`])
      // debugger

      if (`toDate-${date[1]}` in dateVideo) {
        //alert(moment(dateVideo.id).isAfter(dateVideo[`toDate-${date[1]}`], 'day'))
        // alert(dateVideo.id)
        //alert(dateVideo[`toDate-${date[1]}`])

        if (moment(value).isAfter(dateVideo[`toDate-${date[1]}`], "day")) {
          document.getElementById(id).value = "";
          Swal.fire({
            icon: "error",
            title: "Invalid Date",
            text: "From date should  be less than to date !..",
          });
        } else {
          setDateVideo({ ...dateVideo, [id]: value });
        }
      } else {
        setDateVideo({ ...dateVideo, [id]: value });
      }
    } else if (date[0] === "toDate") {
      // alert("toDate")
      if (`fromDate-${date[1]}` in dateVideo) {
        // debugger
        //alert(moment(dateVideo.id).isAfter(dateVideo[`toDate-${date[1]}`], 'day'))
        //  alert(moment(dateVideo[`fromDate-${date[1]}`]).isAfter(value, 'day'))
        //alert(dateVideo[`toDate-${date[1]}`])

        if (moment(dateVideo[`fromDate-${date[1]}`]).isAfter(value, "day")) {
          Swal.fire({
            icon: "error",
            title: "Invalid Date",
            text: "To date should  be grater than from date !..",
          });

          document.getElementById(id).value = "";
        } else {
          setDateVideo({ ...dateVideo, [id]: value });
        }
      } else {
        setDateVideo({ ...dateVideo, [id]: value });
      }
    }

    // setDateVideo({ ...dateVideo, [id]: value })
    // alert(e.target.id)
    // alert(e.target.value)
    // var str = "How are you-doing today?";
    //var res = str.split("-");

    console.log("dateVideo", dateVideo);
  };
  const [inputField, setInputField] = useState({
    title: "",
    degree_id: "",
    subject_id: "",
    grade_id: "",
  });

  const canCallApi = (_selectedId) => {
    for (let key in _selectedId) {
      if (_selectedId[key].length < 4) {
        return false;
      }
    }
    return true;
  };
  const [errors, setErrors] = useState({});

  const formSubmit = (e) => {
    e.preventDefault();
    // textInputRef.current.value;
    // alert(textInputRef.current.value())
    console.log({ selectedIdOrigin });
    console.log("jams---", selectedId);
    setSelectedIdOrigin(selectedId);
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    // body.sync = syncVideo
    let error = null;
    console.log({ body });
    let invalid;
    if (tabNow == 1) {
      error = {
        title: requiredValidator(body.title),
        grade_id: requiredValidator(body.grade_id),
        subject_id: requiredValidator(body.subject_id),
      };
      invalid = error.title || error.grade_id || error.subject_id;
    } else if (tabNow == 2) {
      error = {
        title: requiredValidator(body.title),
        degree_id: requiredValidator(body.degree_id),
        grade_id: requiredValidator(body.grade_id),
        subject_id: requiredValidator(body.subject_id),
      };
      invalid =
        error.title || error.grade_id || error.subject_id || error.degree_id;
    } else {
      error = {
        title: requiredValidator(body.title),
        grade_id: requiredValidator(body.grade_id),
        subject_id: requiredValidator(body.subject_id),
      };
      invalid = error.title || error.grade_id || error.subject_id;
    }
    if (!invalid) {
      setErrors(error);

      if (selectedPlaylist) {
        if (canCallApi(selectedId)) {
          dispatch(createVideoClass(tokenData.token, body, resetForm));
        } else {
          //error
          //alert("error validation!!!!");
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Select playlist",
        });
      }
    } else {
      setErrors(error);
    }

    console.log("body---", body);
  };

  const resetForm = () => {
    dispatch({ type: "RESET_PLAYLIST" });
    setSelectedPlaylist("");
    document.getElementById("create_video_class").reset();
    setMultiSelect([]);

    setPlaylistsVideoId([]);
    dispatch(populateAllVideoClass(tokenData.token))
  };
  return (
    <>
      <PageTitle
        style={{ display: "none" }}
        heading="Create Video Session"
        subheading="Create your own video session under the grade or course"
        icon="pe-7s-video icon-gradient bg-happy-itmeo"
      />

      {/* <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='School' key="1"><CreateSchoolClass /></TabPane>
                    <TabPane tab='College' key="2"><CreateCollegeClass /></TabPane>
                </Tabs> */}

      <form onSubmit={formSubmit} id="create_video_class">
        <Card tabs={true} className="mb-3">
          <CardHeader>
            <Nav justified>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  School
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  College/University
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: state.activeTab === "3" })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Life Long Course
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1">
                {tabNow == "1" ? (
                  <SchoolClass getPlaylist={getPlaylist} errors={errors} />
                ) : null}
              </TabPane>
              <TabPane tabId="2">
                {tabNow == "2" ? (
                  <CollegeClass getPlaylist={getPlaylist} errors={errors} />
                ) : null}
              </TabPane>
              <TabPane tabId="3">
                {tabNow == "3" ? (
                  <LifeLongClass getPlaylist={getPlaylist} errors={errors} />
                ) : null}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        {playlistStore.classPlaylist.length > 0 ? (
          <Card style={{ padding: "10px 20px" }}>
            <Row className="jms-create-video-session">
              <Col md={3}> Playlist </Col>
              <Col md={3}> Videos</Col>
              <Col md={3}> From</Col>
              <Col md={3}>To </Col>
            </Row>
            <Row>
              <input
                type="hidden"
                value={selectedPlaylist}
                name="playlist_id"
              />

              <Col md={3} className="jms-choose-from-col-title">
                {playlistStore.classPlaylist.map((playlist) => {
                  return (
                    <div
                      className={`jms-choose-from-title ${
                        selectedPlaylist === playlist.playlist_id
                          ? "jms-active-playlist"
                          : null
                        }`}
                      onClick={() => getVideos(playlist.playlist_id)}
                    >
                      {playlist.playlist_name}
                    </div>
                  );
                })}
              </Col>

              <Col md={9} className="jms-choose-from-col-videos">
                {playlistStore.playlistVideos.length > 0 ? (
                  playlistStore.playlistVideos.map((video, index) => {
                    return (
                      <ChooseFromPlaylist
                        thumbnile={video.thumbnails[2].link}
                        type={"create_class"}
                        videoId={video.video_id}
                        playlist_video_id={video.playlist_video_id}
                        title={video.title}
                        url={video.url}
                        duration={video.video_duration}
                        format={"mp4"}
                        playlistsVideoId={playlistsVideoId}
                        handleDate={handleDate}
                        multiSelectVideo={multiSelectVideo}
                        mutliSelect={mutliSelect}
                        handleClickSync={handleClickSync}
                        syncVideo={syncVideo}
                        index={index}
                        handleOrder={handleOrder}
                        handleTime={handleTime}
                        textInputRef={textInputRef}
                        selectedIdOrigin={selectedIdOrigin}
                      />
                    );
                  })
                ) : playlistStore.playlistVideosLoader ? (
                  <p className="jms-position">
                    {" "}
                    <Loader color="#f7b924" type="ball-rotate" />{" "}
                  </p>
                ) : !selectedPlaylist ? (
                  <p className="jms-position"> Select a playlist</p>
                ) : (
                        <p className="jms-position">
                          {" "}
                          <i
                            style={{ fontSize: 30 }}
                            className="lnr-warning icon-gradient bg-sunny-morning"
                          >
                            {" "}
                          </i>{" "}
                    There is no videos
                        </p>
                      )}
              </Col>
            </Row>
          </Card>
        ) : playlistStore.classPlaylistLoader ? (
          <>
            <Card style={{ padding: 20 }}>
              <Row>
                <Col md={3}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={50} count={4} />
                  </SkeletonTheme>
                </Col>
                <Col md={9}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={100} count={2} />
                  </SkeletonTheme>
                </Col>
              </Row>
            </Card>
          </>
        ) : null}

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label
                for="exampleCustomSelectDisabled"
                style={{ visibility: "hidden" }}
              >
                Subject
              </Label>

              <LaddaButton
                className="mb-2 mr-2 btn btn-danger"
                loading={playlistStore.createVideoClassLoader}
                // onClick={formSubmit}
                type="submit"
                data-style={ZOOM_IN}
                style={{
                  whiteSpace: "nowrap",
                  marginRight: "0px",
                  width: "-webkit-fill-available",
                }}
              >
                CREATE CLASS
              </LaddaButton>
            </FormGroup>
          </Col>
        </Row>
      </form>
      <Row>
        <Col md={12}>
          <VideoClass />
        </Col>
      </Row>
    </>
  );
};

export default Index;
