import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useParams } from "react-router";
import socketIOClient from "socket.io-client"
import BrowserNotSupport from '../Utility/browserNotSupport'
import * as jose from 'jose'
import {
    isFirefox,
    isIE,
    isEdge,
    isChrome

} from "react-device-detect";
import { addStudentsToSoundComponent, leaveClassRoom, removeStudenFromClass, validateStartClass, classStartedBroadCast } from '../../../redux/Actions/homeTeacher'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import './classRoom.scss'
// import { CircleToBlockLoading } from 'react-loadingg';
// import Upload from "./FileUpload/Upload"
import serialize from 'form-serialize'
import Modals from '../../common/Modal'
// import Success from '../CreateSuccess'
import { addAssignment } from '../../../redux/Actions/assignment';
import Loader from 'react-loaders'
import AppFooter from '../../../Layout/AppFooter';
import SweetAlert from 'sweetalert-react';
import { Prompt } from 'react-router'
import { development } from '../../../config.json'


import Swal from 'sweetalert2'


const ENDPOINT = `${development.socket_io_url}`;


const Index = (props) => {

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    var tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const { week_id, class_id, type } = useParams();


    const [authFields, setAuthFields] = useState()
    const dispatch = useDispatch();
    const [visible, setVisible] = useState();
    const [prompt, setPrompt] = useState(false);
    const [nav, setNav] = useState(false);
    const onSubmitForm = (formData) => {
        console.log("######## " + formData);
        const form = formData.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        console.log("bodddddyyyyyy", body);
        dispatch(addAssignment(tokenData.token, formData, handleOpenSuccessModal))
        //     // dispatch(validateStartClass(tokenData.token, id, cb))


    }
    const [successModal, setSuccessModal] = useState();
    const handleOpenSuccessModal = () => {
        console.log('callback')
        setSuccessModal(true)
    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
    }
    const [unSupportBrowser, setUnSupportBrowser] = useState(false)

    const [popAlert, setpopAlertStatus] = useState(true)


    const browserNotSupport = () => {
        setUnSupportBrowser(true)
    }
    const closeUnsupportPopup = () => {
        setUnSupportBrowser(false)
        props.history.push('/dashboard/overview')

    }
    useEffect(() => {
        const socket = socketIOClient(ENDPOINT);

        var socketData = {
            weekly_class_id: week_id,
            class_id: class_id,
            token: tokenData.token,
            role: "teacher"

        }


        socket.emit('teacherOnline', socketData)

        // socket.on('error', (error) => {
        //     console.log("soc-error"+error);
        //   });

        //   socket.on('reconnect_error', (error) => {
        //     console.log("soc-reconnect_error"+error);
        //   });
        //   socket.on('reconnect_failed', () => {
        //     console.log("soc-reconnect_failed");
        //   });



        //   socket.on('connect_error', () => {
        //     console.log("soc-connect_error");

        //   });
        //   socket.on('connect_timeout', () => {
        //     console.log("soc-connect_timeout");

        //   });
        //   socket.on('reconnect_attempt', () => {
        //     console.log("soc-reconnect_attempt");

        //   });
        //   socket.on('reconnect_error', () => {
        //     console.log("soc-reconnect_error");

        //   });
        //   socket.on('reconnect_failed', () => {
        //     console.log("soc-reconnect_failed");

        //   });

        //   socket.on('reconnecting', () => {
        //     console.log("soc-reconnecting");

        //   });

        socket.on('reconnect', () => {
            console.log("soc-reconnect successfull");

            if (api !== undefined)
                api.executeCommand('hangup');

            setpopAlertStatus(false)


            Swal.fire({
                icon: 'error',
                title: 'Network error',
                text: 'Disconnected Due Network error'
            }).then((result) => {

                if (result.value) {
                    handleCloseSuccesstModal2()
                    props.history.push('/dashboard/')

                }
            });

        });


        // var hangupChannel=class_id+week_id
        var hangupChannel = class_id + week_id


        socket.on(hangupChannel, function (message) {
            console.log("test")
            console.log(message)



        });


        if (type == "conference" || type == "normal") {
            if (isFirefox || isIE || isEdge) {
                browserNotSupport()
            } else {
                dispatch(validateStartClass(cb, tokenData.token, week_id, class_id, type))
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Invalid',
                text: 'Invalid class '
            });
            props.history.push('/dashboard/')

        }
        // dispatch(validateStartClass(cb, tokenData.token, week_id, class_id))
        // const unSupportedBrowser = () => {
        //     props.history.push('/dashboard/')
        // }
        if (tokenData.role !== "teacher") {
            props.history.push('/dashboard/')

        }

        const weekly_class_id = props.match.params.id;

        function componentWillUnmount() {

        }

        console.log("idddd", weekly_class_id)


        return () => {

            // alert("are you sure")
            // Swal.fire({
            //     icon: 'error',
            //     title: '',
            //     text: 'Are You Sure? '
            // });
            // window.addEventListener("beforeunload", (ev) => {
            //     ev.preventDefault();
            //     return ev.returnValue = 'Are you sure you want to close?';
            // });
            //window.addEventListener('beforeunload', alert("don't close"));
            //confirm("Press a button!")
            socket.disconnect();
            if (api) {
                const id = api.getMyId();
                dispatch({ type: 'CLASS_SESSION_STARTED', payload: id })
               // dispatch(leaveClassRoom(id))
            }


        };

    }, []);

    // setTimeout(function () {
    //     Swal.fire({
    //         icon: 'error',
    //         text: 'Are You Sure ?',
    //     });
    // }, 15000);
    const [myId, setMyid] = useState()
    const cb = (res, ress) => {
        getData(week_id, class_id)

        // alert(res)
        // if (!res.result) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: '',
        //         text: res.message,
        //         confirmButtonText: 'Okay',

        //     }).then((result) => {
        //         props.history.push('/dashboard/')


        //     })
        // }


    }
    const handleChange = e => {
        const { name, value } = e.target;
        mutePaticipant(value)

    }
    const handleChanges = () => {
        // setVisible('none');

    }


    // window.onbeforeunload = function () {
    //     // var msg = "Are you sure to closssss"
    //     // return alert("Are you sure ")
    //     Swal.fire({
    //         icon: 'error',
    //         text: 'Are You Sure ?',
    //     });
    // }
    var api;
    const getData = (week_id, class_id, ) => {

        //var studentList = []
        //const  {newUser_join} = this.newUser_join;
        console.log("clg----------------")


        var promise = new Promise(async function (resolve, reject) {

            // var element = document.getElementById('video');

            // element.addEventListener('DOMSubtreeModified', myFunction);

            // function myFunction(e) {
            //     setTimeout(function () {

            //     }, 1500);

            // }

            const domain = development.videoEngine2;

            var enc = new TextEncoder();
            let key = new TextEncoder().encode("motsvideopass");
            const userDetails = localStorage.getItem("authDetails");


            const currentDate = new Date();
const tomorrowDate = new Date(currentDate);
tomorrowDate.setUTCDate(currentDate.getUTCDate() + 1);
const yesterdayDate = new Date(currentDate);
yesterdayDate.setUTCDate(currentDate.getUTCDate());
const tomorrowTimestamp = Math.floor(tomorrowDate / 1000);
const yesterdayTimestamp = Math.floor(yesterdayDate / 1000);
console.log("Tomorrow Timestamp (UTC):", tomorrowTimestamp);
console.log("Yesterday Timestamp (UTC):", yesterdayTimestamp);
    
            let data = {
                "aud": "motsvideoapp",
                "context": {
                  "user": {
                    "id": "0f8b7760-c17f-4a12-b134-c6ac37167144",
                    "name": userDetails?.name,
                    "avatar": "https://link.to/user/avatar/picture",
                    "email": "test@test.com",
                    "moderator": "true"
                  },
                  "features": {
                    "livestreaming": "false",
                    "outbound-call": "false",
                    "transcription": "false",
                    "recording": "false"
                  },
                  "room": {
                    "regex": false
                  }
                },
                "exp": 1694509603895,
                "iss": "motsvideoapp",
                "nbf": 1694423265,
                "room": "*",
                "sub": "view.mymots.com"
              }
              

                    const jwtTok = await new jose.SignJWT(data)
                        .setProtectedHeader({ alg: "HS256", typ: 'JWT' })
                        .setIssuer('motsvideoapp')
                        .setAudience('motsvideoapp')
                        .setSubject('view.mymots.com')
                        .sign(key);
                        console.log("OPTION ",data  );

            const users = [];
            const options = {
                roomName: 'test',
                width: 900,
                height: 500,
                parentNode: document.querySelector('#video'),
                larToken: `Bearer ${tokenData.token}`,
                weekly_class_id: week_id,
                class_id: class_id,
                jwt: jwtTok?jwtTok:''
            };

            console.log("OPTION ",options);
            let JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
            api = new JitsiMeetExternalAPI(domain, options);
            setAuthFields(api)
            //   console.error("hhhhh"+document.getElementById("yy").innerHTML);
            //      const elem = document.querySelector("#video")
            if (api) {
                resolve();
            } else {
                reject();
            }
            setVideo('none')
        });

        promise.
            then(function () {
                setTimeout(function () {
                    api.executeCommand('toggleVideo');
                    api.executeCommand('displayName', tokenData.name);

                    setVisible('none');


                    const id = api.getMyId();
                    console.log('my id .........in getdata fun ' + id);

                    setMyid(id)
                }, 5000);

                //    api.executeCommand('displayName', tokenData.name);


                // const id = api.getMyId();
                // console.log('my id .........in getdata fun ' + id);


            }).
            catch(function () {
                console.log('Some error has occured');
            });

        //  const displayName = api.getDisplayName(participantId);
        //   alert(displayName);
        // var that = this;
        // //api.executeCommand('displayName', sessionStorage.getItem('name'))

        api.addEventListener("participantJoined", function (response) {
            console.log("****muteParticipant event triggered:", response);
          });
          
          api.on("error", function (error) {
            console.error("***An error occurred:", error);
          });
        api.addEventListener("participantJoined", function (response) {

            //  document.dispatchEvent(new KeyboardEvent('keydown',{'key':'f11'}));
            document.dispatchEvent(
                new KeyboardEvent("keydown", {
                    key: "f11",
                    keyCode: 122, // example values.                 
                })
            );


            // var element= document.getElementsByTagName("iframe")[0];
            // element.focus();
            // element.dispatchEvent(new KeyboardEvent('keypress',{'key':'s'}));

            var student = { "name": response.displayName, "id": response.id, "mute": false };
            //studentList.push(student);
            dispatch(addStudentsToSoundComponent(student))
            console.log("MYID id : " + response);
            const id = api.getMyId();
            setMyid(id)
             newUser_join(response.displayName, response.id)
        })

        api.addEventListener("muteParticipant", function (response) {

            console.log("MYID id : " + response);

            const id = api.getMyId();
            setMyid(id)
            console.log('my id ............. ' + id);

        })
        api.addEventListener("audioMuteStatusChanged", function (response) {
            console.log("mute status: " + response);
            const id = api.getMyId();
            console.log('my id ............. ' + id);
            setMyid(id)
        })

        api.addEventListener("tileViewChanged", function (response) {

            console.log("video load changed: " + response);
            setVisible('none');
            dispatch(classStartedBroadCast(tokenData.token, class_id))
            setPrompt(true)
            const id = api.getMyId();
            setMyid(id)
        })

       
        api.addEventListener("readyToClose", function (response) {
            console.log("readyToClose: " + response);
            // setSweet(true);
            setPrompt(false)



           if (popAlert) {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'Video Session Ended',

                }).then((result) => {

                    if (result.value) {
                        handleCloseSuccesstModal2()
                        props.history.push('/dashboard/')

                    }
                })
           }
            setTimeout(function () {
                props.history.push('/dashboard/')

            }, 3000);

            setVideo(true);
        })

        api.addEventListener("participantLeft", function (response) {
            console.log("left id" + response.id);
            dispatch(removeStudenFromClass(response.id))
           // dispatch(leaveClassRoom(response.id))

        })
        api.addEventListener("videoConferenceJoined", function (response) {
            setVisible('none');
         
        })
    }
    const [sweet, setSweet] = useState();
    const [video, setVideo] = useState();
    const [video2, setVideo2] = useState();
    const handleCloseSuccesstModal2 = () => {
        setSweet(false)
        setVideo(true)
        setVideo2('none')
    }

    const [state, setState] = useState({
        connected_users: [],
        event_logs: [],
        user_id: null,
    });

    const { connected_users, event_logs } = state;
    const newUser_join = (name, id) => {
        console.error("in new user")
        console.log(name)

        const newUser = name;
        const user_id = id
        const isOnTheList = connected_users.includes(newUser)

        if (isOnTheList) {
            return;
        }
        else {
            newUser !== "" && setState({ connected_users: [...state.connected_users, newUser] })
        }

        newUser !== "" && setState({ event_logs: [...state.event_logs, event_logs.push(newUser)] })


    }

    const mutePaticipant = (id) => {
        //  console.log("zzzzzzzzzzzz"+api)   
        console.log('aaaaaaaaa' + id)

        console.error("zzzzzzzzzzzz" + authFields)

        authFields.muteStudent(id);

    }

    console.error(event_logs);
    // const user_Left = (data) => {
    //     // console.log("in new user")
    //     // console.log(data)
    //     const { connected_users } = state;
    //     const newUser = data;
    //     const isOnTheList = connected_users.includes(newUser)

    //     if (isOnTheList) {
    //         return;
    //     }
    //     else {
    //         newUser !== "" && setState({ connected_users: [...state.connected_users, newUser] })
    //     }
    //     newUser !== "" && setState({ event_logs: [...state.event_logs, newUser] })
    // }


    return (

        <div>

            <Prompt
                when={prompt && tStore.leaveStatus}
                message=' are you sure you want to leave?'
            />


            {/* <MobileView>
                <div id="video" style={{ display: video2 }}
                className="jms-video-mobile-view"
                >
                    <Loader style={{ display: visible, margin: 'auto', padding: '180px 0px 0px 450px' }} type="ball-scale-multiple" />
                    <AppFooter />
                    <SweetAlert
                        title="Video Session Ended"
                        confirmButtonColor=""
                        show={sweet}
                        text="Video Has Stoped"
                        type="warning"
                        onConfirm={() => handleCloseSuccesstModal2()} />
                </div>
            </MobileView> */}

            <div id="video" style={{ display: video2 }}>

                <Loader style={{ display: visible, margin: 'auto', padding: '180px 0px 0px 450px' }} type="ball-scale-multiple" />
                {/* <AppFooter /> */}
                {/* <SweetAlert
                    title="Video Session Ended"
                    confirmButtonColor=""
                    show={sweet}
                    text="Video Has Stoped"
                    type="warning"
                    onConfirm={() => handleCloseSuccesstModal2()} /> */}
            </div >
            <div height="500px" style={{ display: video, margin: 'auto', padding: '180px 0px 0px 450px' }} >
                <h3>
                    Your session has ended...
               </h3>
            </div>
            <Modals
                open={unSupportBrowser}
                handleClose={closeUnsupportPopup}
                title={`Warning`}
            >
                <>
                    <BrowserNotSupport />
                </>

            </Modals>
        </div>
    )
}


export default Index




