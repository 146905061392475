import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

import { enquirySubmission } from '../../../../redux/Actions/signUp'

import contact from './../../images/contact.png';


const Enquiry = () => {
    const dispatch = useDispatch()
    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const subscription = useSelector(({ signUp }) => signUp)
    console.log("enquiryyy", subscription);
    const [authFields, setAuthFields] = useState({})


    const handlechange = (e) => {
        const { name, value } = e.target;
        setAuthFields({ ...authFields, [name]: value })
    }
    const equirySubmit = (e) => {
        e.preventDefault()
        dispatch(enquirySubmission(authFields, reset))
    }

    const reset = () => {
        document.getElementById("enquiry-form").reset();
    }

    return (
        <Fragment>
            <section id="enquiry" class="contact-section">
                <img class="img-fluid contact-piece" src={contact} />
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 d-flex align-items-center">
                            <div class="contact-left">
                                <h5 className="hh5">Enquiry</h5>
                                <p>If you need any assistance or clarifications, please fill in your contact details
                                    and our guidance counselor will contact you. </p>
                            </div>
                        </div>
                        <div class="col-lg-8">
                            <form onSubmit={equirySubmit} id="enquiry-form">

                                <div class="contact-box">
                                    <div class="contact-hdr">Get in Touch</div>

                                    <input
                                        class="input-filed" placeholder="Name" type="text"
                                        name="name" id="name" required
                                        onChange={handlechange}
                                    />
                                    <input
                                        class="input-filed" placeholder="Email" type="email"
                                        name="email" id="email" required
                                        onChange={handlechange}
                                    />
                                    <input
                                        class="input-filed" placeholder="Phone" type="number"
                                        name="phone" id="phone" required
                                        onChange={handlechange}
                                    />
                                    <textarea
                                        class="text-filed" placeholder="Message" name="message"
                                        required
                                        onChange={handlechange}
                                    ></textarea>

                                    {/* <button class="button-blue-big"
                                        type="submit"
                                        //onClick={(e) => equirySubmit(e)}
                                        loading={subscription.createSubLoaderSchool}>
                                        Submit</button> */}

                                    <LaddaButton class="button-blue-big"
                                        loading={subscription.subscriptionLoader}
                                        // onClick={formSubmit}
                                        type="submit"
                                        data-style={ZOOM_IN}
                                        style={{
                                            color: '#fff', padding: '14px 35px', background: '#4445a1', fontSize: '15px',
                                            fontWeight: '600', borderRadius: '5px', marginTop: '10px', border: 'none'
                                        }}

                                    >
                                        Submit
                                            </LaddaButton>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default Enquiry;
