import React, { useState, useEffect } from 'react'
import { addStudentToVideoClassroom, addStudentToVideoClassroomEnter } from '../../../redux/Actions/playlist'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import Loader from 'react-loaders'
import LaddaButton, { ZOOM_IN } from 'react-ladda';

import {
    Row, Col,
    Card, CardBody, CardTitle,
    CardHeader, CardFooter, Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Nav,
    NavItem,
    NavLink,
    CustomInput,
    FormGroup, Form
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const AddStudentToClass = (props) => {

    // const videoStore = useSelector(({ playlist }) => playlist)

    // const data = useSelector((state) =>state.playlist.specificStudent ? state.playlist.specificStudent : []);


    useEffect(() => {

        return () => {
            dispatch({ type: 'RESET_ASSIGN_STUDENT_VIDEO' })
        }
    }, [])
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();

    let data;
    const pStore = useSelector(({ playlist }) => playlist)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    if (tokenData.enterpriseUser) {
        data = tStore.specificGroup

    } else {
        data = pStore.specificStudent
    }
    const handleChange = () => {
        dispatch({ type: 'CHANGE_ASSIGN_STUDENT_TO_CLASS' })
    }
    const handleClick = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        body.class_id = props.classId
        if (tokenData.enterpriseUser) {
            // dispatch(addStudentToVideoClassroomEnter(props.classId, body, tokenData.token, props.handleCloseStudentModal, props.timeData))

            dispatch(addStudentToVideoClassroomEnter(tokenData.token, body, props.handleCloseStudentModal))

        } else {
            dispatch(addStudentToVideoClassroom(tokenData.token, body, props.handleCloseStudentModal))

        }

    };
    console.log("tStore...tStore", tStore.specificGroup);

    return (
        <Form onSubmit={handleClick}>
            <input name="video_class_id" type="hidden" value={props.classId} />
            <Card className="main-card mb-3">
                <CardHeader>
                    <Col md={5} sm={5}>
                        {tokenData.enterpriseUser ? 'Group' : 'Student'}
                    </Col>
                    <Col md={7} sm={7}>
                        {tokenData.enterpriseUser ? '' : 'Phone'}


                    </Col>
                </CardHeader>
                <CardBody>
                    <div className="scroll-area-sm">
                        <PerfectScrollbar>


                            {
                                props.loader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }

                            {
                                tStore.specificStudentLoader ? <p style={{ position: 'absolute', top: '20%', left: '45%' }}><Loader color="#f7b924" type="ball-rotate" /> </p> : null
                            }

                            {props.data ? props.data.map(dt => (
                                <Row key={dt.user_id ? dt.user_id : dt.group_id}>
                                    <Col md={5} sm={5} style={{ paddingLeft: '30px' }}>
                                        {dt.user_id ? dt.first_name : dt.group_name} {dt.user_id ? dt.last_name : null}
                                    </Col>
                                    {
                                        !tokenData.enterpriseUser ?
                                            <Col md={5} sm={5} style={{ paddingLeft: '20px' }}>
                                                {`${dt.user_id ? dt.mobile : null}`}
                                            </Col> : null
                                    }

                                    <Col md={2} sm={2} >
                                        <FormGroup>
                                            <div>
                                                <input type="checkbox" id="exampleCustomCheckbox"
                                                    name={dt.user_id ? "registered_student[]" : "group_assign_id[]"}
                                                    defaultChecked={dt.selected}
                                                    // checked={dt.selected}
                                                    // checked={selectedStudents.includes(dt.user_id ? dt.user_id : dt.group_id) ? true : false}
                                                    onChange={() => handleChange(dt.user_id ? dt.user_id : dt.group_id)}
                                                    value={dt.user_id ? dt.user_id : dt.group_id}
                                                />

                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                            )) : null}

                        </PerfectScrollbar>
                    </div>
                </CardBody>
                <CardFooter className="d-block text-right">

                    <LaddaButton className="mb-2 mr-2 btn btn-danger"
                        loading={pStore.assignStudentLoader}
                        // style={{ width: '-webkit-fill-available' }}

                        type="button"
                        data-style={ZOOM_IN}
                        type="submit"
                    >
                        Save
                                            </LaddaButton>
                    {/* <Button  size="lg" color="success">Save</Button> */}
                </CardFooter>
            </Card>
        </Form>
    )
}

export default AddStudentToClass
