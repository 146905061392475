import React, { Fragment } from 'react';
import OwlCarousel from 'react-owl-carousel';

import himg5 from './../../images/features-tale.png';
import himg6 from './../../images/features/live_class.png';
import himg7 from './../../images/features/class_schedule.png';
import himg8 from './../../images/features/assignment.png';
import himg9 from './../../images/features/attendance.png';
import himg10 from './../../images/features/recording.png';
import himg11 from './../../images/features/report.png';


const options = {
    responsiveClass: true,
    nav: true,
    loop: true,
    margin: 10,
    dots: false,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 2,
        },
        1000: {
            items: 4,

        }
    },
};

const Features = () => {
    return (
        <Fragment>
            <section id="features" class="features-area">
                <img class="img-fluid feature-tale" src={himg5} alt='feature-image' />
                <div class="container">
                    <h3 className="hh3">Features</h3>

                    <div class="featurs-grid">
                        <div class="row">
                            {/* <div id="feature-slide" class="owl-carousel"> */}
                            <OwlCarousel
                                id="feature-slide"
                                className="owl-carousel"
                                {...options}
                            >
                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg6} alt='feature-image'/>
                                        <h4>Live<br />
                                            classroom</h4>
                                        <p>Fully manageable class room with high quality audio and video.
</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg7} alt='feature-image'/>
                                        <h4>Class<br />
                                            scheduling</h4>
                                        <p>Teacher can schedule classes for a week / Month / Year. Option to
                                        reschedule and special class creation.
                                        </p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg8} alt='feature-image'/>
                                        <h4>Assignments<br /><br />
                                        </h4>
                                        <p>Assignment management system with assessment.</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg9} alt='feature-image'/>
                                        <h4>Session<br />
                                            recording</h4>
                                        <p>Live recording of tutoring for on-demand use.</p>
                                    </div>
                                </div>

                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg10} alt='feature-image'/>
                                        <h4>Attendance<br /><br />
                                        </h4>
                                        <p>Automated attendance tracking.</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-lg-12">
                                    <div class="featue-box">
                                        <img class="img-fluid" src={himg11} alt='feature-image'/>
                                        <h4>Reports<br /><br />
                                        </h4>
                                        <p>Proper MIS report.</p>
                                    </div>
                                </div>
                            </OwlCarousel>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section class="tale-content">
                <div class="container">
                    <p>
                        <b>MOTS</b> is equipped with variety of features like and engaging live classroom, class scheduling list for
                    present and future use, an assignment section to upload, evaluate and grade assignments, attendance section to
                    keep track of the students attending classes regularly, a report section to generate monthly reports of the class and students
                     which can be mailed to the respective students or their parents or can be used for future reference.
                    There is also a session recording system where students can record the class and keep for future reference.
                    There are additional features like upcoming class list on the dashboard, timeline and activity view, forthcoming class alert and countdown,
                    general notifications, assignment list and reports of attendance, assignments etc.
                   </p>
                </div>
            </section>
        </Fragment>
    );
}
export default Features;
