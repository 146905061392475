import React from 'react'
import DataTable from 'material-table'
import './../../common/DataTable/styleee.scss'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import AddIcon from '@material-ui/icons/Add';
// import { Row, Col, Button } from 'reactstrap'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
const Table = (props) => {
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    return (

        <DataTable

            isLoading={props.loader}
            title={props.title ? props.title : 'Class'}
            columns={props.columns}
            data={props.data}
            options={{
                search: true,
                sorting: true,
                // rowStyle: {
                //     backgroundColor: '#EEE',
                // },
                actionsColumnIndex: -1,
                //selection: true



            }}

            // components={{
            //     Action: props => (
            //         <Button
            //             // onClick={(event) => props.action.onClick(event, props.data)}
            //             color="primary"
            //             variant="contained"
            //             style={{ textTransform: 'none' }}
            //             size="small"
            //         >
            //             My Button
            //         </Button>
            //     ),

            // }}
            actions={
                props.actions ?

                    props.actions === "video_class" && props.role !== "student" ?
                        tokenData.role !== "principal" && tokenData.role === "teacher" ?
                            [
                                {
                                    icon: () => <AddIcon style={{ color: '#3b6ac3' }} />,

                                    tooltip: 'Assign Students',
                                    onClick: (event, rowData) => props.hadleOpenStudentModal(rowData.video_class_id, rowData.grade_id)
                                },
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Class',
                                    onClick: (event, rowData) => props.editClass(rowData.video_class_id, rowData.subject_id)
                                },
                                {
                                    icon: () => <PlayCircleFilledIcon style={{ color: 'rgb(41, 183, 155)' }} />,

                                    tooltip: 'Video List',
                                    onClick: (event, rowData) => props.onVideopress(rowData.video_class_id)
                                },
                                rowData => ({
                                    icon: () => <PowerSettingsNewIcon

                                        style={rowData?.status == 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }} />,
                                    tooltip: 'Enable/Disable Class',
                                    onClick: (event, rowData) => props.changeStatus(rowData.video_class_id, rowData.status, rowData.teacher_id),

                                }),
                            ]
                            : tokenData.role === "principal" ?
                                [
                                    rowData => ({
                                        icon: () => <PowerSettingsNewIcon

                                            style={rowData?.status == 1 ? { color: 'rgb(41, 183, 155)' } : { color: 'red' }} />,
                                        tooltip: 'Enable/Disable Class',
                                        onClick: (event, rowData) => props.changeStatus(rowData.video_class_id, rowData.status, rowData.teacher_id),

                                    }),
                                    {
                                        icon: () => <AddIcon style={{ color: '#3b6ac3' }} />,

                                        tooltip: 'Assign Students',
                                        onClick: (event, rowData) => props.hadleOpenStudentModal(rowData.video_class_id, rowData.grade_id)
                                    },
                                    {
                                        icon: 'edit',
                                        tooltip: 'Edit Class',
                                        onClick: (event, rowData) => props.editClass(rowData.video_class_id, rowData.subject_id)
                                    },
                                    {
                                        icon: () => <PlayCircleFilledIcon style={{ color: 'rgb(41, 183, 155)' }} />,

                                        tooltip: 'Video List',
                                        onClick: (event, rowData) => props.onVideopress(rowData.video_class_id)
                                    }
                                ]
                                : [
                                    {
                                        icon: () => <PlayCircleFilledIcon style={{ color: 'rgb(41, 183, 155)' }} />,

                                        tooltip: 'Video List',
                                        onClick: (event, rowData) => props.onVideopress(rowData.video_class_id)
                                    },
                                ]
                        : [
                            {
                                icon: () => <PlayCircleFilledIcon style={{ color: 'rgb(41, 183, 155)' }} />,

                                tooltip: 'Video List',
                                onClick: (event, rowData) => props.onVideopress(rowData.video_class_id)
                            },
                        ] : null
            }

        />
    )
}
export default Table