import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import CreateSchoolClass from './index'
import CreateCollegeClass from './college'
import CreateLifeLongClass from './lifeLong'
import classnames from 'classnames';

import {

    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody,
    CardHeader,Row,Col

} from 'reactstrap'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import PageTitle from '../../HeaderTitle/PageTitle'
import Showclass from "../ShowClass/index"
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import { logout, authCheck } from '../../../redux/Actions/auth'

const Index = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(authCheck(tokenData.token, cbLogOut))
    }, [])

    const [state, setState] = useState({
        activeTab: '1',
        showMore: true,
        transform: true,
        showInkBar: true,

        selectedTabKey: 0,
        transformWidth: 400,
    })
    const toggle = (tab) => {
        dispatch({ type: 'RESET_SUBJECT' })
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }

    const cbLogOut = () => {

        dispatch(logout())
        localStorage.removeItem("authDetails");
        history.push('/')
        Swal.fire({
            icon: 'error',
            title: '',
            text: 'Token Expired',

        })
    }
    return (
        <>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <PageTitle
                    style={{ display: 'none' }}
                    heading="Create Class"
                    subheading="Create class and  your time slot for students"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />

                {/* <Tabs
                    defaultActiveKey="1"
                    renderTabBar={() => <ScrollableInkTabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab='School' key="1"><CreateSchoolClass /></TabPane>
                    <TabPane tab='College' key="2"><CreateCollegeClass /></TabPane>
                </Tabs> */}



                <Card tabs={true} className="mb-3">
                    <CardHeader>
                        <Nav justified>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '1' })}
                                    onClick={() => {
                                        toggle('1');
                                    }}
                                >
                                    School
                                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '2' })}
                                    onClick={() => {
                                        toggle('2');
                                    }}
                                >
                                    College/University
                                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#"
                                    className={classnames({ active: state.activeTab === '3' })}
                                    onClick={() => {
                                        toggle('3');
                                    }}
                                >
                                    Life Long Course
                                                </NavLink>
                            </NavItem>

                        </Nav>
                    </CardHeader>
                    <CardBody>

                        <TabContent activeTab={state.activeTab}>
                            <TabPane tabId="1">
                                <CreateSchoolClass />
                            </TabPane>
                            <TabPane tabId="2">
                                <CreateCollegeClass />
                            </TabPane>
                            <TabPane tabId="3">
                                <CreateLifeLongClass />
                            </TabPane>

                        </TabContent>

                    </CardBody>
                </Card>

                <Row>
                    <Col xs={12}>
                        <Showclass/>
                    </Col>
                </Row>


            </CSSTransitionGroup>
        </>


    )
}

export default Index
