import React from 'react'
import { Button, CardHeader, CardFooter, Table, Card } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay'
// import { useHistory, Link } from 'react-router-dom'

// import {
//     faArchive,
//     faAngleUp,
//     faAngleDown,
//     faCalendarAlt,
//     faCloudSunRain,
//     faEllipsisH,
//     faCheck,
//     faTrashAlt,
//     faBusinessTime,
//     faCog
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'react-loaders';

const index = (props) => {

    const { List_of_student, getDetails, updateExam, emailResult } = props
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

   

return (

        <LoadingOverlay tag="div" active={false}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: '#fff',
                    opacity: 0.5
                })
            }}
            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

            
                

                        
                            {
                                List_of_student?.map((list, index) => {
                                    return (   <Card className="main-card mb-3">
                                    <CardHeader>
                                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                            {tokenData.enterpriseUser ? list.group_name:'Students'}
                                    </div>
                                        <div className="btn-actions-pane-right">
                
                                        </div>
                                    </CardHeader>
                                    
                                    <Table responsive borderless hover className="align-middle text-truncate mb-0 table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th className="text-center"> Student</th>
                                            <th className="text-center">Email</th>
                                            <th className="text-center">Mark</th>
                                            <th className="text-center">Date</th>
                                            <th className="text-center">Time</th>
                                            <th className="text-center">Actions</th>
                                        </tr>
                                    </thead> <tbody>                   
                                       {
                                           list.group_details.map((subitem,i)=> {
                                               return(
                                                   
                                                      <tr>
                                                       
                                                       <th scope='row'  className="text-center">{i+1}</th>
                                                       <td className="text-center"><h6>{subitem.student_name}</h6></td>
                                                       <td className="text-center">{subitem.email}</td>
                                                       <td className="text-center">{subitem.mark==='not evaluvated'?<div className='badge badge-pill badge-warning'>Not evaluated</div>:subitem.mark==='not attendend'?<div className='badge badge-pill badge-danger'>{subitem.mark}</div>:subitem.mark}</td>  
                                                       <td className="text-center">{subitem.date}</td>
                                                       <td className="text-center">{subitem.time}</td>
                                                       <td className="text-center "><Button className=" btn-icon btn-icon-only btn-pill mr-2  " outline onClick={() => getDetails(subitem.answer_sheet_id, subitem.student_id)} color="primary">Details</Button> 
                                                       <Button className=" btn-icon btn-icon-only btn-pill mr-2" outline onClick={() => updateExam(subitem)} color="danger">Update</Button>
                                                       <Button className=" btn-icon btn-icon-only btn-pill" outline onClick={() => emailResult(subitem)} color="danger">Email</Button>
                                                       </td>
                                                      {/* <td className="text-center "><Button className=" btn-icon btn-icon-only btn-pill" outline onClick={() => updateExam(subitem)} color="primary">Update</Button> </td> */}
                                                       </tr>
                                                                                     
                                                
                                                )
                                           })
                                       } </tbody> </Table>
                                        
                    <CardFooter className="d-block p-4 text-center">
                       
                    </CardFooter>
                </Card>
                                       
                                    )
                                })
                            }
                            
                        

                       
                   

            

        </LoadingOverlay>
    )
}

export default index
