import React, { useEffect, } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap'

import './subject.scss'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeListLifeLong, populateDegree } from '../../../redux/Actions/signUp'
import { createSubjectLifeLong, populateSubject, updateSubjectLifeLond, deleteSubjectLifeLong } from '../../../redux/Actions/homeTeacher'
// import serialize from 'form-serialize'
// import Table from '../../common/DataTable'
import Swal from 'sweetalert2'


// const columns = [
//     { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
//     { title: 'Course', field: 'grade_name', editable: 'never' },



// ]

const Index = (props) => {
    const { getPlaylist, errors } = props;
    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        dispatch(getGradeListLifeLong(null, 3))
        //  dispatch(populateAllLifelongSubjects(tokenData.token))



        return () => {
            dispatch({ type: 'RESET_SUBJECT' })
            dispatch({ type: 'RESET_PLAYLIST_VIDEOS' })


        }
    }, [])

    const handleChangeGrade = e => {
        const { name, value } = e.target;
        //setInputField({ ...inputField, [name]: value })
        dispatch(populateSubject(tokenData.token, value, 3))
    };
    const handleChangeSubject = (e) => {
        dispatch({ type: 'RESET_PLAYLIST' })
        getPlaylist(e.target.value)
    }
    const onEdit = (rowData) => {
        console.log("row-", rowData)
        dispatch(updateSubjectLifeLond(tokenData.token, rowData))

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubjectLifeLong(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }

    // const formSubmit = (e) => {
    //     e.preventDefault()
    //     const form = e.currentTarget
    //     let body = serialize(form, { hash: true, empty: true })
    //     dispatch(createSubjectLifeLong(tokenData.token, body, reset))

    // }
    const reset = () => {
        document.getElementById("create-form-lifelong").reset();
    }
    return (
        <>







            <Card>
                <Row className="jms-subject-row-pad">
                    <Col md={6}>
                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Title</Label>
                            <Input
                                //  required
                                type="text" name="title"
                                invalid={errors.title}

                            />
                            <FormFeedback >{errors.title} </FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <input type="hidden" value={3} name="category_id" />

                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Course</Label>
                            <CustomInput type="select" name="grade_id"
                                invalid={errors.grade_id}

                                //required
                                onChange={(e) => handleChangeGrade(e)}

                            >
                                <option value="">Select</option>

                                {
                                    Grades.gradeLifeLong.grades ? Grades.gradeLifeLong.grades.map((data) => {
                                        return (
                                            <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                        )
                                    })
                                        : []
                                }


                            </CustomInput>
                            <FormFeedback >{errors.grade_id}  </FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col sm={3}>

                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Select Subject
                        </Label>
                        <div class="form-group">
                        <div class="input-group">
                        <Input type="select"
                            name='subject_id'
                            //  required
                            invalid={errors.subject_id}
                            onChange={handleChangeSubject}
                        >
                            <option value={''}>{'select'}</option>

                            {tStore.subject ?
                                tStore.subject.map(subj => {
                                    return (
                                        <option value={subj.subject_id}>{subj.subject_name}</option>

                                    )
                                }) : null
                            }

                        </Input>
                        {
                            tStore.loadersub ? 
                            <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            </span>
                            :
                            null
                        }
                        
                        </div>
                        </div>
                        <FormFeedback >{errors.subject_id}</FormFeedback>

                    </Col>

                </Row>
            </Card>

            <hr />
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>

                </Col>

            </Row>



        </>

    )
}

export default Index
