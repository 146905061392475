import axios from 'axios'
import { development } from '../../config.json'
import Swal from 'sweetalert2';



export const createEnterprise = (token, formdata, cb) => (dispatch) => {

    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            // dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
            // if (percent < 100) {
            //     this.setState({ uploadPercentage: percent })
            // }
        },
    };

    //  console.log(`head ${head.Authorization} data ${data.class_name}`)
    // dispatch({ type: "UPLOAD_COURSE_FILES_REQUEST" });
    axios
        .post(`${development.baseUrl}createEnterprise`, formdata, options)
        .then((res) => {
            if (res.data.result) {
                dispatch({
                    type: "UPLOAD_COURSE_FILES_SUCCESS",
                    payload: res.data,
                });
                cb(res.data)
                
            }
            else {
                let percent = ''
                // dispatch({ type: "UPLOAD_COURSE_FILES_FAILED" });
                // dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.data.message,
                });
            }
        })
        .catch((err) => {
            // dispatch({ type: "EDIT_COURSE_FILES_FAILED", payload: err });
            let percent = ''
            dispatch({ type: "UPLOAD_COURSE_FILES_FAILED", payload: err });
            dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)

                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }


        });
};

export const listAllEnterprises = (token) => (dispatch) => {

    const head = {
        Accept : 'application/json',
        Authorization: `Bearer ${token}`
    }

    axios.get(`${development.baseUrl}listAllEnterprises`,{headers:head})
    .then( res=> {
            if(res.data.result){
                dispatch({ type: 'LIST_ENTERPRISE_SUCCESS', payload: res.data.enterprises })
                console.log("datatata".res.data)
            }
    } )
    .catch(
        err =>{
            console.log(err)
        }
    )
}

// export const listAllEnterprises = (token) => (dispatch) => {
//     const head = {
//         Accept: 'application/json',
//         Authorization: `Bearer ${token}`,
//     }
//     dispatch({ type: 'VENDORLIST_ALL_REQUEST', payload: { vendorloader: true } })
//     axios.get(`${development.baseUrl}listAllVendors`, { headers: head })
//         .then(res => {
//             if (res.data) {
//                 dispatch({ type: 'VENDORLIST_ALL_SUCCESS', payload: { VendorArray: res.data, vendorloader: false } })

//             }
//         })
//         .catch(err => {
//             dispatch({ type: 'VENDORLIST_ALL_FAILED', payload: { vendorloader: false } })
//             if (err) {
//                 if (err.response) {
//                     if (err.response) {
//                         if (err.response.status === 401) {
//                             Swal.fire({
//                                 icon: 'error',
//                                 title: 'Something Went Wrong',
//                                 // text: 'You are logged in to another System'
//                             })
//                         }
//                     }
//                 }
//                 else {
//                     Swal.fire({
//                         icon: 'error',
//                         title: 'Something Went Wrong',
//                         // text: 'You are logged in to another System'
//                     })
//                 }
//             }
//         })
// }
export const blockEnterprise = (token,id,cb) => (dispatch) => {

    const head = {
        Accept : 'application/json',
        Authorization: `Bearer ${token}`
    }
    const data = {
        enterprise_id:id
    }
    axios.post(`${development.baseUrl}blockEnterprise`,data,{headers:head})
    .then( res=> {
            if(res.data.result){
                Swal.fire('Success',res.data.message, 'success')
                cb()
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
    } )
    .catch(
        err =>{
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                // text: 'You are logged in to another System'
            })
        }
    )
}

export const editEnterprise = (token,id,cb) => (dispatch) => {

    const head = {
        Accept : 'application/json',
        Authorization: `Bearer ${token}`
    }
    const data = {
        enterprise_id:id
    }
    axios.post(`${development.baseUrl}editEnterprise`,data,{headers:head})
    .then( res=> {
            if(res.data.result){
                //Swal.fire('Success',res.data.message, 'success')
                cb(res.data.enterprise)
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }
    } )
    .catch(
        err =>{
            console.log(err)
            Swal.fire({
                icon: 'error',
                title: 'Something Went Wrong',
                // text: 'You are logged in to another System'
            })
        }
    )
}

export const updateEnterprise = (token, formdata, cb) => (dispatch) => {

    const options = {
        headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
        },

        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            // dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
            // if (percent < 100) {
            //     this.setState({ uploadPercentage: percent })
            // }
        },
    };

    //  console.log(`head ${head.Authorization} data ${data.class_name}`)
    // dispatch({ type: "UPLOAD_COURSE_FILES_REQUEST" });
    axios
        .post(`${development.baseUrl}updateEnterprise`, formdata, options)
        .then((res) => {
            if (res.data.result) {
                dispatch({
                    type: "UPLOAD_COURSE_FILES_SUCCESS",
                    payload: res.data,
                });
                cb(res.data)
                
            }
            else {
                let percent = ''
                // dispatch({ type: "UPLOAD_COURSE_FILES_FAILED" });
                // dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });

                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: res.data.message,
                });
            }
        })
        .catch((err) => {
            // dispatch({ type: "EDIT_COURSE_FILES_FAILED", payload: err });
            let percent = ''
            dispatch({ type: "UPLOAD_COURSE_FILES_FAILED", payload: err });
            dispatch({ type: "UPLOAD_PROGRESS_IMAGE", payload: percent });
            if (err) {
                if (err.response) {
                    if (err.response.status === 422) {
                        var val = Object.values(err.response.data.errors)

                    }
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Something Went Wrong',
                    // text: 'You are logged in to another System'
                })
            }


        });
};

export const checkEnterpriseNumberExists = (token,name,cb) => (dispatch) => {

    const head = {
        Accept : 'application/json',
        Authorization: `Bearer ${token}`
    }
    const data = {
        enterprise_number:name
    }
    axios.post(`${development.baseUrl}checkEnterpriseNumberExist`,data,{headers:head})
    .then( res=> {
            if(res.data.result){
               // Swal.fire('Success',res.data.message, 'success')
               dispatch({type:'ENTERPRISE_ID_AVILABILITY_STATUS',payload:true})
                //cb()
                console.log(res.data)
            }else{
                dispatch({type:'ENTERPRISE_ID_AVILABILITY_STATUS',payload:false})
                console.log(res.data)
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Something Went Wrong',
                //     // text: 'You are logged in to another System'
                // })
            }
    } )
    .catch(
        err =>{
            console.log(err)
            // Swal.fire({
            //     icon: 'error',
            //     title: 'Something Went Wrong',
            //     // text: 'You are logged in to another System'
            // })
        }
    )
}