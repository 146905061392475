import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap'
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import { emailValidator } from '../../common/Validators';
import Table from '../table';
import { showTeachers, getClassList, deleteUser, updateTeacher } from '../../../redux/Actions/videolist'
import Modals from '../../common/Modal';
import Teacher from '../Teacher';
import EditTeacher from './EditTeacher';
import EditPrivilege from '../EditPrivilege';
import Swal from 'sweetalert2'
import { nameValid, requiredValidator, mobileValidator, emailValidator } from '../../../utilities/Validator';

const columns = [
    // { title: '#', field: 'id' },
    {
        title: 'First Name ', field: 'first_name',
    },
    { title: ' Last Name', field: 'last_name' },
    // { title: 'Subject', field: 'privilege' },
    { title: 'Email', field: 'enterprise_email' },
    { title: 'Password', field: 'user_password' },

    { title: 'Mobile', field: 'enterprise_mobile' },
    // { title: 'Roll Number', field: 'enrollment_no' },
]


const ShowTeacher = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const tStore = useSelector(({ videolist }) => videolist)
    // const teachers = tStore.teacherList;
    console.log("historys", tStore.teacherList);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const param = useParams()
    console.log("param ", param);

    useEffect(() => {
        dispatch(showTeachers(tokenData.token))
        return () => {

        }
    }, [])

    const [openModal, setOpenModal] = useState(false)
    const [openPermissionModal, setPermissionModal] = useState(false)
    const [userId, setUserId] = useState()

    const handleCloseStudentModal = () => {
        setOpenModal(false)
    }

    const onEdit = (rowData) => {
        console.log(rowData);

        const err = {
            enterprise_email: emailValidator(rowData.enterprise_email),
            first_name: nameValid(rowData.first_name),
            last_name: nameValid(rowData.last_name),
            user_password: requiredValidator(rowData.user_password),
            enterprise_mobile: mobileValidator(rowData.enterprise_mobile),

        }
        if (err.enterprise_email) {
            Swal.fire(
                'Email',
                err.enterprise_email,
                'warning'
            )
        } else if (err.first_name) {
            Swal.fire(
                'First name',
                err.first_name,
                'warning'
            )
        } else if (err.last_name) {
            Swal.fire(
                'Last name',
                err.last_name,
                'warning'
            )
        } else if (err.user_password) {
            Swal.fire(
                'Password',
                err.user_password,
                'warning'
            )
        } else if (err.enterprise_mobile) {
            Swal.fire(
                'Mobile',
                err.enterprise_mobile,
                'warning'
            )
        } else {
            dispatch(updateTeacher(tokenData.token, rowData))

        }


    }
    const [userID, setUserID] = useState()
    const onPermission = (user_id) => {
        dispatch({ type: 'RESET_EDIT_PRIVILEGE' })

        setUserID(user_id)
        setPermissionModal(true)
    }

    const handleClosePermissionModal = () => {
        setPermissionModal(false)
    }

    const onDelete = (user_id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteUser(tokenData.token, user_id,cb))

                Swal.fire(
                    'Deleted!',
                    'Deleted teacher successfully.',
                    'success'
                )
            
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })
    }

    const cb = () => {
        dispatch(showTeachers(tokenData.token))
    }
    return (
        <>
            <Row style={{ paddingTop: '35px' }}>
                <Col md={12}>
                    <Table
                        title={'Show Teacher'}
                        columns={columns}
                        actions={"show_user"}
                        loader={tStore.userSubLoader}
                        data={tStore.teacherList}
                        loader={tStore.userSubLoader}
                        onEdit={onEdit}
                        onPermission={(user_id) => onPermission(user_id)}
                        onDelete={(user_id) => onDelete(user_id)}
                    />
                </Col>

            </Row>
            <Modals
                open={openModal}
                handleClose={handleCloseStudentModal}
                title={`Edit Form`}
            >
                <EditTeacher
                    user_id={userId}
                />
            </Modals>
            <Modals
                open={openPermissionModal}
                handleClose={handleClosePermissionModal}
                title={`Permission`}
            >
                <EditPrivilege
                    user_id={userID}
                    handleClose={handleClosePermissionModal}

                />
            </Modals>

        </>
    )
}

export default ShowTeacher;