import React, { useState, useEffect, Fragment } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { getMyAccount } from '../../redux/Actions/homeTeacher'
import avatar1 from '../../assets/utils/images/avatars/1.jpg';
import bg1 from '../../assets/utils/images/dropdown-header/abstract1.jpg';
import copy from 'copy-to-clipboard';

import {
    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    CardBody,
    Progress,
    ListGroup,
    ListGroupItem,
    CardFooter,
    CustomInput, Input,
    Dropdown, DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

// import CountUp from 'react-countup';

// import ReactTable from "react-table";

// import avatar1 from '../../../../assets/utils/images/avatars/1.jpg';
// import avatar2 from '../../../../assets/utils/images/avatars/2.jpg';

// import Ionicon from 'react-ionicons';
import { IoIosAnalytics } from "react-icons/io";

import PerfectScrollbar from 'react-perfect-scrollbar';

import Slider from "react-slick";

// import { makeData } from "../../../Tables/DataTables/Examples/utils";

import {
    ResponsiveContainer,
    AreaChart,
    Area,
} from 'recharts';

import {
    Sparklines,
    SparklinesCurve
} from 'react-sparklines';

import {
    faAngleUp,
    faAngleDown,
    faCalendarAlt,
    faEllipsisH,
    faCheck,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Select from 'react-select';


import { useHistory } from 'react-router-dom'

import classnames from 'classnames';

import {

    Table,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
} from 'reactstrap';



import {
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';




// function boxMullerRandom() {
//     let phase = true,
//         x1, x2, w;

//     return (function () {

//         if (phase) {
//             do {
//                 x1 = 2.0 * Math.random() - 1.0;
//                 x2 = 2.0 * Math.random() - 1.0;
//                 w = x1 * x1 + x2 * x2;
//             } while (w >= 1.0);

//             w = Math.sqrt((-2.0 * Math.log(w)) / w);
//             return x1 * w;
//         } else {
//             return x2 * w;
//         }
//     })();
// }

// function randomData(n = 30) {
//     return Array.apply(0, Array(n)).map(boxMullerRandom);
// }

// const sampleData = randomData(10);
// const sampleData2 = randomData(15);
// const sampleData3 = randomData(8);
// const sampleData4 = randomData(12);


// test
console.log("55555")

const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    console.error(tokenData.role)

    const dispatch = useDispatch();

    // const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)

    // const [state, setState] = useState({
    //     data: makeData(),
    //     dropdownOpen: false
    // });
    useEffect(() => {
        dispatch(getMyAccount(tokenData.token))
    }, [])
    const history = useHistory()

    const accountInfo = useSelector(({ homeTeacher }) => homeTeacher)

    // console.log("homeDetail", homeDetail)

    const joinClass = (weekId, classId) => {

        // dispatch(validateStartClass(tokenData.token, id, cb))

        if (tokenData.role === 'student') {
            history.push(`/dashboard/join/${weekId}/${classId}`)
        } else if (tokenData.role === 'teacher') {
            history.push(`/dashboard/startclass/${weekId}/${classId}`)
        }
        else {
            alert("invalid")
        }
    }
    // setState({
    //     selectedOption: null,
    // });

    // const handleChange = (selectedOption) => {
    //     setState({ selectedOption });
    // };

    const toggle = () => {
        // setState(prevState => ({
        //     dropdownOpen: !prevState.dropdownOpen
        // }));
    }

    const myfunction = () => {
        toast.configure();
        copy('https://mymots.com/#/teacher-register?referal=' + accountInfo.myAccountInfo.referral_code);
        toast.error("Copied", {
            position: toast.POSITION.BOTTOM_CENTER
        });
    }
    const upgradePlan = () => {
        dispatch({ type: 'PAYMENT_UPGRADE_POPUP_REQUEST' })

    }

    // const { selectedOption } = state;
    // const { data } = state;

    // const settings = {
    //     className: "",
    //     centerMode: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     speed: 500,
    //     dots: true,
    // };
    // const dateInFuture = '2029-12-31 23:59:59'              
    return (
        <Col md="12" lg="6" xl={{ span: 6, offset: 3 }}    >
            <Card className="card-shadow-primary profile-responsive card-border mb-3">
                <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-danger">
                        <div className="menu-header-image"
                            style={{
                                backgroundImage: 'url(' + bg1 + ')'
                            }}
                        />
                        <div className="menu-header-content btn-pane-right">
                            <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                                <div className="avatar-icon">
                                    <img src={avatar1} alt="Avatar 5" />
                                </div>
                            </div>
                            <div>
                                <h5 className="menu-header-title">{accountInfo.myAccountInfo ? accountInfo.myAccountInfo.first_name + " " + accountInfo.myAccountInfo.last_name : " "} </h5>
                                <h6 className="menu-header-subtitle">{accountInfo.myAccountInfo ? accountInfo.myAccountInfo.role : " "}</h6>
                            </div>
                            {/* <div className="menu-header-btn-pane">
                            <Button size="sm" className="btn-icon mr-2 btn-icon-only"
                                    color="warning">
                                <i className="pe-7s-config btn-icon-wrapper"> </i>
                            </Button>
                            <Button size="sm" className="btn-icon"
                                    color="success">
                                View Profile
                            </Button>
                        </div> */}
                        </div>
                    </div>
                </div>
                <ListGroup flush>
                    {tokenData.role === 'student' && !tokenData.enterpriseUser ?

                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={43}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#28b0cd'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {(accountInfo.myAccountInfo && accountInfo.myAccountInfo.grade) ?
                                        <>
                                            <div className="widget-content-left">
                                                <div className="widget-heading">
                                                    Grade
                </div>
                                                <div className="widget-subheading">
                                                    {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.grade : " "}
                                                </div>
                                            </div>

                                        </> : " "}
                                </div>
                            </div>
                        </ListGroupItem> : null
                    }
                    <ListGroupItem>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left mr-3">
                                    <div className="icon-wrapper m-0">
                                        <div className="progress-circle-wrapper">
                                            <Progress
                                                type="circle"
                                                percent={82}
                                                width="100%"
                                                theme={
                                                    {
                                                        active: {
                                                            trailColor: '#e1e1e1',
                                                            color: '#3ac47d'
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Email
                                </div>
                                    <div className="widget-subheading">
                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.email : " "}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ListGroupItem>
                    <ListGroupItem>
                        <div className="widget-content p-0">
                            <div className="widget-content-wrapper">
                                <div className="widget-content-left mr-3">
                                    <div className="icon-wrapper m-0">
                                        <div className="progress-circle-wrapper">
                                            <Progress
                                                type="circle"
                                                percent={47}
                                                width="100%"
                                                theme={
                                                    {
                                                        active: {
                                                            trailColor: '#e1e1e1',
                                                            color: '#f7b924'
                                                        }
                                                    }
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-content-left">
                                    <div className="widget-heading">
                                        Mobile
                                </div>
                                    <div className="widget-subheading">
                                        {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.mobile : " "}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </ListGroupItem>

                    {tokenData.role === 'teacher' && !tokenData.enterpriseUser ?

                        <ListGroupItem>
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left mr-3">
                                        <div className="icon-wrapper m-0">
                                            <div className="progress-circle-wrapper">
                                                <Progress
                                                    type="circle"
                                                    percent={43}
                                                    width="100%"
                                                    theme={
                                                        {
                                                            active: {
                                                                trailColor: '#e1e1e1',
                                                                color: '#28b0cd'
                                                            }
                                                        }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="widget-content-left">
                                        <div className="widget-heading">
                                            Referral Code
                                </div>
                                        <div className="widget-subheading">
                                            {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.referral_code : " "}

                                        </div>

                                    </div>
                                    <div className="widget-content-right">
                                        <button onClick={myfunction}>copy</button>
                                    </div>
                                </div>
                            </div>

                        </ListGroupItem> : null
                    }
                    {
                        tokenData.role === "teacher" && !tokenData.enterpriseUser ?
                            <ListGroupItem>
                                <div className="widget-content p-0">
                                    <div className="widget-content-wrapper">
                                        <div className="widget-content-left mr-3">
                                            <div className="icon-wrapper m-0">
                                                <div className="progress-circle-wrapper">
                                                    <Progress
                                                        type="circle"
                                                        percent={82}
                                                        width="100%"
                                                        theme={
                                                            {
                                                                active: {
                                                                    trailColor: '#e1e1e1',
                                                                    color: '#3ac47d'
                                                                }
                                                            }
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Plan
                                </div>
                                            <div className="widget-subheading">

                                                {accountInfo.myAccountInfo ? accountInfo.myAccountInfo.plan_name : " "}
                                            </div>
                                        </div>
                                        <div className="widget-content-right">
                                            <Button onClick={upgradePlan} color="warning">Choose Plan</Button>
                                        </div>
                                    </div>
                                </div>
                            </ListGroupItem>
                            : null
                    }
                </ListGroup>
            </Card>

        </Col>

    )

}
export default AnalyticsDashboard1