import React, { useEffect,  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, CustomInput,  FormGroup, Label, FormFeedback, Input } from 'reactstrap'

import './subject.scss'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeList, populateDegree } from '../../../redux/Actions/signUp'
import { createSubject, populateAllCollegeSubjects, updateSubject, deleteSubject, populateSubject } from '../../../redux/Actions/homeTeacher'
// import serialize from 'form-serialize'
// import Table from '../../common/DataTable'
import Swal from 'sweetalert2'


// const columns = [
//     { title: 'Subject', field: 'subject_name', editable: 'onUpdate' },
//     { title: 'Course', field: 'degree_name', editable: 'never' },
//     { title: 'Specialization', field: 'grade_name', editable: 'never' },



// ]

const Index = (props) => {
    const { getPlaylist, errors } = props
    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        dispatch(populateDegree())
        // dispatch(populateAllCollegeSubjects(tokenData.token))


        return () => {
            dispatch({ type: 'RESET_SUBJECT' })
            dispatch({ type: 'RESET_PLAYLIST_VIDEOS' })


        }
    }, [])
    const handleChangeSpecialization = (e) => {
        dispatch({ type: 'RESET_PLAYLIST' })

        const { name, value } = e.target;
        dispatch(populateSubject(tokenData.token, value, 2))
        // setInputField({ ...inputField, [name]: value })

    }

    const handleDegree = (e) => {
        dispatch({ type: 'RESET_PLAYLIST' })

        const { value } = e.target
        dispatch(getGradeList(value, 2))

    }
    const handleChangeSubject = (e) => {
        dispatch({ type: 'RESET_PLAYLIST' })
        getPlaylist(e.target.value)
    }
    const onEdit = (rowData) => {
        dispatch(updateSubject(tokenData.token, rowData))

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubject(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }

    // const formSubmit = (e) => {
    //     e.preventDefault()
    //     const form = e.currentTarget
    //     let body = serialize(form, { hash: true, empty: true })
    //     dispatch(createSubject(tokenData.token, body, reset))

    // }
    const reset = () => {
        document.getElementById("create-form-college").reset();
    }

    return (
        <>


            <Card>
                <Row className="jms-subject-row-pad">
                    <Col md={12}>
                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Title</Label>
                            <Input
                                invalid={errors.title}

                                // required 
                                type="text" name="title" />
                            <FormFeedback >{errors.title}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <input type="hidden" value={2} name="category_id" />
                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Course</Label>
                            <CustomInput type="select" name="degree_id" onChange={handleDegree}
                                // required
                                invalid={errors.degree_id}

                            >
                                <option value=" "> Select</option>
                                {
                                    Grades.degree ? Grades.degree.map((data) => {
                                        return (
                                            <option value={data.degree_id} key={data.degree_id}>{data.degree_name}</option>

                                        )
                                    })
                                        : []
                                }
                            </CustomInput>
                            <FormFeedback >{errors.degree_id}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Specialization</Label>
                                <div class="form-group">
                                            <div class="input-group">
                            <CustomInput type="select" name="grade_id"
                                // required
                                onChange={(e) => handleChangeSpecialization(e)}
                                invalid={errors.grade_id}

                            >
                                <option value="">Select</option>

                                {
                                    Grades.gradeCollege.grades ? Grades.gradeCollege.grades.map((data) => {
                                        return (
                                            <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                        )
                                    })
                                        : []
                                }


                            </CustomInput>
                                 {
                                Grades.loadgrade ? 
                                <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                                </span>
                                :
                                null
                            }
                            </div>
                            </div>
                            <FormFeedback >{errors.grade_id}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <Label for="exampleNameF">
                            <span className="text-danger">*</span>
                            {' '}Select Subject
                                                    </Label>

                            <div class="form-group">
                    <div class="input-group">
                        <Input type="select"
                            name='subject_id'
                            //required
                            onChange={handleChangeSubject}
                            invalid={errors.subject_id}

                        >
                            <option value={''}>{'select'}</option>

                            {tStore.subject ?
                                tStore.subject.map(subj => {
                                    return (
                                        <option value={subj.subject_id}>{subj.subject_name}</option>

                                    )
                                }) : null
                            }

                        </Input>
                        {
                            tStore.loadersub ? 
                            <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                            <div class="spinner-border text-primary spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                            </span>
                            :
                            null
                        }
                        
                        </div>
                        </div>
                        <FormFeedback >{errors.subject_id}</FormFeedback>

                    </Col>

                </Row>
            </Card>

            <hr />



        </>

    )
}

export default Index
