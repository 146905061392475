import React, { Fragment } from 'react';
import cx from 'classnames';

import { connect } from 'react-redux';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import HeaderLogo from '../AppLogo';
import { useLocation, Route, useParams } from 'react-router-dom'
// import { useParams } from "react-router";

import SearchBox from './Components/SearchBox';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "../../Components/AppLayout/Header/headerRightDrawer";

import HeaderDots from "./Components/HeaderDots";

const Header = (props) => {
    let {
        headerBackgroundColor,
        enableMobileMenuSmall,
        enableHeaderShadow
    } = props;
    let location = useLocation();
    //  let param = useParams();
    console.log(location.pathname);
    const { week_id, class_id } = useParams();
    //console.log("paraaaaam", class_id)


    return (
        <Fragment>
            <CSSTransitionGroup
                component="div"
                className={cx("app-header", headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
                transitionName="HeaderAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1500}
                transitionEnter={false}
                transitionLeave={false}>

                <HeaderLogo />

                <div className={cx(
                    "app-header__content",
                    { 'header-mobile-open': enableMobileMenuSmall },
                )}>
                    <div className="app-header-left">
                        {/* <SearchBox/> */}
                        {/* <MegaMenu /> */}
                    </div>
                    <div className="app-header-right">
                        <HeaderDots />

                        <UserBox

                            role={props.role}
                            user={props.user}
                        />

                        <div className="widget-heading" style={{ paddingLeft: '15px' }}>
                            {props.user}
                        </div>

                        <Route path={`/dashboard/startclass/:id/:ids`} component={HeaderRightDrawer} />
                        {/* {
                            location.pathname == `/dashboard/startclass/35/25` ?


                                <HeaderRightDrawer /> : null} */}
                    </div>
                </div>
            </CSSTransitionGroup>
        </Fragment>
    );

}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);