import React, { useState, useEffect, Fragment } from 'react';
import Timer from '../common/Timer'
// import Timernew from "./Timer"

import TimerCopy from '../common/Timer/copy'
// import TestTimer from '../common/Timer/testTimer'
import Modals from '../common/Modal'
import TimeShedule from './timingModel'
import { useHistory, Link } from 'react-router-dom'
import LoadingOverlay from 'react-loading-overlay'
import { development } from '../../config.json'
import { Loader } from 'react-loaders';
import TimerExam from './examTimer'
// import moment from 'moment';
import BrowserNotSupport from '../Class/Utility/browserNotSupport'
import { getExamDetails, validateStartExam } from '../../redux/Actions/exam'


import { useDispatch, useSelector } from "react-redux";
import RecentActivity from './recentActivity'
import {
    isFirefox,
    isIE,
    isEdge,
    // isChrome
} from "react-device-detect";
import { getTodaysClass, getNextClass, getClassTiming, getActivities, validateStartClass } from '../../redux/Actions/homeTeacher'
import { getStudentAssignments } from '../../redux/Actions/assignment'
import {
    Row, Col,
    Button,
    CardHeader,
    Container,
    Card,
    ListGroup,
    ListGroupItem, CardFooter,
} from 'reactstrap';
import noClass from '../../assets/utils/images/myicon/noClass.png'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { faCalendarAlt, faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, ButtonGroup } from 'reactstrap';
// import noFile from '../../assets/utils/images/myicon/noAssignment.png'
import { faBusinessTime, faCog } from '@fortawesome/free-solid-svg-icons';
import Echo from 'laravel-echo';
// import Pusher from "pusher-js";
import noassignment from "../../assets/images/noassignment.png"
// import notimeline from "../../assets/images/notimeline.png"

const assignColor = ['bg-focus', 'bg-warning', 'bg-primary', 'bg-danger', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warning', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-danger']
const options = {
    broadcaster: 'pusher',
    key: "key",
    //  wsHost: window.location.hostname,
    wsHost: development.socketUrl,
    // wsHost: "backend.mymots.com",
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss']

    // broadcaster: 'pusher',
    // key: 'key',

    // cluster: 'mt1',    
    //authEndpoint is your apiUrl + /broadcasting/auth
    // authEndpoint: "http://localhost:8000/broadcasting/auth",
    // host: "http://localhost:6001",
    // wsPort: 6001,
    // wssPort: 6001,

    // As I'm using JWT tokens, I need to manually set up the headers.
    // As I'm using JWT tokens, I need to manually set up the headers.
    // auth: {
    //   headers: {
    //     Authorization: `Bearer ${tokenData.token}`,
    //     Accept: 'application/json',
    //   },
    // },
};



const AnalyticsDashboard1 = () => {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const examStore = useSelector(({ exam }) => exam)
    const [classStatus, setClassStatus] = useState(false)
    useEffect(() => {
        dispatch(getNextClass(tokenData.token))
        const a = window.addEventListener("resize", resize);

        if (tStore.startClassStatus === false) {

            dispatch(getTodaysClass(tokenData.token))
            dispatch(getStudentAssignments(tokenData.token))
            dispatch(getActivities(tokenData.token))

        }


        //  dispatch(getAssignments(tokenData.token))


        if (tStore.nextClass !== 'null' && tStore.nextClass != []) {

            const echo = new Echo(options);
            echo.channel(`classInprogress${tStore.nextClass.class_id}`).listen('classStarted', (e) => {
                // console.log("page broadcast", e.message.class_id)
                // console.log("class _id to broadcast", tStore.nextClass.class_id)

                if (e.message.class_id == tStore.nextClass.class_id) {
                    setClassStatus(true)
                }
                // console.log("e.message", e.message)

            });
        }




        return () => {
            dispatch({ type: 'TIMER_RESET' })
        }

    }, [tStore.startClassStatus])
    useEffect(() => {
        dispatch(getExamDetails(tokenData.token))
    }, [])
    const resize = () => {
        // console.log("resized")
    }


    const history = useHistory()

    const homeDetail = useSelector(({ homeTeacher }) => homeTeacher)
    const assignment = useSelector(({ assignments }) => assignments)
    // console.log("homeDetail", assignment)

    const joinClass = (weekId, classId) => {
        if (isFirefox || isIE || isEdge) {
            browserNotSupport()
        } else {
            dispatch(validateStartClass(cb, tokenData.token, weekId, classId))

        }
        // dispatch(validateStartClass(cb, tokenData.token, weekId, classId))


    }
    const joinExam = (exam_id) => {
        dispatch(validateStartExam(startExam, tokenData.token, exam_id, "home"))

    }
    const startExam = (examId) => {
       // history.push(`/dashboard/examHall/${examId}`)
        history.push(`/dashboard/instructions/${examId}`)

    }



    const [unSupportBrowser, setUnSupportBrowser] = useState(false)
    const browserNotSupport = () => {
        setUnSupportBrowser(true)
    }
    const closeUnsupportPopup = () => {
        setUnSupportBrowser(false)
        // history.push('/dashboard/overview')

    }
    const cb = (weekId, classId) => {
        //props.history.push('/dashboard/')
        //alert("teacher")
        // const weekId = homeDetail.nextClass.weekly_class_id
        // const classId = homeDetail.nextClass.class_id
        // if (res.result) {
        history.push(`/dashboard/join/${weekId}/${classId}`)
        // } else if (!res.result) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: '',
        //         text: res.message,

        //     })
        // }
    }
    const [openModal, setOpenModal] = useState(false)
    const getClassTimes = (id) => {
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }
    const closeTimingModal = () => {
        setOpenModal(false)
    }
    // const cb = (res) => {
    //     //props.history.push('/dashboard/')
    //     alert(res)

    // }

    // setState({
    //     selectedOption: null,
    // });

    // const handleChange = (selectedOption) => {
    //     setState({ selectedOption });
    // };
    const ExamTimer = () => {

        
        return (
            examStore.upcomingExams?.length > 0 &&
           
            <TimerExam
                classname={examStore.nextExam.exam_name}
                date={examStore.nextExam.start_timer_till}
                type="exam"
                label={'Your next exam'}
                loader={examStore.nextExamValidatorLoader}
                // weekly_class_id={tStore.nextExam.weekly_class_id}
                // class_id={tStore.nextExam.class_id}
                // classStatus2={tStore.nextExam.status}
                exam_id={examStore.nextExam.exam_id}
                joinExam={joinExam}
            // active={true}
            // loader={tStore.timerLoader}
            // disablity={tStore.nextExam.length <= 0 ? true : false}
            // classStatus={classStatus}
            // role={'student'}

            />
           


        )
    }
    const toggle = () => {
        // setState(prevState => ({
        //     dropdownOpen: !prevState.dropdownOpen
        // }));
    }
    const UpcomingExams = () => {
        return (
            <Card className="main-card mb-3">
                <CardHeader>
                    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        Upcoming Exams
                            </div>
                    <div className="btn-actions-pane-right">

                    </div>
                </CardHeader>
                <Table responsive borderless hover className="align-middle text-truncate mb-0">
                    <thead>
                        <tr>
                            <th className="text-center">#</th>
                            <th className="text-center">Exam Title</th>

                            <th className="text-center">Class</th>

                            <th className="text-center">Subject</th>
                            <th className="text-center">Date</th>
                            <th className="text-center">Time</th>

                        </tr>
                    </thead>
                    <tbody>
                        {
                            examStore.upcomingExams?.length > 0 ? examStore.upcomingExams.map((exam, index) => {

                                return (

                                    < tr >
                                        <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                        <td className="text-center" style={{ width: '80px' }}>
                                            {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}
                                            {exam.exam_titile}
                                        </td>
                                        <td className="text-center" style={{ width: '80px' }}>
                                            {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}
                                            {exam.class_name}
                                        </td>

                                        <td className="text-center">
                                            {exam.subject_name}
                                        </td>
                                        <td className="text-center">
                                            <span className="pr-2 opacity-6">
                                                <FontAwesomeIcon icon={faCalendarAlt} />
                                            </span>
                                            {exam.date ? exam.date : "null"}
                                        </td>
                                        <td className="text-center">
                                            <span className="pr-2 opacity-6">
                                                <FontAwesomeIcon icon={faCloudSunRain} />
                                            </span>
                                            {exam.time ? exam.time : "null"}
                                        </td>

                                    </tr>
                                )
                            }) : null
                        }

                    </tbody>

                </Table>
                {
                    examStore.upcomingExams?.length == 0 ?
                        <p style={{ textAlign: 'center' }}> <img src={noClass} width="200px" height="150px" /><p style={{ color: 'gray', textAlign: 'center' }}>There is no exams</p></p>
                        : null
                }

                <CardFooter className="d-block p-4 text-center">
                    <Link to="/dashboard/show-class">
                        <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                            <span className="mr-2 opacity-7">
                                <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                            </span>
                            <span className="mr-1">
                                View Full TimeTable
                                    </span>
                        </Button>
                    </Link>
                </CardFooter>
            </Card>
        )
    }

    // const { selectedOption } = state;
    // const { data } = state;

    // const settings = {
    //     className: "",
    //     centerMode: false,
    //     infinite: true,
    //     slidesToShow: 1,
    //     speed: 500,
    //     dots: true,
    // };
    // const dateInFuture = '2029-12-31 23:59:59'   
    const [sampleTime, setSampleTime] = useState({ date: "05 15 2020,10:00 pm", name: "Old" })

    const fullScreen = () => {
        alert("key pressed")
    }
    return (
        <Fragment>
            {/* <Button onClick={() => setSampleTime({ ...sampleTime, date: "06 15 2020,11:00 pm", name: "new" })} >Set time</Button>
            <TestTimer
                classname={sampleTime.name}
                date={sampleTime.date}
            /> */}
            {/* <KeyboardEventHandler
                handleKeys={['s', 'S']}
                onKeyEvent={fullScreen} /> */}
            <Container fluid>

                {/* 
                {homeDetail.nextClass === 'null' ?
                    <LoadingOverlay tag="div" active={homeDetail.nextClass != [] ? true : false}
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: '#fff',
                                opacity: 0.5
                            })
                        }}
                        spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

                        <TimerCopy />
                    </LoadingOverlay>

                    :
                    <Timer
                        classname={homeDetail.nextClass.class_name}
                        date={homeDetail.nextClass.start_timer_till}
                        weekly_class_id={homeDetail.nextClass.weekly_class_id}
                        class_id={homeDetail.nextClass.class_id}
                        joinClass={joinClass}
                        active={true}
                        loader={tStore.timerLoader}
                    
                    />

                } */}
                {
                    tStore.nextClass === 'null' ?
                        <LoadingOverlay tag="div" active={homeDetail.nextClass != [] ? true : false}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>

                            <TimerCopy
                                label={'Your next class'}

                            />
                        </LoadingOverlay>

                        :
                        <Timer
                            classname={homeDetail.nextClass.class_name}
                            // date={homeDetail.nextClass.start_timer_till}
                            date={homeDetail.nextClass.timer}
                            weekly_class_id={homeDetail.nextClass.weekly_class_id}
                            class_id={homeDetail.nextClass.class_id}
                            classStatus2={homeDetail.nextClass.status}
                            joinClass={joinClass}
                            active={true}
                            loader={tStore.timerLoader}
                            disablity={homeDetail.nextClass.length <= 0 ? true : false}
                            classStatus={classStatus}
                            role={'student'}
                            label={'Your next class'}

                        // date={nextC}
                        //timeFormat="MM DD YYYY, h:mm a"
                        />

                }
               {} <ExamTimer />
                <Card className="main-card mb-3">
                    <CardHeader>
                        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                            Upcoming Classes
                            </div>
                        <div className="btn-actions-pane-right">

                        </div>
                    </CardHeader>
                    <Table responsive borderless hover className="align-middle text-truncate mb-0">
                        <thead>
                            <tr>
                                <th className="text-center">#</th>
                                <th className="text-center">Class</th>

                                <th className="text-center">Subject</th>
                                <th className="text-center">Date</th>
                                <th className="text-center">Day</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Time Slot</th>
                                <th className="text-center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                homeDetail.todayClass ? homeDetail.todayClass.map((dt, index) => {

                                    return (

                                        < tr >
                                            <td className="text-center text-muted" style={{ width: '80px' }}>{index + 1}</td>
                                            <td className="text-center" style={{ width: '80px' }}>
                                                {/* <img width={40} className="rounded-circle"
                                               src={avatar1}
                                                  alt=""/> */}
                                                {dt.class_name}
                                            </td>

                                            <td className="text-center">
                                                {dt.subject_name}
                                            </td>
                                            <td className="text-center">
                                                <span className="pr-2 opacity-6">
                                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                                </span>
                                                {dt.date ? dt.date : "null"}
                                            </td>
                                            <td className="text-center">
                                                <span className="pr-2 opacity-6">
                                                    <FontAwesomeIcon icon={faCloudSunRain} />
                                                </span>
                                                {dt.day ? dt.day : "null"}
                                            </td>
                                            <td className="text-center">

                                                <div className={dt.status === 'inprogress' ? 'badge badge-pill badge-warning'
                                                    : dt.status === 'ongoing' ? 'badge badge-pill badge-success'
                                                        : dt.status === 'completed' ? 'badge badge-pill badge-success'
                                                            : dt.status === 'pending' ? 'badge badge-pill badge-info'
                                                                : null}>
                                                    {dt.status}
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <span className="pr-2 opacity-6">
                                                    <FontAwesomeIcon icon={faBusinessTime} />
                                                </span>
                                                {`${dt.class_from_time} - ${dt.class_to_time}`}
                                            </td>

                                            <td className="text-center">
                                                <ButtonGroup size="sm">
                                                    <Button className="btn-shadow" onClick={() => getClassTimes(dt.class_id)} color="primary">Timing</Button>
                                                    {/* <Button className="btn-shadow" color="primary">Fire</Button> */}
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    )
                                }) : null
                            }

                        </tbody>

                    </Table>
                    {
                        homeDetail.todayClass.length == 0 ?
                            <p style={{ textAlign: 'center' }}> <img src={noClass} width="200px" height="150px" /><p style={{ color: 'gray', textAlign: 'center' }}>There is no upcoming class</p></p>
                            : null
                    }

                    <CardFooter className="d-block p-4 text-center">
                        <Link to="/dashboard/show-class">
                            <Button color="dark" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                                <span className="mr-2 opacity-7">
                                    <FontAwesomeIcon spin fixedWidth={false} icon={faCog} />
                                </span>
                                <span className="mr-1">
                                    View Full TimeTable
                                    </span>
                            </Button>
                        </Link>
                    </CardFooter>
                </Card>
                {/* <UpcomingExams /> */}
                <Row>
                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={assignment.loader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <Card className="card-hover-shadow-2x mb-3">
                                <CardHeader className="card-header-tab">
                                    <div
                                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                        <i className="header-icon lnr-database icon-gradient bg-malibu-beach"> </i>
                                       Assignment
                                    </div>


                                </CardHeader>
                                <div className="scroll-area-lg">
                                    <PerfectScrollbar>
                                        <div className="p-2">
                                            <ListGroup className="todo-list-wrapper" flush>

                                                {Array.isArray(assignment.assignmentss) ? assignment.assignmentss.length == 0 ? '' : null : null}
                                                {assignment.assignmentss ?
                                                    assignment.assignmentss.map((assignments, index) => {
                                                        return (
                                                            //         <ListGroupItem>
                                                            //             <div className="todo-indicator bg-focus" />
                                                            //             <div className="widget-content p-0">
                                                            //                 <div className="widget-content-wrapper">
                                                            //                     <div className="widget-content-left mr-2">
                                                            //                         <CustomInput type="checkbox" id="exampleCustomCheckbox1"
                                                            //                             label="&nbsp;" />
                                                            //                     </div>
                                                            //                     <div className="widget-content-left">
                                                            //                         <div className="widget-heading">
                                                            //                             {assignments.title}
                                                            //                         </div>
                                                            //                         <div className="widget-subheading">
                                                            //                             <div>
                                                            //                                 {/* By {tokenData.name} */}
                                                            //                                 {/* <div
                                                            // className="badge badge-pill badge-info ml-2">NEW</div> */}
                                                            //                             </div>
                                                            //                         </div>
                                                            //                     </div>
                                                            //                     <div
                                                            //                         className="widget-content-right widget-content-actions">
                                                            //                         <Dropdown className="d-inline-block rm-pointers"
                                                            //                             //onMouseOver={this.onMouseEnter}
                                                            //                             // onMouseLeave={this.onMouseLeave}
                                                            //                             //  isOpen={state.dropdownOpen}
                                                            //                             toggle={toggle}>
                                                            //                             <DropdownToggle color="link"
                                                            //                                 className="border-0 btn-transition">
                                                            //                                 <FontAwesomeIcon icon={faEllipsisH} />
                                                            //                             </DropdownToggle>
                                                            //                             <DropdownMenu right>
                                                            //                                 <DropdownItem header>Header</DropdownItem>
                                                            //                                 <DropdownItem disabled>Action</DropdownItem>
                                                            //                             </DropdownMenu>
                                                            //                         </Dropdown>
                                                            //                     </div>
                                                            //                 </div>
                                                            //             </div>
                                                            //         </ListGroupItem>

                                                            <Link style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }} to={`/dashboard/assignments/${assignments.assignment_id}/${assignments.class_id}`}>


                                                                <ListGroupItem>
                                                                    <div className={`todo-indicator ${assignColor[index]}`} />


                                                                    <div className="widget-content p-0">
                                                                        <div className="widget-content-wrapper">
                                                                            <div className="widget-content-left mr-2">

                                                                            </div>
                                                                            <div className="widget-content-left flex2">
                                                                                <div className="widget-heading">
                                                                                    {assignments.title}
                                                                                </div>
                                                                                <div className="widget-subheading">
                                                                                    By {assignments.teacher}

                                                                                </div>
                                                                                <p style={{ fontSize: '11px', color: 'rgb(9, 93, 168)', opacity: 0.8, marginBottom: '2px' }}>Deadline : {assignments.deadline}</p>

                                                                            </div>

                                                                            <div className="widget-content-right ml-3">
                                                                                <div className="badge badge-pill badge-warning">{assignments.subject_name} </div>

                                                                            </div>
                                                                            {/* <br />
                                                                            <div className="widget-content-right ml-3">

                                                                                <div className="badge badge-pill badge-danger">Deadline : {assignments.deadline} </div>

                                                                            </div> */}

                                                                        </div>
                                                                    </div>
                                                                </ListGroupItem>




                                                            </Link>


                                                        )
                                                    }) : []
                                                }
                                                {assignment.assignmentss.length === 0 ?
                                                    <p style={{ textAlign: 'center', paddingTop: '40px' }}>
                                                        <img src={noassignment} width="100%" height="100%" />
                                                        {/* <p style={{ textAlign: 'center', color: 'gray' }}>There is no assignments </p> */}
                                                        </p>
                                                    : null}

                                            </ListGroup>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                                <CardFooter className="d-block text-right">
                                    {/* <Button size="sm" className="mr-2" color="link">Cancel</Button> */}
                                    <a href="#/dashboard/assignments"> <Button color="primary">View All Assignments</Button></a>
                                </CardFooter>
                            </Card>
                        </LoadingOverlay>
                    </Col>

                    <Col sm="12" lg="6">
                        <LoadingOverlay tag="div" active={tStore.activityLoader}
                            styles={{
                                overlay: (base) => ({
                                    ...base,
                                    background: '#fff',
                                    opacity: 0.5
                                })
                            }}
                            spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                            <RecentActivity
                                activities={tStore.activities}
                            />
                        </LoadingOverlay>
                    </Col>


                </Row>
            </Container>
            <Modals
                open={openModal}
                handleClose={closeTimingModal}

            >
                <TimeShedule
                    data={tStore.specificClassTime}
                />
            </Modals>
            <Modals
                open={unSupportBrowser}
                handleClose={closeUnsupportPopup}
                title={`Warning`}
            >
                <BrowserNotSupport />

            </Modals>
        </Fragment >
    )

}
export default AnalyticsDashboard1