// import React, { Fragment, useState, useEffect } from "react";
// import { Link, useHistory } from 'react-router-dom'
// import Slider from "react-slick";
// import './sty.scss'
// import moment from 'moment-timezone'

// import {
//     validateTeacherMobile,
//     requestOtp,
//     validatingOtp,
//     registerStudent,
//     verifyEMAIL,
//     validateReferralCode

// } from '../../redux/Actions/signUp'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//     emailValidator,
//     passwordValidator,
//     cpasswordValidator,
//     nameValidator,
//     mobileValidator,
//     checkBoxValidtor
// } from '../../utilities/Validator'
// import bg3 from '../../assets/utils/images/originals/f2a.jpg';
// import { Col, Row, Button, Form, FormGroup, Label, Input, FormFeedback, FormText, InputGroup, InputGroupAddon } from 'reactstrap';
// import logo from '../../assets/utils/images/logo-inverse.png'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
// var option = {
//     lines: 13,
//     length: 4,
//     width: 2,
//     radius: 5,
//     scale: 1.00,
//     corners: 1,
//     color: '#000',
//     opacity: 0.25,
//     rotate: 0,
//     direction: 1,
//     speed: 1,
//     trail: 60,
//     fps: 20,
//     zIndex: 2e9,
//     top: '50%',
//     left: '50%',
//     shadow: false,
//     hwaccel: false,
//     position: 'absolute'
// };
// const TRegister = () => {
//     useEffect(() => {

//         return () => {

//             setInputField(initialState)
//             dispatch({ type: 'RESET_SIGNUP_STORE' })

//         }
//     }, [])

//     const tokenData = JSON.parse(localStorage.getItem("authDetails"));
//     if (tokenData) {
//         history.push('/dashboard')
//     }
//     let settings = {
//         dots: true,
//         infinite: true,
//         speed: 500,
//         arrows: true,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//         fade: true,
//         initialSlide: 0,
//         autoplay: true,
//         adaptiveHeight: true
//     }
//     const dispatch = useDispatch();

//     const Grades = useSelector(({ signUp }) => signUp)
//     const [errorz, setErrorz] = useState({});
//     const [initialState, setInitialState] = useState({
//         first_name: null,
//         last_name: null,
//         email: null,
//         teacher_referral_code: null,
//         password: null,
//         teacher_mobile: '',
//         type: "teacher",
//         timezone: moment.tz.guess(),
//         termsAndCondition: false,

//     })
//     const [inputField, setInputField] = useState({
//         first_name: null,
//         last_name: null,
//         email: null,
//         teacher_referral_code: null,
//         password: null,
//         teacher_mobile: '',
//         type: "teacher",
//         timezone: moment.tz.guess(),
//         termsAndCondition: false

//     });
//     const handleChangeTerm = () => {
//         setInputField({ ...inputField, termsAndCondition: !inputField.termsAndCondition })

//     }
//     const onChng = (e) => {
//         console.log(e + ' Anil123')
//         setInputField({ ...inputField, teacher_mobile: e })
//     }

//     const handleChange = (e) => {
//         console.log(e.name + ' ')
//         const { name, value } = e.target;
//         setInputField({ ...inputField, [name]: value })


//         // if (name === 'teacher_mobile') {
//         //     if (value === '') {
//         //         setInputField({ ...inputField, [name]: value })
//         //     }
//         //     if (!Number(value)) {
//         //         console.log("not number")
//         //     }
//         //     else {
//         //         console.log("is numb")
//         //         setInputField({ ...inputField, [name]: value })
//         //     }
//         // }
//         // else {
//         //     console.log("not teacher fil")
//         //setInputField({ ...inputField, [name]: value })
//         // }
//         console.log({ inputField })

//     }



//     const verifyEmail = (e) => {
//         console.log(e.target.value)
//         if (e.target.value) {
//             dispatch(verifyEMAIL(e.target.value))
//         }
//     }
//     const validateRef = () => {
//         dispatch(validateReferralCode(inputField.teacher_referral_code, callback))
//     }
//     const callback = () => {
//         setErrorz({ ...errorz, refferal: null, refferalValidation: true })
//         // alert("callback")
//     }
//     const verifyMobile = () => {
//         console.log('Anil ' + inputField.teacher_mobile)
//         dispatch(validateTeacherMobile(inputField.teacher_mobile, sendOtp))


//     }
//     const sendOtp = () => {
//         dispatch(requestOtp(inputField.teacher_mobile))
//     }

//     const checkMobileAvailability = (e) => {
//         const { value } = e.target;
//         dispatch(validateTeacherMobile(value))
//     }
//     const [otp, setOtp] = useState('')
//     const handleChangeOTP = (e) => {
//         setOtp(e.target.value)
//     }

//     const validateOtp = () => {
//         dispatch(validatingOtp(inputField.teacher_mobile, otp, callback2))
//     }
//     const callback2 = () => {
//         setErrorz({ ...errorz, mobileValidated: true })
//     }
//     const [passCheck, setPassCheck] = useState();

//     const confirmPass = (e) => {
//         const { value } = e.target;
//         setPassCheck(value)
//     }
//     const [passError, setPassError] = useState();
//     console.log("pppcheeekkk", passError)




//     const formSubmit = (e) => {
//         e.preventDefault()
//         setErrorz({})
//         // debugger;
//         console.log("valid", Grades)

//         const handleInputChange = (telNumber, selectedCountry) => {
//             console.log('input changed. number: ', telNumber, 'selected country: ', selectedCountry)
//         }


//         const errors = {
//             first_name: nameValidator(inputField.first_name),
//             last_name: nameValidator(inputField.last_name),
//             email: emailValidator(inputField.email),
//             password: passwordValidator(inputField.password),
//             cCheckpass: cpasswordValidator(inputField.password, passCheck),
//             cpassword: nameValidator(passCheck),
//             refferal: nameValidator(inputField.teacher_referral_code),
//             mobile: mobileValidator(inputField.teacher_mobile),
//             mobileValidated: Grades.valid,
//             refferalValidation: Grades.refferalValidation.result,
//             termsAndCondi: checkBoxValidtor(inputField.termsAndCondition)

//         }
//         console.log("erroessss-----------", errors)
//         if (
//             !errors.first_name &&
//             !errors.last_name &&
//             !errors.email &&
//             !errors.password &&
//             !errors.cCheckpass &&
//             !errors.mobile &&
//             !errors.refferal &&
//             !errors.termsAndCondi &&
//             Grades.emailValidation.result &&
//             Grades.valid &&
//             Grades.teacherNumberAvail &&
//             Grades.refferalValidation.result
//         ) {
//             console.log("erroessss---after codition--satis------", errors)
//             dispatch(registerStudent(inputField, cb))
//             setInputField(initialState)

//         } else {

//             setErrorz(errors)
//             console.log({ errors })
//             console.log("valid", Grades.emailValidation)
//         }



//     }
//     const [value, setValue] = useState();
//     const history = useHistory()
//     const cb = () => {
//         history.push('/dashboard')
//     }

//     return (

//         <Fragment>
//             <div className="h-100 ">
//                 <Row className=" h-100 no-gutters" >
//                     <Col lg="7" md="12"

//                         className="h-100 jms-h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
//                         <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
//                             {/* <div className="app-logo" /> */}
//                             <img src={logo} width="100px" height="60px" style={{ paddingBottom: '30px' }} />

//                             <h4>
//                                 <div>Teacher Signup</div>
//                             </h4>
//                             <div>
//                                 <Form onSubmit={formSubmit} autocomplete="off">
//                                     <Row form>
//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="exampleNameF">
//                                                     <span className="text-danger">*</span>
//                                                     {' '}First Name
//                                                     </Label>
//                                                 <Input type="text" name="first_name" id="exampleNameF"
//                                                     value={inputField.first_name}
//                                                     placeholder="First Name..."
//                                                     onChange={(e) => handleChange(e)}
//                                                     invalid={errorz.first_name ? true : false}
//                                                 />
//                                                 <FormFeedback >oops! your first name is required</FormFeedback>
//                                             </FormGroup>
//                                         </Col>
//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="exampleNameL">
//                                                     <span className="text-danger">*</span>
//                                                     {' '} Last Name</Label>
//                                                 <Input type="text" name="last_name" id="exampleNameL"
//                                                     placeholder="Last Name... "
//                                                     value={inputField.last_name}
//                                                     onChange={(e) => handleChange(e)}
//                                                     invalid={errorz.last_name ? true : false}

//                                                 />
//                                                 <FormFeedback >oops! your last name is required</FormFeedback>

//                                             </FormGroup>
//                                         </Col>

//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="exampleEmail">
//                                                     <span className="text-danger">*</span>
//                                                     {' '}Email
//                                                     </Label>
//                                                 <Input type="email" name="email" id="exampleEmail"
//                                                     placeholder="Email here..."
//                                                     value={inputField.email}
//                                                     onChange={(e) => handleChange(e)}
//                                                     onBlur={(e) => verifyEmail(e)}

//                                                     valid={Grades.emailValidation.result ? true : false}
//                                                     invalid={Grades.emailValidation.result === false || errorz.email ? true : false}
//                                                 />
//                                                 {errorz.email ?
//                                                     <FormFeedback>oops! email id is required</FormFeedback>
//                                                     : null
//                                                 }
//                                                 {
//                                                     Grades.emailValidation.result === false ?
//                                                         <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

//                                                         : null
//                                                 }
//                                                 {/* {
//                                                     Grades.emailValidation.result= false ?
//                                                         <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

//                                                         : null
//                                                 } */}
//                                                 <FormFeedback valid>Sweet! that email id is available</FormFeedback>
//                                             </FormGroup>
//                                         </Col>
//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="exampleNameL">
//                                                     <span className="text-danger">*</span>
//                                                     {' '} Referral</Label>
//                                                 <InputGroup>
//                                                     <Input
//                                                         value={inputField.teacher_referral_code}
//                                                         disabled={Grades.refferalValidation.result ? true : false}
//                                                         name="teacher_referral_code"
//                                                         type="text"
//                                                         placeholder="Referral here..."
//                                                         valid={Grades.refferalValidation.result}
//                                                         maxLength={12}
//                                                         onChange={(e) => handleChange(e)}
//                                                         //invalid={errorz.refferal || errorz.refferalValidation === null || errorz.refferalValidation === false || Grades.refferalValidation.result === false ? true : false}

//                                                         invalid={errorz.refferal || errorz.refferalValidation === null || errorz.refferalValidation === false || Grades.refferalValidation.result === false ? true : false}
//                                                     />




//                                                     <InputGroupAddon addonType="prepend">
//                                                         <Button
//                                                             onClick={() => validateRef()}
//                                                             disabled={Grades.refferalValidationLoader || Grades.refferalValidation.result ? true : false}
//                                                         >
//                                                             <span style={Grades.refferalValidationLoader ? { visibility: 'hidden' } : null}>
//                                                                 Validate
//                                                                  </span>
//                                                             {Grades.refferalValidationLoader ? <Loader options={option} /> : null}
//                                                         </Button>
//                                                     </InputGroupAddon>

//                                                 </InputGroup>

//                                                 <span style={{ color: '#dc3545', fontSize: '80%' }}>
//                                                     {!errorz.refferal ? errorz.refferalValidation === null || errorz.refferalValidation === false ?
//                                                         `please validate referral code` : null : null
//                                                     }
//                                                 </span>
//                                                 {errorz.refferal ?
//                                                     // <FormFeedback >oops! referral code is required</FormFeedback>
//                                                     // : null
//                                                     <span style={{ color: '#dc3545', fontSize: '80%' }}>
//                                                         {'oops! referral code is required'
//                                                         }</span> : null
//                                                 }
//                                                 {
//                                                     Grades.refferalValidation.result === false ? <p><span style={{ color: '#dc3545', fontSize: '80%' }}>
//                                                         {' oops! please enter valid referral code'


//                                                         }</span> </p> : null
//                                                 }
//                                             </FormGroup>

//                                         </Col>
//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="examplePassword">
//                                                     <span className="text-danger">*</span>
//                                                     {' '}Password
//                                                     </Label>
//                                                 <Input
//                                                     type="password"
//                                                     name="password"
//                                                     id="examplePassword"
//                                                     placeholder=" Password here..."
//                                                     onChange={(e) => handleChange(e)}
//                                                     invalid={errorz.password ? true : false}
//                                                 />
//                                                 <FormFeedback >oops!  password is required</FormFeedback>

//                                             </FormGroup>
//                                         </Col>
//                                         <Col md={6}>
//                                             <FormGroup>
//                                                 <Label for="examplePasswordRep">
//                                                     <span className="text-danger">*</span>
//                                                     {' '}Confirm Password
//                                                     </Label>
//                                                 <Input type="password" name="cpassword" id="examplePasswordRep"
//                                                     placeholder="Confirm Password here..."
//                                                     onChange={(e) => confirmPass(e)}

//                                                     invalid={errorz.cCheckpass ? true : false}
//                                                 />
//                                                 <FormFeedback>oops! the password not matching</FormFeedback>
//                                             </FormGroup>
//                                         </Col>
//                                         <Col md={6}>
//                                             <Row>
//                                                 <Col md={8} xs={8}>
//                                                     <Label for="exampleName">
//                                                         <span className="text-danger">*</span>
//                                                         {' '}Mobile Number</Label>


//                                                     {/* 
//                                             <Label for="exampleName">
//                                                 <span className="text-danger">*</span>
//                                                 {' '}Mobile Number</Label>
//                                             <InputGroup>
//                                             <table width="100%" align="right"><tr><td>

//                                             <PhoneInput
//                                                     placeholder="Enter phone number"
//                                                     value={inputField.teacher_mobile}
//                                                     name="teacher_mobile"
//                                                     disabled={Grades.valid ? true : false}
//                                                     placeholder="Mobile here..."
//                                                     onChange={(e) => onChng(e)}
//                                                     maxLength={12}
//                                                     invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
//                                                      />
//                                                </td> <td> 
//                                                      */}
//                                                     <PhoneInput

//                                                         placeholder="Enter phone number"
//                                                         value={inputField.teacher_mobile}
//                                                         name="teacher_mobile"
//                                                         disabled={Grades.valid ? true : false}
//                                                         placeholder="Mobile here..."
//                                                         onChange={(e) => onChng(e)}
//                                                         maxLength={15}
//                                                         invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
//                                                     />
//                                                     <span style={{ color: '#dc3545', fontSize: '80%' }}>
//                                                         {Grades.teacherNumberAvail === false ?
//                                                             'Mobile Already Registered' : null
//                                                         }
//                                                         {errorz.mobile ? errorz.mobile : null}
//                                                         {!errorz.mobile ? errorz.mobileValidated === null || errorz.mobileValidated === false ? 'please validate your mobile' : null : null}
//                                                     </span>
//                                                 </Col>
//                                                 <Col md={4} xs={4}>

//                                                     <Button
//                                                         style={{ borderRadius: '0px', width: '74px', fontSize: '10px', padding: '10px 10px', marginTop: '29px', marginLeft: '-8px' }}

//                                                         onClick={verifyMobile}
//                                                         disabled={inputField.teacher_mobile ? inputField.teacher_mobile.length <= 9 : null || Grades.validateOtpLoaderT || Grades.valid}
//                                                     >
//                                                         <span style={Grades.validateOtpLoaderT ? { visibility: 'hidden' } : null}>
//                                                             {Grades.teacherNumberAvail ? 'Resend' : 'Send OTP'}
//                                                         </span>

//                                                         <span>
//                                                             {Grades.validateOtpLoaderT ? <Loader options={option} /> : null}

//                                                         </span>
//                                                     </Button>

//                                                 </Col>
//                                             </Row>
//                                             <FormGroup>






//                                                 {/* <InputGroupAddon addonType="prepend">

//                                                     </InputGroupAddon>
//                                                 <span style={{ color: 'red' }}>
//                                                     {''}</span>
//                                                 {
//                                                     errorz.mobileValidated === null || errorz.mobileValidated === false ?
//                                                         <FormFeedback> {`oops! verify mobile number `}</FormFeedback>
//                                                         : null
//                                                 }
//                                                 {
//                                                     errorz.mobile ?
//                                                         <FormFeedback> {`oops! ${errorz.mobile}`}</FormFeedback>
//                                                         : null
//                                                 }

//                                                 {/* <FormText>Enter your mobile number with country code</FormText> */}



//                                             </FormGroup>
//                                         </Col>
//                                         <Col md={6}>


//                                             {Grades.teacherNumberAvail ?
//                                                 // Grades.otp === true ?
//                                                 <FormGroup>
//                                                     <Label for="exampleName">
//                                                         <span className="text-danger">*</span>
//                                                         {' '}Enter OTP</Label>
//                                                     <InputGroup>

//                                                         <Input
//                                                             name="otp"
//                                                             onChange={handleChangeOTP}
//                                                             type="number"
//                                                             invalid={Grades.valid === false || errorz.mobileValidated === null ? true : false}
//                                                             valid={Grades.valid ? true : false}
//                                                             readOnly={Grades.valid ? true : false}
//                                                         />

//                                                         <InputGroupAddon addonType="prepend"><Button
//                                                             onClick={() => validateOtp()}
//                                                             disabled={!otp || Grades.validateOtpValidateLoader || Grades.valid ? true : false}
//                                                         >
//                                                             <span style={Grades.validateOtpValidateLoader ? { visibility: 'hidden' } : null}>
//                                                                 Validate
//                                                                  </span>
//                                                             {Grades.validateOtpValidateLoader ? <Loader options={option} /> : null}
//                                                         </Button>
//                                                         </InputGroupAddon>
//                                                         <br />

//                                                         <FormFeedback>
//                                                             {Grades.valid === false ? `oops! Invalid OTP` : errorz.mobileValidated === null ? 'oops! Please validate OTP' : null}

//                                                         </FormFeedback>


//                                                     </InputGroup>

//                                                 </FormGroup>

//                                                 : null

//                                             }

//                                         </Col>

//                                     </Row>
//                                     <FormGroup className="mt-3" check>
//                                         <Row>
//                                             <Col md={6} xs={12}>
//                                                 <Input type="checkbox" name="check"
//                                                     onChange={() => handleChangeTerm()}
//                                                     checked={inputField.termsAndCondition}
//                                                 //ref={inputRef}
//                                                 //value="accept"

//                                                 />{' '}
//                                                 <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="#" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>

//                                             </Col>
//                                             <Col md={6} xs={12}>
//                                                 <Label for="exampleCheck" check style={{ paddingRight: '10px' }}> Are you a Student? <Link to={'register'}><a>Signup Here</a></Link></Label>

//                                             </Col>
//                                         </Row>
//                                         {/* <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>
//                                         <Label for="exampleCheck" check style={{ float: 'right', paddingRight: '10px' }}> Are you a Student? <Link to={'register'}><a>Signup Here</a></Link></Label> */}

//                                     </FormGroup>
//                                     <div className="mt-4 d-flex align-items-center">
//                                         <h7 className="mb-0">
//                                             Already have an account?{' '}

//                                             <Link to={`/`}>
//                                                 <a className="text-primary">Sign in</a>
//                                             </Link>
//                                         </h7>
//                                         <div className="ml-auto">


//                                             <LaddaButton className="mb-2 mr-2 btn btn-primary"
//                                                 loading={Grades.loader}
//                                                 //  onClick={() => this.toggle('expZoomIn')}
//                                                 type="submit"
//                                                 data-style={ZOOM_IN}
//                                                 style={{ whiteSpace: 'nowrap' }}
//                                             >
//                                                 Create Account
//                                             </LaddaButton>



//                                             {/* <Button type="submit" color="primary"
//                                                 className="btn-wide btn-pill btn-shadow btn-hover-shine"
//                                                 size="lg">
//                                                 {Grades.loader ?
//                                                     <Loader color="white" color="#000" length={1} width={1} type="ball-clip-rotate" /> :
//                                                     'Create Account'
//                                                 }

//                                             </Button> */}

//                                             {/* <Button type="submit" color="primary" className="btn-wide btn-pill btn-shadow btn-hover-shine" size="lg">Create Account</Button> */}
//                                         </div>
//                                     </div>
//                                 </Form>
//                             </div>
//                         </Col>
//                     </Col>
//                     <Col lg="5" className="d-lg-flex d-xs-none">
//                         <div className="slider-light">
//                             <Slider  {...settings}>
//                                 <div
//                                     className="h-100 d-flex justify-content-center align-items-center bg-premium-dark"
//                                     style={{ height: '100vh' }}
//                                 >
//                                     <div className="slide-img-bg"
//                                         style={{
//                                             backgroundImage: 'url(' + bg3 + ')'
//                                         }} />
//                                     <div className="slider-content">
//                                         <div style={{ visibility: 'hidden' }}>
//                                             <h3>Scalable, Modular, Consistent</h3>
//                                             <p>
//                                                 Easily exclude the components you don't require. Lightweight, consistent
//                                                 Bootstrap based styles across all elements and components
//                                             </p>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </Slider>
//                         </div>
//                     </Col>
//                 </Row>
//             </div>
//         </Fragment >
//     );

// }
// export default TRegister





import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import Slider from "react-slick";
import './sty.scss'
import moment from 'moment-timezone'

import {
    validateTeacherMobile,
    requestOtp,
    validatingOtp,
    registerStudent,
    verifyEMAIL,
    validateReferralCode

} from '../../redux/Actions/signUp'
import Loader from 'react-loader'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { useDispatch, useSelector } from 'react-redux';
import {
    emailValidator,
    passwordValidator,
    cpasswordValidator,
    nameValidator,
    mobileValidator,
    checkBoxValidtor
} from '../../utilities/Validator'
import bg3 from '../../assets/utils/images/originals/f2a.jpg';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';
import logo from '../../assets/utils/images/logo-inverse.png'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
var option = {
    lines: 13,
    length: 4,
    width: 2,
    radius: 5,
    scale: 1.00,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
};
const TRegister = () => {
    const history = useHistory()

    useEffect(() => {


        window.scrollTo(0, 0)
       
 

        return () => {

            setInputField(initialState)
            dispatch({ type: 'RESET_SIGNUP_STORE' })

        }
    }, [])

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    if (tokenData) {
        history.push('/dashboard')
    }
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true
    }
    const dispatch = useDispatch();

    const Grades = useSelector(({ signUp }) => signUp)
    const [errorz, setErrorz] = useState({});
    const [initialState, setInitialState] = useState({
        first_name: null,
        last_name: null,
        email: null,
        teacher_referral_code: 'Trial30',
        password: null,
        teacher_mobile: '',
        type: "teacher",
        timezone: moment.tz.guess(),
        termsAndCondition: false,

    })
    const [inputField, setInputField] = useState({
        first_name: null,
        last_name: null,
        email: null,
        teacher_referral_code: 'Trial30',
        password: null,
        teacher_mobile: '',
        type: "teacher",
        timezone: moment.tz.guess(),
        termsAndCondition: false

    });
    const handleChangeTerm = () => {
        setInputField({ ...inputField, termsAndCondition: !inputField.termsAndCondition })

    }
    const onChng = (e) => {
        console.log(e + ' Anil123')
        setInputField({ ...inputField, teacher_mobile: e })
    }

    const handleChange = (e) => {
        console.log(e.name + ' ')
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value })


        // if (name === 'teacher_mobile') {
        //     if (value === '') {
        //         setInputField({ ...inputField, [name]: value })
        //     }
        //     if (!Number(value)) {
        //         console.log("not number")
        //     }
        //     else {
        //         console.log("is numb")
        //         setInputField({ ...inputField, [name]: value })
        //     }
        // }
        // else {
        //     console.log("not teacher fil")
        //setInputField({ ...inputField, [name]: value })
        // }
        console.log({ inputField })

    }



    const verifyEmail = (e) => {
        console.log(e.target.value)
        if (e.target.value) {
            dispatch(verifyEMAIL(e.target.value))
        }
    }
    const validateRef = () => {
        dispatch(validateReferralCode(inputField.teacher_referral_code, callback))
    }
    const callback = () => {
        setErrorz({ ...errorz, refferal: null, refferalValidation: true })
        // alert("callback")
    }
    const verifyMobile = () => {
        console.log('Anil ' + inputField.teacher_mobile)
        dispatch(validateTeacherMobile(inputField.teacher_mobile, sendOtp))


    }
    const sendOtp = () => {
        dispatch(requestOtp(inputField.teacher_mobile))
    }

    // const checkMobileAvailability = (e) => {
    //     const { value } = e.target;
    //     dispatch(validateTeacherMobile(value))
    // }
    const [otp, setOtp] = useState('')
    const handleChangeOTP = (e) => {
        setOtp(e.target.value)
    }

    const validateOtp = () => {
        dispatch(validatingOtp(inputField.teacher_mobile, otp, callback2))
    }
    const callback2 = () => {
        setErrorz({ ...errorz, mobileValidated: true })
    }
    const [passCheck, setPassCheck] = useState();

    const confirmPass = (e) => {
        const { value } = e.target;
        setPassCheck(value)
    }
    // const [passError, setPassError] = useState();
    // console.log("pppcheeekkk", passError)




    const formSubmit = (e) => {
        e.preventDefault()
        setErrorz({})
        // debugger;
        console.log("valid", Grades)

        // const handleInputChange = (telNumber, selectedCountry) => {
        //     console.log('input changed. number: ', telNumber, 'selected country: ', selectedCountry)
        // }


        const errors = {
            first_name: nameValidator(inputField.first_name),
            last_name: nameValidator(inputField.last_name),
            email: emailValidator(inputField.email),
            password: passwordValidator(inputField.password),
            cCheckpass: cpasswordValidator(inputField.password, passCheck),
            cpassword: nameValidator(passCheck),
            //refferal: nameValidator(inputField.teacher_referral_code),
            mobile: mobileValidator(inputField.teacher_mobile),
            mobileValidated: Grades.valid,
            //refferalValidation: Grades.refferalValidation.result,
            termsAndCondi: checkBoxValidtor(inputField.termsAndCondition)

        }
        console.log("erroessss-----------", errors)
        if (
            !errors.first_name &&
            !errors.last_name &&
            !errors.email &&
            !errors.password &&
            !errors.cCheckpass &&
            !errors.mobile &&
            //!errors.refferal &&
            !errors.termsAndCondi &&
            Grades.emailValidation.result &&
            Grades.valid &&
            Grades.teacherNumberAvail
            // Grades.refferalValidation.result
        ) {
            console.log("erroessss---after codition--satis------", errors)
            dispatch(registerStudent(inputField, cb))
            setInputField(initialState)

        } else {

            setErrorz(errors)
            console.log({ errors })
            console.log("valid", Grades.emailValidation)
        }



    }
    // const [value, setValue] = useState();
    const cb = () => {
        history.push('/dashboard')
    }

    return (

        <Fragment>
            <div className="h-100 ">
                <Row className=" h-100 no-gutters" >
                    <Col lg="7" md="12"

                        className="h-100 jms-h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                        <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                            {/* <div className="app-logo" /> */}
                            <Link to="/">
                                <img src={logo} width="100px" height="60px" style={{ paddingBottom: '30px' }} />
                            </Link>
                            <h4>
                                <div>Teacher Signup</div>
                            </h4>
                            <div>
                                <Form onSubmit={formSubmit} autocomplete="off">
                                    <Row form>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}First Name
                                                    </Label>
                                                <Input type="text" name="first_name" id="exampleNameF"
                                                    value={inputField.first_name}
                                                    placeholder="First Name..."
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.first_name ? true : false}
                                                />
                                                <FormFeedback >oops! your first name is required</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleNameL">
                                                    <span className="text-danger">*</span>
                                                    {' '} Last Name</Label>
                                                <Input type="text" name="last_name" id="exampleNameL"
                                                    placeholder="Last Name... "
                                                    value={inputField.last_name}
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.last_name ? true : false}

                                                />
                                                <FormFeedback >oops! your last name is required</FormFeedback>

                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleEmail">
                                                    <span className="text-danger">*</span>
                                                    {' '}Email
                                                    </Label>
                                                <Input type="email" name="email" id="exampleEmail"
                                                    placeholder="Email here..."
                                                    value={inputField.email}
                                                    onChange={(e) => handleChange(e)}
                                                    onBlur={(e) => verifyEmail(e)}

                                                    valid={Grades.emailValidation.result ? true : false}
                                                    invalid={Grades.emailValidation.result === false || errorz.email ? true : false}
                                                />
                                                {errorz.email ?
                                                    <FormFeedback>oops! email id is required</FormFeedback>
                                                    : null
                                                }
                                                {
                                                    Grades.emailValidation.result === false ?
                                                        <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

                                                        : null
                                                }
                                                {/* {
                                                    Grades.emailValidation.result= false ?
                                                        <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

                                                        : null
                                                } */}
                                                <FormFeedback valid>Sweet! that email id is available</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="exampleNameL">
                                                    <span className="text-danger">*</span>
                                                    {' '} Referral</Label>
                                                <InputGroup>
                                                    <Input
                                                        value={inputField.teacher_referral_code}
                                                        disabled={true}
                                                        name="teacher_referral_code"
                                                        type="text"
                                                        placeholder="Referral here..."
                                                        valid={Grades.refferalValidation.result}
                                                        maxLength={12}
                                                        onChange={(e) => handleChange(e)}
                                                    //invalid={errorz.refferal || errorz.refferalValidation === null || errorz.refferalValidation === false || Grades.refferalValidation.result === false ? true : false}

                                                    // invalid={errorz.refferal || errorz.refferalValidation === null || errorz.refferalValidation === false || Grades.refferalValidation.result === false ? true : false}
                                                    />




                                                    <InputGroupAddon addonType="prepend">
                                                        <Button
                                                            onClick={() => validateRef()}
                                                            disabled={true}
                                                        >
                                                            <span style={Grades.refferalValidationLoader ? { visibility: 'hidden' } : null}>
                                                                Validate
                                                                 </span>
                                                            {Grades.refferalValidationLoader ? <Loader options={option} /> : null}
                                                        </Button>
                                                    </InputGroupAddon>

                                                </InputGroup>

                                                {/* <span style={{ color: '#dc3545', fontSize: '80%' }}>
                                                    {!errorz.refferal ? errorz.refferalValidation === null || errorz.refferalValidation === false ?
                                                        `please validate referral code` : null : null
                                                    }
                                                </span>
                                                {errorz.refferal ?
                                                    // <FormFeedback >oops! referral code is required</FormFeedback>
                                                    // : null
                                                    <span style={{ color: '#dc3545', fontSize: '80%' }}>
                                                        {'oops! referral code is required'
                                                        }</span> : null
                                                }
                                                {
                                                    Grades.refferalValidation.result === false ? <p><span style={{ color: '#dc3545', fontSize: '80%' }}>
                                                        {' oops! please enter valid referral code'


                                                        }</span> </p> : null
                                                } */}
                                            </FormGroup>

                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="examplePassword">
                                                    <span className="text-danger">*</span>
                                                    {' '}Password
                                                    </Label>
                                                <Input
                                                    type="password"
                                                    name="password"
                                                    id="examplePassword"
                                                    placeholder=" Password here..."
                                                    onChange={(e) => handleChange(e)}
                                                    invalid={errorz.password ? true : false}
                                                />
                                                <FormFeedback >oops!  password is required</FormFeedback>

                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="examplePasswordRep">
                                                    <span className="text-danger">*</span>
                                                    {' '}Confirm Password
                                                    </Label>
                                                <Input type="password" name="cpassword" id="examplePasswordRep"
                                                    placeholder="Confirm Password here..."
                                                    onChange={(e) => confirmPass(e)}

                                                    invalid={errorz.cCheckpass ? true : false}
                                                />
                                                <FormFeedback>oops! the password not matching</FormFeedback>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={8} xs={8}>
                                                    <Label for="exampleName">
                                                        <span className="text-danger">*</span>
                                                        {' '}Mobile Number</Label>


                                                    {/* 
                                            <Label for="exampleName">
                                                <span className="text-danger">*</span>
                                                {' '}Mobile Number</Label>
                                            <InputGroup>
                                            <table width="100%" align="right"><tr><td>

                                            <PhoneInput
                                                    placeholder="Enter phone number"
                                                    value={inputField.teacher_mobile}
                                                    name="teacher_mobile"
                                                    disabled={Grades.valid ? true : false}
                                                    placeholder="Mobile here..."
                                                    onChange={(e) => onChng(e)}
                                                    maxLength={12}
                                                    invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
                                                     />
                                               </td> <td> 
                                                     */}
                                                    <PhoneInput

                                                        placeholder="Enter phone number"
                                                        value={inputField.teacher_mobile}
                                                        name="teacher_mobile"
                                                        disabled={Grades.valid ? true : false}
                                                        // placeholder="Mobile here..."
                                                        onChange={(e) => onChng(e)}
                                                        maxLength={15}
                                                        invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
                                                    />
                                                    <span style={{ color: '#dc3545', fontSize: '80%' }}>
                                                        {Grades.teacherNumberAvail === false ?
                                                            'Mobile Already Registered' : null
                                                        }
                                                        {errorz.mobile ? errorz.mobile : null}
                                                        {!errorz.mobile ? errorz.mobileValidated === null || errorz.mobileValidated === false ? 'please validate your mobile' : null : null}
                                                    </span>
                                                </Col>
                                                <Col md={4} xs={4}>

                                                    <Button
                                                        style={{ borderRadius: '0px', width: '74px', fontSize: '10px', padding: '10px 10px', marginTop: '29px', marginLeft: '-8px' }}

                                                        onClick={verifyMobile}
                                                        disabled={inputField.teacher_mobile ? inputField.teacher_mobile.length <= 9 : null || Grades.validateOtpLoaderT || Grades.valid}
                                                    >
                                                        <span style={Grades.validateOtpLoaderT ? { visibility: 'hidden' } : null}>
                                                            {Grades.teacherNumberAvail ? 'Resend' : 'Send OTP'}
                                                        </span>

                                                        <span>
                                                            {Grades.validateOtpLoaderT ? <Loader options={option} /> : null}

                                                        </span>
                                                    </Button>

                                                </Col>
                                            </Row>
                                            <FormGroup>






                                                {/* <InputGroupAddon addonType="prepend">

                                                    </InputGroupAddon>
                                                <span style={{ color: 'red' }}>
                                                    {''}</span>
                                                {
                                                    errorz.mobileValidated === null || errorz.mobileValidated === false ?
                                                        <FormFeedback> {`oops! verify mobile number `}</FormFeedback>
                                                        : null
                                                }
                                                {
                                                    errorz.mobile ?
                                                        <FormFeedback> {`oops! ${errorz.mobile}`}</FormFeedback>
                                                        : null
                                                }

                                                {/* <FormText>Enter your mobile number with country code</FormText> */}



                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>


                                            {Grades.teacherNumberAvail ?
                                                // Grades.otp === true ?
                                                <FormGroup>
                                                    <Label for="exampleName">
                                                        <span className="text-danger">*</span>
                                                        {' '}Enter OTP</Label>
                                                    <InputGroup>

                                                        <Input
                                                            name="otp"
                                                            onChange={handleChangeOTP}
                                                            type="number"
                                                            invalid={Grades.valid === false || errorz.mobileValidated === null ? true : false}
                                                            valid={Grades.valid ? true : false}
                                                            readOnly={Grades.valid ? true : false}
                                                        />

                                                        <InputGroupAddon addonType="prepend"><Button
                                                            onClick={() => validateOtp()}
                                                            disabled={!otp || Grades.validateOtpValidateLoader || Grades.valid ? true : false}
                                                        >
                                                            <span style={Grades.validateOtpValidateLoader ? { visibility: 'hidden' } : null}>
                                                                Validate
                                                                 </span>
                                                            {Grades.validateOtpValidateLoader ? <Loader options={option} /> : null}
                                                        </Button>
                                                        </InputGroupAddon>
                                                        <br />

                                                        <FormFeedback>
                                                            {Grades.valid === false ? `oops! Invalid OTP` : errorz.mobileValidated === null ? 'oops! Please validate OTP' : null}

                                                        </FormFeedback>


                                                    </InputGroup>

                                                </FormGroup>

                                                : null

                                            }

                                        </Col>

                                    </Row>
                                    <FormGroup className="mt-3" check>
                                        <Row>
                                            <Col md={6} xs={12}>
                                                <Input type="checkbox" name="check"
                                                    onChange={() => handleChangeTerm()}
                                                    checked={inputField.termsAndCondition}
                                                //ref={inputRef}
                                                //value="accept"

                                                />{' '}
                                                <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="#" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>

                                            </Col>
                                            <Col md={6} xs={12}>
                                                <Label for="exampleCheck" check style={{ paddingRight: '10px' }}> Are you a Student? <Link to={'register'}><a>Signup Here</a></Link></Label>

                                            </Col>
                                        </Row>
                                        {/* <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>
                                        <Label for="exampleCheck" check style={{ float: 'right', paddingRight: '10px' }}> Are you a Student? <Link to={'register'}><a>Signup Here</a></Link></Label> */}

                                    </FormGroup>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h7 className="mb-0">
                                            Already have an account?{' '}

                                            <Link to={`/login`}>
                                                <a className="text-primary">Sign in</a>
                                            </Link>
                                        </h7>
                                        <div className="ml-auto">


                                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                loading={Grades.loader}
                                                //  onClick={() => this.toggle('expZoomIn')}
                                                type="submit"
                                                data-style={ZOOM_IN}
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                Create Account
                                            </LaddaButton>



                                            {/* <Button type="submit" color="primary"
                                                className="btn-wide btn-pill btn-shadow btn-hover-shine"
                                                size="lg">
                                                {Grades.loader ?
                                                    <Loader color="white" color="#000" length={1} width={1} type="ball-clip-rotate" /> :
                                                    'Create Account'
                                                }

                                            </Button> */}

                                            {/* <Button type="submit" color="primary" className="btn-wide btn-pill btn-shadow btn-hover-shine" size="lg">Create Account</Button> */}
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                    <Col lg="5" className="d-lg-flex d-xs-none">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark"
                                    style={{ height: '100vh' }}
                                >
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />
                                    <div className="slider-content">
                                        <div style={{ visibility: 'hidden' }}>
                                            <h3>Scalable, Modular, Consistent</h3>
                                            <p>
                                                Easily exclude the components you don't require. Lightweight, consistent
                                                Bootstrap based styles across all elements and components
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment >
    );

}
export default TRegister








