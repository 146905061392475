import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "reactstrap";
import ListExams from "../../common/DataTable";
import { useHistory } from "react-router-dom";
import Modals from "../../common/Modal";
import { getStudentExams, getTeacherExams } from "../../../redux/Actions/exam";
import AssignStudents from "./assignStudents";
import UpdateExamTime from "./UpdateExamTime";
//import { getTeacherAssignedGroups,populateRegisteredStudents } from '../../../redux/Actions/homeTeacher'
//import { populateVideoClassStudents } from '../../../redux/Actions/playlist'
import {
  populateExamStudents,
  populateExamGroups,
  setApprovalExam,
} from "../../../redux/Actions/exam";
import Swal from "sweetalert2";
// import './style.scss'
let columns;

const Exam = () => {
  const dispatch = useDispatch();
  const examStore = useSelector(({ exam }) => exam);
  const history = useHistory();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const [openAssignModal, setAssignModal] = useState(false);
  const [ExamID, setExamID] = useState(null);
  const [updateModal, setupdateModal] = useState(false);
  const [examDetails, setexamDetails] = useState(null);

  const tStore = useSelector(({ homeTeacher }) => homeTeacher);

  useEffect(() => {
    if (tokenData.role === "student") {
      columns = [
        {
          title: "Exam ",
          field: "exam_title",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Grade ",
          field: "grade_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Duration",
          field: "exam_duration",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Attendance",
          field: "attended",
          render: (rowData) => (
            <Badge color={rowData.attended ? "success" : "danger"} pill>
              {rowData.attended ? "Attended" : "Not Attended"}
            </Badge>
          ),
        },
        {
          title: "Mark",
          field: "mark",
          render: (rowData) => (
            <Badge
              color={
                rowData.attended && !rowData.mark
                  ? "warning"
                  : !rowData.attended
                  ? "danger"
                  : "info"
              }
              pill
            >
              {rowData.attended && !rowData.mark
                ? "Not Evaluated"
                : !rowData.attended
                ? "Not Attended"
                : rowData.mark}
            </Badge>
          ),
        },

        {
          title: "Subject",
          field: "subject_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Date",
          field: "date",
          headerStyle: { fontWeight: "600" },
        },
      ];
      dispatch(getStudentExams(tokenData.token));
    } else if (tokenData.role === "principal") {
      columns = [
        {
          title: "Exam ",
          field: "exam_title",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Question Paper ",
          field: "question_paper",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Question Type ",
          field: "question_paper",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Grade ",
          field: "grade_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Subject",
          field: "subject_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Duration",
          field: "exam_duration",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Teacher",
          field: "teacher_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Date",
          field: "datetime",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Approval",
          field: "approval",
          headerStyle: { fontWeight: "600" },
          render: (rowData) => (
            <Badge color={rowData.approval ? "success" : "warning"} pill>
              {rowData.approval ? "approved" : "pending"}
            </Badge>
          ),
        },
      ];
      dispatch(getTeacherExams(tokenData.token));
    } else if (tokenData.role === "teacher" && tokenData.enterpriseUser) {
      columns = [
        {
          title: "Exam ",
          field: "exam_title",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Question Paper ",
          field: "question_paper",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Grade ",
          field: "grade_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Subject",
          field: "subject_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Duration",
          field: "exam_duration",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Status",
          field: "approval",
          headerStyle: { fontWeight: "600" },
          render: (rowData) => (
            <Badge color={rowData.approval ? "success" : "warning"} pill>
              {rowData.approval ? "approved" : "pending"}
            </Badge>
          ),
        },

        {
          title: "Date",
          field: "datetime",
          headerStyle: { fontWeight: "600" },
        },
      ];
      dispatch(getTeacherExams(tokenData.token));
    } else {
      columns = [
        {
          title: "Exam ",
          field: "exam_title",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Question Paper ",
          field: "question_paper",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Grade ",
          field: "grade_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Subject",
          field: "subject_name",
          headerStyle: { fontWeight: "600" },
        },
        {
          title: "Date",
          field: "datetime",
          headerStyle: { fontWeight: "600" },
        },
        // {
        //   title: "Status",
        //   field: "approval",
        //   headerStyle: { fontWeight: "600" },
        //   render: (rowData) => (
        //     <Badge color={rowData.approval ? "success" : "warning"} pill>
        //       {rowData.approval ? "approved" : "pending"}
        //     </Badge>
        //   ),
        // },
      ];
      dispatch(getTeacherExams(tokenData.token));
    }
  }, []);

  const hadleAnswerSheet = (rowData) => {
    if (!rowData.attended) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "You haven't attended the exam",
        confirmButtonText: "Okay",
      });
    } else if (rowData.attended && !rowData.mark) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Answer sheet isn't valuated yet",
        confirmButtonText: "Okay",
      });
    } else {
      // alert("answer")
      history.push(`/dashboard/answerSheet/${rowData.exam_id}`);
    }
  };

  const assignToExam = (grade_id, exam_id) => {
    setExamID(exam_id);
    //alert(exam_id)
    // console.log('data dat',rowData);
    if (tokenData.enterpriseUser) {
      dispatch(populateExamGroups(tokenData.token, exam_id, ""));
    } else {
      dispatch(populateExamStudents(tokenData.token, grade_id, exam_id));
    }
    setAssignModal(true);
  };

  const toggleApproval = (rowData) => {
    const approval = !rowData.approval;
    console.log(rowData);
    dispatch(
      setApprovalExam(
        tokenData.token,
        approval,
        rowData.exam_id,
        rowData.teacher_id,
        cb
      )
    );
  };
  const cb = () => {
    dispatch(getTeacherExams(tokenData.token));
  };

  const handleCloseStudentModal = () => {
    dispatch({ type: "RESET_ASSIGN_STUDENT_CLASS" }); //Resetting specific student and group
      dispatch({ type: "RESET_GROUP_LIST" });
    setAssignModal(false);
    console.log("callback");
  };
  const doToExamDetails = (data) => {
    // console.log({ data });
    history.push(`/dashboard/exam/showExams/${data.exam_id}`);
  };

  //Opening update exam timings modal
  const updateExam = (rowData) => {
    //setExamID(exam_id)
    //alert(exam_id)
    // console.log('data dat',rowData);
    //if (tokenData.enterpriseUser) {
    //  dispatch(populateExamGroups(tokenData.token, exam_id,'','exam'))

    //} else {
    //   dispatch(populateExamStudents(tokenData.token,grade_id,exam_id))

    //}
    console.log("rowdat", rowData);
    setexamDetails(rowData);
    setupdateModal(true);
  };
  const handleCloseUpdateModal = () => {
    setupdateModal(false);
    console.log("callback");
  };

  const addInstruction = (exam_id) => {
    history.push(`/dashboard/exam/addInstructions/${exam_id}`);
  };

  return (
    <>
      <ListExams
        columns={columns}
        actions={"list_exam"}
        title={"Exams"}
        loader={examStore.listExamLoader}
        data={examStore.listExams}
        hadleAnswerSheet={hadleAnswerSheet}
        assignToExam={assignToExam}
        doToExamDetails={doToExamDetails}
        updateExam={updateExam}
        toggleApproval={toggleApproval}
        addInstruction={addInstruction}
      />
      <Modals
        open={openAssignModal}
        handleClose={handleCloseStudentModal}
        title={tokenData.enterpriseUser ? "Assign groups" : "Assign students"}
      >
        <AssignStudents
          loader={
            tokenData.enterpriseUser
              ? tStore.specificGroupLoader
              : tStore.specificStudentLoader
          }
          examId={ExamID}
          data={
            tokenData.enterpriseUser
              ? tStore.specificGroup
              : tStore.specificStudent
          }
          // data={tStore.specificStudent}
          handleCloseStudentModal={handleCloseStudentModal}
          timeData={"timeData"}
        />
      </Modals>

      <Modals
        open={updateModal}
        handleClose={handleCloseUpdateModal}
        title={`Update exam timings`}
      >
        <UpdateExamTime
          loader={""}
          rowData={examDetails}
          handleCloseUpdateModal={handleCloseUpdateModal}
        />
      </Modals>
    </>
  );
};
export default Exam;
