import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import store from './redux/store'
import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';            
import Main from './Pages/Main';
// import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

//const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {

    console.log("r-1");


    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>

                {/* <BrowserRouter> */}
                <Component />

                {/* </BrowserRouter> */}




            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

// if (module.hot) {
//     module.hot.accept('./DemoPages/Main', () => {
//         const NextApp = require('./DemoPages/Main').default;
//         renderApp(NextApp);
//     });
// }
serviceWorker.unregister();

