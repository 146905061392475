import React, { Fragment, useState } from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { emailValidator, requiredValidator } from '../../../utilities/Validator'
import { authRequest } from '../../../redux/Actions/auth'
import { useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Logo from './motswhite.png'
const LoginBoxed = ({ match }) => {

    //middlewares
    const dispatch = useDispatch()
    const history = useHistory()
    //Local States
    const [Email, setEmail] = useState();
    const [Password, setPassword] = useState();
    const [Errors, setErrors] = useState({});

    const [Loader, setLoader] = useState(false)

    //Functions
    const handleLogin = () => {

        const err = {
            email: emailValidator(Email),
            password: requiredValidator(Password)
        }

        if (!err.email && !err.password) {

            let data = {
                device_type: 'web',
                email: Email,
                password: Password,
                timezone: moment.tz.guess()
            }
            setLoader(true)
            dispatch(authRequest(data, scb, fcb))
        }

        setErrors(err)
    }

    const scb = () => {
        setLoader(false)
        history.push('/dashboard')
    }

    const [InvalidMsg, setInvalidMsg] = useState()
    
    const fcb = (msg) => {
        setLoader(false)
        setInvalidMsg(msg)
    }

    return (
        <Fragment>
            <div className="h-100 bg-plum-plate bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        {/* <div className="app-logo-inverse mx-auto mb-3"/> */}
                        <div style={{textAlign:'center'}} >
                        <img alt="logo" className=" mb-3" src={Logo} ></img>
                        </div>

                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                        <h4 className="mt-2">
                                            <div>Welcome back,</div>
                                            <span>Please sign in to your admin account below.</span>
                                        </h4>
                                    </div>
                                    <Form >
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Input invalid={Errors.email} onChange={(e) => setEmail(e.target.value)} value={Email} type="email" name="email" id="exampleEmail"
                                                        placeholder="Email here..." />
                                                    <FormFeedback>{Errors.email}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Input invalid={Errors.password} onChange={(e) => setPassword(e.target.value)} value={Password} type="password" name="password" id="examplePassword"
                                                        placeholder="Password here..." />
                                                    <FormFeedback>{Errors.password}</FormFeedback>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup check>
                                            <Input type="checkbox" name="check" id="exampleCheck" />
                                            <Label for="exampleCheck" check>Keep me logged in</Label>
                                        </FormGroup>
                                    </Form>
                                    {/* <div className="divider"/>
                                <h6 className="mb-0">
                                    No account?{' '}
                                    <a href="https://colorlib.com/" onClick={(e)=>e.preventDefault()} className="text-primary">Sign up now</a>
                                </h6> */}
                                </div>
                                <div className="modal-footer clearfix">
                                    <div className="float-left text-danger">
                                        {/* <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="btn-lg btn btn-link">Recover
                                        Password</a> */}
                                        {InvalidMsg} 
                                    </div>
                                    <div className="float-right">
                                        {/* <Button onClick={handleLogin} color="primary" size="lg">Login to Dashboard</Button> */}
                                        <LaddaButton className="m-3 btn btn-primary"
                                            loading={Loader}
                                            type="submit"
                                            onClick={handleLogin}
                                            data-style={ZOOM_IN}  >
                                            Login
                                </LaddaButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="text-center text-white opacity-8 mt-3">
                        Copyright &copy; ArchitectUI 2019
                    </div> */}
                    </Col>
                </div>
            </div>
        </Fragment>
    )
};

export default LoginBoxed;
