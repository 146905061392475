import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Col, Row, Card, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { validateStartExam, getExamDetails } from "../../../redux/Actions/exam";
import Countdown from "../../common/Timer/countdown";
import { useHistory, } from "react-router-dom";
// import Swal from "sweetalert2";

const Instruction = () => {
  // const [validationPass, setValidationPass] = useState(false);
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const { exam_id } = useParams();
  const history = useHistory();
  // const { exam_id } = useParams();

  const dispatch = useDispatch();
  // const startExam = () => setValidationPass(true)

  const startExam = () => {
    getExamDetails(tokenData.token);
  };

  useEffect(() => {
    dispatch(validateStartExam(startExam, tokenData.token, exam_id, "home"));
    //dispatch(getDuration(tokenData.token,exam_id))
  }, []);

  //dispatch(validateStartExam(startExam, tokenData.token, exam_id, "instruction"))

  const examStore = useSelector(({ exam }) => exam);
  const [readInstruction, setReadInstruction] = useState(false);

  console.log("estore", examStore);
  const enterExamhall = () => {
    // history.push(`/dashboard/instructions/${examId}`)
    history.push(`/dashboard/examHall/${exam_id}`);
  };

  const start = () => {
    setReadInstruction(true);
  };

  // const enterExam = (exam_id) => {
  //   dispatch(
  //     validateStartExam(enterExamhall, tokenData.token, exam_id, "home")
  //   );
  // };

  return (
    <div>
      <Row className="jms-container">
        <Card className="jms-instruction">
          <Row className="p-5">
            <Col md={12}>
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  fontSize: 25,
                  fontWeight: 700,
                }}
              >
                <Countdown
                  finish={start}
                  date={examStore.instructions?.Timout_Duration}
                  type={"instruction"}
                />
              </div>
            </Col>
            {/* <Col md={6} >
                    <div className="exam-total-duration-position-left">
                        <div ><span className="exam-label">Subject : </span> <span className="exam-title"> {examStore.instructions?.Subject}</span></div>
                        <div ><span className="exam-label">Date : </span> <span className="exam-title">{examStore.instructions?.Date}</span></div>

                    </div>
                </Col>
                <Col md={6}>
                    <div className="exam-total-duration-position-right">
                        <div ><span className="exam-label">Total Mark : </span><span className="exam-title">{examStore.instructions?.Total_mark}</span> </div>
                        <div ><span className="exam-label">Duration : </span><span className="exam-title">{examStore.instructions?.Duration} </span></div>
                    </div>

                </Col> */}
            <Col md={12} style={{ paddingTop: 40 }}>
              <hr />
            </Col>
            <Col md={12}>
              <p
                style={{
                  fontWeight: 700,
                  textAlign: "center",
                  textDecoration: "underline",
                }}
              >
                Instructions
              </p>
              <p>General</p>
              <ol>
                {examStore?.general_instructions.map((row) => (
                  <li>{row.data}</li>
                ))}
              </ol>
              <p>Teacher's</p>
              <ol>
                {examStore?.specific_instructions.map((row) => (
                  <li>{row.data}</li>
                ))}
              </ol>
            </Col>
            <Col md={12} style={{ textAlign: "center" }}>
              <hr />
              <Button
                color="primary"
                disabled={!readInstruction}
                className="btn-pill btn-shadow btn-wide fsize-1"
                size="lg"
                onClick={enterExamhall}
                // onClick={() => setReadInstruction(true)}
                // disabled={true}
                //disabled={(hr == '00' && mn == '00' && (sc == '01' || sc == '00' || sc == "NaN") && loader === false) ? false : true}
              >
                <Row>
                  {/* <span className="mr-2 opacity-7">

                            <IoIosAnalytics color="#ffffff" />
                        </span> */}
                  <span className="mr-1">Read & Continue</span>
                  {/* <ClipLoader
                            size={20}
                            color={"white"}
                            loading={loader}
                        /> */}
                </Row>
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </div>
  );
};

export default Instruction;
