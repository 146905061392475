import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import tag from './../../images/tag.png';

const PriceList = () => {
    return (
        <Fragment>
            <section id="price" class="plan-section">
                <div className="container-fluid">
                    <div className="col-lg-12">
                        <div className="left-plan">
                            <h3 className="hh3">Plans & Pricing<br />
                                <span>great explorer</span>
                            </h3>
                            <p>All features are open and free for the 1st month, for all plans.</p>
                            {/* <a class="button-blue-big" href="#">Plan</a> */}
                            <div className="clearfix"></div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div className="platinum basic">
                                <div className="plan-hdr">
                                    <h5 class="hh5">Basic</h5>
                                    <img className="img-fluid tag-price" alt='price' src={tag} />
                                </div>

                                <div className="data-list">
                                    <h6>Free</h6>

                                    <ul>
                                        <li>10 concurrent students </li>
                                        <li>8 classes / month </li>
                                        <li>Class duration : 2hr / class</li>

                                    </ul>
                                    <button className="button-blue-big basic-btn" >
                                        <Link className="button-trial" to="/teacher-register">Start free trial</Link></button>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div className="platinum gold">
                                <div className="plan-hdr silver">
                                    <h5 class="hh5">Silver</h5>
                                    <img className="img-fluid tag-price" alt='price' src={tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$10 / month </h6>

                                    <ul>
                                        <li>20 concurrent students</li>
                                        <li>15 classes / month</li>
                                        <li>Class duration : 3hr / class</li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring</li>
                                        <li>Recording up to 1GB </li>
                                    </ul>
                                    <button className="button-blue-big silver-btn" >
                                        <Link className="button-trial" to="/teacher-register">Start free trial</Link>
                                    </button>

                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div className="platinum gold silver">
                                <div className="plan-hdr">
                                    <h5 class="hh5">Gold</h5>
                                    <img className="img-fluid tag-price" alt='price' src={tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$15 / month</h6>

                                    <ul>
                                        <li>40 concurrent students </li>
                                        <li>Unlimited number of classes </li>
                                        <li>No duration limit </li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring </li>
                                        <li>Recording up to 1GB</li>
                                    </ul>
                                    <button className="button-blue-big gold-btn" href="/teacher-register">
                                        <Link className="button-trial" to="/teacher-register">Start free trial</Link>
                                    </button>

                                </div>

                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-3 col-md-6">
                            <div className="platinum">
                                <div className="plan-hdr">
                                    <h5 class="hh5">Platinum</h5>
                                    <img className="img-fluid tag-price" alt='price' src={tag} />
                                </div>

                                <div className="data-list">
                                    <h6>$25 / month</h6>

                                    <ul>
                                        <li>100 concurrent students</li>
                                        <li>Unlimited number of classes </li>
                                        <li>No duration limit </li>
                                        <li>Assignment creation & valuation</li>
                                        <li>Attendance monitoring</li>
                                        <li>Recording up to 2.5GB</li>
                                    </ul>
                                    <button className="button-blue-big platinum-btn">
                                        <Link className="button-trial" to="/teacher-register">Start free trial</Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default PriceList;
