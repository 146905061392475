import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Card, Form, FormGroup, Label, FormFeedback, Input } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { getGradeList, populateDegree } from '../../../../redux/Actions/signUp'
import { populateAllCollegeSubjects, updateSubject, deleteSubject, populateSubject } from '../../../../redux/Actions/homeTeacher'
import serialize from 'form-serialize'
import { populatePlayList, createPlayList } from '../../../../redux/Actions/playlist'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { requiredValidator } from '../../../../utilities/Validator'

import Swal from 'sweetalert2'
import PlaylistTumnile from '../../../common/Thumbnile/playlist'



const College = (props) => {
    const { DeletePlaylists, EditPlaylists, editMode, openEdit, handleChange, cancelEdit } = props

    const dispatch = useDispatch()
    const Grades = useSelector(({ signUp }) => signUp)
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const playlistStore = useSelector(({ playlist }) => playlist)


    const tokenData = JSON.parse(localStorage.getItem("authDetails"));


    useEffect(() => {
        dispatch(populateDegree())
        dispatch(populateAllCollegeSubjects(tokenData.token))
        dispatch(populatePlayList(tokenData.token, 2))


        return () => {

        }
    }, [])
    const handleDegree = (e) => {
        const { value, name } = e.target
        dispatch(getGradeList(value, 2))
        setInputField({ ...inputField, [name]: value })


    }
    const handleChangeSpecialization = (e) => {
        const { name, value } = e.target;
        dispatch(populateSubject(tokenData.token, value, 2))
        // setInputField({ ...inputField, [name]: value })
        setInputField({ ...inputField, [name]: value })

    }
    const onEdit = (rowData) => {
        dispatch(updateSubject(tokenData.token, rowData))

    }

    const onDelete = (id) => {

        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            //text: 'All the classes under the subject will be deleted',

            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteSubject(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const handleChangeInput = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [inputField, setInputField] = useState({
        playlist_name: '',
        grade_id: '',
        subject_id: '',
        degree_id: ''
    })
    const [errors, setErrors] = useState({})

    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        let error;
        error = {
            playlist_name: requiredValidator(inputField.playlist_name),
            grade_id: requiredValidator(inputField.grade_id),
            subject_id: requiredValidator(inputField.subject_id),
            degree_id: requiredValidator(inputField.degree_id)
        }
        if (!error.playlist_name && !error.grade_id && !error.subject_id && !error.degree_id) {
            dispatch(createPlayList(tokenData.token, body, reset))
            setErrors(error)

        } else {
            setErrors(error)
        }


    }
    const reset = () => {
        document.getElementById("create-form-college").reset();
    }
    return (
        <>




            <Form onSubmit={formSubmit} id="create-form-college">


                <Card>
                    <Row className="jms-subject-row-pad">
                        <Col md={8}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">
                                <span className="text-danger">*</span>
                                {' '}Title</Label>
                                <Input type="text" name="playlist_name"
                                    invalid={errors.playlist_name}
                                    onChange={handleChangeInput}
                                />
                                <FormFeedback >{errors.playlist_name}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <input type="hidden" value={2} name="category_id" />
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">
                                <span className="text-danger">*</span>
                                {' '}Course</Label>
                                <Input type="select" name="degree_id" onChange={handleDegree}
                                    invalid={errors.degree_id}

                                >
                                    <option value="">Select</option>
                                    {
                                        Grades.degree ? Grades.degree.map((data) => {
                                            return (
                                                <option value={data.degree_id} key={data.degree_id}>{data.degree_name}</option>

                                            )
                                        })
                                            : []
                                    }
                                </Input>
                                <FormFeedback >{errors.degree_id}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">
                                <span className="text-danger">*</span>
                                {' '}Specialization</Label>
                                <div class="form-group">
                                            <div class="input-group"> 
                                <Input type="select" name="grade_id"
                                    invalid={errors.grade_id}

                                    onChange={handleChangeSpecialization}
                                >
                                    <option value="">Select</option>

                                    {
                                        Grades.gradeCollege.grades ? Grades.gradeCollege.grades.map((data) => {
                                            return (
                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                            )
                                        })
                                            : []
                                    }


                                </Input>
                                {
                                    Grades.loadgrade ? 
                                    <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    </span>
                                    :
                                    null
                                }
                                </div>
                                </div>
                                <FormFeedback >{errors.grade_id}</FormFeedback>

                            </FormGroup>
                        </Col>
                        <Col md={4}>

                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Select Subject
                            </Label>
                            <div class="form-group">
                            <div class="input-group">
                            <Input type="select"
                                name='subject_id'
                                invalid={errors.subject_id}
                                onChange={handleChangeInput}
                            >
                                <option value={''}>{'select'}</option>

                                {tStore.subject ?
                                    tStore.subject.map(subj => {
                                        return (
                                            <option value={subj.subject_id}>{subj.subject_name}</option>

                                        )
                                    }) : null
                                }

                            </Input>
                            {
                                tStore.loadersub ? 
                                <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                                </span>
                                :
                                null
                            }
                            
                            </div>
                            </div>
                            <FormFeedback >{errors.subject_id}</FormFeedback>

                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled" style={{ visibility: 'hidden' }}>Subject</Label>

                                <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                    loading={playlistStore.createPlayListCollegeLoader}
                                    // onClick={formSubmit}
                                    type="submit"
                                    data-style={ZOOM_IN}
                                    style={{ whiteSpace: 'nowrap', marginRight: '0px', width: '-webkit-fill-available' }}

                                >
                                    Create Playlist
                                            </LaddaButton>
                            </FormGroup>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <div style={{ paddingTop: 20, fontWeight: 700 }}>{'PLAYLIST'} </div>

            <hr />
            <Row>

                {

                    playlistStore.populatePlaylistCollegeLoader === true ? <Col md={4} style={{ paddingBottom: '10px' }}>
                        <Card style={{ padding: '30px' }}>
                            <Row>
                                <Col md={3}>
                                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                        <Skeleton circle={true} height={50} width={50} count={1} />
                                    </SkeletonTheme>
                                </Col>
                                <Col md={9}>
                                    <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                                        <Skeleton height={20} count={4} />
                                    </SkeletonTheme>
                                </Col>
                            </Row>

                        </Card>
                    </Col> :
                        playlistStore.playlistCollege ? playlistStore.playlistCollege.map((playlist, index) => {
                            return (
                                <Col md={4} style={{ paddingBottom: '20px' }}>
                                    <PlaylistTumnile
                                        to={playlist.playlist_id}
                                        category_id={playlist.category_id}
                                        subject_id={playlist.subject_id}
                                        title={playlist.playlist_name}
                                        subject={playlist.subject_name}
                                        grade={playlist.grade_name}
                                        count={playlist.video_count}
                                        index={index}
                                        DeletePlaylists={DeletePlaylists}
                                        EditPlaylists={EditPlaylists}
                                        editMode={editMode}
                                        openEdit={openEdit}
                                        handleChange={handleChange}
                                        cancelEdit={cancelEdit}

                                    />


                                </Col>
                            )
                        }) : null
                }


            </Row>


        </>

    )
}

export default College
