import React, { Component, Fragment, useState } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'

import PerfectScrollbar from 'react-perfect-scrollbar';
import Toggle from '../../../../Components/Class/ClassRoom/toggle';
import { broadcastMikeSetting, toggleChange } from '../../../../redux/Actions/homeTeacher';

import Switchy from "react-switch";

const ToggleSwitch = () => {
    const dispatch = useDispatch();

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));



    const handleChange = (e) => {
        const { value } = e.target;

    }

    console.log("object", tStore.students)

    const [checkedState, setcheckedState] = useState(false)

    console.log("checkedState", checkedState)
    const handleChangeTo = (student_id, muteState) => {
        dispatch(toggleChange(student_id))
        // debugger;
        //     setcheckedState(!checkedState)
        //     muteDi(student_id);
        // }
        // const muteDi = (student_id) => {
        // setcheckedState(!checkedState)

        if (!muteState) {
            setcheckedState(!checkedState)
            console.log("checked trueeee")
            dispatch(broadcastMikeSetting(tokenData.token, "mute", student_id, tStore.muteStatus))
        }
        else {
            setcheckedState(!checkedState)

            console.log("checked false")
            dispatch(broadcastMikeSetting(tokenData.token, "unmute", student_id, tStore.muteStatus))

        }


    }
    return (
        <Fragment>
            <div className="scroll-area-sm">
                <PerfectScrollbar>
                    <div className="no-results pb-0">
                        <div >
                            {tStore.students ?
                                tStore.students.map((student, index) => {
                                    return (

                                        <Row>
                                            <Col sm={6}>
                                                {student.name}
                                            </Col>
                                            <Col sm={6}>

                                                {console.log("tStore.students", tStore.students)}
                                                <Switchy
                                                    onChange={(e) => handleChangeTo(student.id, !checkedState)}
                                                    // checked={
                                                    //     tStore.muteCheckedStatus.map(ck => {
                                                    //         console.log("student.id", student.id)
                                                    //         return (

                                                    //             ck === student.id ? true : false
                                                    //         )
                                                    //     })
                                                    // }
                                                    checked={student.mute}
                                                />

                                            </Col>



                                        </Row>
                                    )
                                }) : []
                            }
                        </div>
                        <div className="results-subtitle"></div>
                        <div className="results-title invisible">There are no system errors!</div>
                    </div>
                </PerfectScrollbar>
            </div>
        </Fragment>
    )
}


export default ToggleSwitch;