import React, { Fragment, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

// import Ionicon from 'react-ionicons';
import { logout } from '../../../redux/Actions/auth'
// import { IoIosCalendar } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar';
import { leaveClassRoom } from '../../../redux/Actions/homeTeacher'

import {
    DropdownToggle, DropdownMenu,
    Nav, Button, NavItem, NavLink,
    UncontrolledButtonDropdown
} from 'reactstrap';

import {
    toast,
    Bounce
} from 'react-toastify';


import {
    faAngleDown

} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import avatar1 from '../../../assets/utils/images/avatars/1.jpg';

const UserBox = (props) => {
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    // const [state, setstate] = useState({ active: false })
    const history = useHistory();
    // const notify2 = () => this.toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
    //     transition: Bounce,
    //     closeButton: true,
    //     autoClose: 5000,
    //     position: 'bottom-center',
    //     type: 'success'
    // });

    const dispatch = useDispatch()
    //console.log("process.env.PUBLIC_URL", process.env.PUBLIC_URL)

    const logOut = () => {

        console.log("process.env.PUBLIC_URL", window.location.pathname)
        const url = props.location.pathname.split('/')
        console.log("Split", url)
        if (url[1] === "startclass") {
            alert("yes")
            dispatch(leaveClassRoom(tStore.startedSession))
            dispatch(logout())
            localStorage.removeItem("authDetails");
            history.push('/login')
        } else {
            dispatch(logout())
            localStorage.removeItem("authDetails");

            // localStorage.clear()
            history.push('/login')
        }
        //console.log("withRouter", props.location.pathname)
        // if(process.env.PUBLIC_URL)

    }
    return (
        <Fragment>
            <div className="header-btn-lg pr-0">
                <div className="widget-content p-0">
                    <div className="widget-content-wrapper">
                        <div className="widget-content-left">

                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="link" className="p-0">
                                    <img width={42} className="rounded-circle" src={avatar1} alt="" />

                                    <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown} />
                                </DropdownToggle>
                                <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                    <div className="dropdown-menu-header">
                                        <div className="dropdown-menu-header-inner bg-info">
                                            <div className="menu-header-image opacity-2"
                                                style={{
                                                    backgroundImage: 'url(' + city3 + ')'
                                                }}
                                            />
                                            <div className="menu-header-content text-left">
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-3">
                                                            <img width={42} className="rounded-circle" src={avatar1}
                                                                alt="" />
                                                        </div>
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                {props.user}
                                                            </div>
                                                            <div className="widget-subheading opacity-8">
                                                                {props.role}
                                                            </div>
                                                        </div>
                                                        <div className="widget-content-right mr-2">
                                                            <Button className="btn-pill btn-shadow btn-shine"
                                                                color="focus"
                                                                onClick={() => logOut()}
                                                            >
                                                                Logout
                                                                </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="scroll-area-xs" style={{
                                        height: '150px'
                                    }}>
                                        <PerfectScrollbar>
                                            <Nav vertical>
                                                <NavItem className="nav-item-header">
                                                    Activity
                                                    </NavItem>
                                                <NavItem>
                                                    <NavLink href="#/">
                                                        Home
                                                            {/* <div className="ml-auto badge badge-pill badge-info">8</div> */}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="#/dashboard/change-password">
                                                        Change Password
                                                            {/* <div className="ml-auto badge badge-pill badge-info">8</div> */}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink href="#/dashboard/my-account">My Account</NavLink>
                                                </NavItem>
                                                {/* <NavItem className="nav-item-header">
                                                        My Account
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#">
                                                            Settings
                                                            <div className="ml-auto badge badge-success">New</div>
                                                        </NavLink>
                                                    </NavItem> */}
                                                {/* <NavItem>
                                                        <NavLink href="#">
                                                            Messages
                                                            <div className="ml-auto badge badge-warning">512</div>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink href="#">
                                                            Logs
                                                        </NavLink>
                                                    </NavItem> */}

                                            </Nav>
                                        </PerfectScrollbar>
                                    </div>
                                    {/* <Nav vertical>
                                            <NavItem className="nav-item-divider mb-0"/>
                                        </Nav> */}
                                    {/* <div className="grid-menu grid-menu-2col">
                                            <Row className="no-gutters">
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                        outline color="warning">
                                                        <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> </i>
                                                        Message Inbox
                                                    </Button>
                                                </Col>
                                                <Col sm="6">
                                                    <Button
                                                        className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                        outline color="danger">
                                                        <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> </i>
                                                        <b>Support Tickets</b>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                       */}
                                    {/* <Nav vertical>
                                            <NavItem className="nav-item-divider"/>
                                            <NavItem className="nav-item-btn text-center">
                                                <Button size="sm" className="btn-wide" color="primary">
                                                    Open Messages
                                                </Button>
                                            </NavItem>
                                        </Nav> */}
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                        <div className="widget-content-left  ml-3 header-user-info">
                            <div className="widget-heading">

                            </div>
                            {/* <div className="widget-subheading">
                                    VP People Manager
                                </div> */}
                        </div>

                        {/* <div className="widget-content-right header-user-info ml-3">
                                <Button className="btn-shadow p-1" size="sm" onClick={notify2} color="info"
                                        id="Tooltip-1">
                                    <IoIosCalendar color="#ffffff" fontSize="20px" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={'Tooltip-1'}>
                                    Click for Toastify Notifications!
                                </UncontrolledTooltip>
                            </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default withRouter(UserBox);