import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import SweetAlert from 'sweetalert-react';

const Verify = () => {
    const history = useHistory()
    const [successModal, setSuccessModal] = useState(true);

    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        localStorage.removeItem("authDetails");
        history.push('/')

    }
    return (
        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Email Verified Successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />
    )
}

export default Verify
