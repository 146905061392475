import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Row, Col, FormText, InputGroup, Label, Input, InputGroupAddon, Button,Form } from 'reactstrap';
import './studentStyle.scss'
import { getStudentDetails, removeStudentDetails, addStudentUnderTeacher } from '../../redux/Actions/homeTeacher'
import StudentPreviewCard from './studentPreviewCard'
import Loader from 'react-loader'
import serialize from 'form-serialize'
import { emailOrMobileValidator } from '../../utilities/Validator'
import Swal from 'sweetalert2'
var option = {
    lines: 13,
    length: 4,
    width: 2,
    radius: 5,
    scale: 1.00,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
};
const AssignStudents = () => {
    const [inputField, setInputField] = useState({
        email: ''
    })
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    console.log("tStore", tStore)
    const dispatch = useDispatch()
    const handleChange = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
        console.log("previeee", inputField)

    }
    const remove = (id) => {
        // alert(id)
        dispatch(removeStudentDetails(id))
    }

    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const [errors, setErrors] = useState({})

    const submit = () => {
        const err = {
            email: emailOrMobileValidator(inputField.email),
        }
        if (!err.email) {
            setErrors(err)
            dispatch(getStudentDetails(tokenData.token, inputField))
            setInputField({
                email: ''
            })
        } else {

            setErrors(err)
        }

    }
    const cb = () => {
        Swal.fire({
            icon: 'success',
            title: '',
            text: 'Added Successfully',

        })
    }
    const submitForm = (e) => {

        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        dispatch(addStudentUnderTeacher(tokenData.token, body, cb))
    }
    return (
        <>

            <Card>
                <div style={{ padding: '40px' }}>
                    <Row>

                        <Col md={6}>

                            <Label for="exampleNameL">
                                <span className="text-danger">*</span>
                                {' '} Assign Student</Label>
                            <InputGroup>
                                <Input
                                    value={inputField.email}

                                    name="email"
                                    type="text"
                                    placeholder="Search using email or mobile..."
                                    onChange={handleChange}


                                    invalid={errors.email}
                                />
                                <InputGroupAddon addonType="prepend">
                                    <Button
                                        onClick={submit}
                                        disabled={tStore.studentSearchLoader}
                                    >
                                        <span style={tStore.studentSearchLoader ? { visibility: 'hidden' } : null}>
                                            Search
                                                        </span>

                                        <span>
                                            {tStore.studentSearchLoader ? <Loader options={option} /> : null}

                                        </span>

                                    </Button>
                                </InputGroupAddon>



                                {/* <FormFeedback >{errors.email}</FormFeedback> */}
                            </InputGroup>

                            <p style={{ color: 'red', fontSize: 10 }}> {errors.email}</p>
                            <FormText>* Country code required for mobile number </FormText>


                        </Col>
                    </Row>

                    <hr />
                    <Form onSubmit={submitForm}>
                        <Row className="student-preview-row">
                            {tStore.studentSearch ?
                                tStore.studentSearch.map(dt => {
                                    return (
                                        <>
                                            <StudentPreviewCard
                                                data={dt}
                                                remove={remove}
                                            />
                                            <input type="hidden" name="registered_student[]" value={dt.student_id} />
                                        </>
                                    )
                                })
                                : null
                            }
                        </Row>



                        <Button
                            color="danger"
                            disabled={tStore.assignStudentUndetTeacherLoader}

                            style={tStore.studentSearch.length === 0 ? { visibility: 'hidden' } : null} type="submit">

                            <span style={tStore.assignStudentUndetTeacherLoader ? { visibility: 'hidden' } : null}>
                                Add Student
                                                        </span>
                            <span>
                                {tStore.assignStudentUndetTeacherLoader ? <Loader options={option} /> : null}

                            </span>
                        </Button>
                    </Form>

                </div >

            </Card >
        </>
    )
}

export default AssignStudents
