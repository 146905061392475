import React, { Fragment, useState } from 'react';
import { Link, } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../../redux/Actions/auth'
import img1 from './../images/mots-logo.png';

// import Header from './Header';

const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const logoutClick = () => {
        // alert("log")
        dispatch(logout())
        localStorage.removeItem("authDetails");

        // localStorage.clear()
        history.push('/login')
    }
    return (
        <Fragment>
            <header id="global-nav" className="menu-container">
                <nav className="navbar menu-bar sticky-top navbar-expand-lg">
                    <div className="container-fluid">
                        <a href="#"><img className="img-fluid site-title" src={img1} alt="Meet My Brian Logo" /></a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <i className="fas fa-bars"></i>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto w-100 justify-content-end">
                                <li className="nav-item active">
                                    <a className="nav-link header-link" href="#features">Features</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link header-link" href="#price">Plans & Pricing</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link header-link" href="#enquiry">Enquiry</a>
                                </li>
                                {!tokenData ?
                                    <li className="nav-item">
                                        <Link className="nav-link header-link" to="/login">Login</Link>
                                    </li>
                                    :
                                    <li className="nav-item">
                                        <Link className="nav-link header-link" to="/dashboard">Dashboard</Link>
                                    </li>
                                    // :null
                                }
                                {!tokenData ?
                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle signup signup-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Signup
                                    </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">

                                            <Link className="dropdown-item" to="/teacher-register"> Teacher</Link>
                                            <Link className="dropdown-item" to="/register">Student</Link>

                                        </div>
                                    </div>
                                    :

                                    <div className="dropdown">
                                        <button className="btn btn-secondary dropdown-toggle signup signup-link" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {/* {tokenData.name} */}
                                            {tokenData?.name?.length > 15 ? tokenData.name.substr(0, 15) + '..' : tokenData.name}
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <Link className="dropdown-item" onClick={logoutClick}> Logout</Link>
                                        </div>
                                    </div>
                                }
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
}
export default Header;