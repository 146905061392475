import React, { Fragment } from 'react';

import TopBanner from './TopBanner/TopBanner';
import MakeaFlatform from './MakeaFlatform/MakeaFlatform';
import PriceList from './PriceList/PriceList';
import Enquiry from './Enquiry/Enquiry';
import Subscription from './Subscription/Subscription';
import Features from './Features/Features';

// import './../js/owl.carousel.min.js'
// import Header from './Header';

const HomePage=()=>{
	return(
		<Fragment>
            <TopBanner/>
            <MakeaFlatform/>
            <Features />
            <PriceList />
            <Enquiry />
            <Subscription />
		</Fragment>
	);
}
export default HomePage;