import React, { Fragment } from 'react';
import {
    FormGroup, Label, Row, Col, Input, FormFeedback
} from 'reactstrap';
import LaddaButton, {

    ZOOM_IN

} from 'react-ladda';
// import { Link } from 'react-router-dom'
// import Loader from 'react-loader'

const WizardStep2 = (props) => {

    const { handleChange, inputField, errorz, Grades, verifyEmail, confirmPass, formSubmit, handleChangeTerm } = props;
    // console.log("typee==", type)



    return (
        <Fragment>
            <div className="form-wizard-content">
                <Row form>
                    <Col md={12}>
                        <FormGroup>
                            <Label for="exampleEmail">
                                <span className="text-danger">*</span>
                                {' '}Email
                                                    </Label>
                            <Input type="email" name="email" id="exampleEmail"
                                placeholder="Email here..."
                                value={inputField.email}
                                onChange={(e) => handleChange(e)}
                                onBlur={(e) => verifyEmail(e)}
                                valid={Grades.emailValidation.result ? true : false}
                                invalid={Grades.emailValidation.result === false || errorz.email ? true : false}
                            />
                            {errorz.email ?
                                <FormFeedback>oops! email id is required</FormFeedback>
                                : null
                            }{
                                Grades.emailValidation.result === false ?
                                    <FormFeedback>{`oops! ${Grades.emailValidation.message}`}</FormFeedback>

                                    : null
                            }
                            <FormFeedback valid>Sweet! that email id is available</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="examplePassword">
                                <span className="text-danger">*</span>
                                {' '}Password
                                                    </Label>
                            <Input
                                type="password"
                                name="password"
                                id="examplePassword"
                                placeholder=" Password here..."
                                onChange={(e) => handleChange(e)}
                                invalid={errorz.email ? true : false}
                            />
                            <FormFeedback >oops!  password is required</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="examplePasswordRep">
                                <span className="text-danger">*</span>
                                {' '}Confirm Password
                                                    </Label>
                            <Input type="password" name="cpassword" id="examplePasswordRep"
                                placeholder="Confirm Password here..."
                                onChange={(e) => confirmPass(e)}

                                invalid={errorz.cCheckpass ? true : false}
                            />
                            <FormFeedback>oops! the password not matching</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>

                    <Col md={12} >
                        {/* <Input type="checkbox" name="check"
                        //onChange={() => handleChangeTerm()}
                        // checked={inputField.termsAndCondition}

                        />{' '}
                        <Label
                            // style={errorz.termsAndCondi ? { border: '2px solid red' } : null}
                            for="exampleCheck" check>Accept our <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label> */}

                        <FormGroup className="mt-3" check >


                            <Row>
                                <Col xs={12} md={6}>
                                    <Input type="checkbox" name="check"
                                        onChange={() => handleChangeTerm()}
                                        checked={inputField.termsAndCondition}

                                    />{' '}
                                    <Label style={errorz.termsAndCondi ? { border: '2px solid red' } : null} for="exampleCheck" check>Accept our <a href="#" onClick={(e) => e.preventDefault()}>Terms and Conditions</a>.</Label>

                                </Col>

                            </Row>


                        </FormGroup>

                    </Col>
                    <Col md={12} style={{ textAlign: 'end' }}>
                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                            loading={Grades.loader}
                            onClick={formSubmit}
                            type="button"
                            data-style={ZOOM_IN}
                            style={{ whiteSpace: 'nowrap', marginRight: '0px' }}

                        >
                            Create Account
                                            </LaddaButton>
                    </Col>
                </Row>




            </div>
        </Fragment>
    );

}
export default WizardStep2