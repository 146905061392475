import React, { Fragment, useState, useEffect } from 'react';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import classnames from 'classnames';
// import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Swal from 'sweetalert2';
import Modal from '../common/Modal'
import SweetAlert from 'sweetalert-react';


import {
    TabContent, TabPane, Nav, NavItem, NavLink,
    Row, Col,
    Card, CardBody, CardHeader,
    CardTitle, Button, Container,
    FormGroup, Label, Form, CustomInput,
    Input, FormFeedback
} from 'reactstrap';
import './enterprise.scss'
// import logo from '../../assets/utils/images/logo-inverse.png'
import { populatePermission, populateRole, populateGroup, createTeacher } from '../../redux/Actions/videolist'
import Teacher from './Teacher'
import Student from './Student'
import Staff from './Staff'
import serialize from 'form-serialize'
import { development } from '../../config.json'

import './FormRegister.css'
import PageTitle from '../HeaderTitle/PageTitle'
import { nameValid, mobileValidator, requiredValidator, numberValidator, emailValidator, excelValidator } from '../../utilities/Validator';
const formData = new FormData();

const FormRegister = () => {

    const dispatch = useDispatch();
    // const history = useHistory();
    const tStore = useSelector(({ videolist }) => videolist)
    const permission = tStore.permissionDetail;
    const roles = tStore.role;
    const groups = tStore.group;
    // const teacherDetail = tStore.teacherDetail;
    console.log("tssss", tStore);
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const param = useParams()
    useEffect(() => {
        dispatch(populatePermission(tokenData.token))
        dispatch(populateRole(tokenData.token))
        dispatch(populateGroup(tokenData.token))
    }, []);

    // const [activeTab, setActiveTab] = useState(1);
    const [selectedRole, changeRole] = useState(3);
    const [bulkRole, changeBulkRole] = useState(3);
    // const [moduleState, setStateModule] = useState(false);
    // const [module, setModule] = useState([]);

    const [state, setState] = useState({
        activeTab: '1',
    })
    const toggle = (tab) => {
        setInputField({})
        setErrors({})
        changeRole(3)
        changeBulkRole(3)
        setMultiSelect([1, 2, 27, 28, 3, 4, 7,8,29,30, 31])
        document.getElementById("form-single").reset();
        document.getElementById("form-bulk").reset();
        if (state.activeTab !== tab) {
            setState({
                activeTab: tab
            });
        }
    }
    const [successModal, setSuccessModal] = useState();
    // const handleOpenSuccessModal = () => {
    //     setSuccessModal(true)

    // }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        console.log("close-----", tStore.classes)
        // window.location.reload(false);

    }
    const [inputField, setInputField] = useState({})
    const handleChange = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
        document.getElementById('file-choose').value = ''
    }
    const selectRole = (e) => {
        const { name, value } = e.target;
        setErrors({})
        setInputField({})
        document.getElementById('file-choose').value = ''

        setInputField({ ...inputField, [name]: value })
        if (e.target.value == 3) {
            //teacher
            setMultiSelect([1, 2, 27, 28, 3, 4, 7,8,29, 30, 31])
        } else if (e.target.value == 4) {
            //student
            setMultiSelect([1, 2, 27, 28, 3, 4])
        } else {
            //staff
            setMultiSelect([1, 2, 26, 27, 28, 6, 9, 33, 34, 10, 35, 36, 37, 38])
        }
        // debugger
        changeRole(value);
        document.getElementById("form-single").reset();

    }

    const selectBulkRole = (e) => {
        const { name, value } = e.target
        setInputField({})
        setErrors({})
        setInputField({ ...inputField, [name]: value })

        if (e.target.value == 3) {
            //teacher
            setMultiSelect([1, 2, 27, 28, 3, 4, 7,8,29,30, 31])
        } else if (e.target.value == 4) {
            //student
            setMultiSelect([1, 2, 27, 28, 3, 4])
        } else {
            //staff
            setMultiSelect([1, 2, 26, 27, 28, 6, 9, 33, 34, 10, 35, 36, 37, 38])
        }
        changeBulkRole(e.target.value);
    }
    // const changePermission = (e, data) => {
    //     console.log("changePermission", data);
    //     // var checkBox = document.getElementById(e.target.id);
    //     // if (checkBox.checked == true) {
    //     if (data.sub_module != '') {
    //         setModule(data.sub_module);
    //         setStateModule(true);
    //     }
    // }
    const [mutliSelect, setMultiSelect] = useState([1, 2, 27, 28, 3, 4, 7, 8,29, 30, 31])
    const multiSelectPermission = (student_id) => {


        if (mutliSelect.includes(student_id)) {
            // debugger
            const filtered = mutliSelect.filter(videoId => videoId != student_id)
            console.log("after removed", filtered)
            setMultiSelect(filtered)

        } else {
            setMultiSelect([...mutliSelect, student_id])
            console.log("permi selected", mutliSelect)

        }

    }
    // const changeBulkPermission = (e, data) => {
    //     console.log("changePermission", e.target.value);
    //     if (data.sub_module != '') {
    //         setModule(data.sub_module);
    //         setStateModule(true);
    //     }
    // }
    const [errors, setErrors] = useState({})
    const typeofUser = {
        teacher: 3,
        staff: 7,
        student: 4
    }
    const formSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        console.log({ inputField })
        console.log(inputField)

        let err;

        let invalid;
        if (selectedRole == typeofUser.teacher) {
            err = {
                role: requiredValidator(inputField.role),
                first_name: nameValid(inputField.first_name),
                last_name: nameValid(inputField.last_name),
                enterprise_mobile: mobileValidator(inputField.enterprise_mobile),
                enterprise_email: emailValidator(inputField.enterprise_email),
                password: requiredValidator(inputField.password),
            }
            invalid = err.first_name || err.last_name || err.enterprise_mobile || err.enterprise_email || err.password
            // alert("teacher", invalid)
        }
        if (selectedRole == typeofUser.student) {
            err = {
                first_name: nameValid(inputField.first_name),
                last_name: nameValid(inputField.last_name),
                enterprise_mobile: mobileValidator(inputField.enterprise_mobile),
                enterprise_email: emailValidator(inputField.enterprise_email),
                password: requiredValidator(inputField.password),
                enrollment_no: requiredValidator(inputField.enrollment_no),

                group_id: requiredValidator(inputField.group_id)
            }
            invalid = err.first_name || err.last_name || err.enterprise_mobile || err.enterprise_email || err.password || err.enrollment_no || err.group_id
            // alert("student", invalid)

        }
        if (selectedRole == typeofUser.staff) {
            err = {
                first_name: nameValid(inputField.first_name),
                last_name: nameValid(inputField.last_name),
                enterprise_mobile: mobileValidator(inputField.enterprise_mobile),
                enterprise_email: emailValidator(inputField.enterprise_email),
                password: requiredValidator(inputField.password),


            }
            invalid = err.first_name || err.last_name || err.enterprise_mobile || err.enterprise_email || err.password
            // alert("staff", invalid)
        }

        if (!invalid) {
            setErrors(err)
            if (mutliSelect.length == 0 && selectedRole != 4) {
                Swal.fire(
                    'Required',
                    'Select permission',
                    'warning'
                )
            } else {
                dispatch(createTeacher(tokenData.token, body, resetForm, erro))

            }
        } else {
            setErrors(err)
        }




    }

    const resetForm = () => {
        formData.delete('classes')
        formData.delete('import_file')
        setInputField({})
        changeBulkRole(3)
        changeRole(3)
        setMultiSelect([1, 2, 27, 28, 3, 4, 7,8,29,30, 31])



        document.getElementById("form-single").reset();
        document.getElementById("form-bulk").reset();
    }

    const onChangeFile = (e) => {
        const { name, value } = e.target
        setInputField({ ...inputField, [name]: value })
        formData.delete('import_file')

        formData.append('import_file', e.target.files[0])
        console.log("onChangeFile", formData);
    }

    const formSubmitBulk = (e) => {

        formData.delete('classes')

        e.preventDefault()
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })
        var details = JSON.stringify(body);
        let err;
        let invalid;
        console.log("bulk role",bulkRole)
        console.log("bulk role",typeofUser)
        
        if (bulkRole == typeofUser.teacher) {
            formData.append('permission', JSON.stringify(body.permission))
            err = {
                import_file: excelValidator(inputField.import_file)

            }
            invalid = err.import_file
            console.log({ inputField })
        }
        if (bulkRole == typeofUser.student) {
            formData.delete('permission')
            err = {
                group_id: requiredValidator(inputField.group_id),
                import_file: excelValidator(inputField.import_file),
            }
            invalid = err.import_file || err.group_id
            console.log({ invalid })

        }
        if (bulkRole == typeofUser.staff) {
            err = {
                import_file: excelValidator(inputField.import_file),
            }
            invalid = err.import_file
            console.log({ inputField })

        }
        console.log({ err });

        if (!invalid) {
            setErrors(err)
            formData.append('classes', details);
            console.log("serialize", formData);
            if (mutliSelect.length == 0 && bulkRole != 4) {
                Swal.fire(
                    'Required',
                    'Select permission',
                    'warning'
                )
            } else {
                dispatch(createTeacher(tokenData.token, formData, resetForm, erro))
            }
        } else {
            setErrors(err)
        }


    }
    const [errorz, setErrorz] = useState({
        show: false,
        err: []
    })
    const erro = (errors) => {
        //  debugger
        formData.delete('classes')
        formData.delete('import_file')
        console.log(formData)
        setErrorz({
            show: true,
            err: errors
        })
    }
    const closeErr = (errors) => {
        setErrorz({
            show: false,
            err: []
        })
    }

    const downloadExcel = (e, type) => {
        e.preventDefault()
        console.log("downloadExcel", development);
        // dispatch(downloadExcelDoc(tokenData.token, type))
        var basreUrl = development.baseUrlente;
        var url = `${basreUrl}downloadSampleExcel?type=${type}`
        window.location.href = url;
    }

    return (
        <Fragment>
            <CSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <PageTitle
                    style={{ display: 'none' }}
                    heading="Create User"
                    subheading="Create user and  add their permissions"
                    icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                />
                <Container>
                    <Row>
                        <Col md="12" className="h-100 align-items-center">
                            <Card tabs={true} className="mb-3">
                                <CardHeader>
                                    <Nav justified>
                                        <NavItem>
                                            <NavLink href="#"
                                                className={classnames({ active: state.activeTab === '1' })}
                                                onClick={() => toggle('1')}
                                            >
                                                Single
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink href="#"
                                                className={classnames({ active: state.activeTab === '2' })}
                                                onClick={() => toggle('2')}
                                            >
                                                Bulk
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={state.activeTab}>
                                        <TabPane key="1" tabId="1" >
                                            <Form onSubmit={formSubmit} id="form-single">
                                                <Row>
                                                    <Col md={5} className="jms-enterprise-user-reg-col">
                                                        <Card className="main-card m-20 jms-permision-card" style={selectedRole == 4 ? { background: '#f2f2f5' } : null} >

                                                            <FormGroup className="p-10">
                                                                <CardTitle style={{ padding: 10, color: 'rgb(24, 34, 138)', borderBottom: '1px solid ' }}>Permission</CardTitle>

                                                                {/* <Label for="exampleSelect">Permission</Label> */}
                                                                <div style={{ padding: 10 }}>

                                                                    {
                                                                        permission ? permission.map((data) => {
                                                                            return (
                                                                                <>
                                                                                    <li>
                                                                                        <CustomInput className="custom-control-inline" type="checkbox" name="permission"
                                                                                            id={data.module_id} value={data.module_id}
                                                                                            disabled={selectedRole == 4 || (selectedRole == 3 && (data.module_id===6 || data.module_id===9 || data.module_id===10))}
                                                                                            checked={mutliSelect.includes(data.module_id)}
                                                                                            // onChange={(e) => changePermission(e, data)}

                                                                                            onChange={(e) => multiSelectPermission(data.module_id)}

                                                                                        >{data.module_name}</CustomInput>
                                                                                    </li>

                                                                                    <ul>
                                                                                        {data.sub_module.map(subModule => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <CustomInput className="custom-control-inline" type="checkbox" name="permission"
                                                                                                        onChange={(e) => multiSelectPermission(subModule.module_id)}
                                                                                                        disabled={selectedRole == 4 || (selectedRole == 3 && (subModule.module_id===33 || subModule.module_id===34 || subModule.module_id===26 || subModule.module_id===35 || subModule.module_id===36 || subModule.module_id===37 || subModule.module_id===38))}
                                                                                                        checked={mutliSelect.includes(subModule.module_id)}

                                                                                                        id={subModule.module_id} value={subModule.module_id}
                                                                                                    //onChange={(e) => changeBulkPermission(e, data)}
                                                                                                    >{subModule.module_name}</CustomInput>
                                                                                                </li>

                                                                                            )
                                                                                        })
                                                                                        }
                                                                                    </ul>


                                                                                </>)
                                                                        })
                                                                            : []
                                                                    }

                                                                    {/* {module &&
                                                                        <div>
                                                                            {
                                                                                module ? module.map((data) => {
                                                                                    return (
                                                                                        <CustomInput type="checkbox" name="subPermission" name="permission"
                                                                                            id={data.module_id} value={data.module_id}
                                                                                        // onChange={(e) => changeSubPermission(e)}
                                                                                        >{data.module_name}</CustomInput>
                                                                                    )
                                                                                })
                                                                                    : []
                                                                            }
                                                                        </div>
                                                                    } */}
                                                                </div>
                                                            </FormGroup>
                                                        </Card>
                                                    </Col>

                                                    <Col md={7} className="jms-enterprise-user-reg-col2">
                                                        <Row>
                                                            <input
                                                                name='entry'
                                                                type="hidden"
                                                                value="single"
                                                            />
                                                            <Col md={selectedRole != 4 ? 12 : 6}>
                                                                <FormGroup className="p-10">
                                                                    <Label for="exampleSelect">Select Role</Label>
                                                                    <Input type="select" name="role" id="role"
                                                                        onChange={(e) => selectRole(e)}
                                                                        // required
                                                                        style={{ textTransform: 'capitalize' }}
                                                                        value={selectedRole}
                                                                    // invalid={errors.role}
                                                                    >
                                                                        {

                                                                            roles ? roles.map((data) => {
                                                                                return (
                                                                                    <option value={data.role_id} key={data.role_id}>{data.role_name}</option>
                                                                                )
                                                                            })
                                                                                : []
                                                                        }

                                                                    </Input>
                                                                    {/* <FormFeedback >{errors.role}</FormFeedback> */}

                                                                </FormGroup>
                                                            </Col>
                                                            {selectedRole == 4 &&
                                                                <Col md="6" xs="6">
                                                                    <FormGroup className="p-10">
                                                                        <Label for="exampleSelect">Group</Label>
                                                                        <Input type="select" name="group_id" id="group"
                                                                            // required
                                                                            onChange={(e) => handleChange(e)}
                                                                            invalid={errors.group_id}
                                                                        >
                                                                            <option value={''} >{'Select group'}</option>

                                                                            {
                                                                                groups ? groups.map((data) => {
                                                                                    return (
                                                                                        <option value={data.group_id} key={data.group_id}>{data.group_name}</option>
                                                                                    )
                                                                                })
                                                                                    : []
                                                                            }
                                                                        </Input>
                                                                        <FormFeedback >{errors.group_id}</FormFeedback>

                                                                    </FormGroup>
                                                                </Col>
                                                            }
                                                        </Row>

                                                        {selectedRole == 3 ?
                                                            <Teacher
                                                                // changeHandler={changeHandler}
                                                                errors={errors}
                                                                handleChange={handleChange}
                                                            />
                                                            : selectedRole == 4 ?
                                                                <Student
                                                                    // changeHandler={changeHandler}
                                                                    errors={errors}
                                                                    handleChange={handleChange}


                                                                />
                                                                : selectedRole == 7 ?
                                                                    <Staff
                                                                        errors={errors}
                                                                        handleChange={handleChange}


                                                                    />
                                                                    :
                                                                    null
                                                        }

                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col md={5} style={{ marginLeft: 18 }}>
                                                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                            type="submit"
                                                            loading={tStore.singleRegLoader}
                                                            data-style={ZOOM_IN}  >
                                                            ADD USER
                                                    </LaddaButton>
                                                    </Col>
                                                    <Col md={7}>

                                                    </Col>
                                                </Row>

                                            </Form>
                                        </TabPane>
                                        {/* tab 2 */}
                                        <TabPane key="2" tabId="2">
                                            {console.log("tab 2")}
                                            <Form onSubmit={formSubmitBulk} id="form-bulk" >
                                                <Row>
                                                    <Col md={5} className="jms-enterprise-user-reg-col">

                                                        <Card className="main-card m-20 jms-permision-card" style={bulkRole == 4 ? { background: '#f2f2f5' } : null}>
                                                            <FormGroup className="p-10">
                                                                <CardTitle style={{ padding: 10, color: 'rgb(24, 34, 138)', borderBottom: '1px solid' }}>Permission</CardTitle>
                                                                <input name='entry' type="hidden" value="bulk" />
                                                                {/* <Label for="exampleSelect">Permission</Label> */}
                                                                <div style={{ padding: 15 }}>
                                                                    {/* {
                                                                        bulkRole != 4 && permission ? permission.map(data => {
                                                                            return (<>
                                                                                <li>
                                                                                    <CustomInput className="custom-control-inline" type="checkbox" name="permission"
                                                                                        id={`permission${data.module_id}`} value={data.module_id}
                                                                                        //onChange={(e) => changeBulkPermission(e, data)}
                                                                                        // onChange={(e) => multiSelectStudent(data.module_id)}
                                                                                        disabled={selectedRole == 4}
                                                                                    >{data.module_name}</CustomInput>
                                                                                </li>

                                                                                <ul>
                                                                                    {data.sub_module.map(subModule => {
                                                                                        return (
                                                                                            <li>
                                                                                                <CustomInput className="custom-control-inline" type="checkbox" name="permission"
                                                                                                    id={`permission${subModule.module_id}`} value={subModule.module_id}
                                                                                                    onChange={(e) => changeBulkPermission(e, data)}
                                                                                                    disabled={selectedRole == 4}

                                                                                                >{subModule.module_name}</CustomInput>
                                                                                            </li>

                                                                                        )
                                                                                    })
                                                                                    }
                                                                                </ul>

                                                                            </>
                                                                            )
                                                                        })
                                                                            : []
                                                                    } */}




                                                                    {
                                                                        permission ?

                                                                            permission.map((data) => {
                                                                                return (
                                                                                    <>
                                                                                        <li>
                                                                                            <CustomInput className="custom-control-inline" id="resetForm" type="checkbox" name="permission"
                                                                                                id={`permission${data.module_id}`}
                                                                                                value={data.module_id}
                                                                                                disabled={bulkRole == 4}
                                                                                                checked={mutliSelect.includes(data.module_id)}

                                                                                                // onChange={(e) => changePermission(e, data)}

                                                                                                onChange={(e) => multiSelectPermission(data.module_id)}

                                                                                            >{data.module_name}</CustomInput>
                                                                                        </li>

                                                                                        <ul>
                                                                                            {data.sub_module.map(subModule => {
                                                                                                return (
                                                                                                    <li>
                                                                                                        <CustomInput className="custom-control-inline" type="checkbox" name="permission"
                                                                                                            onChange={(e) => multiSelectPermission(subModule.module_id)}
                                                                                                            disabled={bulkRole == 4}
                                                                                                            id={`permission${subModule.module_id}`}
                                                                                                            value={subModule.module_id}
                                                                                                            checked={mutliSelect.includes(subModule.module_id)}

                                                                                                        //onChange={(e) => changeBulkPermission(e, data)}
                                                                                                        >{subModule.module_name}</CustomInput>
                                                                                                    </li>

                                                                                                )
                                                                                            })
                                                                                            }
                                                                                        </ul>


                                                                                    </>)
                                                                            })
                                                                            : []
                                                                    }

                                                                </div>
                                                            </FormGroup>
                                                        </Card>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Card className="main-card " style={{ marginTop: '20px', padding: '30px', background: '#eff1f5' }}>
                                                            <Row>

                                                                <Col md={bulkRole != 4 ? 12 : 6}>

                                                                    <FormGroup className="p-10">
                                                                        <Label for="exampleSelect">Select Role</Label>
                                                                        <Input type="select" name="role" id="roleBulk"
                                                                            //  required
                                                                            onChange={(e) => selectBulkRole(e)}>
                                                                            {/* <option value={0} disabled>Select</option> */}
                                                                            {
                                                                                roles ? roles.map((data) => {
                                                                                    return (
                                                                                        <option value={data.role_id} key={data.role_id}>{data.role_name}</option>
                                                                                    )
                                                                                })
                                                                                    : []
                                                                            }
                                                                        </Input>
                                                                    </FormGroup>
                                                                </Col>
                                                                {bulkRole == 4 &&
                                                                    <Col md="6" xs="6">
                                                                        <FormGroup className="p-10">
                                                                            <Label for="exampleSelect">Group</Label>
                                                                            <Input
                                                                                // required 
                                                                                type="select"
                                                                                onChange={handleChange}
                                                                                invalid={errors.group_id}
                                                                                name="group_id" id="groupBulk"

                                                                            >
                                                                                <option value={''} >Select group</option>

                                                                                {
                                                                                    groups ? groups.map((data) => {
                                                                                        return (
                                                                                            <option value={data.group_id} key={data.group_id}>{data.group_name}</option>
                                                                                        )
                                                                                    })
                                                                                        : []
                                                                                }
                                                                            </Input>
                                                                            <FormFeedback >{errors.group_id}</FormFeedback>

                                                                        </FormGroup>
                                                                    </Col>
                                                                }
                                                            </Row>
                                                            <Row>
                                                                <Col md="12">
                                                                    <FormGroup className="p-10">
                                                                        <Label for="exampleFile">Choose Excel File</Label>
                                                                        <Input
                                                                            // required
                                                                            type="file"
                                                                            name="import_file"
                                                                            id="file-choose"
                                                                            style={{ lineHeight: '35px' }}
                                                                            onChange={(e) => onChangeFile(e)}
                                                                            invalid={errors.import_file}
                                                                        />
                                                                        {/* <FormText color="muted">
                                                                This is some placeholder block-level help text for the above input.
                                                                It's a bit lighter and easily wraps to a new line.
                                                            </FormText> */}
                                                                        <FormFeedback >{errors.import_file}</FormFeedback>

                                                                    </FormGroup>
                                                                    <hr />
                                                                </Col>

                                                                <Col md="12">
                                                                    <div >
                                                                        <p>Download the sample excel file for bulk entry</p>
                                                                        {/* <button className="mg-5" data-toggle="tooltip" title="Teacher" onClick={(e) => downloadExcel(e, 'teacher')}><i className="excel-icon pe-7s-download"></i></button> */}
                                                                        {/* <button className="mg-5" data-toggle="tooltip" title="Student" onClick={(e) => downloadExcel(e, 'student')}><i className="excel-icon pe-7s-download"></i></button> */}
                                                                        {/* <button className="mg-5" data-toggle="tooltip" title="Staff" onClick={(e) => downloadExcel(e, 'staff')}><i className="excel-icon pe-7s-download"></i></button> */}
                                                                    </div>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <Row>
                                                                        <Col md={4}>
                                                                            <Button className="mb-2 mr-2 btn-icon-vertical" color="primary"
                                                                                data-toggle="tooltip" title="Teacher"
                                                                                onClick={(e) => downloadExcel(e, 'teacher')}
                                                                            >
                                                                                <i className="  pe-7s-download btn-icon-wrapper"> </i>
                                                                                Teacher
                                                                    </Button>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Button className="mb-2 mr-2 btn-icon-vertical" color="info"
                                                                                data-toggle="tooltip" title="Student"
                                                                                onClick={(e) => downloadExcel(e, 'student')}
                                                                            >
                                                                                <i className="  pe-7s-download btn-icon-wrapper"> </i>
                                                                                Student
                                                                    </Button>
                                                                        </Col>
                                                                        <Col md={4}>
                                                                            <Button className="mb-2 mr-2 btn-icon-vertical" color="success"
                                                                                data-toggle="tooltip" title="Staff"
                                                                                onClick={(e) => downloadExcel(e, 'staff')}
                                                                            >
                                                                                <i className=" pe-7s-download btn-icon-wrapper"> </i>
                                                                                Staff
                                                                     </Button>
                                                                        </Col>
                                                                    </Row>



                                                                </Col>
                                                            </Row>
                                                        </Card>


                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md={5} style={{ marginLeft: 18, paddingTop: 38 }}>
                                                        <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                                            loading={tStore.bulkLoader}
                                                            type="submit"
                                                            data-style={ZOOM_IN}  >
                                                            ADD USERS

                                                    </LaddaButton>
                                                    </Col>
                                                    <Col md={7}>

                                                    </Col>
                                                </Row>


                                            </Form>
                                        </TabPane>

                                    </TabContent>
                                </CardBody>

                            </Card>

                        </Col>
                    </Row>
                </Container>
            </CSSTransitionGroup>
            <SweetAlert
                title="Success"
                confirmButtonColor=""
                show={successModal}
                text="Completed successfully"
                type="success"
                onConfirm={() => handleCloseSuccesstModal()} />
            <Modal
                open={errorz.show}
                handleClose={closeErr}
                title={`Errors`}
            >
                {
                    <ul>
                        {
                            errorz.err.map(error => <li style={{ color: 'red', padding: 7 }}>{error}</li>)

                        }
                    </ul>

                }
            </Modal>
        </Fragment>
    );
}

export default FormRegister;