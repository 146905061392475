import axios from 'axios'
import { development } from '../../config.json'
// import { useHistory } from 'react-router-dom'     
import Swal from 'sweetalert2'


export function setNewActionData(name, value) {
    return {
        type: 'SET_NEWACTION_DATA',
        payload: { [name]: value },
    }
}

export const getClassStudentListUnderClassGroup = (token, group_d,class_id,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        group_id: group_d,
        class_id:class_id
    }
 
    dispatch({ type: 'NEWACTION_ALL_REQUEST',payload: {load_stdent:true} })
    axios.post(`${development.baseUrl}getClassStudentListUnderClassGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                let details=res.data.details ? res.data.details:[]
                let arr=[]
                details.forEach(element => {
                    if(element.selected){
                        arr.push(element.user_id)
                    }
                });
                dispatch({ type: 'NEWACTION_ALL_SUCCESS', payload: {
                    sourceGroupStudents:res.data.details,
                    checkedArray:arr,
                    load_stdent:false
                }})
                cb(res.data.details)
            }
            else{
                dispatch({ type: 'NEWACTION_ALL_FAILED',payload: {load_stdent:false} })
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
                dispatch({ type: 'NEWACTION_ALL_FAILED',payload: {load_stdent:false} })
        }
        )
}


export const addStudentsToClassRoomFromGroup = (token,class_id,arr1,arr2,cb) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,

    }
    const data = {
        class_id:class_id,
        registered_student:arr1,
        unregistered_student:arr2
    }
 
    dispatch({ type: 'NEWACTION_ALL_REQUEST',payload: {load_submit_stdent:true} })
    axios.post(`${development.baseUrl}addStudentsToClassRoomFromGroup`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'NEWACTION_ALL_SUCCESS', payload: {
                    load_submit_stdent:false
                }})
                cb()
            }
            else{
                dispatch({ type: 'NEWACTION_ALL_FAILED',payload: {
                    load_submit_stdent:false
                    
                } })
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            console.log('eeeee')
                dispatch({ type: 'NEWACTION_ALL_FAILED',payload: {
                    load_submit_stdent:false,
                } })
        }
        )
}
