import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { subscribeSite } from '../../../../redux/Actions/signUp'

const Subscription = () => {
    const dispatch = useDispatch()
    const subscription = useSelector(({ signUp }) => signUp)
    const [emailField, setemailFields] = useState({})

    const handlechange = (e) => {
        const { name, value } = e.target;
        setemailFields({ ...emailField, [name]: value })
    }
    const subscriptionSubmit = (e) => {
        e.preventDefault()
        dispatch(subscribeSite(emailField, reset))
    }

    const reset = () => {
        document.getElementById("subscription-form").reset();
    }

    return (
        <Fragment>
            {console.log("staty", subscription.subscriptionStatus)}
            <section class="subscription-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 d-flex align-items-center">
                            <div class="sub-area">

                                <form onSubmit={subscriptionSubmit} class="subscribe_form" id="subscription-form">
                                    <div class="input-group">
                                        <input
                                            type="email" class="form-control" name="email"
                                            placeholder="Enter your email"
                                            onChange={handlechange}
                                            required
                                        />
                                        <span class="input-group-btn">
                                            {/* <LaddaButton
                                            loading={subscription.subscriptionLoader}
                                            // onClick={formSubmit}
                                            type="submit"
                                            data-style={ZOOM_IN}                                    
                                        > */}

                                            <button class="btn btn-default" type="submit" >
                                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                            </button>


                                            {/* </LaddaButton> */}
                                        </span>
                                    </div>
                                    <br />
                                    {subscription.subscriptionStatus ?
                                        <div class="btn btn-secondary sucess-toast" data-toggle="snackbar" data-style="toast" data-content="Fried chicken out of stock.">
                                            Subscribed successfully
                                            </div>
                                        :
                                        subscription.subscriptionStatus == false ?
                                            <div class="btn btn-secondary subscribe-toast" style={{ float: 'left' }} data-toggle="snackbar" data-style="toast" data-content="Fried chicken out of stock.">
                                                Already subscribed
                                            </div>
                                            : null
                                    }
                                </form>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="sub-content">
                                <h6>Subscription</h6>
                                <p className="f-15">Subscribe and get a heads up on all updates and new features on <b>MOTS</b>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
}
export default Subscription;