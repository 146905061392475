import React, { useState, useEffect } from 'react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { Col, Row, Form, FormFeedback, Card, CardHeader, CardBody, Input, } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styleph.scss'
import { emailValidator, requiredValidator } from '../../../utilities/Validator'
import { createEnterprise, listAllEnterprises,blockEnterprise, checkEnterpriseNumberExists } from '../../../redux/Actions/superAdmin'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import DataTable from '../../common/DataTable'
import {useHistory}from 'react-router-dom'

function CreateEnterprise() {

    const tokenData = JSON.parse(localStorage.getItem("authDetails"))
    const Enterprises = useSelector(state => state.superAdmin.Enterprises)
    const EidAvilable = useSelector(state => state.superAdmin.EidAvilable)

    const dispatch = useDispatch();
    const history = useHistory();

   // console.log("avilabilty status",EidAvilable)
 
    const [Logo, setLogo] = useState()
    const [Errors, setErrors] = useState({})
    const [Loader, setLoader] = useState(false)
    const [InputField, setInputField] = useState({
        first_name:'',
        last_name:'',
        enterprise_name:'',
        enterprise_mobile:'',
        enterprise_email:'',
        enterprise_number:'',
        enterprise_password:'',
    })

    let columns = [
        { title: 'Enterprise', field: 'enterprise_name', headerStyle: { fontWeight: '600' } },
        { title: 'Email', field: 'enterprise_email', headerStyle: { fontWeight: '600' } },
        { title: 'Mobile', field: 'enterprise_mobile', headerStyle: { fontWeight: '600' } },

    ]

    useEffect(() => {
        dispatch(listAllEnterprises(tokenData.token))
    }, [])

    const refresh = () =>{
        dispatch(listAllEnterprises(tokenData.token))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...InputField, [name]: value })
        console.log("input field", InputField)
    }

    const handlechangephone = (e) => {
        setInputField({ ...InputField, enterprise_mobile: e })

    }

    const handleEnteNumber = (e) => {
        setInputField({...InputField , enterprise_number:e.target.value})
        dispatch(checkEnterpriseNumberExists(tokenData.token, e.target.value))
    }

    const cb = (res) => {
        setLoader(false)
        Swal.fire('Success', 'Created a new Enterprise', 'success')
        document.getElementById("create-form").reset();
        setInputField({
            first_name:'',
            last_name:'',
            enterprise_name:'',
            enterprise_mobile:'',
            enterprise_email:'',
            enterprise_number:'',
            enterprise_password:'',
        })
        refresh()
    }

 

    const handleSubmit = (e) => {

        const formData = new FormData();
        e.preventDefault()
        const Errors = {
            first_name: requiredValidator(InputField.first_name),
            last_name: requiredValidator(InputField.last_name),
            enterprise_mobile: requiredValidator(InputField.enterprise_mobile),
            enterprise_name: requiredValidator(InputField.enterprise_name),
            enterprise_email: requiredValidator(InputField.enterprise_email),
            enterprise_number: requiredValidator(InputField.enterprise_number),
            enterprise_password: requiredValidator(InputField.enterprise_password),
            logo: Logo ? "" : "Required"
        }
        console.log("Errors report", Errors)
        if ( EidAvilable && !Errors.first_name && !Errors.last_name && !Errors.enterprise_mobile && !Errors.enterprise_name && !Errors.enterprise_email && !Errors.enterprise_number && !Errors.enterprise_password && !Errors.logo) {

            formData.append("first_name", InputField.first_name);
            formData.append("last_name", InputField.last_name);
            formData.append("enterprise_mobile", InputField.enterprise_mobile);
            formData.append("enterprise_name", InputField.enterprise_name);
            formData.append("enterprise_email", InputField.enterprise_email);
            formData.append("enterprise_number", InputField.enterprise_number);
            formData.append("password", InputField.enterprise_password);
            formData.append("enterprise_logo_url", Logo);
            formData.append("enterprise_description", "this field isnt required //hardcoded");
            setLoader(true)
            dispatch(createEnterprise(tokenData.token, formData, cb))
        }
        setErrors(Errors)

        
    }

   

    const handleBlock = (id) =>{
        
        console.log("consoled id",id)
        dispatch(blockEnterprise(tokenData.token,id,refresh))
    }
    
    const handleEdit = (ent_id) =>{
        history.push(`/dashboard/editEnterprise/${ent_id}/`)
    }

    return (
        <div>
            <Form  id={"create-form"} onSubmit={handleSubmit} >     
                <Card >
                    <CardHeader>Create Enterprise</CardHeader>
                    <CardBody>
                        <Row className="p-2">
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>First Name</label>
                                
                                <Input value={InputField.first_name} name="first_name" onChange={handleChange} invalid={Errors.first_name} ></Input>
                                <FormFeedback>{Errors.first_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Last Name</label>
                                <Input value={InputField.last_name}  name="last_name" onChange={handleChange} invalid={Errors.last_name} ></Input>
                                <FormFeedback>{Errors.last_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Mobile</label>
                                {/* <Input name="mobile" onChange={handleChange}></Input> */}
                                <PhoneInput
                                    value={InputField?.number}
                                    name="number"
                                    // id='number'
                                    country={'in'}
                                    onChange={(e) => handlechangephone(e)}
                                    invalid={Errors.enterprise_mobile}

                                />
                                <span style={{color:'red'}}>{Errors.enterprise_mobile}</span>
                            </Col>
                        </Row>

                        <Row className="p-2">
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise Name</label>
                                <Input value={InputField.enterprise_name} name="enterprise_name" onChange={handleChange} invalid={Errors.enterprise_name} ></Input>
                                <FormFeedback>{Errors.enterprise_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise ID</label>
                                <Input value={InputField.enterprise_number} name="enterprise_number" onChange={handleEnteNumber} invalid={Errors.enterprise_number}  ></Input>
                                <FormFeedback>{Errors.enterprise_number}</FormFeedback> {InputField.enterprise_number!==""? EidAvilable? <span style={{color:'green'}} >This ID is avilable</span> : <span style={{color:'red'}} >This ID is taken</span>  : ""}
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise Email</label>
                                <Input value={InputField.enterprise_email} name="enterprise_email" onChange={handleChange} invalid={Errors.enterprise_email}  ></Input>
                                <FormFeedback>{Errors.enterprise_email}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="p-2">
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Password</label>
                                <Input value={InputField.enterprise_password} name="enterprise_password" onChange={handleChange} type='password' invalid={Errors.enterprise_password}  ></Input>
                                <FormFeedback>{Errors.enterprise_password}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Logo</label>
                                <Input onChange={(e) => setLogo(e.target.files[0])} type='file'></Input>
                                <span style={{ color: 'red' }}>{Errors.logo} </span>
                            </Col>
                        </Row>



                        <Row className="p-2" style={{ textAlign: 'right' }}>
                            <Col sm={9}>{" "}</Col>
                            <Col sm={3}>
                                <LaddaButton className="m-3 btn btn-primary"
                                    loading={Loader}
                                    type="submit"
                                    data-style={ZOOM_IN}  >
                                    Create Enterprise
                                </LaddaButton>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Form>

            <div className="mt-3">
                <DataTable
                    title={"Enterprises"}
                    data={Enterprises}
                    columns={columns}
                    actions={"enterprise_list"}
                    handleBlock={ (id)=> handleBlock(id)}
                    handleEdit={ (id)=> handleEdit(id)}
                />
            </div>


        </div>
    )
}

export default CreateEnterprise
