import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router";
import {
  Col,
  Row,
  Card,
  Button,
  Label,
  FormFeedback,
  Input,
  Form,
} from "reactstrap";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import {
  populateSubject,
  populateGrade,
} from "../../../redux/Actions/homeTeacher";
import {
  filterQuestionPaper,
  createExam,
  getTeacherExams,
} from "../../../redux/Actions/exam";
import {
  requiredValidator,
  // numberValidator,
} from "../../../utilities/Validator";

function School() {
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const tStore = useSelector(({ homeTeacher }) => homeTeacher);
  const examStore = useSelector(({ exam }) => exam);
  const [PassMark, setPassMark] = useState();
  const [inputField, setInputField] = useState({
    exam_title: "",
    subject_id: "",
    grade_id: "",
    exam_type: "",
    pass_mark: "",
    question_paper_id: "",
    date: "",
    time: "",
    duration: "",
  });

  useEffect(() => {
    dispatch(populateGrade(null, 1));
    setQuestionType(); // hardcoding question type to exam type to class_test
  }, []);

  const setQuestionType = () => {
    if (!tokenData.enterpriseUser) {
      setInputField({ exam_type: "class_test" });
    }
  };
  const handleChangeGrade = (e) => {
    const { name, value } = e.target;
    //    setInputField({ ...inputField, subject_id: '' })

    setInputField({ ...inputField, [name]: value, subject_id: "" });
    if (value) {
      dispatch(populateSubject(tokenData.token, value, 1, null));
    }
  };
  const handleSelectPaper = (e) => {
    var pass_mark = e.target.options[e.target.selectedIndex].dataset.pmark;
    setPassMark(pass_mark);
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };
  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setInputField({ ...inputField, [name]: value });
  };
  const handleChangeSubject = (e) => {
    const { name, value } = e.target;
    if (value) {
      dispatch(
        filterQuestionPaper(tokenData.token, inputField.grade_id, value)
      );
    }

    setInputField({ ...inputField, [name]: value });
  };
  const handleSubmit = () => {
    if (inputField.pass_mark > PassMark) {
      Swal.fire({
        icon: "warning",
        title: "Error",
        text: "Pass mark exceeds total mark",
      });
    } else {
      const error = {
        exam_title: requiredValidator(inputField.exam_title),
        subject_id: requiredValidator(inputField.subject_id),
        grade_id: requiredValidator(inputField.grade_id),
        exam_type: requiredValidator(inputField.exam_type),
        pass_mark: requiredValidator(inputField.pass_mark),
        date: requiredValidator(inputField.date),
        question_paper_id: requiredValidator(inputField.question_paper_id),
        time: requiredValidator(inputField.time),
        duration: requiredValidator(inputField.duration),
      };
      if (
        !error.exam_title &&
        !error.subject_id &&
        !error.grade_id &&
        !error.exam_type &&
        !error.question_paper_id &&
        !error.date &&
        !error.pass_mark &&
        !error.time &&
        !error.duration &&
        !error.question_paper_id
      ) {
        dispatch(createExam(tokenData.token, inputField, cb));
      }
      setMainError(error);
    }
  };
  const cb = () => {
    setInputField({
      exam_title: "",
      subject_id: "",
      grade_id: "",
      exam_type: "",
      pass_mark: "",
      question_paper_id: "",
      date: "",
      time: "",
      duration: "",
    });
    dispatch(getTeacherExams(tokenData.token));
  };

  const [mainError, setMainError] = useState({});

  return (
    <div>
      <Card style={{ margin: "32px 29px 10px 29px" }}>
        <Row style={{ padding: "40px" }}>
          <Col md={12}>
            <p style={{ color: "#495259", fontWeight: 600 }}>GENERAL DETAILS</p>
            <hr />
          </Col>
          <Col
            md={8}
            style={{ paddingRight: "29px", borderRight: "1px solid #d4d0d0" }}
          >
            <Row>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Title
                </Label>
                <Input
                  onChange={handleChangeInput}
                  type="text"
                  name="exam_title"
                  value={inputField.exam_title}
                  invalid={mainError.exam_title}
                />
                <FormFeedback>{mainError.exam_title}</FormFeedback>
              </Col>
              <Col md={tokenData.enterpriseUser ? 4 : 6} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Class
                </Label>
                <Input
                  type="select"
                  onChange={(e) => handleChangeGrade(e)}
                  name="grade_id"
                  //required

                  invalid={mainError.grade_id}
                  value={inputField.grade_id}
                >
                  <option value={""}>{"select"}</option>
                  {tStore.grade
                    ? tStore.grade.map((grade) => {
                        return (
                          <option value={grade.grade_id}>
                            {grade.grade_name}
                          </option>
                          //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>
                        );
                      })
                    : []}
                </Input>
                <FormFeedback>{mainError.grade_id}</FormFeedback>
              </Col>
              <Col md={tokenData.enterpriseUser ? 4 : 6} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Subject
                </Label>
                <div class="form-group">
                  <div class="input-group">
                <Input
                  type="select"
                  name="subject_id"
                  //required
                  value={inputField.subject_id}
                  invalid={mainError.subject_id}
                  onChange={handleChangeSubject}
                >
                  <option value={""}>{"select"}</option>

                  {tStore.subject
                    ? tStore.subject.map((subj) => {
                        return (
                          <option value={subj.subject_id}>
                            {subj.subject_name}
                          </option>
                        );
                      })
                    : null}
                </Input>
                {
                    tStore.loadersub ? 
                    <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                    <div class="spinner-border text-primary spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    </span>
                    
                    :
                    null
                }
                
                </div>
                </div>
                <FormFeedback>{mainError.subject_id}</FormFeedback>
              </Col>
              {tokenData.enterpriseUser && (
                <Col md={4} className="jms-p5">
                  <Label for="exampleNameF">
                    <span className="text-danger">*</span> Exam Type
                  </Label>
                  <Input
                    onChange={handleChangeInput}
                    value={
                      tokenData.enterpriseUser //Hard coded since mots users doesnt have two ypes for
                        ? inputField.exam_type
                        : "class_test"
                    }
                    invalid={mainError.exam_type}
                    type="select"
                    name="exam_type"
                  >
                    <option value={""}>{"Select Type"}</option>
                    <option value={"main"}>{"Main Exam"}</option>
                    <option value={"class_test"}>{"Class Test"}</option>
                  </Input>
                  <FormFeedback>{mainError.exam_type}</FormFeedback>
                </Col>
              )}
              <Col md={8} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Select Question Paper
                </Label>
                <Input
                  type="select"
                  name="question_paper_id"
                  onChange={handleSelectPaper}
                  invalid={mainError.question_paper_id}
                  value={inputField.question_paper_id}
                >
                  <option value={""}>{"Select Paper"}</option>

                  {examStore.questionPapers?.map((item) => (
                    <option
                      data-pmark={item.pass_mark}
                      value={item.question_paper_id}
                    >
                      {item.question_paper}
                    </option>
                  ))}
                </Input>
                <FormFeedback>{mainError.question_paperId}</FormFeedback>
              </Col>
              <Col md={4}>
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Pass Mark
                </Label>
                <Input
                  onChange={handleChangeInput}
                  type="number"
                  name="pass_mark"
                  value={inputField.pass_mark}
                  invalid={mainError.pass_mark}
                />
                <FormFeedback>{mainError.pass_mark}</FormFeedback>
              </Col>
            </Row>
          </Col>
          <Col md={4} style={{ paddingLeft: 29 }}>
            <Row>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Date
                </Label>
                <Input
                  onChange={handleChangeInput}
                  type="date"
                  name="date"
                  value={inputField.date}
                  invalid={mainError.date}
                />

                <FormFeedback>{mainError.date}</FormFeedback>
              </Col>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Time
                </Label>
                <Input
                  type="time"
                  invalid={mainError.time}
                  name="time"
                  value={inputField.time}
                  onChange={handleChangeInput}
                />

                <FormFeedback>{mainError.time}</FormFeedback>
              </Col>
              <Col md={12} className="jms-p5">
                <Label for="exampleNameF">
                  <span className="text-danger">*</span> Duration
                </Label>
                <Input
                  invalid={mainError.duration}
                  type="number"
                  name="duration"
                  value={inputField.duration}
                  onChange={handleChangeInput}
                />

                <FormFeedback>{mainError.duration}</FormFeedback>
              </Col>
            </Row>
          </Col>
          <Col md={4} style={{ paddingLeft: 7 }}>
            <Label
              for="exampleCustomSelectDisabled"
              style={{ visibility: "hidden" }}
            >
              ...
            </Label>

            <LaddaButton
              className="mb-2 mr-2 btn btn-danger"
              loading={examStore.createExamLoader}
              onClick={handleSubmit}
              type="submit"
              data-style={ZOOM_IN}
              style={{
                whiteSpace: "nowrap",
                marginRight: "0px",
                width: "-webkit-fill-available",
              }}
            >
              CREATE EXAM
            </LaddaButton>
          </Col>
        </Row>
      </Card>
      {/* <hr />
            <SlotForm />

            <Row style={{ padding: 29 }}>
                {Array(10).fill().map(i =>
                    <Col md={12}>
                        <PreviewCard />
                    </Col>)}
            </Row> */}
    </div>
  );
}

export default School;
