import React, { useEffect, useState } from 'react'
import PageTitle from '../common/HeaderTitle/PageTitle'
import { Card, Col, Row, Input, Label, FormFeedback } from 'reactstrap'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Table from '../common/DataTable'
import { populateGroup, createGroup, deleteGroup, editGroup, getGroupAssignedTeachers, populateGroupStudents } from '../../redux/Actions/grouping'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import AssignTeacher from './assignTeacher'
import Modals from '../common/Modal'
import Swal from 'sweetalert2'
import ListStudent from './ListStudent'
import { requiredValidator } from '../../utilities/Validator';

const Index = () => {


    const columns = [{ title: 'Group ', field: 'group_name', editable: 'onUpdate' },];
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const dispatch = useDispatch();
    const groupStore = useSelector(({ grouping }) => grouping)
    useEffect(() => {

        dispatch(populateGroup(tokenData.token))

        return () => {

        }
    }, [])

    console.log("groupStore", groupStore)
    const onEdit = (newData) => {

        console.log("newData", newData)
        if (!requiredValidator(newData.group_name)) {
            dispatch(editGroup(tokenData.token, newData))

        } else {
            Swal.fire(
                'Required',
                requiredValidator(newData.group_name),
                'warning'
            )
        }

    }
    const onDelete = (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Are you sure !',
            // text: 'All the classes under the subject will be deleted',
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "CONFIRM",
            cancelButtonText: "CANCEL",

        }).then((result) => {
            if (result.value) {
                dispatch(deleteGroup(tokenData.token, id))

                Swal.fire(
                    'Deleted!',
                    'Subject has been deleted.',
                    'success'
                )

            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                    'Cancelled',
                    'Delete action cancelled',
                    'error'
                )
            }
        })

    }
    const [assignStudent, setAssignStudent] = useState(false)
    const closeAssignStudent = () => {
        dispatch({ type: 'RESET_ASSIGN_TEACHERS_LIST' })
        setAssignStudent(false)
    }

    const handleAssignStudent = (id) => {
        //  alert("Group-ID", id)
        console.log("group_id", id)
        setGroupID(id)
        dispatch(populateGroupStudents(tokenData.token, id, 'test'))
        setAssignStudent(true)

    }
    const [inputField, setInputField] = useState({
        group_name: ''
    })
    const handleChange = (e) => {
        const { value, name } = e.target
        setInputField({ ...inputField, [name]: value })
    }
    const [errors, setErrors] = useState({})
    const formSubmit = (e) => {
        const err = {
            group_name: requiredValidator(inputField.group_name)
        }
        e.preventDefault()
        if (!err.group_name) {
            dispatch(createGroup(tokenData.token, inputField, resetForm))
            setErrors(err)
        } else {
            setErrors(err)

        }
    }
    const [assignPopup, setAssignPopup] = useState(false)
    const [groupID, setGroupID] = useState()
    const handleAssignTeacher = (id) => {
        //  alert("Group-ID", id)
        console.log("group_id", id)
        setGroupID(id)
        dispatch(getGroupAssignedTeachers(tokenData.token, id))
        setAssignPopup(true)

    }
    const closeAssignPopup = () => {
        dispatch({ type: 'RESET_ASSIGN_TEACHERS_LIST' })
        setAssignPopup(false)

    }
    const resetForm = () => {
        setInputField({group_name: ''})
        document.getElementById("group-form").reset();
    }
    return (
        <>
            <PageTitle

                heading="Create Group"
                subheading="Create group to  grouping teacher"
                icon="pe-7s-users icon-gradient bg-happy-itmeo"
            />
            <Card style={{ padding: 20 }}>
                <h6>Group</h6><hr />
                <form onSubmit={formSubmit} id="group-form">
                    <Row >
                        <Col md={2}>

                        </Col>
                        <Col md={5}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Group Name
                                            </Label>

                            <Input
                                type="text"
                                name="group_name"
                                // required
                                value={inputField.group_name}
                                onChange={handleChange}
                                invalid={errors.group_name}
                            />
                            <FormFeedback >{errors.group_name}</FormFeedback>

                        </Col>
                        <Col md={2}>
                        <Label for="exampleNameF" style={{visibility:'hidden'}}>
                                <span className="text-danger">*</span>
                                {' '}Group Name
                                            </Label>
                            <LaddaButton className="mb-2 mr-2 btn btn-primary"
                                loading={groupStore.createGroupLoader}
                                type="submit"
                                data-style={ZOOM_IN}  >
                                CREATE GROUP
                        </LaddaButton>
                        </Col>
                        <Col md={3}>

                        </Col>
                    </Row>
                </form>
            </Card>

            <h6 style={{ paddingTop: 20 }}>List of Groups</h6>
            <hr />
            <Table
                data={groupStore.groupList}
                loader={groupStore.groupListLoader}
                columns={columns}
                onEdit={onEdit}
                onDelete={onDelete}
                handleAssignTeacher={handleAssignTeacher}
                title={'Groups'}
                handleAssignStudent={handleAssignStudent}
                // handleSelectedRow={handleSelectedRow}
                actions={"create_group"}
            />
            <Modals
                open={assignPopup}
                handleClose={closeAssignPopup}
                title={`Assign teacher`}
            >
                <AssignTeacher
                    loader={groupStore.assignTeahersLoader}
                    groupID={groupID}
                    data={groupStore.assignTeahersList}
                    handleCloseStudentModal={closeAssignPopup}

                />
            </Modals>
            <Modals
                open={assignStudent}
                handleClose={closeAssignStudent}
                title={`Student list`}
            >
                <ListStudent
                    loader={groupStore.destinationGroupStudentsLoader}
                    groupID={groupID}
                    data={groupStore.destinationGroupStudents}
                    handleCloseStudentModal={closeAssignPopup}

                />
            </Modals>

        </>
    )
}

export default Index
