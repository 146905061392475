import React, { useEffect } from 'react'
import Table from '../../common/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { populateAllUserSpecialClass } from '../../../redux/Actions/homeTeacher'
import { Badge } from 'reactstrap'

const Index = () => {
    const tokenDate = JSON.parse(localStorage.getItem("authDetails"))
    const dispatch = useDispatch()
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    useEffect(() => {
        dispatch(populateAllUserSpecialClass(tokenDate.token))
    }, [])

    const columns =  tokenDate.role === 'principal' ? [
        {
            title: 'Class ', field: 'class_name',
    
        },
        {
            title: 'Teacher', field: 'teacher_name',
    
        },
        { title: ' Grade', field: 'grade_name' },
        { title: 'Subject', field: 'subject_name' },
        { title: ' Date', field: 'starts_at' },
        // { title: 'From time', field: 'class_from_time' },
        // { title: 'To time', field: 'class_to_time' },
        {
            title: 'From - To', field: 'class_from_time',
            render: rowData => <><Badge color="warning" pill>{`${rowData.class_from_time} to ${rowData.class_to_time}`}</Badge></>
    
        },
    ] : tokenDate.role === 'student' ? [{
        title: 'Class ', field: 'class_name',

    },
    {
        title: 'Teacher', field: 'teacher_name',

    },
    { title: ' Grade', field: 'grade_name' },
    { title: 'Subject', field: 'subject_name' },
    { title: ' Date', field: 'starts_at' },
    // { title: 'From time', field: 'class_from_time' },
    // { title: 'To time', field: 'class_to_time' },
    {
        title: 'From - To', field: 'class_from_time',
        render: rowData => <><Badge color="warning" pill>{`${rowData.class_from_time} to ${rowData.class_to_time}`}</Badge></>

    }] : [
        {
            title: 'Class ', field: 'class_name',
    
        },
        { title: ' Grade', field: 'grade_name' },
        { title: 'Subject', field: 'subject_name' },
        { title: ' Date', field: 'starts_at' },
        // { title: 'From time', field: 'class_from_time' },
        // { title: 'To time', field: 'class_to_time' },
        {
            title: 'From - To', field: 'class_from_time',
            render: rowData => <><Badge color="warning" pill>{`${rowData.class_from_time} to ${rowData.class_to_time}`}</Badge></>
    
        },
    ]
    return (
        <>
            <Table
                title={'Special Class'}
                data={tStore.specialClassList}
                columns={columns}
                loader={tStore.specialClassListLoader}
            />
        </>
    )
}

export default Index
