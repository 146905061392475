import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Tabs, { TabPane } from 'rc-tabs';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
import { useParams } from "react-router-dom";
import LaddaButton, { ZOOM_IN } from "react-ladda";
import serialize from "form-serialize";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import Loader from 'react-loaders'
import { useHistory } from "react-router-dom";
import ChooseFromPlaylist from "./editForm";
import { nameValid, requiredValidator } from "../../../utilities/Validator";


import { Row, Col, FormGroup, Input, Card, Label, FormFeedback } from "reactstrap";
import {
  populatePlayListInClass,
  updateVideoClass,
  editClassVideos,
} from "../../../redux/Actions/playlist";

import PageTitle from "../../HeaderTitle/PageTitle";
import "../Playlist/playlist.scss";
import "./subject.scss";
const Index = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const playlistStore = useSelector(({ playlist }) => playlist);
  const params = useParams();
  const [editClassDetails, setEditClassDetails] = useState("");
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  // let initialPlaylist = null;
  const [initialPlaylist, setInitialPlaylist] = useState("");
  useEffect(() => {
    dispatch(
      editClassVideos(
        tokenData.token,
        params.class_id,
        params.subject_id,
        setSelected
      )
    );
  }, []);
  const [selectedId, setSelectedId] = useState({});
  //   console.log({ selectedId });
  console.log({ selectedId });

  const setSelected = (playlist_id, details) => {
    // debugger
    // initialPlaylist = playlist_id

    // indexOfVideo.map(setIndex => selectedId[setIndex].push({`fromDate-${setIndex}`, `toDate-${setIndex}`, `fromTime-${setIndex}`, `toTime-${setIndex}`}))

    // setSelectedId({ ...selectedId, [index]: [] })

    setInitialPlaylist(playlist_id);
    setEditClassDetails(details);
    setSelectedPlaylist(playlist_id);

    let selectedVideos = details.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );
    let indexOfVideo = selectedVideos[0].videos.map((video, index) => {
      if (video.selected === true) {
        return index;
      }
    });

    // selectedId[keyName].push(id)

    // indexOfVideo.map(setIndex => selectedId[setIndex].push(`fromDate-${setIndex}`, `toDate-${setIndex}`, `fromTime-${setIndex}`, `toTime-${setIndex}`}))
    indexOfVideo.map((setIndex) => {
      // let el = [] `fromDate-${setIndex}`, `toDate-${setIndex}`, `fromTime-${setIndex}`, `toTime-${setIndex}`}
      //   console.log({ setIndex });
      const updatedState = selectedId;
      updatedState[setIndex] = [
        `fromDate-${setIndex}`,
        `toDate-${setIndex}`,
        `fromTime-${setIndex}`,
        `toTime-${setIndex}`,
      ];
      setSelectedId(updatedState);

      // selectedId[setIndex].push([
      //   `fromDate-${setIndex}`,
      //   `toDate-${setIndex}`,
      //   `fromTime-${setIndex}`,
      //   `toTime-${setIndex}`,
      // ]);
    });

    selectedVideos.findIndex((video) => video.selected == true);

    selectedVideos = selectedVideos[0].videos.map((video) => {
      if (video.selected === true) {
        return video.video_id;
      }
    });
    setMultiSelect(selectedVideos);

    let selectedPlaylistVideo = details.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );
    selectedPlaylistVideo = selectedPlaylistVideo[0].videos.map((video) => {
      if (video.selected === true) {
        return video.playlist_video_id;
      }
    });
    setPlaylistsVideoId(selectedPlaylistVideo);
    console.log("playlistVideo---Id", selectedPlaylistVideo);
  };
  const [state, setState] = useState({
    activeTab: "1",
    showMore: true,
    transform: true,
    showInkBar: true,

    selectedTabKey: 0,
    transformWidth: 400,
  });
  const [tabNow, setTabNow] = useState(1);
  const toggle = (tab) => {
    if (tabNow !== tab) {
      dispatch({ type: "RESET_PLAYLIST" });
    }
    setTabNow(tab);
    if (state.activeTab !== tab) {
      setState({
        activeTab: tab,
      });
    }
  };
  const getPlaylist = (subject_id) => {
    // alert(subject_id)
    if (!subject_id) {
      dispatch({ type: "RESET_PLAYLIST" });
    } else {
      dispatch(populatePlayListInClass(tokenData.token, subject_id));
    }
  };
  const getVideos = (playlist_id) => {
    // debugger
    if (initialPlaylist == playlist_id) {
      dispatch(
        editClassVideos(
          tokenData.token,
          params.class_id,
          params.subject_id,
          setSelected
        )
      );
    }
    setMultiSelect([]);
    setPlaylistsVideoId([]);
    setSelectedPlaylist(playlist_id);
    let selectedVideos = editClassDetails.playlists.filter(
      (playlist) => playlist.playlist_id == playlist_id
    );
    selectedVideos = selectedVideos[0].videos.map((video) => {
      if (video.selected === true) {
        return video.video_id;
      }
    });
    setMultiSelect(selectedVideos);
  };
  const handleTime = (e) => {
    const { id, value } = e.target;
    const date = id.split("-");
    // console.log(""{ id });
    //alert(date[1])
    let keyName = date[1];
    console.log("selectedId=======", id);
    // debugger;
    if (selectedId[keyName]) {
      if (!selectedId[keyName].includes(id)) {
        selectedId[keyName].push(id);
      }
      // console.log(selectedId);
    }
    // setPlaylistDetails({ ...palylistDetails, [id]: value });
    // selectedId[keyName].push(id)
  };

  const [mutliSelect, setMultiSelect] = useState([]);
  const [playlistsVideoId, setPlaylistsVideoId] = useState([]);
  const [selectedIdOrigin, setSelectedIdOrigin] = useState({});

  const multiSelectVideo = (video_id, playlists_video_id, index) => {
    // debugger
    //alert(playlists_video_id)
    console.log(playlistsVideoId);
    document.getElementById(`fromDate-${index}`).value = "";
    document.getElementById(`toDate-${index}`).value = "";
    document.getElementById(`fromTime-${index}`).value = "";
    document.getElementById(`toTime-${index}`).value = "";
    if (playlistsVideoId.includes(playlists_video_id)) {
      const filtered = playlistsVideoId.filter(
        (videoId) => videoId != playlists_video_id
      );
      setPlaylistsVideoId(filtered);
      console.log(playlistsVideoId);
      delete selectedId[index];
    } else {
      setSelectedId({ ...selectedId, [index]: [] });

      setPlaylistsVideoId([...playlistsVideoId, playlists_video_id]);
    }

    if (mutliSelect.includes(video_id)) {
      // debugger
      const filtered = mutliSelect.filter((videoId) => videoId != video_id);
      console.log("after removed", filtered);
      setMultiSelect(filtered);
    } else {
      setMultiSelect([...mutliSelect, video_id]);
      console.log("video selected", mutliSelect);
    }
  };
  const [syncVideo, setSyncVideo] = useState([]);

  const handleClickSync = (video_id) => {
    if (syncVideo.includes(video_id)) {
      // debugger
      const filtered = syncVideo.filter((videoId) => videoId != video_id);
      console.log("after removed", filtered);
      setSyncVideo(filtered);
    } else {
      setSyncVideo([...syncVideo, video_id]);
      console.log("video selected", syncVideo);
    }
  };
  const handleChange = (e, name, videoId) => {
    // debugger
    const { id, value } = e.target;

    let copy = editClassDetails;
    if (e.target.name == "title") {
      copy.class_name = e.target.value;
    } else {
      copy.playlists
        .filter((playlist) => playlist.playlist_id == selectedPlaylist)[0]
        .videos.map((video) => {
          console.log("object");
          if (video.video_id == videoId) {
            if (name == "from_date") {
              video.from_date = e.target.value;
              // console.log("chnge.......")
            } else if (name == "to_date") {
              video.to_date = e.target.value;
            } else if (name == "from_time") {
              video.from_time = e.target.value;
            } else if (name == "to_time") {
              video.to_time = e.target.value;
            }
          }
        });

      const date = id.split("-");
      // console.log(""{ id });
      //alert(date[1])
      let keyName = date[1];
      console.log("selectedId=======", id);
      // debugger;
      if (selectedId[keyName]) {
        if (!selectedId[keyName].includes(id)) {
          selectedId[keyName].push(id);
        }
        // console.log(selectedId);
      }
      // setPlaylistDetails({ ...palylistDetails, [id]: value });
      // selectedId[keyName].push(id)
    }

    console.log("copy----", copy);
    // alert("j")

    setEditClassDetails({ ...editClassDetails, copy });
    // document.write(copy)
  };
  const canCallApi = (_selectedId) => {
    for (let key in _selectedId) {
      if (_selectedId[key].length < 4) {
        return false;
      }
    }
    return true;
  };

  const [errors, setErrors] = useState({});

  const formSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    // body.sync = syncVideo
    setSelectedIdOrigin(selectedId);


    let error = {
      title: requiredValidator(body.title),
    };

    if (selectedPlaylist) {
      if (canCallApi(selectedId) && !error.title) {
        dispatch(updateVideoClass(tokenData.token, body, updatedSuccess));
        setErrors(error)

      } else {
        setErrors(error)
        //error
        //alert("error validation!!!!");
      }
    } else {
      alert("Select playlist");
    }

    console.log("body---", body);
  };
  const updatedSuccess = () => {
    history.push("/dashboard/videoClass");
  };
  return (
    <>
      <PageTitle
        style={{ display: "none" }}
        heading="Edit Video Session"
        subheading="Edit your  video session under the grade or course"
        icon="pe-7s-video icon-gradient bg-happy-itmeo"
      />

      <form onSubmit={formSubmit} id="create_video_class">
        <input type="hidden" value={params.class_id} name="video_class_id" />
        {playlistStore.editClassDetails ? (
          <>
            <Card style={{ padding: "25px 20px", marginBottom: 25 }}>
              <Row>
                <Col md={3}> </Col>

                <Col md={6}>
                  <Label for="exampleCustomSelectDisabled"><span className="text-danger">*</span>
                                {' '} Title</Label>

                  <Input
                    type="text"
                    name="title"
                    value={editClassDetails ? editClassDetails.class_name : ""}
                    onChange={handleChange}
                    invalid={errors.title}
                  />
                  <FormFeedback >{errors.title}</FormFeedback>

                </Col>
                <Col md={3}> </Col>
              </Row>
            </Card>
            <Card style={{ padding: "10px 20px" }}>
              <Row className="jms-create-video-session">
                <Col md={3}> Playlist </Col>
                <Col md={3}> Videos</Col>
                <Col md={3}> From</Col>
                <Col md={3}>To </Col>
              </Row>
              <Row>
                <input
                  type="hidden"
                  value={selectedPlaylist}
                  name="playlist_id"
                />

                <Col md={3} className="jms-choose-from-col-title">
                  {playlistStore.editClassDetails.playlists.map((playlist) => {
                    return (
                      <div
                        className={`jms-choose-from-title ${
                          selectedPlaylist === playlist.playlist_id
                            ? "jms-active-playlist"
                            : null
                          }`}
                        onClick={() => getVideos(playlist.playlist_id)}
                      >
                        {playlist.playlist_name}
                      </div>
                    );
                  })}
                </Col>

                <Col md={9} className="jms-choose-from-col-videos">
                  {console.log("state------------", editClassDetails)}

                  {editClassDetails && selectedPlaylist
                    ? editClassDetails.playlists
                      .filter(
                        (playli) => playli.playlist_id == selectedPlaylist
                      )[0]
                      .videos.map((video, index) => {
                        return (
                          <ChooseFromPlaylist
                            thumbnile={
                              video?.thumbnails?.length > 0
                                ? video.thumbnails[2].link
                                : null
                            }
                            type={"create_class"}
                            videoId={video.video_id}
                            playlist_video_id={video.playlist_video_id}
                            title={video.video_title}
                            //url={video.url}
                            duration={video.video_duration}
                            //format={'mp4'}
                            index={index}
                            fromDate={video.from_date}
                            toDate={video.to_date}
                            fromTime={video.from_time}
                            toTime={video.to_time}
                            order={video.video_order}
                            watch={video.percentage}
                            //selected={video.selected}
                            handleChange={handleChange}
                            multiSelectVideo={multiSelectVideo}
                            mutliSelect={mutliSelect}
                            playlistsVideoId={playlistsVideoId}
                            handleClickSync={handleClickSync}
                            syncVideo={syncVideo}
                            handleTime={handleTime}
                            selectedIdOrigin={selectedIdOrigin}

                          />
                        );
                      })
                    : null}
                </Col>
              </Row>
            </Card>
          </>
        ) : playlistStore.classPlaylistLoader ? (
          <>
            <Card style={{ padding: 20 }}>
              <Row>
                <Col md={3}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={50} count={4} />
                  </SkeletonTheme>
                </Col>
                <Col md={9}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={100} count={2} />
                  </SkeletonTheme>
                </Col>
              </Row>
            </Card>
          </>
        ) : null}

        <Row>
          <Col md={3}>
            <FormGroup>
              <Label
                for="exampleCustomSelectDisabled"
                style={{ visibility: "hidden" }}
              >
                Subject
              </Label>

              <LaddaButton
                className="mb-2 mr-2 btn btn-danger"
                loading={playlistStore.classUpdateLoader}
                // onClick={formSubmit}
                type="submit"
                data-style={ZOOM_IN}
                style={{
                  whiteSpace: "nowrap",
                  marginRight: "0px",
                  width: "-webkit-fill-available",
                }}
              >
                UPDATE CLASS
              </LaddaButton>
            </FormGroup>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default Index;
