import React, { Fragment, useEffect } from 'react';
import {
    Row, Col,
    FormGroup, Label, Input, CustomInput, Button, InputGroup, InputGroupAddon, FormFeedback

} from 'reactstrap';
import PhoneInput from 'react-phone-input-2'
import Loader from 'react-loader'
var option = {
    lines: 13,
    length: 4,
    width: 2,
    radius: 5,
    scale: 1.00,
    corners: 1,
    color: '#000',
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: '50%',
    left: '50%',
    shadow: false,
    hwaccel: false,
    position: 'absolute'
};
const WizardStep1 = (props) => {

    const { handleChange, inputField, handleType, type, store, handleDegree,
        onChng, errorz, verifyMobile, handleChangeOTP, validateOtp
    } = props;
    console.log("typee==", type)
    useEffect(() => {

        return () => {
            // setType(null)
        }
    }, [])

    return (
        <Fragment>
            <div className="form-wizard-content">
                <Row form>
                    <Col md={6}>

                        <FormGroup>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}First Name
                                                    </Label>
                            <Input type="text" name="first_name" id="exampleNameF"
                                value={inputField.first_name}
                                placeholder="First Name..."
                                onChange={(e) => handleChange(e)}
                                invalid={errorz.first_name ? true : false}
                            />
                            <FormFeedback >oops! your first name is required</FormFeedback>
                        </FormGroup>
                    </Col>



                    <Col md={6}>
                        <FormGroup>
                            <Label for="exampleNameL">
                                <span className="text-danger">*</span>
                                {' '} Last Name</Label>
                            <Input type="text" name="last_name" id="exampleNameL"
                                placeholder="Last Name... "
                                value={inputField.last_name}
                                onChange={(e) => handleChange(e)}
                                invalid={errorz.last_name ? true : false}

                            />
                            <FormFeedback >oops! your last name is required</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <Label for="exampleCustomSelectDisabled">Type</Label>
                    <CustomInput type="select" name="education_type" onChange={handleType} value={inputField.education_type}
                        invalid={errorz.education_type ? true : false}
                    >
                        <option value="">Select</option>
                        <option value="schools" selected={inputField.education_type === 'schools' ? true : false}>School</option>
                        <option value="college" selected={inputField.education_type === 'college' ? true : false} >College/University</option>
                        <option value="lifeLong" selected={inputField.education_type === 'lifeLong' ? true : false} >Life Long Course</option>
                    </CustomInput>
                    <FormFeedback >oops! Type is required</FormFeedback>

                </FormGroup>
                <Row form>{
                    type === "schools" || inputField.education_type === "lifeLong" ?
                        <Col md={12}>
                            <FormGroup>
                                <Label for="exampleCustomSelectDisabled">{inputField.education_type === "lifeLong" ? "Course" : "Grade"}</Label>
                                <CustomInput
                                    type="select"
                                    name="grade_id"
                                    onChange={handleChange}
                                    value={inputField.grade_id}
                                    invalid={errorz.grade ? true : false}
                                >
                                    <option value="">Select</option>
                                    {
                                        store.gradeSchool.grades ? store.gradeSchool.grades.map((data) => {
                                            return (
                                                <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                            )
                                        })
                                            : []
                                    }

                                </CustomInput>
                                <FormFeedback >oops! Grade is required</FormFeedback>

                            </FormGroup>
                        </Col>
                        : type === "college" ?
                            <>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCustomSelectDisabled">Course</Label>
                                        <CustomInput type="select" name="degree_id"
                                            invalid={errorz.course ? true : false}
                                            onChange={handleDegree} value={inputField.degree_id}>
                                            <option value="">Select</option>
                                            {
                                                store.degree ? store.degree.map((data) => {
                                                    return (
                                                        <option value={data.degree_id} key={data.degree_id}>{data.degree_name}</option>
                                                    )
                                                })
                                                    : []
                                            }

                                        </CustomInput>
                                        <FormFeedback >oops! Course is required</FormFeedback>

                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="exampleCustomSelectDisabled">Specialization</Label>
                                        <CustomInput type="select" name="grade_id"
                                            invalid={errorz.specialization ? true : false}

                                            onChange={handleChange} value={inputField.grade_id}>
                                            <option value="">Select</option>
                                            {
                                                store.gradeCollege.grades ? store.gradeCollege.grades.map((data) => {
                                                    return (
                                                        <option selected={inputField.specialization_id === data.grade_id ? true : false} value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                                    )
                                                })
                                                    : []
                                            }
                                        </CustomInput>
                                        <FormFeedback >oops! Course is required</FormFeedback>

                                    </FormGroup>
                                </Col>
                            </>
                            : null

                }


                </Row>
                <Row form>
                    <Col md={6}>
                        <Row>
                            <Col md={8} xs={8}>
                                <Label for="exampleName">
                                    <span className="text-danger">*</span>
                                    {' '}Mobile Number</Label>
                                <PhoneInput
                                    style={{ width: '170px' }}

                                    placeholder="Enter phone number"
                                    value={inputField.parent_mobile}
                                    name="parent_mobile"
                                    disabled={store.valid ? true : false}
                                    placeholder="Mobile here..."
                                    onChange={(e) => onChng(e)}
                                    maxLength={15}
                                    invalid={errorz.mobile || errorz.mobileValidated === null || errorz.mobileValidated === false ? true : false}
                                />

                            </Col>
                            <Col md={4} xs={4}>
                                <Button
                                    style={{ borderRadius: '0px', width: '74px', fontSize: '10px', padding: '10px 10px', marginTop: '29px', marginLeft: '-8px' }}

                                    onClick={verifyMobile}
                                    disabled={inputField.parent_mobile ? inputField.parent_mobile.length <= 9 : null || store.validateOtpLoader || store.valid}
                                >

                                    <span
                                        style={store.validateOtpLoader ? { visibility: 'hidden' } : null}
                                    >
                                        {store.studentNumberAvail ? 'Resend' : 'Send OTP'}
                                    </span>

                                    <span>
                                        {store.validateOtpLoader ? <Loader options={option} />

                                            : null}

                                    </span>
                                </Button>
                            </Col>

                        </Row>
                        <InputGroup>
                            <InputGroupAddon addonType="prepend"></InputGroupAddon>
                            <span style={{ color: '#dc3545', fontSize: '80%' }}>
                                {store.studentNumberAvail === false ?
                                    'Mobile Already Registered' : null
                                }
                                {
                                    errorz.mobile ?
                                        `oops! ${errorz.mobile}`
                                        : null
                                }
                                {!errorz.mobile ? errorz.mobileValidated === null || errorz.mobileValidated === false ?
                                    `oops! verify mobile number `
                                    : null : null}

                            </span>



                        </InputGroup>
                    </Col>
                    <Col md={6}>

                        {store.studentNumberAvail ?

                            <FormGroup>
                                <Label for="exampleName">
                                    <span className="text-danger">*</span>
                                    {' '}Enter OTP</Label>
                                <InputGroup>

                                    <Input
                                        name="otp"
                                        onChange={handleChangeOTP}
                                        type="number"
                                        invalid={store.valid === false || errorz.mobileValidated === null ? true : false}
                                        valid={store.valid ? true : false}
                                        readOnly={store.valid ? true : false}
                                    />

                                    <InputGroupAddon addonType="prepend"><Button
                                        onClick={() => validateOtp()}
                                        disabled={store.validateOtpValidateLoader || store.valid ? true : false}
                                    >
                                        <span
                                            style={store.validateOtpValidateLoader ? { visibility: 'hidden' } : null}
                                        >
                                            Validate
                     </span>
                                        {store.validateOtpValidateLoader ? <Loader options={option} /> : null}
                                    </Button>
                                    </InputGroupAddon>
                                    <br />
                                    <FormFeedback>{`oops! Invalid OTP`}</FormFeedback>
                                </InputGroup>

                            </FormGroup>
                            : null

                        }




                    </Col>






                </Row>

            </div>
        </Fragment >
    );

}
export default WizardStep1