

import axios from 'axios'
import { development } from '../../config.json'
// import moment from 'moment'
import Swal from 'sweetalert2'






export const changeStatusOfVideoClass = (token, class_id, status, teacher_id, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        class_id: class_id,
        status: status,
        teacher_id: teacher_id

    }
    dispatch({ type: 'REQUEST_VIDEOCLASS_STATUS' })

    axios.post(`${development.baseUrl}changeStatusOfVideoClass`, data, { headers: head })
        .then(res => {
            // debugger
            dispatch({ type: 'SUCCESS_VIDEOCLASS_STATUS' })
            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: res.data.message,

                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'FAILED_VIDEOCLASS_STATUS', payload: err })
        })
}
export const updateVideoClass = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }

    dispatch({ type: 'REQUEST_FOR_UPDATE_CLASS' })

    axios.post(`${development.baseUrl}updateVideoClass`, data, { headers: head })
        .then(res => {
            // debugger
            dispatch({ type: 'SUCCESS_FOR_UPDATE_CLASS' })

            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Class updated  successfully',

                })
            }
            cb()

        })
        .catch(err => {
            dispatch({ type: 'REQUEST_FOR_UPDATE_CLASS_FAILED', payload: err })
        })
}

export const editClassVideos = (token, class_id, subject_id, setSelected) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        subject_id: subject_id,
        video_class_id: class_id
    }
    dispatch({ type: 'REQUEST_FOR_EDIT_CLASS' })

    axios.post(`${development.baseUrl}editClassVideos`, data, { headers: head })
        .then(res => {
            // debugger

            if (res.data.result) {
                dispatch({ type: 'REQUEST_FOR_EDIT_CLASS_SUCCESS', payload: res.data })
                const updated = res.data.playlists.filter(row => row.selected === true)
                if (updated.length > 0)
                    setSelected(updated[0].playlist_id, res.data)
            }

        })
        .catch(err => {
            dispatch({ type: 'REQUEST_FOR_EDIT_CLASS_FAILED', payload: err })
        })
}

export const updatePlaylistVideos = (token, video_id, playlist_id, title, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: playlist_id,
        video_id: video_id,
        playlist_video_title: title
    }

    dispatch({ type: 'UPDATE_PLAYLIST_VIDEO_REQUEST' })

    axios.post(`${development.baseUrl}updatePlaylistVideos`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_PLAYLIST_VIDEO_SUCCESS', payload: { id: video_id, title: title, playlist_id: playlist_id } })
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Updated  successfully',

                })
                cb(title, video_id)
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_PLAYLIST_VIDEO_FAILED', payload: err })
        })
}

export const deletePlaylistVideos = (token, video_id, playlist_id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: playlist_id,
        video_id: video_id
    }

    dispatch({ type: 'DELETE_PLAYLIST_VIDEO_REQUEST' })

    axios.post(`${development.baseUrl}deletePlaylistVideos`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'DELETE_PLAYLIST_VIDEO_SUCCESS', payload: res.data.vidoes })
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Video deleted  successfully',

                })
                // cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'DELETE_PLAYLIST_VIDEO_FAILED', payload: err })
        })
}


export const updatePlaylist = (token, playlist_id, playlist_name, category_id, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: playlist_id,
        playlist_name: playlist_name

    }
    if (category_id == 1) {
        dispatch({ type: 'UPDATE_PLAYLIST_SCHOOL_REQUEST' })

    } else if (category_id == 2) {
        dispatch({ type: 'UPDATE_PLAYLIST_COLLEGE_REQUEST' })

    } else if (category_id == 3) {
        dispatch({ type: 'UPDATE_PLAYLIST_LIFELONG_REQUEST' })

    }
    else {
        dispatch({ type: 'UPDATE_PLAYLIST_GENARAL_REQUEST' })

    }
    // dispatch({ type: 'UPDATE_PLAYLIST_REQUEST' })

    axios.post(`${development.baseUrl}updatePlaylist`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                if (category_id == 1) {
                    dispatch({ type: 'UPDATE_PLAYLIST_SCHOOL_SUCCESS', payload: { playlist_id: playlist_id, playlist_name: playlist_name } })

                } else if (category_id == 2) {
                    dispatch({ type: 'UPDATE_PLAYLIST_COLLEGE_SUCCESS', payload: { playlist_id: playlist_id, playlist_name: playlist_name } })

                } else if (category_id == 3) {
                    dispatch({ type: 'UPDATE_PLAYLIST_LIFELONG_SUCCESS', payload: { playlist_id: playlist_id, playlist_name: playlist_name } })

                }
                else {
                    dispatch({ type: 'UPDATE_PLAYLIST_GENARAL_SUCCESS', payload: { playlist_id: playlist_id, playlist_name: playlist_name } })

                }

                // dispatch({ type: 'UPDATE_PLAYLIST_SUCCESS', payload: { playlist_id: playlist_id, playlist_name: playlist_name } })

                cb(playlist_id)
                //  dispatch({ type: 'DELETE_PLAYLIST_SCHOOL_SUCCESS', payload: res.data.playlists })


            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_PLAYLIST_FAILED', payload: err })
        })
}




export const deletePlaylist = (token, playlist_id, category_id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: playlist_id,
        category_id: category_id

    }
    if (category_id == 1) {
        dispatch({ type: 'DELETE_PLAYLIST_SCHOOL_REQUEST' })

    } else if (category_id == 2) {
        dispatch({ type: 'DELETE_PLAYLIST_COLLEGE_REQUEST' })

    } else if (category_id == 3) {
        dispatch({ type: 'DELETE_PLAYLIST_LIFELONG_REQUEST' })

    }
    else {
        dispatch({ type: 'DELETE_PLAYLIST_GENARAL_REQUEST' })

    }
    axios.post(`${development.baseUrl}deletePlaylist`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    text: 'Playlist deleted  successfully',

                })
                if (category_id == 1) {
                    dispatch({ type: 'DELETE_PLAYLIST_SCHOOL_SUCCESS', payload: res.data.playlists })

                } else if (category_id == 2) {
                    dispatch({ type: 'DELETE_PLAYLIST_COLLEGE_SUCCESS', payload: res.data.playlists })

                } else if (category_id == 3) {
                    dispatch({ type: 'DELETE_PLAYLIST_LIFELONG_SUCCESS', payload: res.data.playlists })

                }
                else {
                    dispatch({ type: 'DELETE_PLAYLIST_GENARAL_SUCCESS', payload: res.data.playlists })

                }
                //  dispatch({ type: 'DELETE_PLAYLIST_SCHOOL_SUCCESS', payload: res.data.playlists })


            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'DELETE_PLAYLIST_FAILED', payload: err })
        })
}


export const populateVideoClassStudents = (token, classId, gradeId) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        video_class_id: classId,
        grade_id: gradeId
    }
    dispatch({ type: 'POPULATE_VIDEO_CLASS_STUDENT_REQUEST' })
    axios.post(`${development.baseUrl}populateVideoClassStudents`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_VIDEO_CLASS_STUDENT_SUCCESS', payload: res.data.students })


            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_VIDEO_CLASS_STUDENT_FAILED', payload: err })
        })
}


export const addStudentToVideoClassroom = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }


    dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_REQUEST' })
 
    axios.post(`${development.baseUrl}addStudentToVideoClassroom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_SUCCESS', payload: res.data.class_details })
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Assign Students  successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_FAILED', payload: err })
        })
}
export const addStudentToVideoClassroomEnter = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }


    dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_REQUEST' })

    axios.post(`${development.baseUrlEnte}addStudentToVideoClassroom`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_SUCCESS', payload: res.data.class_details })
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Assign group  successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'ADD_STUDENTS_TO_VIDEO_CLASS_FAILED', payload: err })
        })
}

export const populateAllVideoClass = (token) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }


    dispatch({ type: 'POPULATE_VIDEO_CLASS_REQUEST' })

    axios.get(`${development.baseUrl}populateAllVideoClass`, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_VIDEO_CLASS_SUCCESS', payload: res.data.class_details })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_VIDEO_CLASS_FAILED', payload: err })
        })
}

export const addVideosFromPlaylist = (token, data, reset, deteleForm) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }


    dispatch({ type: 'UPLOAD_CHOOSE_FROM_REQUEST' })

    axios.post(`${development.baseUrl}addVideosFromPlaylist`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPLOAD_CHOOSE_FROM_SUCCESS', payload: res.data.vidoes })
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Video uploaded successfully',

                })
                reset()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'UPLOAD_CHOOSE_FROM_FAILED', payload: err })
            deteleForm()
        })
}

export const getPlayListVideos = (token, id, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: id
    }

    dispatch({ type: 'GET_PLAYLIST_VIDEO_REQUEST' })

    axios.post(`${development.baseUrl}getPlayListDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'GET_PLAYLIST_VIDEO_SUCCESS', payload: res.data.details[0].vidoes })
                cb(res.data.details[0].vidoes)
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'GET_PLAYLIST_VIDEO_FAILED', payload: err })
        })
}

export const createVideoClass = (token, body, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }


    dispatch({ type: 'CREATE_VIDEO_CLASS_REQUEST' })

    axios.post(`${development.baseUrl}createVideoClass`, body, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'CREATE_VIDEO_CLASS_SUCCESS', payload: res.data.playlists })
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Create class successfully',

                })
                cb()
            } else {
                dispatch({ type: 'CREATE_VIDEO_CLASS_FAILED' })

                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: res.data.message,

                })
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'CREATE_VIDEO_CLASS_FAILED', payload: err })
        })
}


export const populatePlayListInClass = (token, subject_id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        subject_id: subject_id
    }
    //dispatch({ type: 'POPULATE_PLAYLIST_REQUEST' })


    dispatch({ type: 'POPULATE_PLAYLIST_IN_CLASS_REQUEST' })

    axios.post(`${development.baseUrl}populatePlayList`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_PLAYLIST_IN_CLASS_SUCCESS', payload: res.data.playlists })

            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_PLAYLIST_IN_CLASS_FAILED', payload: err })
        })
}


export const uploadVideo = (token, data, reset, deteleForm) => (dispatch) => {
    // const formData = new FormData();
    // formData.append('serialized', serialized)
    // formData.append('data', data)
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'content-type': 'multipart/form-data'

        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_VIDEO_PROGRESS_BAR', payload: percent })
            // if (percent < 100) {
            //     this.setState({ uploadPercentage: percent })
            // }
        }
    };

    dispatch({ type: 'UPLOAD_VIDEO_REQUEST' })
    axios.post(`${development.baseUrl}uploadVideo`, data, options)
        .then(res => {

            if (res.data.result) {
                dispatch({ type: 'UPLOAD_VIDEO_SUCCESS', payload: res.data.vidoes[0] })
                Swal.fire({
                    icon: 'success',
                    title: 'Uploaded',
                    text: 'Video uploaded successfully',

                })
                reset()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            console.log("err.response.status", err)
            deteleForm()
            if (err.response) {
                console.log("err.response.status", err.response.status)
                if (err.response.status === 422) {

                }
            }

            dispatch({ type: 'UPLOAD_VIDEO_FAILED', payload: err })
        })
}



export const getPlayListDetails = (token, id) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        playlist_id: id
    }
    dispatch({ type: 'POPULATE_PLAYLIST_DETAILS_REQUEST' })
    axios.post(`${development.baseUrl}getPlayListDetails`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'POPULATE_PLAYLIST_DETAILS_SUCCESS', payload: res.data.details[0] })


            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_PLAYLIST_DETAILS_FAILED', payload: err })
        })
}

export const populatePlayList = (token, category) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    const data = {
        category_id: category
    }
    //dispatch({ type: 'POPULATE_PLAYLIST_REQUEST' })
    if (category == 1) {
        dispatch({ type: 'POPULATE_PLAYLIST_SCHOOL_REQUEST' })

    } else if (category == 2) {
        dispatch({ type: 'POPULATE_PLAYLIST_COLLEGE_REQUEST' })

    }
    else if (category == 3) {
        dispatch({ type: 'POPULATE_PLAYLIST_LIFELONG_REQUEST' })

    }
    else {
        dispatch({ type: 'POPULATE_PLAYLIST_GENARAL_REQUEST' })

    }
    axios.post(`${development.baseUrl}populatePlayList`, data, { headers: head })
        .then(res => {
            if (res.data.result) {
                //dispatch({ type: 'POPULATE_PLAYLIST_SUCCESS', payload: res.data.playlists })
                if (category == 1) {
                    dispatch({ type: 'POPULATE_PLAYLIST_SCHOOL_SUCCESS', payload: res.data.playlists })

                } else if (category == 2) {
                    dispatch({ type: 'POPULATE_PLAYLIST_COLLEGE_SUCCESS', payload: res.data.playlists })

                }
                else if (category == 3) {
                    dispatch({ type: 'POPULATE_PLAYLIST_LIFELONG_SUCCESS', payload: res.data.playlists })

                }
                else {
                    dispatch({ type: 'POPULATE_PLAYLIST_GENARAL_SUCCESS', payload: res.data.playlists })

                }
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'POPULATE_PLAYLIST_FAILED', payload: err })
        })
}

export const createPlayList = (token, data, cb) => (dispatch) => {

    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
    console.log("create play", data.category_id)
    if (data.category_id == 1) {
        dispatch({ type: 'CREATE_PLAYLIST_SCHOOL_REQUEST' })
    } else if (data.category_id == 2) {
        dispatch({ type: 'CREATE_PLAYLIST_COLLEGE_REQUEST' })
    } else if (data.category_id == 3) {
        dispatch({ type: 'CREATE_PLAYLIST_LIFELONG_REQUEST' })
    }
    else {
        dispatch({ type: 'CREATE_PLAYLIST_GENARAL_REQUEST' })
    }
    // dispatch({ type: 'CREATE_PLAYLIST_REQUEST' })
    axios.post(`${development.baseUrl}createPlayList`, data, { headers: head })
        .then(res => {
            if (res.data.result) {

                if (data.category_id == 1) {
                    dispatch({ type: 'CREATE_PLAYLIST_SCHOOL_SUCCESS', payload: res.data.subjects[0] })
                } else if (data.category_id == 2) {
                    dispatch({ type: 'CREATE_PLAYLIST_COLLEGE_SUCCESS', payload: res.data.subjects[0] })
                } else if (data.category_id == 3) {
                    dispatch({ type: 'CREATE_PLAYLIST_LIFELONG_SUCCESS', payload: res.data.subjects[0] })
                }
                else {
                    dispatch({ type: 'CREATE_PLAYLIST_GENARAL_SUCCESS', payload: res.data.subjects[0] })
                }
                //dispatch({ type: 'CREATE_PLAYLIST_SUCCESS', payload: res.data.subjects[0] })
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Create playlist successfully',

                })
                cb()
            }
            //console.log("grade", res.data)
        })
        .catch(err => {
            dispatch({ type: 'CREATE_PLAYLIST_FAILED', payload: err })
        })
}
