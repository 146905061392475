import React, { useState } from 'react'
import {
    Row, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon,
    CustomInput,
    Card, CardBody, FormText, Label, ButtonToggle,
    CardTitle
} from 'reactstrap';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Switchy from "react-switch";
import cx from 'classnames';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import DateFnsUtils from '@date-io/date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';
const Index = (props) => {

    const { handleChadeCard, createTimeSlot, createCard, tempCart, loader, specialClassDay, changeSpecialClass, specialClass, mode, setStartTime } = props;
    const sub = () => {
        document.getElementById("edit_form").reset();

        changeSpecialClass()
    }

    const [Default, setDefault] = useState(null)        // needed because material ui time picker's value field must be bounded to a state that set on handle change
    const [DefaultToTime, setDefaultToTime] = useState(null)

    return (
        <Card className="main-card mb-3">
            <CardBody>
                <CardTitle>

                    TIMESLOT DETAILS
                    {mode === 'edit' ?
                        <div style={{ float: 'right' }} >
                            <Row>
                                <p style={{ paddingRight: '10px' }}>Special Class </p>
                                <CustomInput type="checkbox" id="exampleCustomCheckbox"
                                    checked={specialClass}
                                    onChange={sub}
                                />




                            </Row>


                        </div> : null
                    }

                </CardTitle>
                <Form className="mt-4" id="edit_form">
                    <FormGroup row>

                        <Col sm={3}>
                            <Label for="exampleNameF">
                                <span className="text-danger">*</span>
                                {' '}Day
                                                    </Label>
                            <Input
                                type="select"
                                value={tempCart ? tempCart.day : ''}
                                id='day'
                                name='day'
                                onChange={handleChadeCard}

                            // value={this.state.loaderType}
                            >

                                {specialClass ?
                                    specialClassDay ?

                                        <>
                                            <option value={""}>{""}</option>
                                            <option value={
                                                specialClassDay === 'Sunday' ? "sun" :
                                                    specialClassDay === 'Monday' ? "mon" :
                                                        specialClassDay === 'Tuesday' ? "tue" :
                                                            specialClassDay === 'Wednesday' ? "wed" :
                                                                specialClassDay === 'Thursday' ? "thu" :
                                                                    specialClassDay === 'Friday' ? "fri" :
                                                                        specialClassDay === 'Saturday' ? "sat" :
                                                                            null}
                                            >{specialClassDay}</option>
                                        </>
                                        : null
                                    :
                                    createTimeSlot ?
                                        createTimeSlot[0].days.map(dayss => {
                                            return (


                                                <option
                                                    // selected={dayss.day === null ? 'selected' : null} 
                                                    value={dayss.val}>{dayss.day}</option>
                                                //<MenuItem value={dayss.day} key={dayss.day}>{dayss.day}</MenuItem>

                                            )
                                        }) : []
                                }

                            </Input>

                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    {' '}From Time
                                                    </Label>

                                {/* <Input
                                    onChange={handleChadeCard}
                                    value={tempCart ? tempCart.class_from_time : ''}
                                    type="time"
                                    name="time"
                                    name="class_from_time"
                                    id="class_from_time"

                                    placeholder="time placeholder"
                                /> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        keyboardIcon={<AccessTimeIcon />}
                                        size="small"
                                        inputVariant="outlined"
                                        invalidLabel={false}
                                        // margin="normal"
                                        id="time-picker"
                                        // label="From Time"
                                        name="class_from_time"
                                        //value={"Sat Apr 24 2021 01:00:31 GMT+0530 (India Standard Time)"}
                                        value={Default}
                                        onChange={(e) => { setDefault(e); handleChadeCard(e, "class_from_time") }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label for="exampleNameF">
                                    <span className="text-danger">*</span>
                                    {' '}To Time
                                                    </Label>
                                {/* <Input

                                    id='class_to_time'
                                    onChange={handleChadeCard}
                                    value={tempCart ? tempCart.class_to_time : ''}

                                    name="class_to_time"
                                    type="time"
                                    name="time"

                                    placeholder="time placeholder"
                                /> */}
                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    keyboardIcon={<AccessTimeIcon />}
                                    fullWidth={false}
                                    size="small"
                                    inputVariant="outlined"
                                    // margin="normal"
                                    id="time-picker"
                                    // label="To Time"
                                    value={DefaultToTime}
                                    name="class_to_time"
                                    onChange={(e) => {handleChadeCard(e,"class_to_time") ; setDefaultToTime(e) }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                </MuiPickersUtilsProvider>
                            </FormGroup>
                        </Col>
                    <Col sm={3}>
                        <FormGroup>
                            <Label for="exampleTime" style={{ visibility: 'hidden' }}>bt</Label><br />
                            {/* <Button style={{ width: '-webkit-fill-available' }} color="danger" onClick={() => createCard()}>Create time slot</Button> */}


                            <LaddaButton className="mb-2 mr-2 btn btn-danger"
                                loading={loader}
                                style={{ width: '-webkit-fill-available' }}
                                onClick={() => {createCard(); setDefault(null); setDefaultToTime(null);}}
                                type="button"
                                data-style={ZOOM_IN}

                            >
                                Create time slot
                                            </LaddaButton>
                            {/* <Input
                                    type="button"
                                    name="time"
                                    id="exampleTime"
                                    color="primary"
                                //placeholder="time placeholder"
                                /> */}
                        </FormGroup>
                    </Col>



                    </FormGroup>
                </Form>
            </CardBody>
        </Card >
    )
}

export default Index
