import React, { useState } from 'react'
// import {  useSelector } from 'react-redux'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Row, Col, Card } from 'reactstrap'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
// import { updatePlaylist } from '../../../redux/Actions/playlist'
import EditIcon from '@material-ui/icons/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import './thumbStyle.scss'
const Index = (props) => {

    const { title, count, subject, grade, to, type,
        subject_id, DeletePlaylists, category_id,
        openEdit, index } = props
    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    // const storeData = useSelector(({ playlist }) => playlist)

    // const dispatch = useDispatch();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    // const [editMode, setEditMode] = useState(false);
    // const [playlistName, setPlaylistName] = useState(title)
    // const handleChange = (e, playlist_id) => {
    //     // alert(playlist_id)
    //     const { value } = e.target;
    //     // alert(value)
    //     setPlaylistName(value)

    //     // dispatch({ type: 'CHANGE_PLAYLIST_TITLE', payload: { title: value, id: playlist_id :} })

    // }
    // const [updating, setUpdating] = useState([])


    // const EditPlaylists = (playlist_id, playlist_name) => {
    //     setUpdating([...updating, playlist_id])

    //     // alert(playlist_name)
    //     dispatch(updatePlaylist(tokenData.token, playlist_id, playlist_name, updated))
    // }
    // const updated = (playlist_id) => {
    //     const removed = updating.filter(id => id != playlist_id)
    //     setEditMode(!editMode)
    //     setUpdating(removed)
    // }
    // const cancelEdit = (playlist_id) => {
    //     const removed = updating.filter(id => id != playlist_id)
    //     setEditMode(false)
    //     setUpdating(removed)


    // }
    // const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <Card className="jms-thumb-card">

            <Row>
                <Col md={4} className="jms-thumb-icon">

                    <PlayCircleOutlineIcon style={{ fontSize: '56px' }} />
                    <p className="jms-video-count" >{count} VIDEOS </p>
                </Col>
                <Col md={8} className="jms-thumb-details">
                    <Link to={`/dashboard/playlist/${to}/${subject_id}`}>
                        <div className="jms-thumb-link">
                            <p className="jms-thumb-title" id={`jms-tooltip-${index}`}>{title} </p>
                            {/* {
                                editMode ? <><Input type="text" name="playlist_name" value={title} onChange={(e) => handleChange(e, to)} style={{ marginBottom: '13px' }} />
                                    <Row>
                                        <Col md={6}>
                                            <Button onClick={() => cancelEdit(to)} className="jms-playlist-save">Cancel</Button>

                                        </Col>
                                        <Col md={6}>
                                            <Button onClick={() => EditPlaylists(to)} color="info" className="jms-playlist-save">{updating.includes(to) ? 'Updating...' : 'Update'}</Button>

                                        </Col>
                                    </Row>
                                </>
                                    : <p className="jms-thumb-title" id={`jms-tooltip-${index}`}>{title} </p>
                            } */}


                            {/* <Tooltip
                                placement={'bottom'}
                                isOpen={tooltipOpen}
                                target={`jms-tooltip-${index}`}
                                toggle={toggle}
                            >
                                {title}
                            </Tooltip> */}
                            {
                                type === "genaral" ?
                                    null :
                                    <>
                                        <p className="jms-thumb-sub-details"><FontAwesomeIcon color="#329481" icon={faBookOpen} /> &nbsp;&nbsp;{subject}</p>
                                        <p className="jms-thumb-sub-details"> <FontAwesomeIcon color="#329481" icon={faGraduationCap} /> &nbsp;&nbsp;{grade} </p>
                                    </>
                            }

                        </div>
                    </Link>

                    <div className="jms-thumb-action">
                        <div className="jms-thumb-action-edit" onClick={() => openEdit(title, to, category_id)}>  <EditIcon style={{ fontSize: '20px' }} />Edit</div>
                        <div className="jms-thumb-action-delete" onClick={() => DeletePlaylists(to, category_id)} ><DeleteOutlineIcon style={{ fontSize: '20px' }} />Delete</div>

                    </div>
                </Col>
            </Row>
            {/* <Row>
                <Col md={12} style={{ padding: '5px 30px' }}>
                    <Button type={'button'} style={{ width: '100%' }} >Show Videos</Button>

                </Col>
            </Row> */}
        </Card>



    )
}

export default Index
