import React, { Fragment, useState, useEffect } from 'react'
// import { addStudentToClassroom } from '../../../redux/Actions/homeTeacher'
import { useDispatch, useSelector } from 'react-redux'
import serialize from 'form-serialize'
import {
    FormFeedback, TabPane, Nav, NavItem, NavLink,
    Row, Col,
    Card, CardBody, CardHeader,
    CardTitle, Button, ButtonGroup, Container,
    FormGroup, Label, Form, CustomInput,
    Input,
} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Student = (props) => {
    const { handleChange, errors } = props;
    return (
        <Fragment>
            <Card className="main-card " style={{ padding: '30px', background: '#eff1f5' }}>
                <CardTitle>Student Form</CardTitle>
                <Row>
                    <Col md="6" xs="6">
                        <input type="hidden" name="user_id" value="" />
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">First Name</Label>
                            <Input type="text" name="first_name" id="firstname"
                                placeholder="First name"
                                // required
                                onChange={(e) => handleChange(e)}
                                invalid={errors.first_name}
                            />
                            <FormFeedback >{errors.first_name}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md="6" xs="6">
                        <FormGroup className="p-10">
                            <Label for="examplePassword">Last Name</Label>
                            <Input type="text" name="last_name" id="lastname"
                                placeholder="Last Name"
                                // required
                                onChange={(e) => handleChange(e)}
                                invalid={errors.last_name}

                            />
                            <FormFeedback >{errors.last_name}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="examplePassword">Phone</Label>
                            <Input type="text" name="enterprise_mobile" id="phone"
                                placeholder="phone"
                                //  required
                                invalid={errors.enterprise_mobile}

                                onChange={(e) => handleChange(e)}
                            />
                            <FormFeedback >{errors.enterprise_mobile}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">Email</Label>
                            <Input type="text" name="enterprise_email" id="email"
                                placeholder="Email"
                                //  required
                                invalid={errors.enterprise_email}

                                onChange={(e) => handleChange(e)}

                            />
                            <FormFeedback >{errors.enterprise_email}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="exampleEmail">Password</Label>
                            <Input type="password" name="password" id="password"
                                placeholder="Password"
                                //    required
                                onChange={(e) => handleChange(e)}
                                invalid={errors.password}

                            />
                            <FormFeedback >{errors.password}</FormFeedback>

                        </FormGroup>
                    </Col>
                    <Col md="12" xs="12">
                        <FormGroup className="p-10">
                            <Label for="examplePassword">Roll number</Label>
                            <Input type="text" name="enrollment_no" id="enrollment_no"
                                placeholder="Roll number"
                                //   required
                                onChange={(e) => handleChange(e)}
                                invalid={errors.enrollment_no}

                            />
                            <FormFeedback >{errors.enrollment_no}</FormFeedback>

                        </FormGroup>
                    </Col>
                </Row>
            </Card>
        </Fragment>

    )
}

export default Student;
