
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useParams } from "react-router";
import Loader from 'react-loaders'
import Swal from 'sweetalert2'
import { validateStartClass } from '../../../redux/Actions/homeTeacher'
// import SweetAlert from 'sweetalert-react';
import BrowserNotSupport from '../Utility/browserNotSupport'

import socketIOClient from "socket.io-client"
import {
    isFirefox,
    isIE,
    isEdge,
    isChrome

} from "react-device-detect";
import Modals from '../../common/Modal'

import { Prompt } from 'react-router'
import Pusher from "pusher-js";
import { development } from '../../../config.json'
// import {
//     BrowserView,
//     MobileView,
//     isBrowser,
//     isMobile
// } from "react-device-detect";

const ENDPOINT = `${development.socket_io_url}`;


// import Echo from 'laravel-echo';

// const options = {

//     broadcaster: 'pusher',
//     key: "key",
//     //  wsHost: window.location.hostname,
//     wsHost: development.socketUrl,
//     // wsHost: "backend.mymots.com",

//     wsPort: 6001,
//     wssPort: 6001,
//     disableStats: true,
//     enabledTransports: ['ws', 'wss']

//     // broadcaster: 'pusher',
//     // key: 'key',

//     // cluster: 'mt1',    
//     //authEndpoint is your apiUrl + /broadcasting/auth
//     // authEndpoint: "http://localhost:8000/broadcasting/auth",
//     // host: "http://localhost:6001",
//     // wsPort: 6001,
//     // wssPort: 6001,

//     // As I'm using JWT tokens, I need to manually set up the headers.
//     // As I'm using JWT tokens, I need to manually set up the headers.
//     // auth: {
//     //   headers: {
//     //     Authorization: `Bearer ${tokenData.token}`,
//     //     Accept: 'application/json',
//     //   },
//     // },
// };


const Index = (props) => {
    var api;
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    console.log("tokk", tokenData)
    const { week_id, class_id } = useParams();
    const [visible, setVisible] = useState();
    // const [sweet, setSweet] = useState();
    const dispatch = useDispatch();
    const [video, setVideo] = useState();
    const [video2, setVideo2] = useState();
    const handleCloseSuccesstModal2 = () => {
        // setSweet(false)
        setVideo(true)
        setVideo2('none')
        props.history.push('/dashboard/')

    }
    const [jitsiObj, setjitsiObj] = useState()
    const [prompt, setPrompt] = useState(false);
    const stStore = useSelector(({ homeStudent }) => homeStudent)
    const [getDataCount, setGetDataCount] = useState(false)
    const [unSupportBrowser, setUnSupportBrowser] = useState(false)

    const browserNotSupport = () => {
        setUnSupportBrowser(true)
    }
    const closeUnsupportPopup = () => {
        setUnSupportBrowser(false)
        props.history.push('/dashboard/overview')

    }
    useEffect(() => {

        const socket = socketIOClient(ENDPOINT);
        if (!getDataCount) {

            if (isFirefox || isIE || isEdge) {
                browserNotSupport()
            } else {
                dispatch(validateStartClass(cb, tokenData.token, week_id, class_id))

            }

        }
        setGetDataCount(true)

        if (stStore.firstBroadcasting) {
            toggleMute()
        }

        var hangupChannel = class_id + week_id

        socket.on(hangupChannel, function (message) {
            console.log(message)
            if (api !== undefined)
                api.executeCommand('hangup');

        });

        // const [mute, setMute] = useState("mute")
        // var mute = "mute"
        console.warn("render_checking!!!!!!!")
        // const echo = new Echo(options);
        // echo.channel("newEvent").listen('MessagePushed', (e) => {


        //     //let mutee=mute
        //     // if (typeof (jitsiObj) !== 'undefined') {
        //     //     console.error("popop", jitsiObj.getMyId());
        //     //     console.log("e.message.mute_status", e.message)
        //     //     console.log('var mute', mute)

        //     //     const id = jitsiObj.getMyId();
        //     //     if (id == e.message.student_id)
        //     //         if (e.message.mute_status !== mute) {
        //     //             debugger
        //     //             mute = e.message.mute_status
        //     //             jitsiObj.executeCommand('toggleAudio');
        //     //             console.log("e.message", e.message)

        //     //         }

        //     // }

        //     if (e.message.mute_status !== mute) {
        //         mute = e.message.mute_status

        //         console.log("e.message---index", e.message)
        //     }

        // });

        return () => {
            setGetDataCount(false)
            dispatch({ type: 'CLASS_LEAVE_REQUEST' })

        }


    }, [stStore]);

    const toggleMute = () => {
        console.log("mutee tog")
        if (typeof (jitsiObj) !== 'undefined') {
            if (stStore.sessionStudentId === jitsiObj.getMyId()) {
                jitsiObj.executeCommand('toggleAudio');
                console.log("toggleAudio")
            }


        }


        // jitsiObj.executeCommand('toggleAudio');
    }
    const cb = (res, ress) => {
        getData(week_id, class_id)

        //alert(res)
        // if (!res.result) {
        //     Swal.fire({
        //         icon: 'error',
        //         title: '',
        //         text: res.message,
        //         confirmButtonText: 'Okay',
        //     }).then((result) => {

        //         if (result.value) {
        //             handleCloseSuccesstModal2()

        //         }




        //     })

        // }
    }

    const getData = (week_id, class_id) => {
        //const  {newUser_join} = this.newUser_join;

        var tokenData = JSON.parse(localStorage.getItem("authDetails"));

        var promise = new Promise(function (resolve, reject) {

            const domain = development.videoEngine2;
            const users = [];
            const options = {
                roomName: 'test',
                width: 900,
                height: 500,
                parentNode: document.querySelector('#video'),
                larToken: `Bearer ${tokenData.token}`,
                weekly_class_id: week_id,
                class_id: class_id
            };

            // var element = document.getElementById('video');
            // element.addEventListener('DOMSubtreeModified', myFunction);

            // function myFunction(e) {
            //     setTimeout(function () {
            //         setVisible('none');
            //     }, 2200);

            // }

            // 
            let JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;
            api = new JitsiMeetExternalAPI(domain, options);
            setjitsiObj(api)
            console.error("kokokoko" + api);
            //   console.error("hhhhh"+document.getElementById("yy").innerHTML);
            //      const elem = document.querySelector("#video")
            if (api) {
                resolve();
            } else {
                reject();
            }
            setVideo('none')
        });

        promise.
            then(function () {
                setTimeout(function () {
                    api.executeCommand('displayName', tokenData.name);

                }, 5000);


            }).
            catch(function () {
                console.log('Some error has occured');
            });

        //  const displayName = api.getDisplayName(participantId);
        //   alert(displayName);
        // var that = this;
        // //api.executeCommand('displayName', sessionStorage.getItem('name'))
        api.addEventListener("participantJoined", function (response) {
            newUser_join(response.displayName, response.id)
        })


        api.addEventListener("muteParticipant", function (response) {
            console.log("mute id : " + response);
            const id = api.getMyId();
            if (id == response)
                api.executeCommand('toggleAudio');
            console.log('my id ......... ' + id);

        })

        api.addEventListener("tileViewChanged", function (response) {
            console.log("video load changed: " + response);
            setVisible('none');
            setPrompt(true)


        })
        api.addEventListener("readyToClose", function (response) {
            console.log("readyToClose: " + response);
            // setSweet(true);
            setPrompt(false)

            Swal.fire({
                icon: 'error',
                title: '',
                text: 'Video Session Ended',

            }).then((result) => {
                handleCloseSuccesstModal2()


            })
            setTimeout(function () {
                handleCloseSuccesstModal2()


            }, 3000);

        })

        api.addEventListener("participantLeft", function (response) {
            console.log("left id" + response.id);
            console.log("left id" + response.id);
            dispatch({ type: 'CLASS_LEAVE_REQUEST' })

            // const axios = require('axios');
            //     const options122 = {
            //         headers: {'Accept': 'application/json'},

            //       };
            //     axios.post('https://srj.mojgenie.com/api/leaveClassRoom', {
            //         video_session_id: response.id,  
            //         "from":"browser"  ,
            //         "user":"student"                      
            //       },options122)
            //       .then((response) => {
            //         console.log('response left '+response.data);
            //     }, (error) => {
            //         console.log('axios '+ error);
            //       });
        })
    }
    // const handleCloseSuccesstModal = () => {
    //     setSweet(false)
    //     props.history.push('/');
    // }

    // const options = {

    //     broadcaster: 'pusher',
    //     key: "key",
    //     //  wsHost: window.location.hostname,
    //     wsHost: "srjtest.mojgenie.com",
    //     wsPort: 6001,
    //     wssPort: 6001,
    //     disableStats: true,
    //     enabledTransports: ['ws', 'wss']

    //     // broadcaster: 'pusher',
    //     // key: 'key',
    //     // cluster: 'mt1',    
    //     //authEndpoint is your apiUrl + /broadcasting/auth
    //     // authEndpoint: "http://localhost:8000/broadcasting/auth",
    //     // host: "http://localhost:6001",
    //     // wsPort: 6001,
    //     // wssPort: 6001,

    //     // As I'm using JWT tokens, I need to manually set up the headers.
    //     // As I'm using JWT tokens, I need to manually set up the headers.
    //     // auth: {
    //     //   headers: {
    //     //     Authorization: `Bearer ${tokenData.token}`,
    //     //     Accept: 'application/json',
    //     //   },
    //     // },
    // };


    // // const [mute, setMute] = useState("mute")
    // var mute = "mute"
    // console.warn("render_checking!!!!!!!")

    // debugger
    // const echo = new Echo(options);
    // echo.channel("newEvent").listen('MessagePushed', (e) => {
    //     debugger

    //     //let mutee=mute
    //     // if (typeof (jitsiObj) !== 'undefined') {
    //     //     console.error("popop", jitsiObj.getMyId());
    //     //     console.log("e.message.mute_status", e.message)
    //     //     console.log('var mute', mute)

    //     //     const id = jitsiObj.getMyId();
    //     //     if (id == e.message.student_id)
    //     //         if (e.message.mute_status !== mute) {
    //     //             debugger
    //     //             mute = e.message.mute_status
    //     //             jitsiObj.executeCommand('toggleAudio');
    //     //             console.log("e.message", e.message)

    //     //         }

    //     // }





    //     if (e.message.mute_status !== mute) {
    //         debugger
    //         mute = e.message.mute_status
    //         //jitsiObj.executeCommand('toggleAudio');
    //         console.log("e.message", e.message)

    //     }



    // });

    // const [state, setState] = useState({
    //     // connected_users: ['Resolved',],
    //     event_logs: ['shanib', 'anil'],
    // });
    const newUser_join = (name, id) => {
        console.log("in new user")
        console.log(name)

        // const newUser = name;
        // const user_id = id
        // const isOnTheList = connected_users.includes(newUser)

        // if (isOnTheList) {
        //     return;
        // }
        // else {
        //     newUser !== "" && setState({ connected_users: [...state.connected_users, newUser] })
        // }

        // newUser !== "" && setState({ event_logs: [...state.event_logs, {name:newUser,id:user_id}] })



    }
    // const user_Left = (data) => {
    //     // console.log("in new user")
    //     // console.log(data)
    //     const { connected_users } = state;
    //     const newUser = data;
    //     const isOnTheList = connected_users.includes(newUser)

    //     if (isOnTheList) {
    //         return;
    //     }
    //     else {
    //         newUser !== "" && setState({ connected_users: [...state.connected_users, newUser] })
    //     }
    //     newUser !== "" && setState({ event_logs: [...state.event_logs, newUser] })
    // }

    return (



        <div>
            <Prompt
                when={prompt && stStore.leaveStatus}
                message=' are you sure you want to leave?'
            />

            {/* <MobileView>
                <div id="video" style={{ display: video2 }}
                className="jms-video-mobile-view"
                >
                    <Loader style={{ display: visible, margin: 'auto', padding: '180px 0px 0px 450px' }} type="ball-scale-multiple" />

                    <SweetAlert
                        title="Video Session Ended"
                        confirmButtonColor=""
                        show={sweet}
                        text="Video Has Stoped"
                        type="warning"
                        onConfirm={() => handleCloseSuccesstModal2()} />
                </div>
            </MobileView> */}


            <div id="video" style={{ display: video2 }}>

                <Loader style={{ display: visible, margin: 'auto', padding: '180px 0px 0px 450px' }} type="ball-scale-multiple" />

                {/* <SweetAlert
                    title="Video Session Ended"
                    confirmButtonColor=""
                    show={sweet}
                    text="Video Has Stoped"
                    type="warning"
                    onConfirm={() => handleCloseSuccesstModal2()} /> */}
            </div >
            <div height="500px" style={{ display: video, margin: 'auto', padding: '180px 0px 0px 450px' }} >
                <h3>
                    Your Session Has Ended...
               </h3>
            </div>
            <Modals
                open={unSupportBrowser}
                handleClose={closeUnsupportPopup}
                title={`Warning`}
            >
                <BrowserNotSupport />

            </Modals>
        </div>

    )
}

export default Index





