import React, { useState, useEffect, useRef } from 'react'
import { 
    Row, Col, 
    // Form, FormGroup, Input, InputGroupAddon,
    TabContent, TabPane, 
    Nav, NavItem, NavLink,Badge,Card,CardHeader,
    CardBody
    // FormText, Label, 
    // CardTitle, CardFooter,
    // Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap';
import ViewStudent from './viewStudent'
import Swal from 'sweetalert2'

import TimeShedule from './viewTime'
// import Loader from 'react-loader'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
import AssignStudents from './addStudentToClass'
import Modals from '../../common/Modal'
import Table from "./table";
import SweetAlert from 'sweetalert-react';
// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { deleteClasses, validateClassTiming, editClass, populateGrade, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, populateAllEnterpriseUserClass, getTeacherAssignedGroups } from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid';
// import "~react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from 'react-image-gallery';
import classnames from 'classnames';
import StudentGrouping from "./studentGrouping"
import { setNewActionData} from '../../../redux/Actions/new-action'

import { populateGroup } from '../../../redux/Actions/grouping'

// import DatePicker from 'react-datepicker';
// import CreateTimeSlot from './createTimeSlot'
// import TimeSlot from './timeSlotCard'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {
//     faClock, faWindowClose, faCalendarAlt, faBusinessTime,
//     faCog
// } from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';
// const images = [
//     {
//         original: 'https://picsum.photos/id/1018/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1018/250/150/',
//     },
//     {
//         original: 'https://picsum.photos/id/1015/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1015/250/150/',
//     },
//     {
//         original: 'https://picsum.photos/id/1019/1000/600/',
//         thumbnail: 'https://picsum.photos/id/1019/250/150/',
//     },
// ];

const Index = () => {

    var columns
    var action
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const st = useSelector(state => state)

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const groupStore = useSelector(({ grouping }) => grouping)
    // console.log("tStore-------1", st)
    useEffect(() => {
        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }
        dispatch(populateGrade())
        dispatch(populateGroup(tokenData.token))
        if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
            dispatch(populateAllEnterpriseUserClass(tokenData.token))


        } else {
            dispatch(populateAllUserClass(tokenData.token))

        }

    }, []);


    if (  tokenData.role == 'staff' || tokenData.role == 'principal') {
       
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
            { title: 'Teacher ', field: 'teacher_name', headerStyle: { fontWeight: '600' } },
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]
        action = 'assign_student'

    }
    else if(!tokenData.enterpriseUser && tokenData.role === 'teacher'){
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
         
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]
        action = 'assign_student'
    }
    else if (tokenData.enterpriseUser && tokenData.role === 'teacher') {
        columns = [
            { title: '#', field: 'tableData.id' },
            {
                title: 'Class ', field: 'class_name',

            },
            { title: ' Grade', field: 'grade_name' },
            { title: 'Subject', field: 'subject_name' },
            { title: 'From Date', field: 'class_from_date' },
            { title: 'To Date', field: 'class_to_date' },
            // {
            //   title: 'Open', field: 'class_id',
            //   render: rowData => <button onClick={(event, rowData) => handleOpenEditModal(rowData.class_id)}>{rowData.class_id}</button>

            // },

        ]
        action = 'show_class'
    }
    else {
        columns = [
            {
                title: '#', field: 'tableData.id',

            },

            { title: 'Class ', field: 'class_name' },
             { title: ' Teacher', field: 'teacher_name' },
            { title: ' Grade', field: 'grade_name' },
            { title: 'Subject', field: 'subject_name' },
            {
                title: 'From - To Date', field: 'class_from_date',
                render: rowData => <><Badge color="warning" pill>{`${rowData.class_from_date} to ${rowData.class_to_date}`}</Badge></>

            },
            // { title: 'To Date', field: 'class_to_date' },

            // {
            //   title: 'Open', field: 'class_id',
            //   render: rowData => <button onClick={(event, rowData) => handleOpenEditModal(rowData.class_id)}>{rowData.class_id}</button>

            // },

        ]
        action = 'student_class'
    }


    const [createTimeSlot, setTimeSlot] = useState([
        {
            key: uuid(),
            days: [
                { day: null, val: null },
                { day: 'Sunday', val: 'sun' },
                { day: 'Monday', val: 'mon' },
                { day: 'Tuesday', val: 'tue' },
                { day: 'Wednesday', val: 'wed' },
                { day: 'Thursday', val: 'thu' },
                { day: 'Friday', val: 'fri' },
                { day: 'Saturday', val: 'sat' }]
        }

    ])
    const [tempCart, setTempCard] = useState()
    const handleChadeCard = (e) => {
        const { id, value } = e.target;
        setTempCard({ ...tempCart, key: uuid(), [id]: value })
        setValidayeData({ ...validateData, [id]: value })
    }
    // console.log("day==========", tempCart)
    const [realCard, setRealCard] = useState([])
    //const textInput = React.createRef()

    const removeCard = (id) => {
        //alert(id)
        const updatedCard = realCard.filter(card => !id.includes(card.key))
        setRealCard(updatedCard)

    }


    const [validateData, setValidayeData] = useState({
        class_from_date: null,
        class_to_date: null
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setValidayeData({ ...validateData, [name]: value })

    }

    const [slotLoader, setSlotLoader] = useState(false)
    const loaderFalse = (res) => {
        setSlotLoader(false)
        // alert(res)
        Swal.fire({
            icon: 'warning',
            // title: 'Required',
            text: res

        })

    }
    const validateCard = () => {
        setSlotLoader(true)
        dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
    }
    const [dateDisablity, setDateDisabliyt] = useState(false)
    const createCard = () => {
        setDateDisabliyt(true)
        // console.log("valid")
        let err;

        if (realCard.length === 0) {
            setRealCard([...realCard, tempCart])
            setTempCard()
            setSlotLoader(false)
        }
        else {
            //let err;
            realCard.forEach(item => {
                if (tempCart.day == item.day) {
                    console.log("hii")
                    if (tempCart.class_from_time !== item.class_from_time && tempCart.class_to_time !== item.class_to_time) {
                        const fr = moment(tempCart.class_from_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const to = moment(tempCart.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const rfr = moment(item.class_from_time, 'hh:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));
                        const rto = moment(item.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));

                        if (fr || to || rfr || rto) {
                            err = "from or to slot already taken";
                        }
                    }
                    // else if (tempCart.from == item.from || tempCart.to == item.to) {
                    //   err = "from time t0 time same so notpossible";
                    // }
                    else {
                        err = "from time t0 time same so notpossible";
                    }
                }
            })
            if (!err) {

                setRealCard([...realCard, tempCart])
                setTempCard()
                setSlotLoader(false)


            }
            else {
                // alert(err)
                Swal.fire({
                    icon: 'warning',
                    // title: 'Required',
                    text: err

                })
                setSlotLoader(false)

            }
        }

        // console.log("tmp=============", tempCart)


    }

    // console.log("store", tStore)

    const [inputField, setInputField] = useState({})

    const handleChangeGrade = e => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value })
        dispatch(populateSubject(tokenData.token, value))
    };
    const handleEdit = (classId, teacherId) => {
        //alert(classId)

        history.push(`/dashboard/editClass/${classId}/${teacherId}`)
    }


    const [openModal, setOpenModal] = useState(false)
    const hadleOpenModal = (id) => {
        //alert(id)
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const [openStdModal, setOpenStdModal] = useState(false)
    const [classId, setClassId] = useState()
    const [timing, setTiming] = useState()
    const hadleOpenStudentModal = (class_id, grade_id, teacher_id, timing) => {

        setClassId()
        setClassId(class_id);
        setTiming(timing)
        if (tokenData.enterpriseUser) {

            dispatch(getTeacherAssignedGroups(tokenData.token, class_id, teacher_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id, getStudentCount))

        }
        dispatch(setNewActionData('checkedArray',[]))
        dispatch(setNewActionData('sourceGroupStudents',[]))


        setOpenStdModal(true)
        console.log("timing", timing)
    }
    const [selectedStudents, setSelectedStudents] = useState([])
    const getStudentCount = (studentList) => {
        // console.log("studentList", studentList)
        setSelectedStudents([])
        studentList.map(row => {
            if (row.selected === true) {
                return (
                    setSelectedStudents([...selectedStudents, row.user_id])
                )
            }
        })

    }
    const handleCloseStudentModal = () => {
        setOpenStdModal(false)
        setClassId('')
        setTiming('')
    }
    const handleCloseStudentModal2 = () => {
        dispatch({ type: 'RESET_ASSIGN_STUDENT_CLASS' })
        dispatch({ type: 'RESET_GROUP_LIST' })
        dispatch(setNewActionData('checkedArray',[]))
        dispatch(setNewActionData('sourceGroupStudents',[]))
        setTab("1")
        setOpenStdModal(false)
        handleOpenSuccessModal()
    }
    const [successModal, setSuccessModal] = useState();
    const handleOpenSuccessModal = () => {
        setSuccessModal(true)

    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        console.log("close-----", tStore.classes)
        //  console.log("close-----2", tStore.classes)
        // if (tStore.classes) {
        //     if (tStore.classes.length === 0)
        // window.location.reload(false);
        // }


    }
    const [studentListModal, setStudentListModal] = useState();
    const handleOpenStudentListModal = (class_id, grade_id) => {
        if (tokenData.enterpriseUser) {
            dispatch(populateRegisteredStudents(tokenData.token, null, class_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        }
        // dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        setStudentListModal(true)
    }
    const handleCloseStudentListModal = () => {
        setStudentListModal(false)
    }
    const deleteClass = (class_id) => {
        dispatch(deleteClasses(tokenData.token, class_id))
    }




    const [editForm, setEditForm] = useState()
    const [editModal, setEditModal] = useState()
    const handleOpenEditModal = (rowData) => {
        //dispatch(editClass((tokenData.token, class_id)))
        //console.log("rowData", rowData)
        setEditForm(rowData);
        setEditModal(true)

    }

    const handleCloseEditModal = () => {
        setEditModal(false)
    }







    const submitForm = (e) => {
        e.preventDefault();
        const form = e.currentTarget
        let body = serialize(form, { hash: true, empty: true })


        dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm))

    }
    const resetForm = () => {
        document.getElementById("class-form").reset();
        setRealCard([])

    }



    const [tabval, setTab] = useState('1')

    const toggle = (tab) => {
        //dispatch({ type: 'RESET_SUBJECT' })
    
        if (tabval !== tab) {
            setTab(tab);
        }
     
    }



    return (<>

        <Row>
            <Col sm={12} md={12}>
                {/* <ImageGallery items={images} /> */}
                <Table
                    loader={tStore.allUserTableLoader}
                    actions={action}
                    data={(tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) ? tStore.enterpriseClasses : tStore.classes}
                    handleEdit={handleEdit}

                    columns={columns}
                    hadleOpenModal={hadleOpenModal}
                    hadleOpenStudentModal={hadleOpenStudentModal}
                    handleOpenStudentListModal={handleOpenStudentListModal}
                    handleOpenEditModal={handleOpenEditModal}
                    deleteClass={deleteClass}
                />
            </Col>
        </Row >
        <Modals
            open={openStdModal}
            handleClose={handleCloseStudentModal}
            title={tokenData.enterpriseUser ? 'Assign Group' : 'Assign Students'}

        >
             {/* <Card>
                <CardHeader>
                <Nav justified>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: tabval=== '1' })}
                                onClick={() => {
                                    toggle('1');
                                }}
                            >
                                Group
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink href="#"
                                className={classnames({ active: tabval === '2' })}
                                onClick={() => {
                                    toggle('2');
                                }}
                            >
                                Student
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody>
                    <TabContent activeTab={tabval}>
                        <TabPane tabId="1"> */}
                            <AssignStudents
                                classId={classId}
                                loader={tStore.specificGroupLoader}
                                data={tStore.specificStudent ? tStore.specificStudent : tStore.specificGroup}
                                timing={timing}
                                handleCloseStudentModal={handleCloseStudentModal2}
                                selectedStudents={selectedStudents}
                                setSelectedStudents={setSelectedStudents}
                            />
                        {/* </TabPane>
                        <TabPane tabId="2">
                            <StudentGrouping 
                                classId={classId}
                                timing={timing}
                                Grouplist={groupStore.groupList}
                                handleCloseStudentModal={handleCloseStudentModal2}
                            />
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card> */}
        </Modals>
        <Modals
            open={studentListModal}
            handleClose={handleCloseStudentListModal}
            title={`View student`}
        >
            <ViewStudent
                data={tStore.specificStudent}
                loader={tStore.specificStudentLoader}
            />
        </Modals>
        <Modals
            open={openModal}
            handleClose={handleCloseModal}

        >
            <TimeShedule
                data={tStore.specificClassTime}
                loader={tStore.specificClassTimeLoader}

            />
        </Modals>
        <SweetAlert
            title="Success"
            confirmButtonColor=""
            show={successModal}
            text="Completed successfully"
            type="success"
            onConfirm={() => handleCloseSuccesstModal()} />
    </>
    )
}

export default Index
