import { useHistory } from 'react-router-dom'
// import SweetAlert from 'sweetalert-react';
import StudentTable from './StudentList/table'
import { getStudentAssignmentRemark, submitAssignmnetMark, getAssignmnetMark, downloadStudentAssignments, populateStudentAssignment } from '../../redux/Actions/assignment'
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from 'react'
import Modals from '../common/Modal'
import {
  Row, Col, Input
} from 'reactstrap';
import { useParams } from "react-router";





//const formData = new FormData();
const StudentsTable = () => {
  const history = useHistory()
  useEffect(() => {
    // if (!localStorage.getItem('authDetails')) {
    //     history.push('/')
    // }
    dispatch(populateStudentAssignment(tokenData.token, assignment_id, class_id))


  }, [history]);

  const [inputField, setInputField] = useState({
    assignment_id: '',
    student_id: '',
    comment: '',
    mark: ''
  })


  const cb = (data, ids) => {
    console.log("dataaa----", data)
    setInputField({ ...inputField, comment: data.teacher_remark, mark: data.mark, assignment_id: ids.assignment_id, student_id: ids.student_id, grading_type: data.grading_type, total: data.total })
  }
  const { assignment_id, class_id } = useParams();

  // var mark = '';
  // var remark = '';
  // const [successModal, setSuccessModal] = useState(true);
  const [viewDisc, setViewDisc] = useState(false);
  const [viewAddMark, setViewAddMark] = useState(false);
  // const [assignmentId, setAssignmentId] = useState();
  // const [studentId, setStudentId] = useState();
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const assignment = useSelector(({ assignments }) => assignments)

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log("inputField..", inputField)
    //dispatch({ type: 'EDIT_MARKS_REMARKS', payload: e.target })
    setInputField({ ...inputField, [name]: value })
  }
  const handleCloseSuccesstModal = () => {
    setViewAddMark(false)
    setViewDisc(false)
    // localStorage.clear()
    // history.push('/')

  }
  // const onChangeMark = (e) => {
  //   mark = e.target.value
  //   formData.set('mark', e.target.value)
  //   formData.set('grade', e.target.value)
  //   console.log('bsbsz')
  // }
  // const onChangeRemark = (e) => {
  //   remark = e.target.value
  //   formData.set('remark', e.target.value)

  //   console.log('bsbsz')
  // }

  const columns2 = [
    { title: 'Name', field: 'first_name' },
    { title: ' LastName', field: 'last_name' },
    { title: ' status', field: 'status' },

  ]

  const downloadStudentAssignment = (rowData) => {
    if (rowData.status == 'submitted') {
      dispatch(downloadStudentAssignments(tokenData.token, rowData.assignment_id, rowData.class_id, rowData.student_id, viewDiscription));
    } else {
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Assignment not submited',

      })
    }
  }

  const hideStudentMark = () => {
    console.log('hide ')
    setViewAddMark(false);
  }
  const onSubmit = () => {
    // console.log('form ata ', formData)
    // dispatch(submitAssignmnetMark(tokenData.token, assignmentId, studentId, mark, remark, hideStudentMark));
    dispatch(submitAssignmnetMark(tokenData.token, inputField, hideStudentMark));

  }

  const addMarks = (rowData) => {
    // setStudentId(rowData.student_id)
    // setAssignmentId(rowData.assignment_id)
    //setInputField({ ...inputField, student_id: rowData.student_id, assignment_id: rowData.assignment_id })
    dispatch(getAssignmnetMark(tokenData.token, rowData.assignment_id, rowData.student_id, loadStudentMark, cb));


  }
  const loadStudentMark = () => {
    console.log('inside ', assignment.remarkNew)
    setViewAddMark(true);
  }
  console.log('pop ', assignment);
  console.log('inside ', assignment.remarkNew)


  const viewDiscription = (rowData) => {
    dispatch(getStudentAssignmentRemark(tokenData.token, rowData.assignment_id, rowData.student_id, viewRemark));


  }
  const viewRemark = () => {
    console.log("ccallbacklass leave rs---")
    setViewDisc(true);
  }

  console.log("inputField..", inputField)

  return (
    <div>
      <StudentTable
        actions={'dashboard'}
        columns={columns2}
        loader={assignment.loader}
        data={assignment.students ? assignment.students : []}
        addMarks={addMarks}
        downloadStudentAssignment={downloadStudentAssignment}
        viewDiscription={viewDiscription}

      />

      <Modals
        open={viewDisc}
        handleClose={handleCloseSuccesstModal}

      >
        <table >
          <tr>
            <th>
              Student Remark
    </th>
          </tr>
          <tr>
            <td>
              {assignment.remark}
            </td>
          </tr>
        </table>
      </Modals>

      <Modals
        open={viewAddMark}
        handleClose={handleCloseSuccesstModal}
        title={"Add Mark"}
      >

        <Row>
          <Col md={3} className="assign-form-grid">
            Grade/Marks
  </Col>
          <Col md={6} className="assign-form-grid">
            <Input type="text" id="grade" name="mark"
              value={inputField.mark}
              required onChange={handleChange} />
          </Col>
          <Col md={3} className="assign-form-grid"
            style={{ position: 'relative', height: '14px' }}
          >
            <div
              style={{ position: 'absolute', top: '50%', color: 'gray' }}
            ><h6><strong> {inputField.grading_type == "marks" ? ` / ${inputField.total}` : null}</strong></h6></div>
          </Col>
          <Col md={3} className="assign-form-grid">
            Remark
  </Col>
          <Col md={9} className="assign-form-grid">
            <textarea id="remark" name="comment" required rows="4" style={{ width: '-webkit-fill-available' }}
              value={inputField.comment}
              onChange={handleChange}
            />
          </Col>
          <Col md={12} className="assign-form-grid">
            <button type="submit" onClick={onSubmit} className="btn btn-success btn-block" >Submit</button>

          </Col>
        </Row>



      </Modals>
    </div>
  )
}

export default StudentsTable