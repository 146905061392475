import React, { useEffect, useState } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import CreateClass from "../Class/CreateClass/Home";
import ShowClass from "../Class/ShowClass";
import Students from "../Students";
import SpecialClass from "../Class/SpecialClass/";
import ShowAttendance from "../Attendance/ShowAttendance";
import ShowAttendanceDetails from "../Attendance/Details";
import AuthCheck from "../AuthCheck";
import EditClass from "../Class/EditClass";
import { useDispatch, useSelector } from "react-redux";
import Modals from "../common/Modal";
import Loader from "react-loader";
import MyAccount from "../MyAccount";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Updates from "./updates";
import Exam from "../Exam";
import Instructions from "../Exam/Instructions/instruction";
import Plans from "./plans";
import showGroup from "../Group/showGroup";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import ChangePassword from "../ChangePassword";
import Grouping from "../Group";
import PageTitle from "../../Layout/AppMain/PageTitle";
import ClassTeacher from "../Class/ClassRoom/teacher";
import ClassStudent from "../Class/ClassRoom/student";
import { development } from "../../config.json";
import Subject from "../Subjects";
// import Tabs, { TabPane } from 'rc-tabs';
// import TabContent from 'rc-tabs/lib/SwipeableTabContent';
// import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import Payment from "../Payment";
// Layout
import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter";
import Feedback from "../Feedback";
// Examples
// import HomeTeacher from './Examples/Variation1';
import HomeTeacher from "../Home/teacher";
import EditVideoClass from "../VideoSession/VideoClass/editVideoClass";
import HomeStudent from "../Home/student";
import HomePrincipal from "../Home/principal";
import Assignments from "../Assignment";
import Assignmentss from "../Assignment/Details";
import StudentAssignments from "../Assignment/StudentAssigment";
import SpecificAssignment from "../Assignment/Details/specificStudentAssignment";
import CreateVideoSession from "../VideoSession/CreateVideoSession";
import Recordings from "../Recordings";
import VideoClass from "../VideoSession/VideoClass";
import ListVideo from "../VideoSession/ListVideos";
import FormRegister from "../FormRegister";
import ShowTeacher from "../FormRegister/Teacher/ShowTeacher";
import ShowStudent from "../FormRegister/Student/ShowStudent";
import ShowStaff from "../FormRegister/Staff/ShowStaff";
// Theme Options
import Playlist from "../VideoSession/Playlist";
import PlaylistDetails from "../VideoSession/Playlist/UploadVideos";
import ThemeOptions from "../../Layout/ThemeOptions";
import { Switch, Route, Redirect } from "react-router-dom";
import ManageGroup from "../Group/ManageGroup";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import {
  emailSendForVerification,
  getNotification,
  getNotificationAlert,
  getTrialDays
} from "../../redux/Actions/homeTeacher";
import { logout } from "../../redux/Actions/auth";
import { useHistory, Link } from "react-router-dom";
import store from "../../redux/store";
import moment from "moment";
//exams components here
import ListExam from "../Exam/ListExams";
import AnswerSheet from "../Exam/AnswerSheet";
import CreateQuestionPaper from "../Exam/CreateQuestionPaper";
import ShowQuestionPaper from "../Exam/ListQuestionPaper";
import AddQuestions from "../Exam/AddQuestions";
import ShowQuestions from "../Exam/ListQuestions";
import CreateExam from "../Exam/CreateExam/index";
import ExamDetails from "../Exam/ExamDetails";
import ReviewAnswers from "../Exam/Review";
import AddInstruction from "../Exam/AddInstructions";
import EditQuestionPaper from "../Exam/EditQuestionPaper/EditQuestionPaper";
import QuestionEdit from "../Exam/QuestionEdit";
import MotsGrouping from '../GroupMots'
import AssignStudent from '../GroupMots/AssignStudents'
//Super admin's
import CreateEnterprise from '../SuperAdminMots/Enterprise/CreateEnterprise';
import EditEnterprise from '../SuperAdminMots/Enterprise/EditEnterprise';


const AnalyticsDashboard = () => {
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));
  const whatsnewStore = JSON.parse(localStorage.getItem("whatsnew"));
  const storeData = useSelector(({ homeTeacher }) => homeTeacher);
  const authData = useSelector(({ auth }) => auth);

  const history = useHistory();
  const dispatch = useDispatch();
  const [newUpdates, setNewUpdates] = useState(false);

  useEffect(() => {
    console.log("dashboard-test");
    if (tokenData) {
      dispatch({ type: "SET_PLIVILLAGES", payload: tokenData.privilege });
      if (tokenData.role === "teacher") {
        // debugger
        if (whatsnewStore) {
          //debugger
          if (whatsnewStore.count <= 1 && storeData.whatsnew) {
            if (whatsnewStore.date != storeData.whatsnew.date) {
              dispatch(getNotificationAlert(tokenData.token, openUpdate));
            }
          } else {
            dispatch(getNotificationAlert(tokenData.token, openUpdate));
          }

          // }
        } else {
          dispatch(getNotificationAlert(tokenData.token, openUpdate));
        }
        // if(!tokenData.enterpriseUser){
        //   dispatch(getTrialDays(tokenData.token, ))
        // }
      }
    }

    if (!tokenData) {
      history.push("/");
    }
    return () => { };
  }, []);
  const [updateDate, setUpdateDate] = useState();
  const openUpdate = (date) => {
    //  debugger

    setUpdateDate(date);
    setNewUpdates(true);
  };
  const closeNewUpdates = () => {
    setNewUpdates(false);
    //  debugger
    const localstore = {
      date: updateDate,
      count: whatsnewStore.count + 1,
    };

    localStorage.setItem("whatsnew", JSON.stringify(localstore));
  };

  const logOut = () => {
    dispatch(logout());
    localStorage.removeItem("authDetails");
    history.push("/");
  };
  const resentMail = () => {
    dispatch(emailSendForVerification(tokenData.token));
  };

  const getComponent = (typ) => {
    switch (typ) {
      case true:
        return (
          <>
            <p style={{ color: "blue" }}>Sending...</p>
            <Loader options={options} />
          </>
        );
      case "sent":
        return (
          <>
            <p style={{ color: "green" }}>
              Sent{`  `}
              <FontAwesomeIcon color="green" icon={faCheckCircle} />
            </p>
          </>
        );
      case "failed":
        return (
          <>
            <p style={{ color: "red" }}>
              Failed{`  `}
              <FontAwesomeIcon color="red" icon={faExclamationTriangle} />
            </p>
          </>
        );
      case "":
        return <Link onClick={() => resentMail()}>Verify</Link>;
    }
  };
  var options = {
    lines: 13,
    length: 4,
    width: 2,
    radius: 5,
    scale: 1.0,
    corners: 1,
    color: "#000",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "36%",
    left: "60%",
    shadow: false,
    hwaccel: false,
    position: "absolute",
  };
  const changePlan = (plan) => {
    dispatch({ type: "CHANGE_PLAN", payload: plan });
  };
  return (
    <AuthCheck>
      {tokenData ? (
        <>
          <ThemeOptions />
          <AppHeader
            user={tokenData ? tokenData.name : null}
            role={tokenData ? tokenData.role : null}
          />
          <div className="app-main">
            <AppSidebar
              role={tokenData ? tokenData.role : null}
              enterprise_user={tokenData ? tokenData.enterpriseUser : null}
              privillage={authData.privillages}
            />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <CSSTransitionGroup
                  component="div"
                  transitionName="TabsAnimation"
                  transitionAppear={true}
                  transitionAppearTimeout={0}
                  transitionEnter={false}
                  transitionLeave={false}
                >
                  {/* <PageTitle
                     heading={`${tokenData.name}'s Dashboard`}
                     subheading="This is an example dashboard created using build-in elements and components."
                     icon="pe-7s-car icon-gradient bg-mean-fruit"
                 /> */}

                  {tokenData.role === "teacher" ? (
                    <Switch>
                      {tokenData.enterpriseUser ? (
                        <Redirect
                          exact
                          from="/dashboard"
                          to={
                            tokenData.privilege[0].content.length > 0
                              ? tokenData.privilege[0].content[0].to.replace(
                                "#",
                                ""
                              )
                              : tokenData.privilege[0].to.replace("#", "")
                          }
                        />
                      ) : (
                        <Redirect
                          exact
                          from="/dashboard"
                          to="/dashboard/overview"
                        />
                      )}

                      {!tokenData.enterpriseUser && (
                        <Route
                          exact
                          path="/dashboard/payment"
                          component={Payment}
                        />

                      )}
                      {!tokenData.enterpriseUser && (
                        <Route
                          path="/dashboard/manageGroup"
                          component={ManageGroup}
                        />

                      )}
                      <Route
                        exact
                        path="/dashboard/videoClass/edit/:class_id/:subject_id"
                        component={EditVideoClass}
                      />
                      <Route
                        exact
                        path="/dashboard/group/:group_id"
                        component={AssignStudent}
                      />
                      <Route
                        exact
                        path="/dashboard/overview"
                        component={HomeTeacher}
                      />
                      <Route
                        path="/dashboard/createClass"
                        component={CreateClass}
                      />
                      <Route
                        path="/dashboard/editClass/:class_id/:teacher_id"
                        component={EditClass}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/students/:assignment_id/:class_id"
                        component={StudentAssignments}
                      />
                      <Route
                        path="/dashboard/specialClass"
                        component={SpecialClass}
                      />

                      <Route
                        path="/dashboard/show-class"
                        component={ShowClass}
                      />

                      <Route
                        exact
                        path="/dashboard/exam/createQuestionPaper"
                        component={CreateQuestionPaper}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showQuestionPaper"
                        component={ShowQuestionPaper}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/addQuestions/:question_paper_id"
                        component={AddQuestions}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showQuestions/:question_paper_id"
                        component={ShowQuestions}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/editQuestionPaper/:question_paper_id"
                        component={EditQuestionPaper}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/editQuestion/:question_id/:question_type/:question_paper_id"
                        component={QuestionEdit}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/createExam"
                        component={CreateExam}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showExams"
                        component={ListExam}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showExams/:exam_id"
                        component={ExamDetails}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showExams/:exam_id/:student_id"
                        component={ReviewAnswers}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/addInstructions/:exam_id"
                        component={AddInstruction}
                      />
                      <Route path="/dashboard/students" component={Students} />
                      <Route path="/dashboard/subjects" component={Subject} />
                      <Route
                        exact
                        path="/dashboard/playlist"
                        component={Playlist}
                      />
                      <Route
                        exact
                        path="/dashboard/playlist/:playlist_id/:subject_id"
                        component={PlaylistDetails}
                      />

                      <Route
                        path="/dashboard/createVideoSession"
                        component={CreateVideoSession}
                      />
                      <Route
                        path="/dashboard/videoClass"
                        component={VideoClass}
                      />
                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route
                        path="/dashboard/change-password"
                        component={ChangePassword}
                      />
                      <Route
                        exact
                        path="/dashboard/my-account"
                        component={MyAccount}
                      />
                      <Route
                        path="/dashboard/videolist/:class_id"
                        component={ListVideo}
                      />
                      <Route
                        exact
                        path="/dashboard/startclass/:week_id/:class_id/:type"
                        component={ClassTeacher}
                      />

                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route
                        path="/dashboard/show-teacher"
                        component={ShowTeacher}
                      />
                      <Route
                        path="/dashboard/show-student"
                        component={ShowStudent}
                      />
                      <Route
                        path="/dashboard/show-staff"
                        component={ShowStaff}
                      />
                      {tokenData.enterpriseUser ?
                        <Route path="/dashboard/group" component={Grouping} /> :
                        <Route path="/dashboard/group" component={MotsGrouping} />
                      }
                      <Route
                        path="/dashboard/showGroup"
                        component={showGroup}
                      />
                      {tokenData.plan_id !== 1 && (
                        <>
                          <Route
                            exact
                            path="/dashboard/show-attendance"
                            component={ShowAttendance}
                          />
                          <Route
                            exact
                            path="/dashboard/show-attendance/:class_id"
                            component={ShowAttendanceDetails}
                          />
                          <Route
                            exact
                            path="/dashboard/assignments"
                            component={Assignments}
                          />
                          <Route
                            exact
                            path="/dashboard/assignments/:assig_id/:class_id"
                            component={Assignmentss}
                          />
                          <Route
                            exact
                            path="/dashboard/assignments/:assig_id/:class_id/:student_id/:assesment_id"
                            component={SpecificAssignment}
                          />
                        </>
                      )}

                      <Route component={() => <p>page not found</p>} />
                    </Switch>
                  ) : tokenData.role === "student" ? (
                    <Switch>
                      {tokenData.enterpriseUser ? (
                        <Redirect
                          exact
                          from="/dashboard"
                          to={
                            tokenData.privilege[0].content.length > 0
                              ? tokenData.privilege[0].content[0].to.replace(
                                "#",
                                ""
                              )
                              : tokenData.privilege[0].to.replace("#", "")
                          }
                        />
                      ) : (
                        <Redirect
                          exact
                          from="/dashboard"
                          to="/dashboard/overview"
                        />
                      )}

                      <Route
                        path="/dashboard/overview"
                        component={HomeStudent}
                      />
                      <Route
                        path="/dashboard/show-class"
                        component={ShowClass}
                      />
                      <Route
                        path="/dashboard/join/:week_id/:class_id"
                        component={ClassStudent}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance"
                        component={ShowAttendance}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance/:class_id/:session_id"
                        component={ShowAttendanceDetails}
                      />

                      <Route
                        exact
                        path="/dashboard/examHall/:exam_id"
                        component={Exam}
                      />
                      <Route
                        exact
                        path="/dashboard/instructions/:exam_id"
                        component={Instructions}
                      />
                      <Route
                        exact
                        path="/dashboard/exam/showExams"
                        component={ListExam}
                      />
                      <Route
                        exact
                        path="/dashboard/answerSheet/:exam_id"
                        component={AnswerSheet}
                      />

                      <Route
                        path="/dashboard/specialClass"
                        component={SpecialClass}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments"
                        component={Assignments}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/:assig_id/:class_id"
                        component={Assignmentss}
                      />
                      <Route
                        path="/dashboard/change-password"
                        component={ChangePassword}
                      />
                      <Route
                        path="/dashboard/my-account"
                        component={MyAccount}
                      />
                      <Route
                        path="/dashboard/videoClass"
                        component={VideoClass}
                      />
                      <Route
                        path="/dashboard/videolist/:class_id"
                        component={ListVideo}
                      />
                      <Route path="/dashboard/feedback" component={Feedback} />

                      <Route component={() => <p>page not found</p>} />
                    </Switch>
                  ) : tokenData.role === "principal" ? (
                    <Switch>
                      <Redirect exact from="/dashboard" to="/dashboard/overview" />
                      <Route
                        exact
                        path="/dashboard/overview"
                        component={HomePrincipal}
                      />
                      {/* <Route exact path="/dashboard/overview2" component={HomePrincipal} /> */}
                      {/* <Route exact path="/dashboard/overview" component={HomeTeacher} /> */}
                      {/* <Route exact path="/dashboard/overview2" component={HomePrincipal} /> */}

                      <Route
                        path="/dashboard/join/:week_id/:class_id"
                        component={ClassStudent}
                      />
                      <Route
                        path="/dashboard/createClass"
                        component={CreateClass}
                      />
                      <Route
                        path="/dashboard/editClass/:class_id/:teacher_id"
                        component={EditClass}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/students/:assignment_id/:class_id"
                        component={StudentAssignments}
                      />
                      <Route
                        path="/dashboard/specialClass"
                        component={SpecialClass}
                      />
                      <Route
                        path="/dashboard/show-class"
                        component={ShowClass}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance"
                        component={ShowAttendance}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance/:class_id"
                        component={ShowAttendanceDetails}
                      />
                      <Route path="/dashboard/students" component={Students} />
                      <Route path="/dashboard/subjects" component={Subject} />
                      <Route
                        exact
                        path="/dashboard/playlist"
                        component={Playlist}
                      />
                      <Route
                        exact
                        path="/dashboard/playlist/:playlist_id/:subject_id"
                        component={PlaylistDetails}
                      />
                      <Route
                        path="/dashboard/createVideoSession"
                        component={CreateVideoSession}
                      />
                      <Route
                        path="/dashboard/videoClass"
                        component={VideoClass}
                      />
                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments"
                        component={Assignments}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/:assig_id/:class_id"
                        component={Assignmentss}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/:assig_id/:class_id/:student_id/:assesment_id"
                        component={SpecificAssignment}
                      />
                      <Route
                        path="/dashboard/videolist/:class_id"
                        component={ListVideo}
                      />
                      <Route
                        exact
                        path="/dashboard/startclass/:week_id/:class_id/:type"
                        component={ClassTeacher}
                      />
                      <Route
                        path="/dashboard/change-password"
                        component={ChangePassword}
                      />
                      <Route
                        path="/dashboard/my-account"
                        component={MyAccount}
                      />
                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route path="/dashboard/group" component={Grouping} />
                      <Route path="/dashboard/forms" component={FormRegister} />
                      <Route
                        path="/dashboard/manageGroup"
                        component={ManageGroup}
                      />
                      <Route
                        path="/dashboard/show-teacher"
                        component={ShowTeacher}
                      />
                      <Route
                        path="/dashboard/show-student"
                        component={ShowStudent}
                      />
                      <Route
                        path="/dashboard/show-staff"
                        component={ShowStaff}
                      />
                      <Route
                        path="/dashboard/showGroup"
                        component={showGroup}
                      />
                      <Route path="/dashboard/feedback" component={Feedback} />
                      <Route
                        exact
                        path="/dashboard/exam/showExams"
                        component={ListExam}
                      />

                      <Route component={() => <p>page not found</p>} />
                    </Switch>
                  ) : tokenData.role === "staff" ? (
                    <Switch>
                      <Redirect
                        exact
                        from="/dashboard"
                        to={
                          tokenData.privilege[0].content.length > 0
                            ? tokenData.privilege[0].content[0].to.replace(
                              "#",
                              ""
                            )
                            : tokenData.privilege[0].to.replace("#", "")
                        }
                      />
                      <Route
                        exact
                        path="/dashboard/overview"
                        component={HomeTeacher}
                      />
                      <Route
                        path="/dashboard/createClass"
                        component={CreateClass}
                      />
                      <Route
                        path="/dashboard/editClass/:class_id/:teacher_id"
                        component={EditClass}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/students/:assignment_id/:class_id"
                        component={StudentAssignments}
                      />
                      <Route
                        path="/dashboard/specialClass"
                        component={SpecialClass}
                      />
                      <Route
                        path="/dashboard/show-class"
                        component={ShowClass}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance"
                        component={ShowAttendance}
                      />
                      <Route
                        exact
                        path="/dashboard/show-attendance/:class_id"
                        component={ShowAttendanceDetails}
                      />
                      <Route path="/dashboard/students" component={Students} />
                      <Route path="/dashboard/subjects" component={Subject} />
                      <Route
                        exact
                        path="/dashboard/playlist"
                        component={Playlist}
                      />
                      <Route
                        exact
                        path="/dashboard/playlist/:playlist_id/:subject_id"
                        component={PlaylistDetails}
                      />
                      <Route
                        path="/dashboard/createVideoSession"
                        component={CreateVideoSession}
                      />
                      <Route
                        path="/dashboard/videoClass"
                        component={VideoClass}
                      />
                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments"
                        component={Assignments}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/:assig_id/:class_id"
                        component={Assignmentss}
                      />
                      <Route
                        exact
                        path="/dashboard/assignments/:assig_id/:class_id/:student_id/:assesment_id"
                        component={SpecificAssignment}
                      />
                      <Route
                        path="/dashboard/videolist/:class_id"
                        component={ListVideo}
                      />
                      <Route
                        exact
                        path="/dashboard/startclass/:week_id/:class_id/:type"
                        component={ClassTeacher}
                      />
                      <Route
                        path="/dashboard/change-password"
                        component={ChangePassword}
                      />
                      <Route
                        path="/dashboard/my-account"
                        component={MyAccount}
                      />
                      <Route
                        path="/dashboard/Recordings"
                        component={Recordings}
                      />
                      <Route path="/dashboard/group" component={Grouping} />
                      <Route path="/dashboard/forms" component={FormRegister} />
                      <Route
                        path="/dashboard/manageGroup"
                        component={ManageGroup}
                      />
                      <Route
                        path="/dashboard/show-teacher"
                        component={ShowTeacher}
                      />
                      <Route
                        path="/dashboard/show-student"
                        component={ShowStudent}
                      />
                      <Route
                        path="/dashboard/show-staff"
                        component={ShowStaff}
                      />
                      <Route component={() => <p>page not found</p>} />
                    </Switch>
                  ) : tokenData.role === "superAdmin" ? (<Switch>
                    <Redirect
                      exact
                      from="/dashboard"
                      to="/dashboard/overview"
                    />
                    <Route
                      exact
                      path="/dashboard/overview"
                      component={HomePrincipal}
                    />
                    <Route
                      exact
                      path="/dashboard/createEnterprise/"
                      component={CreateEnterprise}
                    />
                    <Route
                      exact
                      path="/dashboard/editEnterprise/:ent_id"
                      component={EditEnterprise}
                    />
                  </Switch>) : null}

                  {/* <AnalyticsDashboard1 /> */}
                  {/* /<CreateClass /> */}
                </CSSTransitionGroup>
              </div>
              {/* <AppFooter /> */}
            </div>
          </div>
          {tokenData.emailVerified === false ? (
            <Modals open={true} title={`Email verification`}>
              <Row>
                <Col md={5}>
                  <p>Please verify your email</p>
                </Col>
                <Col md={4}>{getComponent(storeData.resentMailLoader)}</Col>
                <Col md={3}>
                  <Button
                    style={{ fontSize: "11px", padding: "1px 18px" }}
                    className="btn-shadow"
                    onClick={() => logOut()}
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md="12">
                  {storeData.resentMailLoader === "sent" ? (
                    <p style={{ color: "green" }}>
                      <FontAwesomeIcon color="green" icon={faCheckCircle} />
                      {`  `}Send you verification link to your mail,Please
                      Verify{" "}
                    </p>
                  ) : null}
                </Col>
              </Row>
            </Modals>
          ) : null}
        </>
      ) : null}
      <Modals
        open={newUpdates}
        handleClose={closeNewUpdates}
      // title={`Time shedule`}
      >
        <Updates data={storeData.whatsnew} />
      </Modals>
      {console.log("auth data", authData)}
      <Modals
        open={authData.payment ? authData.payment.payment_alert : false}
        // open={false}
        //handleClose={closeNewUpdates}
        // title={`Time shedule`}
        size="xl"
      >
        <Plans data={authData.payment} changePlan={changePlan} />
      </Modals>
    </AuthCheck>
  );
};
export default AnalyticsDashboard;
