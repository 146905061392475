import React, { useState, useEffect, } from 'react'
import {
    Row, Col, Form, FormGroup, Input,
    Card, CardBody, FormFeedback, Label,
    CardTitle, InputGroup,
    Button,

} from 'reactstrap';
import {  requiredValidator } from '../../../utilities/Validator'

import ViewStudent from './viewStudent'
import TimeShedule from './viewTime'
// import Loader from 'react-loader'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import AssignStudents from './addStudentToClass'
import Modals from '../../common/Modal'
import Swal from 'sweetalert2';
// import Table from "./table";
import SweetAlert from 'sweetalert-react';
// import Switchy from "react-switch";


// import Tabl from '../../common/DataTable'
import moment from 'moment'
import serialize from 'form-serialize'
import { useDispatch, useSelector } from 'react-redux'
import { deleteClasses, validateClassTiming, getTeacherAssignedGroups, populateSubject, createClass, populateAllUserClass, getClassTiming, populateRegisteredStudents, getEditFormDetails, populateAllEnterpriseUserClass } from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";
import uuid from 'react-uuid'
// import { logout, authCheck } from '../../../redux/Actions/auth'
import { populateDegree, getGradeList } from '../../../redux/Actions/signUp'

import DatePicker from 'react-datepicker';
import CreateTimeSlot from './createTimeSlot'
// import TimeSlot from './timeSlotCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock, faWindowClose,
} from '@fortawesome/free-solid-svg-icons';
// import { set } from 'date-fns';
// import Showclass from "../ShowClass/index"

const College = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const st = useSelector(state => state)

    const [teacherId, setTeacherId] = useState(null)

    var columns

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const Grades = useSelector(({ signUp }) => signUp)
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

    // console.log("tStore-------", st)
    // const cbLogOut = () => {

    //     dispatch(logout())
    //     localStorage.removeItem("authDetails");
    //     history.push('/')
    //     Swal.fire({
    //         icon: 'error',
    //         title: '',
    //         text: 'Token Expired',

    //     })
    // }

    useEffect(() => {
        // dispatch(authCheck(tokenData.token, cbLogOut))

        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }
        // dispatch(populateGrade())
        // dispatch(getGradeList(null))
        dispatch(populateDegree())

        // if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
        //     dispatch(populateAllEnterpriseUserClass(tokenData.token))


        // } else {
        //     dispatch(populateAllUserClass(tokenData.token))

        // }

    }, []);


    if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
            { title: 'Teacher ', field: 'teacher_name', headerStyle: { fontWeight: '600' } },
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]

    } else {
        columns = [
            { title: 'Class ', field: 'class_name', headerStyle: { fontWeight: '600' } },
            { title: 'Grade', field: 'grade_name', headerStyle: { fontWeight: '600' } },
            { title: 'Subject', field: 'subject_name', headerStyle: { fontWeight: '600' } },
            { title: 'From Date', field: 'class_from_date', headerStyle: { fontWeight: '600' } },
            { title: 'To Date', field: 'class_to_date', headerStyle: { fontWeight: '600' } }

        ]
    }


    console.log("momemt", moment().format('DD-MM-YYYY'))
    const [createTimeSlot, setTimeSlot] = useState([
        {
            key: uuid(),
            days: [
                { day: null, val: null },
                { day: 'Sunday', val: 'sun' },
                { day: 'Monday', val: 'mon' },
                { day: 'Tuesday', val: 'tue' },
                { day: 'Wednesday', val: 'wed' },
                { day: 'Thursday', val: 'thu' },
                { day: 'Friday', val: 'fri' },
                { day: 'Saturday', val: 'sat' }]
        }

    ])
    const [tempCart, setTempCard] = useState({
        class_from_date: null,
        class_to_date: null,
        day: null,
        class_from_time: '',
        class_to_time: ''
    })
    const handleChadeCard = (e,name) => {
        if (name) {

            let a = String(e)
            let b = a.slice(16, 21)

            setTempCard({ ...tempCart, key: uuid(), [name]: b })
            setValidayeData({ ...validateData, [name]: b })
        }
        else {
            const { id, value } = e.target;
            setTempCard({ ...tempCart, key: uuid(), [id]: value })
            setValidayeData({ ...validateData, [id]: value })
        }
    }
    const handleChangeTeacher = e => {
        const { name, value } = e.target;
        setTeacherId(value)
        //setInputField({ ...inputField, [name]: value })
        //S  dispatch(populateSubject(tokenData.token, value, 1))
    };

    console.log("day==========", tempCart)
    const [realCard, setRealCard] = useState([])

    const removeCard = (id) => {

        const updatedCard = realCard.filter(card => !id.includes(card.key))
        setRealCard(updatedCard)

    }


    const [validateData, setValidayeData] = useState({
        class_from_date: new Date(),
        class_to_date: moment().add(7, 'd')._d,
        day: null
    })




    const handleChange = (e, c) => {
        console.log(`eee${e}-${c}`)
        console.log(`eee${e}-${c}`)
        const dt = moment(e).format('YYYY-MM-DD');


        if (!c) {
            const { name, value } = e.target;
            setValidayeData({ ...validateData, [name]: value })
        }
        else {
            if (c === 'class_from_date') {
                setValidayeData({ ...validateData, [c]: dt, class_to_date: moment(dt).add(7, 'd')._d })

            }
            else {

                setValidayeData({ ...validateData, [c]: dt })

            }
        }
        // if (!c) {
        //     const { name, value } = e.target;
        //     setValidayeData({ ...validateData, [name]: value })
        // }
        // else {
        //     if (c === 'class_from_date') {
        //         setValidayeData({ ...validateData, [c]: e, class_to_date: '' })

        //     }
        //     else {
        //         setValidayeData({ ...validateData, [c]: e })

        //     }
        // }

    }
    console.log("validateData", validateData)

    const [slotLoader, setSlotLoader] = useState(false)
    const loaderFalse = (res) => {
        setSlotLoader(false)
        Swal.fire({
            icon: 'error',
            title: '',
            text: res,

        })

    }

    const validateCard = () => {

        var startTime = moment(validateData.class_from_time, 'hh:mm');
        var endTime = moment(validateData.class_to_time, 'hh:mm');
        var duration = endTime.diff(startTime, 'minutes');
        const maxDuration = tokenData.class_duration * 60
        console.log(`diff:${duration}`)

        //         if (tempCart.class_from_time > tempCart.class_to_time || tempCart.class_from_time == tempCart.class_to_time) {
        //             alert(`Start time should be less than End time
        // or 
        // should not be same`)


        //         }
        // else {

        if (tokenData.allowed_classes != '' && realCard.length >= tokenData.allowed_classes) {
            Swal.fire({
                icon: 'error',
                title: '',
                text: `You just have to create ${tokenData.allowed_classes}  classes...`,


            })
        } else {
            if (validateData.class_from_date &&
                validateData.class_to_date &&
                tempCart.class_from_time &&
                tempCart.class_to_time &&
                tempCart.day !== null || '') {
                if (Math.sign(duration) === -1 && !tokenData.enterpriseUser) {
                    const convertedDuration = 1440 - maxDuration //1440 is minuts in a day - 60*24
                    // alert(-Math.abs(convertedDuration))

                    if (tokenData.class_duration != '' && !(duration <= (-Math.abs(convertedDuration)))) {//   -1260 its minutes and its converted to negative
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: `You just have to create ${tokenData.class_duration} Hour class...`,


                        })


                    } else {
                        dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
                        setSlotLoader(true)
                    }

                } else {
                    // alert(duration)

                    if (tokenData.class_duration != '' && !(duration <= maxDuration) && !tokenData.enterpriseUser) {
                        // alert("You just have to create 3 Hour class...")
                        Swal.fire({
                            icon: 'error',
                            title: '',
                            text: `You just have to create ${tokenData.class_duration} Hour class...`,

                        })
                    }
                    else {
                        dispatch(validateClassTiming(tokenData.token, validateData, createCard, loaderFalse))
                        setSlotLoader(true)
                    }
                }
                // console.log("inclu", typeof duration)




            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: 'Fill the required fields',

                })
            }
        }

    }
    const [dateDisablity, setDateDisabliyt] = useState(false)
    const createCard = () => {
        setDateDisabliyt(true)

        let err;

        if (realCard.length === 0) {
            setRealCard([...realCard, tempCart])
            setTempCard({
                class_from_date: '',
                class_to_date: '',
                day: '',
                class_from_time: '',
                class_to_time: ''
            })
            setSlotLoader(false)
        }
        else {

            realCard.forEach(item => {
                if (tempCart.day == item.day) {
                    console.log("hii")
                    if (tempCart.class_from_time !== item.class_from_time && tempCart.class_to_time !== item.class_to_time) {
                        const fr = moment(tempCart.class_from_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const to = moment(tempCart.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));
                        const rfr = moment(item.class_from_time, 'hh:mm').isBetween(moment(tempCart.class_from_time, 'hh:mm'), moment(tempCart.class_to_time, 'hh:mm'));
                        const rto = moment(item.class_to_time, 'hh:mm').isBetween(moment(item.class_from_time, 'hh:mm'), moment(item.class_to_time, 'hh:mm'));

                        if (fr || to || rfr || rto) {
                            err = "from or to slot already taken";
                        }
                    }

                    else {
                        err = "from time to time same so not possible";
                    }
                }
            })
            if (!err) {

                setRealCard([...realCard, tempCart])
                setTempCard({
                    class_from_date: '',
                    class_to_date: '',
                    day: '',
                    class_from_time: '',
                    class_to_time: ''
                })
                setSlotLoader(false)


            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: err,

                })
                setSlotLoader(false)

            }
        }

        console.log("tmp=============", tempCart)


    }

    console.log("store", tStore)

    //const [inputField, setInputField] = useState({})

    const handleChangeDegree = e => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value, subject_id: '', grade_id: '' })
        dispatch(getGradeList(value, 2))

    };
    const handleChangeSpecialization = (e) => {
        const { name, value } = e.target;
        dispatch(populateSubject(tokenData.token, value, 2, teacherId))
        setInputField({ ...inputField, [name]: value, subject_id: '' })

    }


    const [openModal, setOpenModal] = useState(false)
    const hadleOpenModal = (id) => {
        //alert(id)
        dispatch(getClassTiming(tokenData.token, id))
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }
    const [openStdModal, setOpenStdModal] = useState(false)
    const [classId, setClassId] = useState()
    const [timeData, setTimeData] = useState()
    const hadleOpenStudentModal = (class_id, grade_id, teacher_id, rowData) => {
        setClassId()

        setTimeData(rowData)
        console.warn("rrr", rowData)
        setClassId(class_id);
        if (tokenData.enterpriseUser) {

            dispatch(getTeacherAssignedGroups(tokenData.token, class_id, teacher_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))

        }
        //dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        setOpenStdModal(true)

    }
    const handleCloseStudentModal = () => {
        setOpenStdModal(false)
    }
    const handleCloseStudentModal2 = () => {
        setOpenStdModal(false)
        handleOpenSuccessModal()
    }
    const [successModal, setSuccessModal] = useState();
    const handleOpenSuccessModal = () => {
        if (tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) {
            dispatch(populateAllEnterpriseUserClass(tokenData.token))


        } else {
            dispatch(populateAllUserClass(tokenData.token))

        }

        setSuccessModal(true)

    }
    const handleCloseSuccesstModal = () => {
        setSuccessModal(false)
        console.log("close-----", tStore.classes)
        // window.location.reload(false);

    }
    const [studentListModal, setStudentListModal] = useState();
    const handleOpenStudentListModal = (class_id, grade_id, teacher_id) => {
        // 
        if (tokenData.enterpriseUser) {
            dispatch(populateRegisteredStudents(tokenData.token, null, class_id))

        } else {
            dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        }
        // dispatch(populateRegisteredStudents(tokenData.token, grade_id, class_id))
        setStudentListModal(true)
    }
    const handleCloseStudentListModal = () => {
        setStudentListModal(false)
    }
    const deleteClass = (class_id) => {
        dispatch(deleteClasses(tokenData.token, class_id))
    }



    console.log("added days#######", moment(new Date).add(3, 'd')._d)
    const [editForm, setEditForm] = useState()
    const [editModal, setEditModal] = useState()
    const handleOpenEditModal = (rowData) => {

        setEditForm(rowData);
        setEditModal(true)

    }

    const handleCloseEditModal = () => {
        setEditModal(false)
    }




    const handleEdit = (classId, teacherID) => {
        //alert(classId)

        history.push(`/dashboard/editClass/${classId}/${teacherID}`)
    }
    const [inputField, setInputField] = useState({
        class_name: '',
        degree_id: '',
        subject_id: '',
        grade_id: ''
        // toDate: ''
    })
    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setInputField({ ...inputField, [name]: value })
    }
    const [errors, setErrors] = useState({})

    const submitForm = (e) => {
        e.preventDefault();
        // console.log("realCard in form ", realCard)
        let error = null;
        if (tokenData.enterpriseUser) {
            error = {
                class_name: requiredValidator(inputField.class_name),
                teacher: requiredValidator(inputField.teacher_id),
                grade: requiredValidator(inputField.grade_id),
                subject: requiredValidator(inputField.subject_id),
                degree: requiredValidator(inputField.degree_id)

            }
            if (!error.class_name && !error.grade && !error.subject && !error.teacher && !error.degree) {
                // console.log("realCard in form ", realCard)
                setErrors(error)
                if (realCard.length > 0) {
                    const form = e.currentTarget
                    let body = serialize(form, { hash: true, empty: true })
                    const type = tokenData.enterpriseUser
                    dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm, type))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Create atleast one time slot',

                    })
                }
            } else {
                setErrors(error)
                if(error.class_name){
                    Showerror('classname2')
                  }
                  else if(error.teacher){
                    Showerror('teacher2')
                  }
                  else if(error.degree){
                    Showerror('degree2')
                  }
                  else if(error.grade){
                    Showerror('grade2')
                  }
                  else{
                    Showerror('subject2')
                  }
            }
        } else {
            error = {
                class_name: requiredValidator(inputField.class_name),
                grade: requiredValidator(inputField.grade_id),
                subject: requiredValidator(inputField.subject_id),
                degree: requiredValidator(inputField.degree_id)

            }
            if (!error.class_name && !error.grade && !error.subject && !error.degree) {
                // console.log("realCard in form ", realCard)
                setErrors(error)
                if (realCard.length > 0) {
                    const form = e.currentTarget
                    let body = serialize(form, { hash: true, empty: true })
                    const type = tokenData.enterpriseUser
                    dispatch(createClass(tokenData.token, body, handleOpenSuccessModal, resetForm, type))
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'Create atleast one time slot',

                    })
                }
            } else {
                setErrors(error)
                if(error.class_name){
                    Showerror('classnamemots2')
                  }
                  else if(error.degree){
                    Showerror('degree2')
                  }
                  else if(error.subject){
                    Showerror('subject2')
                  }
                  else{
                    Showerror('grade2')
                  }
            }
        }

    }

    const Showerror=(val)=>{
        var elmnt = document.getElementById(val);
        elmnt.scrollIntoView();
    }
    const resetForm = () => {
        setInputField({
            class_name: '',
            degree_id: '',
            subject_id: '',
            grade_id: '',
            teacher_id: '',

            // toDate: ''
        })
        document.getElementById("class-form").reset();
        setRealCard([])

    }


    return (
        <>
            <div style={{ padding: "0px 15px 0px 15px" }}>
                <Form
                    onSubmit={submitForm}
                    id="class-form" >


                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle>
                                CLASS DETAILS
                    </CardTitle>

                            <FormGroup row>
                                <Col md={6}>
                                    <Row>


                                        {tokenData.enterpriseUser ? <>

                                            <Col sm={6}>

                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Class Name
                                                    </Label>
                                                <Input
                                                    type="text"
                                                    name="class_name"
                                                    id='classname2'
                                                    //  required
                                                    value={inputField.class_name}
                                                    onChange={handleChangeInput}
                                                    invalid={errors.class_name}


                                                />
                                                <FormFeedback >{errors.class_name}</FormFeedback>

                                                <input
                                                    name='class_type'
                                                    type="hidden"
                                                    value="sheduled"
                                                />
                                            </Col>
                                            <Col sm={6}>
                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Select Teacher
                                                    </Label>

                                                <Input type="select"
                                                    onChange={(e) => handleChangeTeacher(e)}
                                                    name='teacher_id'
                                                    //required
                                                    id='teacher2'
                                                    value={inputField.teacher_id}
                                                    onChange={handleChangeInput}
                                                    invalid={errors.teacher}


                                                >

                                                    <option value={''}>{'select'}</option>
                                                    {tStore.registeredTeachers ?
                                                        tStore.registeredTeachers.map(registeredTeachers => {
                                                            return (
                                                                <option value={registeredTeachers.user_id}>{registeredTeachers.first_name + " " + registeredTeachers.last_name}</option>
                                                                //  <MenuItem value={grade.grade_id} key={grade.grade_id}>{grade.grade_name}</MenuItem>

                                                            )
                                                        }) : []
                                                    }

                                                </Input>
                                                <FormFeedback >{errors.teacher}</FormFeedback>

                                            </Col>
                                        </> :

                                            (<Col sm={12}>

                                                <Label for="exampleNameF">
                                                    <span className="text-danger">*</span>
                                                    {' '}Class Name
                                              </Label>
                                                <Input
                                                    type="text"
                                                    name="class_name"
                                                    // required
                                                    id='classnamemots2'
                                                    value={inputField.class_name}
                                                    onChange={handleChangeInput}
                                                    invalid={errors.class_name}

                                                />
                                                <FormFeedback >{errors.class_name}</FormFeedback>

                                                <input
                                                    name='class_type'
                                                    type="hidden"
                                                    value="sheduled"
                                                />
                                            </Col>
                                            )
                                        }

                                        <Col sm={12} >


                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}From Date
        </Label>
                                            {/* <DatePicker
                                                // selected={validateData.class_from_date}
                                                selected={moment(validateData.class_from_date).toDate()}

                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                onChange={(e) => handleChange(e, 'class_from_date')}
                                                withPortal

                                                minDate={moment().toDate()}
                                                required

                                            /> */}
                                                <DatePicker
                                              selected={moment(validateData.class_from_date).toDate()}

                                              dateFormat="dd/MM/yyyy"
                                              className="form-control"
                                              onChange={(e) => handleChange(e, 'class_from_date')}
                                              withPortal

                                              minDate={moment().toDate()}
                                              required
                                                renderCustomHeader={({
                                                    date,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    increaseYear,
                                                    decreaseYear,

                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                            {"<<"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </Button>

                                                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                            {">>"}
                                                        </Button>
                                                    </div>
                                                )}

                                            />
                                            <Input
                                                value={moment(validateData.class_from_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_from_date"
                                            />

                                        </Col>
                                        <Col sm={12} >

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}To Date
        </Label>    <InputGroup>
                                                {/* <DatePicker

                                                    selected={moment(validateData.class_to_date).toDate()}

                                                    className="form-control"
                                                    onChange={(e) => handleChange(e, 'class_to_date')}
                                                    withPortal
                                                    dateFormat="dd/MM/yyyy"
                                                    minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                                    required
                                                /> */}
                                                   <DatePicker
                                                 selected={moment(validateData.class_to_date).toDate()}

                                                 className="form-control"
                                                 onChange={(e) => handleChange(e, 'class_to_date')}
                                                 withPortal
                                                 dateFormat="dd/MM/yyyy"
                                                 minDate={moment(validateData.class_from_date).add(7, 'd')._d}
                                                 required
                                                renderCustomHeader={({
                                                    date,
                                                    decreaseMonth,
                                                    increaseMonth,
                                                    increaseYear,
                                                    decreaseYear,

                                                    prevMonthButtonDisabled,
                                                    nextMonthButtonDisabled
                                                }) => (
                                                    <div
                                                        style={{
                                                            margin: 10,
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}
                                                    >
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseYear} disabled={prevMonthButtonDisabled}>
                                                            {"<<"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="mr-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                                            {"<"}
                                                        </Button>

                                                        <h4 style={{ color: "#1D5D90", fontWeight: 'bold' }}>{months[date.getMonth()]} {date.getFullYear()}</h4>

                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                                            {">"}
                                                        </Button>
                                                        <Button size="sm" color='light' className="ml-2" style={{ fontWeight: 'bold', fontSize: '18px' }} onClick={increaseYear} disabled={nextMonthButtonDisabled}>
                                                            {">>"}
                                                        </Button>
                                                    </div>
                                                )}

                                            />
                                            </InputGroup>
                                            {/* <Input type="date"
                                                value={"2021-12-02"}
                                                min={"2020-06-11"}
                                                onChange={(e) => handleChange(e, 'class_to_date')}

                                            /> */}
                                            <Input
                                                value={moment(validateData.class_to_date).format('YYYY-MM-DD')}
                                                type="hidden"
                                                name="class_to_date"
                                            />
                                        </Col>



                                    </Row>


                                </Col>
                                <Col sm={6} style={{ borderLeft: 'solid 1px #ced4db' }}>
                                    <Row>

                                        <Col sm={12}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Course
                                                    </Label>

                                            <Input type="select"
                                                onChange={(e) => handleChangeDegree(e)}
                                                name='degree_id'
                                                // required
                                                id='degree2'
                                                value={inputField.degree_id}
                                                invalid={errors.degree}

                                            >
                                                <option value={''}>Select Course</option>
                                                {
                                                    Grades.degree ? Grades.degree.map((data) => {
                                                        return (
                                                            <option value={data.degree_id} key={data.degree_id}>{data.degree_name}</option>
                                                        )
                                                    })
                                                        : []
                                                }


                                            </Input>
                                            <FormFeedback >{errors.degree}</FormFeedback>

                                        </Col>
                                        <Col sm={12}>
                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Specialization
                                                    </Label>
                                        <div class="form-group">
                                            <div class="input-group">  
                                            <Input type="select"
                                                onChange={(e) => handleChangeSpecialization(e)}
                                                name='grade_id'
                                                // required
                                                id='grade2'
                                                value={inputField.grade_id}
                                                invalid={errors.grade}

                                            >

                                                <option value={''}>{'select'}</option>
                                                {
                                                    Grades.gradeCollege.grades ? Grades.gradeCollege.grades.map((data) => {
                                                        return (
                                                            <option value={data.grade_id} key={data.grade_id}>{data.grade_name}</option>
                                                        )
                                                    })
                                                        : []
                                                }
                                            </Input>
                                            {
                                                Grades.loadgrade ? 
                                                <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                                </span>
                                                :
                                                null
                                            }
                                            </div>
                                            </div>
                                            <FormFeedback >{errors.grade}</FormFeedback>

                                        </Col>
                                        <Col sm={12}>

                                            <Label for="exampleNameF">
                                                <span className="text-danger">*</span>
                                                {' '}Select Subject
                                                    </Label>
                                        <div class="form-group">
                                            <div class="input-group">               
                                            <Input type="select"
                                                name='subject_id'
                                                //required
                                                id='subject2'
                                                value={inputField.subject_id}
                                                onChange={handleChangeInput}
                                                invalid={errors.subject}
                                            >
                                                <option value={''}>{'select'}</option>

                                                {tStore.subject ?
                                                    tStore.subject.map(subj => {
                                                        return (
                                                            <option value={subj.subject_id}>{subj.subject_name}</option>

                                                        )
                                                    }) : null
                                                }

                                            </Input>
                                            {
                                                tStore.loadersub ? 
                                                <span class="input-group-addon" style={{border:'1px solid #ccc',padding:'6px 6px'}}>
                                                <div class="spinner-border text-primary spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                                </span>
                                                :
                                                null
                                            }
                                            
                                            </div>
                                            </div>
                                            <FormFeedback >{errors.subject}</FormFeedback>

                                        </Col>


                                    </Row>
                                </Col>


                            </FormGroup>
                            <input type="hidden" name="class_category" value={null} />

                            {/* <strong>Class Type</strong>
                            <hr />
                            <Row>
                                <Col md={2}>
                                    Normal:
                                </Col>
                                <Col md={1}>
                                    <input defaultChecked type="radio" name="class_category" value={`normal`} />

                                </Col>
                                <Col md={2}>
                                    Conference:
                                </Col>
                                <Col md={1}>
                                    <input type="radio" name="class_category" value={`conference`} />

                                </Col>
                            </Row> */}
                           
                        </CardBody>
                    </Card>

                    <CreateTimeSlot
                        handleChadeCard={handleChadeCard}
                        createTimeSlot={createTimeSlot}
                        createCard={validateCard}
                        tempCart={tempCart}
                        loader={slotLoader}


                    />

                    <Row>
                        {realCard ?
                            realCard.map(days => {
                                return (
                                    <Col md="6" lg="3">
                                        <Card className="widget-chart widget-chart2 text-left mb-3 card-btm-border card-shadow-primary border-primary">
                                            <div className="widget-chat-wrapper-outer">
                                                <div className="widget-chart-content"> <p style={{ textAlign: 'right' }}><FontAwesomeIcon onClick={() => removeCard(days.key)} icon={faWindowClose} /></p>
                                                    <div className="widget-title opacity-5 text-uppercase">
                                                        <h6>
                                                        {`${days ? moment(days.class_from_time, 'hh:mm ').format("h:mm A") : null} - ${days ? moment(days.class_to_time, 'hh:mm ').format("h:mm A") : null}`}
                                                        </h6>
                                                        <input
                                                            value={days ? days.class_from_time : null}
                                                            type="hidden"
                                                            name={

                                                                days.day === 'sun' ? "week[sun][class_from_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_from_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_from_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_from_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_from_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_from_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_from_time][]" :
                                                                                            "name from"
                                                            }
                                                            id="from"
                                                        />
                                                        <input type="hidden"
                                                            value={days ? days.class_to_time : null}
                                                            id='to'
                                                            name={
                                                                days.day === 'sun' ? "week[sun][class_to_time][]" :
                                                                    days.day === 'mon' ? "week[mon][class_to_time][]" :
                                                                        days.day === 'tue' ? "week[tue][class_to_time][]" :
                                                                            days.day === 'wed' ? "week[wed][class_to_time][]" :
                                                                                days.day === 'thu' ? "week[thu][class_to_time][]" :
                                                                                    days.day === 'fri' ? "week[fri][class_to_time][]" :
                                                                                        days.day === 'sat' ? "week[sat][class_to_time][]" :
                                                                                            "name to"
                                                            }
                                                        />
                                                    </div>
                                                    <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                                                        <div className="widget-chart-flex align-items-center">

                                                            <div>
                                                                <h4>

                                                                    {days ? days.day === 'sun' ? 'Sunday'
                                                                        : days.day === 'mon' ? 'Monday'
                                                                            : days.day === 'tue' ? 'Tuesday'
                                                                                : days.day === 'wed' ? 'Wednesday'
                                                                                    : days.day === 'thu' ? 'Thursday'
                                                                                        : days.day === 'fri' ? 'Friday'
                                                                                            : days.day === 'sat' ? 'Saturday' : null
                                                                        : null}
                                                                </h4>

                                                            </div>
                                                            <div style={{ display: 'none' }}>

                                                                <select
                                                                    id='day'
                                                                    name='day'
                                                                >
                                                                    <option value={days ? days.day : null}>
                                                                        {days ? days.day === 'sun' ? 'Sunday'
                                                                            : days.day === 'mon' ? 'Monday'
                                                                                : days.day === 'tue' ? 'Tuesday'
                                                                                    : days.day === 'wed' ? 'Wednesday'
                                                                                        : days.day === 'thu' ? 'Thursday'
                                                                                            : days.day === 'fri' ? 'Friday'
                                                                                                : days.day === 'sat' ? 'Saturday' : null
                                                                            : null}</option>
                                                                </select>

                                                            </div>
                                                            <div className="widget-title ml-auto font-size-lg font-weight-normal text-muted">
                                                                <div className="ml-auto">
                                                                    <FontAwesomeIcon style={{ fontSize: '40px' }} color="#545bd8" icon={faClock} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            }) : null
                        }

                    </Row>

                    <LaddaButton className="mb-2 mr-2 btn btn-primary"
                        loading={tStore.ccLoader}
                        type="submit"
                        data-style={ZOOM_IN}  >
                        CREATE CLASS
             </LaddaButton>

                </Form>
                <Button style={{ visibility: 'hidden' }} type="submit">{` `}</Button>
                <Button style={{ visibility: 'hidden' }} type="submit">{` `}</Button>
                <hr />
                <Row>
                    <Col sm={12} md={12}>
                        {/* <Table
                            loader={tStore.allUserTableLoader}
                            actions={'assign_student'}
                            // data={tStore.classes ? tStore.classes : []}
                            data={(tokenData.enterpriseUser && (tokenData.role == 'staff' || tokenData.role == 'principal')) ? tStore.enterpriseClasses : tStore.classes}

                            columns={columns}
                            hadleOpenModal={hadleOpenModal}
                            hadleOpenStudentModal={hadleOpenStudentModal}
                            handleOpenStudentListModal={handleOpenStudentListModal}
                            handleOpenEditModal={handleOpenEditModal}
                            handleEdit={handleEdit}
                            deleteClass={deleteClass}
                        /> */}
                          {/* <Showclass /> */}
                    </Col>
                </Row >

                <Modals
                    open={openStdModal}
                    handleClose={handleCloseStudentModal}
                    title={tokenData.enterpriseUser ? 'Assign Group' : 'Assign Students'}
                >
                    <AssignStudents
                        classId={classId}
                        // data={tStore.specificStudent}
                        data={tokenData.enterpriseUser ? tStore.specificGroup : tStore.specificStudent}
                        loader={tStore.specificGroupLoader}
                        handleCloseStudentModal={handleCloseStudentModal2}
                        timeData={timeData}
                    />
                </Modals>
                <Modals
                    open={studentListModal}
                    handleClose={handleCloseStudentListModal}
                    title={`View student`}

                    contentClassName="bg-light"
                >

                    <ViewStudent
                        loader={tStore.specificStudentLoader}
                        data={tStore.specificStudent}
                    //data={tokenData.enterpriseUser ? tStore.specificGroup : tStore.specificStudent}

                    />
                </Modals>
                <Modals
                    open={openModal}
                    handleClose={handleCloseModal}
                    title={`Time shedule`}
                >
                    <TimeShedule
                        data={tStore.specificClassTime}
                    />
                </Modals>

                <SweetAlert
                    title="Success"
                    confirmButtonColor=""
                    show={successModal}
                    text="Completed successfully"
                    type="success"
                    onConfirm={() => handleCloseSuccesstModal()} />
            </div>
        </>
    )
}

export default College
