import React from 'react'

import { Row, Col, Card } from "reactstrap";
import { Loader } from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUser,
    faBookOpen,
    faUsers,
    faClipboardCheck,
    faGraduationCap,
    faCalendarAlt,
    faHourglassStart

} from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay'
const DescriptionDetails = (props) => {
    const { assignmentStore, role } = props

    return (
        <>
            <Col md={7} className="assign-details-grid">
                <LoadingOverlay tag="div" active={!assignmentStore.assignmentDetails ? true : false}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 0.5
                        })
                    }}
                    spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                    <Card style={role === "teacher" ? { height: '295px' } : null} className="assign-details-card assign-details-card-descrip ">
                        <h6><strong>Description</strong></h6><hr />
                        <div className="assign-details-card-descrip-div" style={{ lineHeight: '25px' }}>{assignmentStore.assignmentDetails.Description}</div>
                    </Card>
                </LoadingOverlay>
            </Col>

            <Col md={5} className="assign-details-grid  ">
                <LoadingOverlay tag="div" active={!assignmentStore.assignmentDetails ? true : false}
                    styles={{
                        overlay: (base) => ({
                            ...base,
                            background: '#fff',
                            opacity: 0.5
                        })
                    }}
                    spinner={<Loader color="#ffffff" active type={'ball-scale-ripple-multiple'} />}>
                    <Card style={role === "teacher" ? { height: '295px' } : null} className="assign-details-card assign-details-card-descrip">
                        <h6><strong>Details</strong></h6><hr />
                        <Row>
                            <Col md={5} style={{ display: role === "teacher" ? 'none' : 'block' }} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: 'rgb(59, 226, 193)' }}> <FontAwesomeIcon icon={faUser} /></span>{` Teacher`}</Col><Col md={7} style={{ display: role === "teacher" ? 'none' : 'block' }} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Teacher_name}</Col>
                            <Col md={5} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: '#3FD2C7' }}> <FontAwesomeIcon icon={faBookOpen} /></span>{'Subject'}</Col><Col md={7} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Subject}</Col>
                            <Col md={5} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: '#3FD2C7' }}> <FontAwesomeIcon icon={faUsers} /></span>{'Class'}</Col><Col md={7} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Class}</Col>
                            <Col md={5} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: '#3FD2C7' }}> <FontAwesomeIcon icon={faGraduationCap} /></span>{'Grade'}</Col><Col md={7} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Grade}</Col>
                            <Col md={5} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: '#3FD2C7' }}> <FontAwesomeIcon icon={faCalendarAlt} /></span>{' Created On'}</Col><Col md={7} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Created_on}</Col>
                            <Col md={5} className="assign-details-card-div-head"><span className="pr-2 opacity-6" style={{ color: '#3FD2C7' }}> <FontAwesomeIcon icon={faHourglassStart} /></span>{' Deadline'}</Col><Col md={7} className="assign-details-card-div"> {assignmentStore.assignmentDetails.Deadline}</Col>
                            {
                                assignmentStore.assignmentDetails.grading_type === "grading" &&
                                <>
                                    <Col md={5}

                                        className="assign-details-card-div-head">
                                        <span className="pr-2 opacity-6"
                                            style={{ color: 'rgb(59, 226, 193)' }}>
                                            <FontAwesomeIcon icon={faClipboardCheck} />
                                        </span>{' Mark System'}
                                    </Col>
                                    <Col md={7} className="assign-details-card-div">
                                        Grading
                                </Col>
                                </>
                            }
                            <Col md={5}
                                style={{ display: assignmentStore.assignmentDetails.grading_type === "grading" || assignmentStore.assignmentDetails.grading_type === null ? 'none' : null }}
                                className="assign-details-card-div-head"><span className="pr-2 opacity-6"
                                    style={{ color: 'rgb(59, 226, 193)' }}>
                                    <FontAwesomeIcon icon={faClipboardCheck} /></span>{' Total Mark'}
                            </Col>
                            <Col md={7} style={{ display: assignmentStore.assignmentDetails.grading_type === "grading" ? 'none' : null }} className="assign-details-card-div">
                                {assignmentStore.assignmentDetails.Total_mark}
                            </Col>




                        </Row>



                    </Card>
                </LoadingOverlay>
            </Col>
        </>
    )
}

export default DescriptionDetails
