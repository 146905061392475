import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import CountUp from 'react-countup';
import Swal from 'sweetalert2'

import noData from './2953962.jpg'
import {
    Row, Col, Form, FormGroup, Input, InputGroupAddon,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card, CardBody, FormText, Label,
    CardTitle, CardHeader, CardFooter,
    Button, ButtonGroup, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Container
} from 'reactstrap';
import PageTitle from './PageTitle'
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import Table from "./table";

import { useDispatch, useSelector } from 'react-redux'
import { getStudentAttendance } from '../../../redux/Actions/homeTeacher'
import { useHistory } from "react-router-dom";

const Index = (props) => {

    console.log("dataaaaa", props)
    const dispatch = useDispatch();
    const history = useHistory();
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    const tStore = useSelector(({ homeTeacher }) => homeTeacher)
    const params = useParams()
    useEffect(() => {
        if (!localStorage.getItem('authDetails')) {
            history.push('/')
        }

        if ('data' in props.location) {
            dispatch(getStudentAttendance(tokenData.token, props.location.data))

        } else {
            Swal.fire({
                icon: 'warning',
                //  title: 'Required',
                text: "You have to re-enter the dateo"

            })
            //  alert('You have to re-enter the date');
            history.push('/dashboard/show-attendance')

        }

    }, [dispatch]);


    const columns = [
        { title: '#', field: 'tableData.id' },
        { title: 'Student_name', field: 'name' },
        { title: 'Status', field: 'status' },
        { title: 'Student_Duration', field: 'student_duration' },
        { title: 'Teacher_Duration', field: 'teacher_duration' },
        // { title: 'Standerd', field: 'grade' },
    ]
    console.log("tStore.studentAttandance[0]", tStore.studentAttandance[0])
    return (
        <>

            <PageTitle

                heading={tStore.studentAttandance.details.length > 0 ? tStore.studentAttandance.details[0] ? tStore.studentAttandance.details[0].class_name : '' : ''}
                subheading={tStore.studentAttandance.details.length > 0 ? tStore.studentAttandance.details[0] ? tStore.studentAttandance.details[0].subject_name : '' : ''}
                date={tStore.studentAttandance.details.length > 0 ? tStore.studentAttandance.details[0] ? tStore.studentAttandance.details[0].created_at : '' : ''}
                icon={tStore.studentAttandance.details.length > 0 ? "pe-7s-users icon-gradient bg-happy-itmeo" : ''}
            />
            {
                tStore.studentAttandance.details.length > 0 ?

                    <>
                        <Row>
                            <Col lg="6" xl="4">
                                <div className="card mb-3 widget-content bg-night-fade">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Absent Students
                            </div>
                                            <div className="widget-subheading">
                                                Students not present
                            </div>
                                        </div>

                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-white">
                                                <CountUp end={tStore.studentAttandance.nof_absent}
                                                // duration="10" 
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" xl="4">
                                <div className="card mb-3 widget-content bg-arielle-smile">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Present Students
                            </div>
                                            <div className="widget-subheading">
                                                Students attanded the class
                            </div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-white">
                                                <CountUp
                                                    //start={75}
                                                    end={tStore.studentAttandance.nof_present}
                                                //separator=""
                                                //decimals={0}
                                                //decimal=","
                                                //prefix="$ "
                                                // duration="10"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="6" xl="4">
                                <div className="card mb-3 widget-content bg-premium-dark">
                                    <div className="widget-content-wrapper text-white">
                                        <div className="widget-content-left">
                                            <div className="widget-heading">
                                                Total Students
                            </div>
                                            <div className="widget-subheading">
                                                Total Students in the class
                            </div>
                                        </div>
                                        <div className="widget-content-right">
                                            <div className="widget-numbers text-warning">
                                                <CountUp
                                                    start={1}
                                                    end={tStore.studentAttandance.total_students}
                                                // separator=""
                                                // decimals={0}
                                                // decimal=","
                                                // prefix="$"
                                                // suffix="M"
                                                // duration="10"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>

                        <Row>
                            <Col sm={12} md={12}>
                                <Table
                                    loader={tStore.studentAttandanceLoader}
                                    data={tStore.studentAttandance.details}
                                    //data={dummyData}
                                    columns={columns}
                                // actions='attandance'
                                //handleSelectedRow={handleSelectedRow}
                                />
                            </Col>
                        </Row >
                    </>
                    : <Row style={{ justifyContent: 'center' }}>
                        <img src={noData} width={500} />
                    </Row>

            }

        </>
    )
}

export default Index
