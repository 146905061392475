import React, { useState } from 'react'
import { Col, Row, Input, FormGroup, FormFeedback, } from 'reactstrap'
// import LaddaButton, { ZOOM_IN } from 'react-ladda';
// import serialize from 'form-serialize'
// import thumb from '../../../assets/utils/images/thumbnail1.png'
import './chooseFromPlaylist.scss'
// import cx from 'classnames';
import NumericInput from 'react-numeric-input';
// import NumberPicker from 'react-widgets/lib/NumberPicker';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import moment from 'moment'
simpleNumberLocalizer()

const UploadVideos = (props) => {
    const { type, title, selectedIdOrigin, index, textInputRef, handleChange, handleTime, handleOrder, handleDate, duration, playlistsVideoId, playlist_video_id, format, id, url, syncVideo, multiSelectVideo, videoId, mutliSelect, thumbnile, handleClickSync } = props
    const [isToggleOn, setIsToggleOn] = useState(false)
    const handleClick = () => {

        setIsToggleOn(!isToggleOn)
    }
    const [editTitle, setEditTitle] = useState(title)
    const [orderVal, setOrderVal] = useState(1)
    const onChangeOrder = (val) => {
        handleOrder(`order-${index}`)
        setOrderVal(val)
    }
    console.log("title", title)
    return (
        <>
            {
                type == "upload_video" ?
                    <>
                        <Col md={4} lg={4} sm={4} xs={4} style={{ marginBottom: 25 }} className="jms-choose-from-playlist-thumb-video-col-3" >
                            {/* <input type="hidden" disabled={!mutliSelect.includes(videoId)} value={videoId} name="videos[playlist_video][]" /> */}
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={playlist_video_id} name="videos[playlist_video_id][]" />
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={videoId} name="videos[playlist_video][]" />


                            <div className="jms-choose-from-playlist-thumb-card" style={{ backgroundImage: `url(${thumbnile})` }}>
                                <input
                                    style={{ marginLeft: 6 }}
                                    type="checkbox"
                                    id={`id-${id}`}
                                    checked={playlistsVideoId.includes(playlist_video_id)}
                                    value={videoId}
                                    className="jms-choose-from-playlist-thumb-checkbox"
                                    onChange={() => multiSelectVideo(videoId, playlist_video_id)}
                                    name="muliselect"
                                />
                                <div className="jms-choose-from-playlist-thumb-title" > {editTitle}</div>
                                <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                                {/* <div className="jms-choose-from-playlist-thumb-format" >{format}</div> */}
                                {/* <div className="jms-choose-from-playlist-thumb-format-choose" ><span style={{ float: 'right', fontSize: 11, }}>
                                    Sync  &nbsp;{` `}
                                    <input
                                        // style={{ float: 'right' }}
                                        onChange={() => handleClickSync(videoId)}
                                        value={isToggleOn}
                                        type="checkbox"
                                        id={`sync${videoId}`}
                                        name={`sync-${videoId}`}
                                        disabled={!mutliSelect.includes(videoId)}
                                        checked={syncVideo.includes(videoId)}

                                    />
                                </span></div> */}

                            </div>
                            {/* <i style={{ fontSize: '125px', paddingTop: 10 }} className="pe-7s-film icon-gradient bg-happy-itmeo" /> */}
                            <Input type="text" name="videos[title][]"
                                value={title}
                                // onChange={(e) => setEditTitle(e.target.value)}
                                disabled={!playlistsVideoId.includes(playlist_video_id)}
                                placeholder="New title here"
                                onChange={(e) => handleChange(e, id)}
                                style={{ width: '-webkit-fill-available', marginLeft: 8, marginTop: 10 }}
                                invalid={!title}
                            />



                        </Col>
                    </>
                    :
                    <Row style={playlistsVideoId.includes(playlist_video_id) ? { padding: '6px 0px 6px 10px', borderBottom: '1px solid #dedada', background: 'aliceblue' } : { background: '#e9ecef', padding: '6px 0px 6px 10px', borderBottom: '1px solid #dedada' }}>

                        <Col md={4} lg={4} sm={12} xs={12} className="jms-choose-from-playlist-thumb-video-col-3" >
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={videoId} name="videos[video_id][]" />
                            <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} value={playlist_video_id} name="videos[playlist_video_id][]" />

                            <Row>
                                <Col md={12} >
                                    <div className="jms-choose-from-playlist-thumb-card" style={{ backgroundImage: `url(${thumbnile})` }}>
                                        <input type="checkbox" checked={playlistsVideoId.includes(playlist_video_id)} value={videoId}
                                            className="jms-choose-from-playlist-thumb-checkbox"
                                            onClick={() => multiSelectVideo(videoId, playlist_video_id, thumbnile, index)} />
                                        <div className="jms-choose-from-playlist-thumb-title" > {title}</div>
                                        <div className="jms-choose-from-playlist-thumb-size">{duration}</div>
                                        <div className="jms-choose-from-playlist-thumb-format" >{format}</div>
                                    </div>
                                    {/* <i style={{ fontSize: '125px', paddingTop: 10 }} className="pe-7s-film icon-gradient bg-happy-itmeo" /> */}

                                </Col>


                            </Row>
                        </Col>

                        <Col md={8} lg={8} sm={12} xs={12}>
                            <Row style={{ paddingTop: 5, paddingLeft: 10, fontSize: 10 }}>
                                Everyday student can watch between these dates
                            </Row>
                            <Row style={{ paddingTop: 10 }}>

                                <Col md={6} lg={6} sm={6} xs={6}>

                                    <FormGroup style={{ marginBottom: '4px' }}>
                                        {/* {console.log(selectedId[index] ? true : false)}

                                        {console.log(selectedId[index]?.length > 0 ? selectedId[index].length : false)}

                                        {console.log(selectedId[index]?.length > 0 ? !selectedId[index].includes(`fromDate-${index}`) : false)} */}
                                        {/* <Label style={{ marginBottom: '0px' }} for="exampleCustomSelectDisabled">From Date *</Label> */}
                                        <Input
                                            // required 
                                            invalid={selectedIdOrigin[index] ? !selectedIdOrigin[index].includes(`fromDate-${index}`) : false}
                                            onChange={handleDate} id={`fromDate-${index}`}
                                            min={moment(new Date()).format("YYYY-MM-DD")
                                            }
                                            ref={textInputRef}
                                            type="date" name="videos[video_from_date][]" placeholder="from date" disabled={!playlistsVideoId.includes(playlist_video_id)} />
                                        <FormFeedback >oops! Type is required</FormFeedback>

                                    </FormGroup>

                                </Col>
                                <Col md={6} lg={6} sm={6} xs={6}>
                                    <FormGroup style={{ marginBottom: '4px' }}>
                                        {/* <Label style={{ marginBottom: '0px' }} for="exampleCustomSelectDisabled">To Date *</Label> */}
                                        <Input
                                            // required 
                                            onChange={handleDate}
                                            min={moment(new Date()).format("YYYY-MM-DD")}
                                            ref={textInputRef}
                                            invalid={selectedIdOrigin[index] ? !selectedIdOrigin[index].includes(`toDate-${index}`) : false}

                                            id={`toDate-${index}`} type="date" name="videos[video_to_date][]" placeholder="from date" disabled={!playlistsVideoId.includes(playlist_video_id)} />
                                        <FormFeedback >oops! Type is required</FormFeedback>

                                    </FormGroup>

                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 5, paddingLeft: 10, fontSize: 10 }}>
                                Everyday student can watch dialy(in given date) in these times
                            </Row>
                            <Row style={{ paddingTop: 10 }}>
                                <Col md={6} lg={6} sm={6} xs={6}>
                                    <FormGroup style={{ marginBottom: '4px' }}>
                                        {/* <Label style={{ marginBottom: '0px' }} for="exampleCustomSelectDisabled">From Time *</Label> */}
                                        <Input
                                            //  required 
                                            invalid={selectedIdOrigin[index] ? !selectedIdOrigin[index].includes(`fromTime-${index}`) : false}

                                            type="time"
                                            name="videos[video_from_time][]"
                                            placeholder="from Time"
                                            id={`fromTime-${index}`}
                                            onChange={handleTime}
                                            disabled={!playlistsVideoId.includes(playlist_video_id)} />
                                        <FormFeedback >oops! Type is required</FormFeedback>

                                    </FormGroup>

                                </Col>
                                <Col md={6} lg={6} sm={6} xs={6}>
                                    {/*  */}
                                    <FormGroup style={{ marginBottom: '4px' }}>
                                        {/* <Label style={{ marginBottom: '0px' }} for="exampleCustomSelectDisabled">To Time *</Label> */}
                                        <Input
                                            // required
                                            type="time"
                                            name="videos[video_to_time][]"
                                            placeholder="to time"
                                            onChange={handleTime}
                                            invalid={selectedIdOrigin[index] ? !selectedIdOrigin[index].includes(`toTime-${index}`) : false}

                                            id={`toTime-${index}`}


                                            disabled={!playlistsVideoId.includes(playlist_video_id)} />
                                        <FormFeedback >oops! Type is required</FormFeedback>

                                    </FormGroup>

                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 5 }}>
                                <Col md={6} lg={6} sm={6} xs={6}>
                                    <div style={{ fontSize: 11, float: 'left', display: 'contents' }}>
                                        Order of video
                    </div>
                                    <div style={{ float: 'right' }}>
                                        <NumericInput
                                            // required
                                            min={1} mobile className="jms-number-height"
                                            onChange={onChangeOrder}
                                            value={orderVal}
                                            // id={`order-${index}`}
                                            name="videos[order_of_video][]" disabled={!playlistsVideoId.includes(playlist_video_id)} />

                                    </div>

                                </Col>
                                <Col md={6}  >
                                    {
                                        type == "upload_video" ?
                                            <>
                                                {/* <div style={{ fontSize: 11, float: 'left', display: 'contents' }}>
                                        Sync this
                                    </div> */}
                                                {/* <input type="hidden" name="sync" value={isToggleOn} /> */}
                                                {/* <div className="switch has-switch mr-2 mb-2 mr-2" data-on-label="Yes"
                            style={{ float: 'right' }}
                            data-off-label="No"
                            onClick={handleClick}>
                            <div className={cx("switch-animate", {
                                'switch-on': isToggleOn,
                                'switch-off': !isToggleOn
                            })}> */}
                                                <span style={{ float: 'right', fontSize: 11, }}>
                                                    Sync this &nbsp;{` `}
                                                    <input
                                                        // style={{ float: 'right' }}
                                                        onClick={() => handleClickSync(videoId)}
                                                        value={isToggleOn}
                                                        type="checkbox"
                                                        disabled={!mutliSelect.includes(videoId)}
                                                        checked={syncVideo.includes(videoId)}

                                                    />
                                                </span>


                                                {/* <span
                                    className="switch-left bg-warning">Yes</span><label>&nbsp;</label><span
                                        className="switch-right bg-warning">No</span>
                            </div>
                        </div> */}
                                            </>
                                            : type == "create_class" ?
                                                <>
                                                    {/* <div style={{ fontSize: 11, float: 'left', display: 'contents' }}>
                                                        % should watch
                        </div>
                                                    <div style={{ float: 'right' }}>
                                                        <NumericInput
                                                            required
                                                            className="jms-number-height"
                                                            max={100}
                                                            name="videos[percentage][]"
                                                            step={25}
                                                            disabled={!playlistsVideoId.includes(playlist_video_id)}
                                                            min={0}
                                                        />

                                                        
                                                    </div>
                                                   */}
                                                    <input type="hidden" disabled={!playlistsVideoId.includes(playlist_video_id)} name="videos[percentage][]" value={25} />
                                                </> : null

                                    }

                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }

        </>
    )
}
UploadVideos.defaultProps = {
    syncVideo: [],
    mutliSelect: [],

};
export default UploadVideos
