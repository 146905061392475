import React, { Fragment } from 'react';

import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import PerfectScrollbar from 'react-perfect-scrollbar';
const color = ['text-warning', 'text-danger', 'text-warning', 'text-info', 'text-success', 'text-danger', 'text-warning', 'text-danger', 'text-warning', 'text-danger']

const TimelineEx = (props) => {
    console.log("prooo", props)

    return (
        <Fragment>
            <div className="scroll-area-sm">
                <PerfectScrollbar>
                    <div className="p-3">

                        {props.data.length > 0 ? props.data.map((dt, index) => {
                            return (
                                <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column">

                                    <VerticalTimelineElement
                                        className="vertical-timeline-item"
                                    >
                                        <p >{dt.title}, at{dt.time}
                                            <b className={`${color[index] ? color[index] : 'text-danger'}`}>{dt.time}</b></p>

                                    </VerticalTimelineElement>
                                </VerticalTimeline>

                            )
                        }) : <p> There is no notifications</p>}
                        {/* {props.data.length === 0 ? "There is no notifications" : null} */}

                        {/* <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <p>
                                    Another meeting today, at <b className="text-danger">12:00 PM</b>
                                </p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">Build the production release</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">All Hands Meeting</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title text-success">FontAwesome Icons</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <h4 className="timeline-title">Build the production release</h4>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                className="vertical-timeline-item"
                            >
                                <p>
                                    Another meeting today, at <b className="text-warning">12:00 PM</b>
                                </p>
                            </VerticalTimelineElement> */}
                    </div>
                </PerfectScrollbar>
            </div>
        </Fragment>
    )

}

export default TimelineEx;