import React, { Fragment, useState, useEffect, useRef } from "react";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Container, Card } from "reactstrap";
// import './imageView.scss'
import { useParams } from "react-router-dom";
import UpdateExam from "./UpdateExam";
import PublishResult from "./PublishResult";
import SendIndividualEmail from "./IndividualEmail";
import { Loader } from "react-loaders";
import LoadingOverlay from "react-loading-overlay";
import Table from "../common/table";
import { useHistory } from "react-router-dom";
// import '../assign.scss'
import { examDetails } from "../../../redux/Actions/exam";
import PageTitle from "../../HeaderTitle/PageTitle";

import Modals from "../../common/Modal";
import SendEmail from "./Email";

const SplitLayout = () => {
  const dispatch = useDispatch();
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  const [updateModal, setupdateModal] = useState(false);
  const [emailModal, setemailModal] = useState(false);
  const [resultModal, setresultModal] = useState(false);
  const [individualEmailModal, setindividualEmailModal] = useState(false);
  const [questionPaperId, setquestionPaperId] = useState();

  const [studentID, setstudentID] = useState(null);
  const [ExamID, setExamID] = useState(null);
  const [RowData, setRowData] = useState(null);
  const history = useHistory();
  const { exam_id } = useParams();
  const ims = useRef(null);
  const examStore = useSelector(({ exam }) => exam);

  //console.log("xam", examStore.examDetails);

  // for( let i=0; i<examStore.examDetails?.student_details.length; i++ )
  // {
  //    examStore.examDetails.student_details[i].group_details.map((val)=>{
  //       let name = val.student_name;

  //    })
  // }

  useEffect(() => {
    dispatch(examDetails(cb, tokenData.token, exam_id));
    //console.log('asdf',examStore)
    return () => {
      //  dispatch({ type: 'RESET_ASSIGNMENT_DETAILS' })
    };
  }, []);

  const cb = (question_paper_id) => {
    setquestionPaperId(question_paper_id);
  };

  /// const question_paper_id = examStore.examDetails?.question_paper_id;

  const getDetails = (answer_sheet_id, student_id) => {
    history.push(`/dashboard/exam/showExams/${exam_id}/${student_id}`);
  };

  const emailIndividualResult = (rowData) => {
    console.log("data from email", rowData);
    setRowData(rowData);
    setindividualEmailModal(true);
  };
  const handleCloseIndividualEmail = () => {
    setindividualEmailModal(false);
  };
  const updateExam = (rowData) => {
    setRowData(rowData);
    setupdateModal(true);
  };
  const handleClose = () => {
    console.log("closed");
    setupdateModal(false);
  };

  const handleCloseEmail = () => {
    setemailModal(false);
  };

  const handleCloseResult = () => {
    setresultModal(false);
  };

  const handleShowQuestion = () => {
    history.push(`/dashboard/exam/showQuestions/${questionPaperId}`);
  };

  //Publish result and email result buttons are made to be a component so can pass it to page header component

  function renderButton() {
    return (
      <Row>
        <Col>
          <Button
            style={{ marginRight: "10px" }}
            className="btn-pill btn-shadow fsize-1 "
            size="lg"
            color="primary"
            onClick={() => setresultModal(true)}
          >
            Publish Result
          </Button>

          <Button
            style={{ marginRight: "10px" }}
            className="btn-pill btn-shadow  fsize-1 "
            size="lg"
            color="primary"
            onClick={() => setemailModal(true)}
          >
            E-mail Results
          </Button>

          <Button
            className="btn-pill btn-shadow  fsize-1 "
            size="lg"
            color="primary"
            onClick={handleShowQuestion}
          >
            Show Question Paper
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row>
          <Col md={12} className="assign-details-grid">
            <Row>
              <Col md={12}>
                <PageTitle
                  heading={examStore.examDetails?.exam_title}
                  subheading={examStore.examDetails?.subject}
                  icon="pe-7s-drawer icon-gradient bg-happy-itmeo"
                  actions={renderButton}
                />
              </Col>
            </Row>
          </Col>
          {/* <Col md={4}  className="assign-details-grid">
                    <Row>
                      <Col>
                        <Button className="btn-pill btn-shadow btn-wide fsize-1 mt-0 mb-4 mr-2" size="sm" color="success"  onClick={() => setresultModal(true)} >Publish Result</Button>                 
                      </Col>
                      <Col>
                      <Button className="btn-pill btn-shadow btn-wide fsize-1 mb-4"  size="sm" color="primary" onClick={ ()=>setemailModal(true)} >E-mail Results</Button>      
                      </Col>
                    </Row> 
                 </Col> */}
        </Row>

        <Row>
          <Col md="6" lg="4">
            <div
              style={{ color: "white", backgroundColor: "#123456" }}
              className="card mb-3 widget-chart card-hover-shadow-2x text-left"
            >
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-numbers">
                  {examStore.examDetails?.exam_duration}
                </div>
                <div className="widget-subheading">Exam Duration</div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div
              style={{ color: "white", backgroundColor: "rgb(117 62 74)" }}
              className="card mb-3 widget-chart card-hover-shadow-2x text-left"
            >
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-numbers">
                  {examStore.examDetails?.total_students}
                </div>
                <div className="widget-subheading">Total Students</div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="card mb-3 widget-chart card-hover-shadow-2x text-left">
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                {/* <i className="lnr-enter icon-gradient bg-grow-early" /> */}
                <i className="lnr-laptop-phone icon-gradient bg-grow-early ">
                  {" "}
                </i>
              </div>
              <div className="widget-chart-content">
                <div className="widget-numbers">
                  {examStore.examDetails?.attended}
                </div>
                <div className="widget-subheading">Attended Students</div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div
              style={{ color: "white", backgroundColor: "#3FD2C7 " }}
              className="card mb-3 widget-chart card-hover-shadow-2x text-left"
            >
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                <i className="lnr-enter icon-gradient bg-heavy-rain" />
              </div>
              <div className="widget-chart-content jms-opacity-overwrite">
                <div className="widget-numbers">
                  {examStore.examDetails?.not_attended}
                </div>
                <div className="widget-subheading">Not Attended Students</div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div
              style={{ color: "white", backgroundColor: "rgb(143 150 142)" }}
              className="card mb-3 widget-chart card-hover-shadow-2x text-left"
            >
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-numbers">
                  {examStore.examDetails?.total_mark}
                </div>
                <div className="widget-subheading">Total Mark</div>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div
              style={{ color: "white", backgroundColor: "rgb(52 113 108)" }}
              className="card mb-3 widget-chart card-hover-shadow-2x text-left"
            >
              <div className="icon-wrapper border-light rounded">
                <div className="icon-wrapper-bg bg-light" />
                <i className="lnr-graduation-hat icon-gradient bg-heavy-rain" />
              </div>
              <div className="widget-chart-content">
                <div className="widget-numbers">
                  {examStore.examDetails?.total_questions}
                </div>
                <div className="widget-subheading">Total Questions</div>
              </div>
            </div>
          </Col>
        </Row>

        <h6>Exam Submissions </h6>
        <hr />
        <Table
          getDetails={getDetails}
          List_of_student={examStore.examDetails?.student_details}
          updateExam={updateExam}
          emailResult={emailIndividualResult}
        />

        <Modals
          open={updateModal}
          handleClose={handleClose}
          title={"Update timings"}
        >
          <UpdateExam
            handleClose={handleClose}
            exam_id={exam_id}
            exam_title={examStore.examDetails?.exam_title}
            rowData={RowData}
          />
        </Modals>

        <Modals
          size={"lg"}
          open={emailModal}
          handleClose={handleCloseEmail}
          title={"Email students"}
        >
          <SendEmail handleClose={handleCloseEmail} exam_id={exam_id} />
        </Modals>

        <Modals
          size={"lg"}
          open={individualEmailModal}
          handleClose={handleCloseIndividualEmail}
          title={"Email result"}
        >
          <SendIndividualEmail
            handleClose={handleCloseIndividualEmail}
            exam_id={exam_id}
            student_id={RowData?.student_id}
          />
        </Modals>

        <Modals
          open={resultModal}
          handleClose={handleCloseResult}
          title={"Set result publishing date"}
        >
          <PublishResult handleClose={handleCloseResult} exam_id={exam_id} />
        </Modals>
      </CSSTransitionGroup>
    </Fragment>
  );
};
export default SplitLayout;
