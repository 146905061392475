import React from 'react'
import {

    Button,
    CardHeader,

    Card,

    ListGroup,
    ListGroupItem, CardFooter,


} from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import noFile from '../../assets/utils/images/myicon/noAssignment.png'
import noassignment from "../../assets/images/noassignment.png"
// import {
//     faAngleUp,
//     faAngleDown,
//     faCalendarAlt,
//     faEllipsisH,
//     faCheck,
//     faTrashAlt
// } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const assignColor = ['bg-focus', 'bg-warning', 'bg-primary', 'bg-danger', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warning', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-focus', 'bg-danger', 'bg-warnnig', 'bg-primary', 'bg-warning', ' bg-info', 'bg-success', 'bg-primary', 'bg-danger']
const Assignments = (props) => {
    // const tokenData = JSON.parse(localStorage.getItem("authDetails"));
    const { assignments } = props;
    // const toggle = () => {
    //     // setState(prevState => ({
    //     //     dropdownOpen: !prevState.dropdownOpen
    //     // }));
    // }



    return (
        <Card className="card-hover-shadow-2x mb-3">
            <CardHeader className="card-header-tab">
                <div
                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-database icon-gradient bg-malibu-beach"> </i>
                   Assignment
                </div>

                <div className="btn-actions-pane-right text-capitalize actions-icon-btn">
                    {/* <UncontrolledButtonDropdown>
                        <DropdownToggle className="btn-icon btn-icon-only" color="link">
                            <i className="pe-7s-menu btn-icon-wrapper" />
                        </DropdownToggle>
                        <DropdownMenu
                            className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                            <DropdownItem header>Header</DropdownItem>
                            <DropdownItem>
                                <i className="dropdown-icon lnr-inbox"> </i>
                                <span>Menus</span>
                            </DropdownItem>
                            <DropdownItem>
                                <i className="dropdown-icon lnr-file-empty"> </i>
                                <span>Settings</span>
                            </DropdownItem>
                            <DropdownItem>
                                <i className="dropdown-icon lnr-book"> </i>
                                <span>Actions</span>
                            </DropdownItem>
                            <DropdownItem divider />
                            <div className="p-3 text-right">
                                <Button className="mr-2 btn-shadow btn-sm" color="link">View
                                    Details</Button>
                                <Button className="mr-2 btn-shadow btn-sm"
                                    color="primary">Action</Button>
                            </div>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown> */}
                </div>
            </CardHeader>
            <div className="scroll-area-lg">
                <PerfectScrollbar>
                    <div className="p-2">
                        <ListGroup className="todo-list-wrapper" flush>


                            {assignments ?
                                assignments.map((assignments, index) => {
                                    return (
                                        <Link style={{ textDecoration: 'none', color: 'black', textTransform: 'capitalize' }} to={`/dashboard/assignments/${assignments.assign_id}/${assignments.class_id}/${assignments.student_id}/${assignments.aa_id}`}>

                                            <ListGroupItem>
                                                <div className={`todo-indicator ${assignColor[index]}`} />

                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left mr-2">
                                                            {/* <CustomInput type="checkbox" id="exampleCustomCheckbox4"
                                                                label="&nbsp;" /> */}
                                                        </div>
                                                        <div className="widget-content-left flex2">
                                                            <div className="widget-heading">
                                                                {assignments.title}
                                                            </div>
                                                            <div className="widget-subheading">
                                                                By {`${assignments.first_name} ${assignments.last_name}`}

                                                            </div>
                                                        </div>
                                                        {/* <div
                                                            className="widget-content-right widget-content-actions">
                                                            <Button className="border-0 btn-transition" outline
                                                                color="success">
                                                                <FontAwesomeIcon icon={faCheck} />
                                                            </Button>
                                                        </div> */}
                                                        {
                                                            assignments.upload_notified_status === 1 ?
                                                                <div className="widget-content-right ml-3">
                                                                    <div className="badge badge-pill badge-success">New
                                                                </div>
                                                                </div> : null
                                                        }

                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        </Link>
                                    )
                                }) : []
                            }

                            {assignments.length === 0 ?
                                <p style={{ textAlign: 'center', paddingTop: '40px' }}><img style={{   width:'100%' }} src={noassignment}  />
                                {/* <p style={{ textAlign: 'center', color: 'gray' }}>There is no assignments </p> */}
                                </p>

                                : null}





                        </ListGroup>
                    </div >
                </PerfectScrollbar >
            </div >
            <CardFooter className="d-block text-right">
                {/* <Button size="sm" className="mr-2" color="link">Cancel</Button> */}
                <a href="#/dashboard/assignments"> <Button color="primary">View All Assignments</Button></a>
            </CardFooter>
        </Card >
    )
}

export default Assignments
