import React, { Fragment, useState } from "react";

import Slider from "react-slick";
import { useDispatch } from 'react-redux'
import bg1 from '../../assets/utils/images/originals/f1a.jpg';
import bg2 from '../../assets/utils/images/originals/f2a.jpg';
import bg3 from '../../assets/utils/images/originals/d1.jpg';
import logo from '../../assets/utils/images/logoMots.png'
import { recoverPassword } from '../../redux/Actions/auth'
import { Col, Row, Button, Form, FormGroup, Label, Input, Alert } from 'reactstrap';

import {
    ToastContainer,
    toast,
    Bounce,
    Slide,
    Flip,
    Zoom
} from 'react-toastify';
import { is } from "date-fns/locale";
const ForgotPassword = () => {

    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true

    };
    const dispatch = useDispatch()
    const [inputField, setInputField] = useState()
    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputField({ ...inputField, [name]: value })

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log("from submitted")
        dispatch(recoverPassword(inputField.email, cb, cb2))
    }

    const cb = (res) => {
        if (res.result) {
            toast["success"](res.message, { containerId: 'A' });

        } else if (res.result === false) {
            toast["danger"](res.message, { containerId: 'A' });

        }
    }
    const [error, setError] = useState({
        result: false,
        message: ''
    })
    const cb2 = (msg) => {
        setError({
            result: true,
            message: msg
        })
    }
    const onDismiss = () => {
        setError({
            result: false,
            message: ''
        })
    }


    return (

        <Fragment>
            <ToastContainer containerId={'A'} />
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="4" className="d-none d-lg-block">
                        <div className="slider-light">
                            <Slider  {...settings}>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg1 + ')'
                                        }} />

                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                    <div className="slide-img-bg"
                                        style={{
                                            backgroundImage: 'url(' + bg3 + ')'
                                        }} />

                                </div>
                                <div
                                    className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                                    <div className="slide-img-bg opacity-6"
                                        style={{
                                            backgroundImage: 'url(' + bg2 + ')'
                                        }} />

                                </div>
                            </Slider>
                        </div>
                    </Col>
                    <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
                        <Col lg="6" md="8" sm="12" className="mx-auto app-login-box">
                            {/* <div className="app-logo" /> */}
                            <img src={logo} width="120px" height="180px" style={{ paddingBottom: '20px' }} />

                            <h4>
                                <div>Forgot your Password?</div>
                                <span>Use the form below to recover it.</span>
                            </h4>
                            <div>
                                {
                                    error.result ? <Alert color="danger" isOpen={true} toggle={onDismiss}
                                    //toggle={""}
                                    >{error.message}
                                    </Alert> : null
                                }

                                <Form onSubmit={handleSubmit}>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="exampleEmail">Email</Label>
                                                <Input type="email" name="email" id="exampleEmail"
                                                    placeholder="Email here..."
                                                    required
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div className="mt-4 d-flex align-items-center">
                                        <h6 className="mb-0">
                                            <a href="/#" className="text-primary">Sign in existing account</a>
                                        </h6>
                                        <div className="ml-auto">
                                            <Button color="primary" size="lg" type="submit">Recover Password</Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );

}
export default ForgotPassword