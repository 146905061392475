import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requiredValidator } from "../../../../utilities/Validator";

import {
  Row,
  Col,
  Card,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
} from "reactstrap";
import PlaylistTumnile from "../../../common/Thumbnile/playlist";
import LaddaButton, { ZOOM_IN } from "react-ladda";
// import { getGradeList, populateDegree } from '../../../redux/Actions/signUp'
import { populateSubject } from "../../../../redux/Actions/homeTeacher";
import serialize from "form-serialize";
import {
  populatePlayList,
  createPlayList,
} from "../../../../redux/Actions/playlist";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

// import Table from '../common/DataTable'

const School = (props) => {
  const {
    DeletePlaylists,
    EditPlaylists,
    editMode,
    openEdit,
    handleChange,
    cancelEdit,
  } = props;

  const dispatch = useDispatch();
  // const tStore = useSelector(({ homeTeacher }) => homeTeacher)
  const playlistStore = useSelector(({ playlist }) => playlist);
  const tokenData = JSON.parse(localStorage.getItem("authDetails"));

  useEffect(() => {
    dispatch(populatePlayList(tokenData.token, null));

    //  dispatch(populateGrade(null, ))

    return () => {
      console.log("unmount school");
    };
  }, []);

  // const handleChangeGrade = e => {
  //     const { value } = e.target;
  //     //setInputField({ ...inputField, [name]: value })
  //     dispatch(populateSubject(tokenData.token, value, 1))
  // };

  // const onEdit = (rowData) => {
  //     console.log("row-", rowData)
  //     dispatch(updateSubjectSchhol(tokenData.token, rowData))

  // }

  // const onDelete = (id) => {

  //     Swal.fire({
  //         icon: 'warning',
  //         title: 'Are you sure !',
  //         // text: 'All the classes under the subject will be deleted',
  //         showCancelButton: true,
  //         confirmButtonColor: "#DD6B55",
  //         confirmButtonText: "CONFIRM",
  //         cancelButtonText: "CANCEL",

  //     }).then((result) => {
  //         if (result.value) {
  //             dispatch(deleteSubjectSchool(tokenData.token, id))

  //             Swal.fire(
  //                 'Deleted!',
  //                 'Subject has been deleted.',
  //                 'success'
  //             )

  //         } else if (result.dismiss === Swal.DismissReason.cancel) {
  //             Swal.fire(
  //                 'Cancelled',
  //                 'Delete action cancelled',
  //                 'error'
  //             )
  //         }
  //     })

  // }
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setInputField({ ...inputField, [name]: value });
  };
  const [inputField, setInputField] = useState({
    playlist_name: "",
  });
  const [errors, setErrors] = useState({});

  const formSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true, empty: true });
    let error = {
      playlist_name: requiredValidator(inputField.playlist_name),
    };
    if (!error.playlist_name) {
      dispatch(createPlayList(tokenData.token, body, reset));
      setErrors(error);
    } else {
      setErrors(error);
    }
  };
  const reset = () => {
    document.getElementById("create-form-genaral").reset();
  };
  return (
    <>
      <Form onSubmit={formSubmit} id="create-form-genaral">
        <input type="hidden" value={4} name="category_id" />
        <input type="hidden" value={null} name="grade_id" />
        <input type="hidden" value={null} name="subject_id" />
        <Card>
          <Row className="jms-subject-row-pad">
            <Col md={2}></Col>
            <Col md={5}>
              <FormGroup>
                <Label for="exampleCustomSelectDisabled">
                <span className="text-danger">*</span>
                                {' '}Title</Label>
                <Input
                  invalid={errors.playlist_name}
                  onChange={handleChangeInput}
                  type="text"
                  name="playlist_name"
                />
                <FormFeedback>{errors.playlist_name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label
                  for="exampleCustomSelectDisabled"
                  style={{ visibility: "hidden" }}
                >
                  Subject
                </Label>

                <LaddaButton
                  className="mb-2 mr-2 btn btn-danger"
                  loading={playlistStore.createPlayLisGenaralLoader}
                  // onClick={formSubmit}
                  type="submit"
                  data-style={ZOOM_IN}
                  style={{
                    whiteSpace: "nowrap",
                    marginRight: "0px",
                    width: "-webkit-fill-available",
                  }}
                >
                  Create
                </LaddaButton>
              </FormGroup>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Card>
      </Form>
      <div style={{ paddingTop: 20, fontWeight: 700 }}>{"PLAYLIST"} </div>

      <hr />

      <Row>
        {playlistStore.populatePlaylistGenaralLoader === true ? (
          <Col md={4} style={{ paddingBottom: "10px" }}>
            <Card style={{ padding: "30px" }}>
              <Row>
                <Col md={3}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton circle={true} height={50} width={50} count={1} />
                  </SkeletonTheme>
                </Col>
                <Col md={9}>
                  <SkeletonTheme color="#e9ecef" highlightColor="#f6f6f7">
                    <Skeleton height={20} count={4} />
                  </SkeletonTheme>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : playlistStore.playlistGenaral ? (
          playlistStore.playlistGenaral.map((playlist, index) => {
            return (
              <Col md={4} style={{ paddingBottom: "25px" }}>
                <PlaylistTumnile
                  subject_id={null}
                  to={playlist.playlist_id}
                  category_id={playlist.category_id}
                  title={playlist.playlist_name}
                  // subject={playlist.subject}
                  // grade={playlist.grade}
                  type="genaral"
                  count={playlist.video_count}
                  DeletePlaylists={DeletePlaylists}
                  DeletePlaylists={DeletePlaylists}
                  EditPlaylists={EditPlaylists}
                  editMode={editMode}
                  index={index}
                  openEdit={openEdit}
                  handleChange={handleChange}
                  cancelEdit={cancelEdit}
                />
              </Col>
            );
          })
        ) : null}
      </Row>
    </>
  );
};

export default School;
