import React, { useState, useEffect } from 'react';
import LaddaButton, { ZOOM_IN } from 'react-ladda';
import { Col, Row, Form, FormFeedback, Card, CardHeader, CardBody, Input } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styleph.scss'
import { emailValidator, requiredValidator } from '../../../utilities/Validator'
import { updateEnterprise, listAllEnterprises,editEnterprise, checkEnterpriseNumberExists } from '../../../redux/Actions/superAdmin'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import {useParams}from 'react-router-dom'

function CreateEnterprise() {

    const params = useParams();

    const tokenData = JSON.parse(localStorage.getItem("authDetails"))
    //const Enterprises = useSelector(state => state.superAdmin.Enterprises)
    const EidAvilable = useSelector(state => state.superAdmin.EidAvilable)


    const dispatch = useDispatch()

    const [InputField, setInputField] = useState()
    const [Logo, setLogo] = useState()
    const [Errors, setErrors] = useState({})
    const [Loader, setLoader] = useState(false)

  const [CurrentValue, setCurrentValue] = useState()

    useEffect(() => {
        dispatch(editEnterprise(tokenData.token,params.ent_id,cb2))
    }, [])

    const [EntId, setEntId] = useState()

    const cb2 = (data) => {
        setCurrentValue(data)
        setEntId(data.enterprise_number)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
       // setInputField({ ...InputField, [name]: value })
       setCurrentValue({...CurrentValue, [name]: value}) 
       console.log("input field", InputField)
    }

    const handlechangephone = (e) => {
 
        setCurrentValue({ ...CurrentValue, enterprise_mobile: e }) 

    }

    const handleEnteNumber = (e) => {
        setCurrentValue({...CurrentValue, enterprise_number:e.target.value})
        dispatch(checkEnterpriseNumberExists(tokenData.token, e.target.value))
    }

    const cb = (res) => {
        setLoader(false)
        Swal.fire('Success', res.message, 'success')
      
    }

    const handleSubmit = (e) => {

        const formData = new FormData();
        e.preventDefault()
        const Errors = {
            first_name: requiredValidator(CurrentValue.first_name),
            last_name: requiredValidator(CurrentValue.last_name),
            enterprise_mobile: requiredValidator(CurrentValue.enterprise_mobile),
            enterprise_name: requiredValidator(CurrentValue.enterprise_name),
            enterprise_email: requiredValidator(CurrentValue.enterprise_email),
            enterprise_number: requiredValidator(CurrentValue.enterprise_number),
            //enterprise_password: requiredValidator(CurrentValue.enterprise_password),
           // logo: Logo ? "" : "Required"
        }
        console.log("Errors report", Errors)
        if ( (EidAvilable || EntId === CurrentValue?.enterprise_number ) &&!Errors.first_name && !Errors.last_name && !Errors.enterprise_mobile && !Errors.enterprise_name && !Errors.enterprise_email && !Errors.enterprise_number && !Errors.enterprise_password && !Errors.logo) {

            formData.append("enterprise_id", CurrentValue.enterprise_id);
            formData.append("first_name", CurrentValue.first_name);
            formData.append("last_name", CurrentValue.last_name);
            formData.append("enterprise_mobile", CurrentValue.enterprise_mobile);
            formData.append("enterprise_name", CurrentValue.enterprise_name);
            formData.append("enterprise_email", CurrentValue.enterprise_email);
            formData.append("enterprise_number", CurrentValue.enterprise_number);
            if(Password){
                formData.append("password",Password);
            }
            if(Logo){
                formData.append("enterprise_logo_url", Logo);
            }
            formData.append("enterprise_description", "this field isnt required //hardcoded");
            setLoader(true)
            dispatch(updateEnterprise(tokenData.token, formData, cb))
        }
        setErrors(Errors)

        
    }

    const [Password, setPassword] = useState()
 
    const handlePasswordChange = (e) =>{
      setPassword(e.target.value)
    }
   


    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <Card >
                    <CardHeader>Update Enterprise</CardHeader>
                    <CardBody>
                        <Row className="p-2">
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>First Name</label>
                                <Input name="first_name" onChange={handleChange} invalid={Errors.first_name} value={CurrentValue?.first_name} ></Input>
                                <FormFeedback>{Errors.first_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Last Name</label>
                                <Input value={CurrentValue?.last_name} name="last_name" onChange={handleChange} invalid={Errors.last_name} ></Input>
                                <FormFeedback>{Errors.last_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Mobile</label>
                                {/* <Input name="mobile" onChange={handleChange}></Input> */}
                                <PhoneInput
                                    value={CurrentValue?.enterprise_mobile}
                                    name="number"
                                    // id='number'
                                    country={'in'}
                                    onChange={(e) => handlechangephone(e)}
                                    invalid={Errors.enterprise_mobile}

                                />
                                <FormFeedback>{Errors.enterprise_mobile}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="p-2">
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise Name</label>
                                <Input value={CurrentValue?.enterprise_name} name="enterprise_name" onChange={handleChange} invalid={Errors.enterprise_name} ></Input>
                                <FormFeedback>{Errors.enterprise_name}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise ID</label>
                                <Input value={CurrentValue?.enterprise_number} name="enterprise_number" onChange={handleEnteNumber} invalid={Errors.enterprise_number}  ></Input>
                                <FormFeedback>{Errors.enterprise_number}</FormFeedback> { EntId !== CurrentValue?.enterprise_number ? EidAvilable? <span style={{color:'green'}} >This ID is avilable</span> : <span style={{color:'red'}} >This ID is taken</span>  : ""}
                            </Col>
                            <Col sm={4}>
                                <label><span className="text-danger">*</span>Enterprise Email</label>
                                <Input value={CurrentValue?.enterprise_email} name="enterprise_email" onChange={handleChange} invalid={Errors.enterprise_email}  ></Input>
                                <FormFeedback>{Errors.enterprise_email}</FormFeedback>
                            </Col>
                        </Row>

                        <Row className="p-2">
                            <Col sm={4}>
                                <label>Password</label>
                                <Input  onChange={handlePasswordChange} type='password'   ></Input>
                                <FormFeedback>{Errors.enterprise_password}</FormFeedback>
                            </Col>
                            <Col sm={4}>
                                <label>Logo</label>
                                <Input  onChange={(e) => setLogo(e.target.files[0])} type='file'></Input>
                                <span style={{ color: 'red' }}>{Errors.logo} </span>
                            </Col>
                        </Row>



                        <Row className="p-2" style={{ textAlign: 'right' }}>
                            <Col sm={9}>{" "}</Col>
                            <Col sm={3}>
                                <LaddaButton className="m-3 btn btn-primary"
                                    loading={Loader}
                                    type="submit"
                                    data-style={ZOOM_IN}  >
                                    Update Enterprise
                                </LaddaButton>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </Form>


        </div>
    )
}

export default CreateEnterprise
