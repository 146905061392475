import React, { Fragment, useState, useEffect } from 'react'
// import { Fab, Tooltip } from '@material-ui/core';
import { Col, Row, Card, CardHeader, CardBody, TabPane, TabContent, Button, Label, CardFooter, InputGroup, InputGroupAddon, Input, } from "reactstrap"
// import classnames from 'classnames';
// import ChatPeople from "./PeopleList"
import { Chip } from '@material-ui/core';
//import { createGroup } from "../../../redux/Actions/new-action"
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import LaddaButton, { ZOOM_IN } from "react-ladda";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, TextField } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { listAllGroupStudents, getMyStudents, addStudentToGroup } from '../../redux/Actions/homeTeacher'
import { useParams } from 'react-router-dom'
import { Checkbox } from '@material-ui/core';
import {
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AssignStudents() {

    const dispatch = useDispatch();
    const params = useParams()
    const tokenData = JSON.parse(localStorage.getItem("authDetails"));

    useEffect(() => {
        dispatch(listAllGroupStudents(tokenData.token, params.group_id, cb))
        // dispatch(getMyStudents(tokenData.token))
    }, [])

    const itemstyle = { display: 'block', width: '100%', padding: '0.4rem 1.5rem', clear: 'both', fontWeight: '400', color: '#212529', textAlign: 'inherit', whiteSpace: 'nowrap', backgroundColor: 'transparent', border: '0',  }
    const tStore = useSelector(({ homeTeacher }) => homeTeacher)

    const [UnassignedCopy, setUnassignedCopy] = useState([])

    const cb = (data) => {
        setAssignedStudents(data.assignedStudents)
        setAlreadyAssigned(data.assignedStudents)
        setUnassignedStudents(data.unAssignedStudents)
        setUnassignedCopy(data.unAssignedStudents)
    }

    const SearchTeacher = (e) => {
        //   setTeacherName(e.target.value)
        if (e.target.value) {
            let arr = [...UnassignedStudents]
            let filter = arr.filter(i => i.full_name.toUpperCase().includes(e.target.value.toUpperCase()))
            //dispatch(setNewActionData('teachers_list', filter))
            setUnassignedStudents(filter)
        }
        else {
            // dispatch(setNewActionData('teachers_list', teacher_data_copy))
            setUnassignedStudents(UnassignedCopy)
        }

    }


    const [AssignedStudents, setAssignedStudents] = useState([])
    const [UnassignedStudents, setUnassignedStudents] = useState([])
    const [AlreadyAssigned, setAlreadyAssigned] = useState([])

    const [DeletedIds, setDeletedIds] = useState([])
    console.log("deleted student", DeletedIds)
    const handleDelete = (val) => {
        // let arr = [...AssignedStudents]
        // let filtered = arr.filter(id => id !== val.receiver_id)
        // setSelected(filtered)

        if (AlreadyAssigned.includes(val)) {
            let arr = DeletedIds
            arr.push(val.student_id)
            setDeletedIds(arr)

            let arr2 = UnassignedStudents
            arr2.push(val)
            setUnassignedStudents(arr2)
        }
        let arr2 = [...AssignedStudents]
        let filtered2 = arr2.filter(i => i.student_id !== val.student_id)
        setAssignedStudents(filtered2)
    }

    const successcb = () => {
        // alert("called from suc")

        dispatch(listAllGroupStudents(tokenData.token, params.group_id, cb));
        setDeletedIds([]);
    }

    const handleSubmit = () => {
        let assigned = []
        AssignedStudents.map((student) => {
            assigned.push(student.student_id)
        })
        // console.log("logging all the ids assigned",assigned)
        // console.log("logging all the ids unassigned",DeletedIds)
        dispatch(addStudentToGroup(tokenData.token, assigned, DeletedIds, params.group_id, successcb))
    }

    const SelectStudent = (val) => {
        console.log("value", val, AssignedStudents)
        if (AssignedStudents.includes(val)) {
            // let arr = [...AssignedStudents]
            // let filtered = arr.filter(id => id !== val.student_id)
            // setSelected(filtered)

            let arr2 = [...AssignedStudents]
            let filtered2 = arr2.filter(i => i.student_id !== val.student_id)
            //setSelectedFull(filtered2)
            setAssignedStudents(filtered2)
        }
        else {
            // setSelected([...selected, val.receiver_id])
            setAssignedStudents([...AssignedStudents, val])
        }
    }

    return (
        <div>
            <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ border: '1px solid #fff', textAlign: 'center', width: '30px', marginBottom: '5px' }}>
                        {/* <ArrowBackIcon onClick={CloseGroup} style={{ cursor: 'pointer' }} /> */}
                    </div>
                    <span>add to group</span>
                    <div></div>
                </CardHeader>
                <CardBody style={{ padding: '0px 30px' }}>
                    <Row>


                        {
                            AssignedStudents.length > 0 ?
                                <Col xs={12} className='mt-3'>
                                    <Label>
                                        Members
                                    </Label>

                                    <div style={{ width: '100%', minHeight: '100px', border: '1px solid #777', borderRadius: '8px', padding: '5px', boxSizing: 'border-box', wordWrap: 'break-word' }}>
                                        {
                                            AssignedStudents.map((val, ky) => {
                                                return (
                                                    <Chip
                                                        key={ky}
                                                        label={val.full_name}
                                                        onDelete={() => { handleDelete(val) }}
                                                        color='secondary'
                                                        style={{ marginLeft: '3px', marginTop: '3px' }}
                                                        size='small'
                                                    />
                                                )
                                            })
                                        }


                                    </div>
                                </Col>
                                : ''

                        }
                        <Col xs={12} >
                            <InputGroup className='my-4' >
                                <InputGroupAddon addonType="prepend">
                                    <div className="input-group-text">
                                        <FontAwesomeIcon icon={faSearch} />
                                    </div>
                                </InputGroupAddon>
                                <Input
                                    placeholder="Search..."
                                    onChange={SearchTeacher}
                                // value={search_value}
                                />
                            </InputGroup>
                        </Col>
                        {
                            UnassignedStudents.map((val, ky) => {
                                return (
                                    <div key={ky}>
                                        <div style={itemstyle}>
                                            <div className="widget-content p-0" >
                                                <div className="widget-content-wrapper" >
                                                    <div className="widget-content-left mr-3">
                                                        <div className="avatar-icon-wrapper">
                                                            <Checkbox
                                                                checked={AssignedStudents.includes(val)}
                                                                color='primary'
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                onChange={() => { SelectStudent(val) }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading">
                                                            {val.full_name}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        }

                    </Row>
                </CardBody>
                <CardFooter className='d-flex justify-content-end' >
                    <LaddaButton
                        className="mb-2 mr-2 btn btn-primary"
                        onClick={handleSubmit}
                        // loading={groupStore.createGroupLoader}
                        //type="submit"
                        data-style={ZOOM_IN}
                    >Add To Group</LaddaButton>
                </CardFooter>
            </Card>

        </div>
    )
}

export default AssignStudents
