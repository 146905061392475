import axios from 'axios'
import { development } from '../../config.json'
import Swal from 'sweetalert2'
// import moment from 'moment'






export const updateAssignment = (token, details, cb) => (dispatch) => {
    const data = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    dispatch({ type: 'UPDATE_ASSIGNMENT_REQUEST' })
    axios.post(`${development.baseUrl}updateAssignment`, details, { headers: data })
        .then(res => {
            if (res.data.result) {
                dispatch({ type: 'UPDATE_ASSIGNMENT_SUCCESS', payload: res.data.assignments })
                Swal.fire({
                    icon: 'success',
                    title: 'Updated',
                    text: 'Assignment updated successfully',
                })
                cb()
            }
        })
        .catch(err => {
            dispatch({ type: 'UPDATE_ASSIGNMENTS_FAILED', payload: err })
        })
}



export const removeAssignment = (token, assignment_id) =>

    (dispatch) => {
        const data = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`

        }
        const details = { assignment_id: assignment_id }
        dispatch({ type: 'DELETE_ASSIGNMENT_REQUEST' })
        //console.log(`${development.baseUrl}populatUserClasses`, data);
        axios.post(`${development.baseUrl}deleteAssignment`, details, { headers: data })
            .then(res => {
                if (res.data.result) {
                    dispatch({ type: 'DELETE_ASSIGNMENT_SUCCESS', payload: assignment_id })

                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted',
                        text: 'Assignment deleted successfully',

                    })
                }

                // window.location.reload(false);
                // dispatch({ type: 'POPULATE_ASSIGNMENTS', payload: res.data })
                // console.log("assignment action", res.data.assignments)
            })
            .catch(err => {
                dispatch({ type: 'DELETE_ASSIGNMENTS_FAILED', payload: err })
            })
    }

export const addAssignment = (token, data, cb) =>

    (dispatch) => {

        // const head = {
        //     Accept: 'application/json',
        //     Authorization: `Bearer ${token}`,
        //     'content-type': 'multipart/form-data'

        // }
        // const options = {
        //     onUploadProgress: (progressEvent) => {
        //         const { loaded, total } = progressEvent;
        //         let percent = Math.floor((loaded * 100) / total)
        //         console.log(`${loaded}kb of ${total}kb | ${percent}%`);

        //         // if (percent < 100) {
        //         //     this.setState({ uploadPercentage: percent })
        //         // }
        //     }
        // }
        const options = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
                'content-type': 'multipart/form-data'

            },
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                let percent = Math.floor((loaded * 100) / total)
                console.log(`${loaded}kb of ${total}kb | ${percent}%`);
                // debugger

                if (data.has('assignment[]')) {
                    dispatch({ type: 'UPLOAD_PROGRESS_BAR', payload: percent })
                }
                // if (percent < 100) {
                //     this.setState({ uploadPercentage: percent })
                // }
            }
        };
        // console.log(`head ${head.Authorization} data ${data.class_name}`)
        dispatch({ type: 'UPLOAD_ASSIGNMENT_REQUEST' })
        axios.post(`${development.baseUrl}uploadAssignments`, data, options)

            .then(res => {
                debugger
                console.log("created Assignment", res.data)
                if (res.data.result) {
                    cb()//to open success popup
                    dispatch({ type: 'UPLOAD_ASSIGNMENT_SUCCESS', payload: res.data })

                } else {
                    dispatch({ type: 'UPLOAD_ASSIGNMENT_FAILED' })

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: ' UPLOAD_ASSIGNMENT_FAILED', payload: err })
            })
    }

export const getAssignments = (token) =>

    (dispatch) => {
        const data = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`

        }

        dispatch({ type: 'POPULATE_ASSIGNMENT_REQUEST' })
        //console.log(`${development.baseUrl}populatUserClasses`, data);
        axios.get(`${development.baseUrl}getRecentStudentAssignemnt`, { headers: data })
            .then(res => {
                dispatch({ type: 'POPULATE_ASSIGNMENTS', payload: res.data })
                console.log("assignment action", res.data.assignments)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ASSIGNMENTS_FAILED', payload: err })
            })
    }

export const getTrialDays = (token) =>

    (dispatch) => {
        const data = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`

        }


        //console.log(`${development.baseUrl}populatUserClasses`, data);
        axios.get(`${development.baseUrl}getTrialDays`, { headers: data })
            .then(res => {
                if (res.data.result) {
                    dispatch({ type: 'GET_TRIAL_DAYS', payload: res.data.details.days })
                    console.log("assignment action", res.data.details.days)
                }
            })
            .catch(err => {
                dispatch({ type: 'GET_TRIAL_DAYS_FAILED', payload: err })
            })
    }


export const getStudentAssignments = (token) =>

    (dispatch) => {
        const data = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`

        }

        dispatch({ type: 'POPULATE_ASSIGNMENT_REQUEST' })
        //console.log(`${development.baseUrl}populatUserClasses`, data);
        axios.get(`${development.baseUrl}getStudentAssignments`, { headers: data })
            .then(res => {
                dispatch({ type: 'POPULATE_STUDENT_ASSIGNMENTS', payload: res.data })
                console.log("assignment action", res.data.assignments)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ASSIGNMENTS_STUDSNT_FAILED', payload: err })
            })
    }

export const populateStudentAssignment = (token, assignment_id, class_id, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }

        const body = {
            assignment_id: assignment_id,
            class_id: class_id,
            user: "teacher"
        }
        // dispatch({ type: 'POPULATE_STUDENTS', payload: body })
        axios.post(`${development.baseUrl}populateStudentAssignments`, body, { headers: head })
            .then(res => {

                console.log("class leave rs---", res)
                if (res.data.result) {
                    dispatch({ type: 'ASSIGNMENTS_POPULATE_STUDENTS', payload: res.data.assignments })

                }
            })
            .catch(err => {
                dispatch({ type: 'ASSIGNMENTS_POPULATE_STUDENTS_FAILED', payload: err })
            })
    }



export const addAssignmentResponse = (token, data, cb) => (dispatch) => {
    console.log("data", data)
    const options = {
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'content-type': 'multipart/form-data'

        },
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            dispatch({ type: 'UPLOAD_PROGRESS_BAR', payload: percent })
            // if (percent < 100) {
            //     this.setState({ uploadPercentage: percent })
            // }
        }
    };

    //  console.log(`head ${head.Authorization} data ${data.class_name}`)
    dispatch({ type: 'UPLOAD_ASSIGNMENT_STUDENT_REQUEST' })
    axios.post(`${development.baseUrl}uploadAssignments`, data, options)

        .then(res => {
            console.log("created Assignment", res.data)
            if (res.data.result) {
                dispatch({ type: 'UPLOAD_ASSIGNMENT_STUDENT_SUCCESS', payload: res.data, })

                cb()//to open success popup


            } else {
                dispatch({ type: 'UPLOAD_ASSIGNMENT_STUDENT_FAILED' })

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'UPLOAD_ASSIGNMENT_STUDENT_FAILED', payload: err })
        })
}

export const downloadAssignment = (token, assignment_id, class_id, cb) =>
    (dispatch) => {
        const data = {
            // headers: {'Accept': 'application/json'},
            //   headers: { "Content-Disposition": "attachment"},
            //    headers: {"Content-Type": "application/octet-stream"},


            headers: { 'Authorization': `Bearer ${token}` },

        }

        dispatch({ type: 'POPULATE_DOWNLOAD_REQUEST' })
        // console.log(`${development.baseUrl}downloadAssignment`);
        axios.post(`${development.baseUrl}downloadMyAssignment`, {
            assignment_id: assignment_id,
            class_id: class_id,
        }, data)

            .then(res => {

                if (res.data.result) {


                    var link = document.createElement("a");
                    link.href = res.data.download_url;

                    link.click();
                    link.remove();

                    //  cb();
                }
                else if (!res.data.result) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_DOWNLOAD_FAILED', payload: err })
            })
    }


export const downloadStudentAssignments = (token, assignment_id, class_id, student_id, cb) =>

    (dispatch) => {

        const data = {
            // headers: {'Accept': 'application/json'},

            //    headers: {'Content-Type': 'multipart/form-data'},

            // headers:{'responseType': 'arraybuffer'},
            headers: { 'Authorization': `Bearer ${token}` },

        }

        dispatch({ type: 'POPULATE_DOWNLOAD_REQUEST' })
        console.log(`downloadAssignment`);
        axios.post(`${development.baseUrl}downloadStudentAssignment`, {
            assignment_id: assignment_id,
            class_id: class_id,
            student_id: student_id,
        }, data)

            .then(res => {
                if (res.data.result) {


                    var link = document.createElement("a");
                    link.href = res.data.download_url;

                    link.click();
                    link.remove();

                    cb();
                }
                else if (!res.data.result) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_DOWNLOAD_FAILED', payload: err })
            })
    }

export const downloadTeacherAssignments = (token, assignment_id, class_id, student_id, cb) =>

    (dispatch) => {

        const data = {

            headers: { 'Authorization': `Bearer ${token}` },

        }

        dispatch({ type: 'POPULATE_DOWNLOAD_REQUEST' })
        console.log(`${development.baseUrl}downloadAssignment`);
        axios.post(`${development.baseUrl}downloadTeacherAssignment`, { assignment_id: assignment_id, class_id: class_id, student_id: student_id }, data)

            .then(res => {




                // res.blob().then(blob => {
                //     let url = window.URL.createObjectURL(blob);
                //     let a = document.createElement('a');
                //     a.href = url;
                //     a.download = 'employees.json';
                //     a.click();
                // });


                if (res.data.result) {
                    dispatch({ type: 'POPULATE_DOWNLOAD_SUCCESS' })


                    var link = document.createElement("a");
                    link.href = res.data.download_url;

                    link.click();
                    link.remove();

                    // cb();
                }
                else if (!res.data.result) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_DOWNLOAD_FAILED', payload: err })
            })
    }

export const getStudentAssignmentRemark = (token, assignment_id, student_id, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }

        const body = {
            assignment_id: assignment_id,
            student_id: student_id,
            user: "teacher"
        }
        // dispatch({ type: 'POPULATE_STUDENTS', payload: body })
        axios.post(`${development.baseUrl}getStudentAssignmentRemark`, body, { headers: head })
            .then(res => {

                console.log("class leave rs---", res)
                if (res.data.result) {
                    dispatch({ type: 'STUDENT_ASSIGNMENT_RESPONSE', payload: res.data.details.remark })
                    cb();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: 'No Remark Submited',

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_ASSIGNMENT_RESPONSE_ERROR', payload: err })
            })
    }


export const getAssignmnetMark = (token, assignment_id, student_id, cb, cb2) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }

        const body = {
            assignment_id: assignment_id,
            student_id: student_id,

        }
        dispatch({ type: 'GET_ASSIGNMENT_MARK_REQUEST' })
        axios.post(`${development.baseUrl}getAssignmentMark`, body, { headers: head })
            .then(res => {


                if (res.data.result) {
                    console.log("class leave rs---", res)
                    dispatch({ type: 'GET_ASSIGNMENT_MARK_RESPONSE', payload: res.data })
                    cb();
                    cb2(res.data.details[0], body)
                }
            })
            .catch(err => {
                dispatch({ type: 'STUDENT_ASSIGNMENT_RESPONSE_ERROR', payload: err })
            })
    }


export const submitAssignmnetMark = (token, body, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }

        // const body = {
        //     assignment_id: assignment_id,
        //     student_id: student_id,
        //     mark: mark,
        //     comment: remark
        // }
        // dispatch({ type: 'POPULATE_STUDENTS', payload: body })
        axios.post(`${development.baseUrl}submitAssignmentMark`, body, { headers: head })
            .then(res => {

                console.log("class leave rs---", res)
                if (res.data.result) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Done',
                        text: res.data.message,

                    })
                    dispatch({ type: 'SUBMIT_ASSIGNMENT_MARK_SUCCESS', payload: res.data })
                    cb()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: res.data.message,

                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'GET_ASSIGNMENT__MARK_RESPONSE_ERROR', payload: err })
            })
    }




export const getAssignmentDescription = (token, assignmentId, cb) =>
    (dispatch) => {
        const head = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        }

        const body = {
            assignment_id: assignmentId,

        }
        dispatch({ type: 'GET_ASSIGNMENT_DESCRIPTION_REQUEST' })
        axios.post(`${development.baseUrl}getAssignmentDescription`, body, { headers: head })
            .then(res => {
                console.log("class leave rs---", res)
                if (res.data.result) {
                    dispatch({ type: 'GET_ASSIGNMENT_DESCRIPTION_SUCCESS', payload: res.data.details[0] })
                    cb(res.data.details[0])
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '',
                        text: res.data.message,
                    })
                }
            })
            .catch(err => {
                dispatch({ type: 'GET_ASSIGNMENT_DESCRIPTION_FAILED', payload: err })
            })
    }



export const getAssignmentData = (token, assignmentId, classId) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const body = {
        assignment_id: assignmentId,
        class_id: classId,


    }
    dispatch({ type: 'GET_ASSIGNMENT_DATA_REQUEST' })

    axios.post(`${development.baseUrl}getAssignmentData`, body, { headers: head })
        .then(res => {
            // debugger
            console.log("class leave rs---", res)
            if (res.data.result) {
                dispatch({ type: 'GET_ASSIGNMENT_DATA_SUCCESS', payload: res.data.assignmentDetails })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'GET_ASSIGNMENT_DATA_FAILED' })
        })
}



export const getStudentAssignmentData = (token, assignmentId, classId, studentId, assesment_id) => (dispatch) => {
    const head = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
    }
    const body = {
        assignment_id: assignmentId,
        class_id: classId,
        student_id: studentId,
        aa_id: assesment_id
    }
    dispatch({ type: 'GET_STUDENT_ASSIGNMENT_DATA_REQUEST' })

    axios.post(`${development.baseUrl}getStudentAssignmentData`, body, { headers: head })
        .then(res => {
            console.log("class leave rs---", res)
            if (res.data.result) {
                dispatch({ type: 'GET_STUDENT_ASSIGNMENT_DATA_SUCCESS', payload: res.data.assignmentDetails })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: res.data.message,

                })
            }
        })
        .catch(err => {
            dispatch({ type: 'GET_STUDENT_ASSIGNMENT_DATA_FAILED' })
        })
}




export const getAssignmentsData = (token) =>

    (dispatch) => {
        const data = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`

        }

        dispatch({ type: 'POPULATE_ALL_ASSIGNMENT_REQUEST' })
        //console.log(`${development.baseUrl}populatUserClasses`, data);
        axios.get(`${development.baseUrl}getTeacherAssignments`, { headers: data })
            .then(res => {
                dispatch({ type: 'POPULATE_ALL_ASSIGNMENT_SUCCESS', payload: res.data })
                console.log("assignment action", res.data.assignments)
            })
            .catch(err => {
                dispatch({ type: 'POPULATE_ALL_ASSIGNMENT_FAILED', payload: err })
            })
    }
